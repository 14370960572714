import {
  ExternalPerson,
  Supplier,
  UpdateExternalPeopleInfoMutation,
  UpdateExternalPeopleInfoMutationVariables
} from 'generated/graphql'
import { Button, Dialog, DialogContent, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import * as yup from 'yup'
import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { Formik } from 'formik'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { SupplierSelector } from 'components/common/SupplierSelector'
import { PHONE_NUMBER_REGEX, emailValidator } from 'utils/tools'
import { REQUIRED } from 'utils/Constants'
import { SelectorObjectField } from 'components/common/SelectorObjectField'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
interface EditUserModalProps {
  onCloseModal: () => void
  selectedItem?: ExternalPerson | null
  onRefetch?: () => void
}

export const UPDATE_EXTERNAL_PERSON = loader('../../../graphql/common/updateExternalPeopleInfo.graphql')

const personSchema = yup.object({
  Email: yup
    .string()
    .label('Email')
    .nullable()
    .required()
    .test('is-email-valid', 'Email address is not valid', emailValidator)
    .max(255, 'Email maximum 255 characters long'),
  Phone: yup
    .string()
    .min(0, 'Minimal value 0')
    .max(30, 'Maximum value 30')
    .matches(PHONE_NUMBER_REGEX, {
      message: 'Phone number is not valid',
      excludeEmptyString: true
    })
    .trim(),
  Supplier: yup
    .object()
    .nullable()
    .required(`Supplier ${REQUIRED}`)
})

interface FormValues {
  PersonID: string
  Email: string
  Phone: string
  Supplier: Supplier | null
}

const useStyles = makeStyles(() => ({
  spaceBottom: {
    paddingBottom: '2rem'
  },
  spaceHorizontal: {
    marginLeft: '2rem',
    marginRight: '2rem'
  },
  spaceBottomRow: {
    marginRight: '1rem',
    marginBottom: '2rem'
  },
  spaceRight: {
    marginRight: '2rem'
  },
  textCenter: {
    paddingTop: '2rem',
    paddingBottom: '2rem'
  },
  bottomActions: {
    marginRight: '1rem',
    marginBottom: '2rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
}))

export const EditUserModal: FC<EditUserModalProps> = ({ onCloseModal, selectedItem, onRefetch }) => {
  const classes = useStyles()
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext()

  const initialValues: FormValues = useMemo(
    () => ({
      PersonID: selectedItem?.person.ID || '',
      Email: selectedItem?.person?.Email || '',
      Phone: selectedItem?.person?.Phone || '',
      Supplier: selectedItem?.supplier || null
    }),
    [selectedItem]
  )

  const [updateExternalPerson, { loading: isSubmitting }] = useMutation<
    UpdateExternalPeopleInfoMutation,
    UpdateExternalPeopleInfoMutationVariables
  >(UPDATE_EXTERNAL_PERSON)

  const handleSubmit = ({ PersonID, Email, Phone, Supplier }: FormValues) => {
    if (PersonID) {
      updateExternalPerson({
        variables: {
          input: {
            ID: selectedItem?.ID || '',
            Email,
            Phone,
            SupplierID: Supplier?.ID || ''
          }
        }
      })
        .then(({ data }) => {
          if (data?.updateExternalPeopleInfo) {
            onRefetch && onRefetch()
            onCloseModal()
            setSuccessMessage('User has been updated successfully')
          }
        })
        .catch(error => setErrorMessage(error.message))
    }
  }

  return (
    <>
      <Dialog onClose={onCloseModal} open={!!selectedItem} maxWidth="md" fullWidth>
        <DialogContent>
          <Typography
            id="max-width-dialog-title"
            variant="h5"
            className={classes.spaceBottom}
          >{`${selectedItem?.person?.Firstname} ${selectedItem?.person?.Lastname}`}</Typography>

          <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={personSchema}>
            {props => {
              const { values, errors, handleBlur, handleChange, touched, handleSubmit } = props

              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item xs={6} sm={6}>
                      <GridLayoutInput
                        fullWidth
                        id="email"
                        label="Email*"
                        name="Email"
                        value={values.Email}
                        onBlur={handleBlur}
                        textFieldBreakpoints={{ xs: 12 }}
                        onChange={handleChange}
                        error={!!errors.Email && !!touched.Email}
                        helperText={touched.Email ? errors.Email : null}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <GridLayoutInput
                        fullWidth
                        type="string"
                        id="phone"
                        label="Mobile"
                        name="Phone"
                        textFieldBreakpoints={{ xs: 12 }}
                        value={values.Phone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!errors.Phone && !!touched.Phone}
                        helperText={touched.Phone ? errors.Phone : null}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <SelectorObjectField
                        selector={SupplierSelector}
                        name="Supplier"
                        label="Supplier*"
                        error={!!errors.Supplier && !!touched.Supplier}
                        helperText={touched.Supplier ? errors.Supplier : null}
                      />
                    </Grid>
                    <Grid item className={classes.bottomActions}>
                      <Button variant="contained" className={classes.spaceRight} onClick={onCloseModal}>
                        Close
                      </Button>
                      <Button color="secondary" variant="contained" type="submit" disabled={isSubmitting}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}
