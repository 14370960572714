import React from 'react'
import { Switch, withRouter, Route, Redirect } from 'react-router-dom'
import { Container } from './common/Container'
import { Publication } from '../pages/Publication/Publication'
import { Users } from '../pages/Users/Users'
import { MyProfile } from '../pages/MyProfile/MyProfile'
import { PrivateRoute } from './PrivateRoute'
import Modules from 'pages/Modules/Modules'
import { ManageAudit } from '../pages/Audit/Audit'
import { SystemConfigurations } from '../pages/SystemConfigurations/SystemConfigurations'
import FailedEvents from 'pages/FailedEvents/FailedEvents'
import Payroll from 'pages/Payroll/Payroll'
import { ManagePolicies } from 'pages/ManagePolicies/ManagePolicies'
import { ManagePrintingTemplates } from 'pages/ManagePrintingTemplates/ManagePrintingTemplates'
import { ManageMobileVersions } from 'pages/ManageMobileVersions/ManageMobileVersions'
import { ManageProjects } from 'pages/ManageProjects'
import { Privileges } from 'utils/rolesAndPrivileges'
export const Content = withRouter(() => (
  <Container>
    <Switch>
      <Route path="/my-profile" component={MyProfile} />
      <PrivateRoute permission={Privileges.ManageCommunications} path="/manage-comms" component={Publication} />
      <PrivateRoute permission={Privileges.ManageUsers} path="/manage-users" component={Users} />
      <PrivateRoute
        permission={[Privileges.ManageModule, Privileges.ManageIntegrations]}
        path="/manage-modules"
        component={Modules}
      />
      <PrivateRoute
        permission={[Privileges.ManageProjectLogo, Privileges.ManageIntegrations]}
        path="/manage-projects"
        component={ManageProjects}
      />
      <PrivateRoute permission={Privileges.ManageAudit} path="/manage-audit" component={ManageAudit} />
      <PrivateRoute
        permission={Privileges.ManageAudit}
        path="/system-configurations"
        component={SystemConfigurations}
      />
      <PrivateRoute permission={Privileges.ManageAudit} path="/manage-failed-events" component={FailedEvents} />
      <PrivateRoute permission={Privileges.ViewPayrollSettings} path="/payroll" component={Payroll} />
      <PrivateRoute permission={Privileges.ManagePrivacy} path="/manage-policies" component={ManagePolicies} />
      <PrivateRoute
        permission={Privileges.ManagePrintingTemplate}
        path="/manage-printing-templates"
        component={ManagePrintingTemplates}
      />
      <PrivateRoute
        permission={Privileges.ManageMobileVersions}
        path="/manage-mobile-versions"
        component={ManageMobileVersions}
      />
      <Redirect to="/manage-comms" />
    </Switch>
  </Container>
))
