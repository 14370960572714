import { BaseTextFieldProps, Chip, Grid, GridSize, TextField, TextFieldProps } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { Autocomplete } from '@material-ui/lab'
import { Field } from 'formik'
import { fieldToTextField } from 'formik-material-ui'
import React, { ChangeEvent } from 'react'

export interface RenderTagsProps {
  variant?: 'default' | 'outlined'
  disabled?: boolean
  icon?: Element
  label?: string
  size?: 'medium' | 'small'
  color?: 'default' | 'primary' | 'secondary'
  clickable?: boolean
  avatar?: Element
}

export interface GridLayoutAutoCompleteTagsInputProps {
  disabled?: boolean
  name: string
  options: string[] | []
  textFieldProps?: BaseTextFieldProps
  renderTagsProps?: RenderTagsProps
  multiple?: boolean
  textFieldBreakpoints?: Partial<Record<Breakpoint, boolean | GridSize>>
  autoComplete?: string
  onChange?: (e: any) => void
  error?: TextFieldProps['error']
  helperText?: TextFieldProps['helperText']
}

const FormikAutocomplete = ({
  textFieldProps,
  renderTagsProps,
  options,
  autoComplete = 'off',
  error,
  helperText,
  ...props
}: any) => {
  const {
    form: { setTouched, setFieldValue }
  } = props
  const { name, ...field } = fieldToTextField(props)

  return (
    <Autocomplete
      {...props}
      {...field}
      name={name}
      options={options}
      autoComplete={autoComplete}
      freeSolo
      renderTags={(value: string[], getTagProps) =>
        value
          ?.filter(item => item !== '')
          ?.map((option, index: number) => (
            <Chip {...renderTagsProps} key={index} label={option} {...getTagProps({ index })} />
          ))
      }
      onChange={(_: ChangeEvent<NonNullable<any>>, value: string[]) => {
        const listValue = value?.map(item => item.trim())?.filter(item => item !== '')
        if (listValue) {
          const value = listValue
            .map(item => {
              if (item.includes(',')) {
                return item.split(', ')
              }
              return item
            })
            .flatMap(item => item)
          setFieldValue(name, value)
        }
      }}
      onBlur={() => setTouched({ [name || '']: true })}
      renderInput={props => <TextField {...props} {...textFieldProps} helperText={helperText} error={error} />}
      error={error}
      helperText={helperText}
    />
  )
}

export const GridLayoutAutoCompleteTagsInput = ({
  disabled,
  name,
  options,
  textFieldProps,
  renderTagsProps,
  multiple,
  textFieldBreakpoints,
  autoComplete,
  onChange,
  error,
  helperText
}: GridLayoutAutoCompleteTagsInputProps) => (
  <Grid item {...textFieldBreakpoints}>
    <Field
      disabled={disabled}
      name={name}
      component={FormikAutocomplete}
      options={options}
      textFieldProps={textFieldProps}
      renderTagsProps={renderTagsProps}
      multiple={multiple}
      autoComplete={autoComplete}
      onChange={onChange}
      error={error}
      helperText={helperText}
    />
  </Grid>
)
