import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Content } from './Content'
import { Footer } from './Footer'
import { SideBar } from './SideBar'
import { TopBar } from './TopBar'
import { Banner } from './Banner'

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flex: 1
  },
  screen: {
    display: 'flex',
    flex: 1,
    height: '100vh',
    flexDirection: 'column'
  }
})

interface MainProps {
  signOut: () => void
}

export const Main = (props: MainProps) => {
  const classes = useStyles()
  return (
    <div className={classes.main} data-testid="app-main">
      <SideBar />
      <div className={classes.screen}>
        <Banner />
        <TopBar {...props} />
        <Content />
        <Footer />
      </div>
    </div>
  )
}
