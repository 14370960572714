import { Box, DialogContentText, FormControlLabel, Grid, Radio, TablePagination } from '@material-ui/core'
import { Table } from 'components/common/Table'
import React, { useState, useEffect, useMemo } from 'react'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import {
  Person,
  GetPagingPersonQuery,
  GetPagingPersonQueryVariables,
  ImpersonateUserQuery,
  ImpersonateUserQueryVariables,
  Privilege
} from 'generated/graphql'
import { SecurityIcon, ListAltIcon, EditIcon, PersonIcon } from 'components/common/icons'
import { UserModal } from './UserModal'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import { EditUserModal } from './EditUserModal'
import { RoleAccessModal } from '../RoleAccess/RoleAccessModal'
import { customFilterAndSearchColumn } from 'utils/tools'
import { GET_PAGING_PERSONS, IMPERSONATE_USER } from 'graphql/common'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { flattenDeep } from 'lodash'
import { useUserInfo } from 'hooks/useUserInfo'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import { BriefPortal, Portal } from 'utils/Constants'
import { FormRadioGroup } from 'components/common/FormRadioGroup'
interface Sorter {
  field: string
  orderBy: string | 'ASC'
}

export const Internal = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [selectedItem, setSelectedItem] = useState<Person | null>(null)
  const [selectedRoleItem, setSelectedRoleItem] = useState<Person | null>(null)
  const [selectedEditUserItem, setSelectedEditUserItem] = useState<Person | null>(null)
  const [selectedImpersonateUser, setSelectedImpersonateUser] = useState<Person | null>(null)
  const [filter, setFilter] = useState<string | null>(null)
  const [sorter, setSorter] = useState<Sorter | null>(null)
  const [autoHideFeedback, setAutoHideFeedback] = useState<Feedback | null>(null)
  const [portal, setPortal] = useState(Portal.LinkedSite)

  const queryHookOptions = {
    variables: {
      filter: filter || '',
      IsInternalPerson: true,
      sorter: sorter,
      pagination: {
        pageSize: currentPageSize,
        pageNumber: currentPage
      }
    }
  }

  const { loading, data, refetch: refetchPersonList } = useQuery<GetPagingPersonQuery, GetPagingPersonQueryVariables>(
    GET_PAGING_PERSONS,
    queryHookOptions
  )

  const [impersonateUser] = useLazyQuery<ImpersonateUserQuery, ImpersonateUserQueryVariables>(IMPERSONATE_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setAutoHideFeedback(getSuccessFeedback('Impersonate User has been updated successfully.'))
      window.open(
        `${data?.impersonateUser?.RedirectUrl}?impersonateUser=${encodeURIComponent(
          JSON.stringify(pick(data.impersonateUser, ['IdToken', 'AccessToken', 'RefreshToken', 'ExpiresIn']))
        )}`,
        '_blank'
      )
    },
    onError: error => {
      setAutoHideFeedback(getErrorFeedback(error.message))
    }
  })

  const { personInfoData } = useUserInfo()

  const privileges: Privilege[] = useMemo(
    () => personInfoData?.personInfo?.roles?.map((e: { privileges: Privilege[] }) => e.privileges),
    [personInfoData]
  )

  const canImpersonateUser = useMemo(() => {
    return !!flattenDeep(privileges)?.find((arr: { Name: string }) => arr.Name === 'CanImpersonate')
  }, [privileges])

  const onImpersonateUser = () => {
    if (selectedImpersonateUser) {
      impersonateUser({
        variables: {
          PersonID: selectedImpersonateUser?.ID,
          Source: portal === Portal.LinkedSite ? BriefPortal.LinkedSite : BriefPortal.LinkedOffice
        }
      })
    }
    setPortal(Portal.LinkedSite)
  }

  const onCloseModal = () => {
    setSelectedItem(null)
  }

  const onCloseModalManageRole = () => {
    setSelectedRoleItem(null)
  }

  const onCloseModalEditUser = () => {
    setSelectedEditUserItem(null)
  }

  const columns = [
    {
      title: 'Name',
      field: 'Firstname',
      render: (row: Person) => `${row.Firstname} ${row.Lastname}`,
      customFilterAndSearch: (filter: string, rowData: Person) =>
        customFilterAndSearchColumn(filter, rowData, (row: Person) => `${row.Firstname} ${row.Lastname}`)
    },
    {
      title: 'Company',
      field: 'internalPerson.company.Name',
      render: (row: Person) => row?.internalPerson?.company?.Name
    },
    {
      title: 'Email',
      field: 'Email'
    },
    {
      title: 'Mobile',
      field: 'Phone'
    },
    {
      title: 'GeotrakID',
      field: 'internalPerson.GeotrakID',
      render: (row: Person) => row?.internalPerson?.GeotrakID
    },
    {
      title: 'WorkdayID',
      field: 'internalPerson.WorkdayID',
      render: (row: Person) => row?.internalPerson?.WorkdayID
    },
    {
      title: 'Status',
      field: 'IsActive',
      render: (row: Person) => (row.IsActive ? 'Active' : 'Inactive')
    }
  ]

  const setFilterChange = debounce(search => {
    setFilter(search)
    setCurrentPage(1)
  }, 700)

  const setSortChange = (sortKey: string | '', orderType: string | '') => {
    let keyName = sortKey
    switch (sortKey) {
      case 'Email':
        keyName = `Person.${sortKey}`
        break
      case 'Name':
        keyName = `Person.Firstname`
        break
      case 'Company':
        keyName = `Company.Name`
        break
      case 'Employee status':
        keyName = `Person.IsActive`
        break
      case 'Mobile':
        keyName = `Person.Phone`
        break
      case 'GeotrakID':
        keyName = `InternalPerson.GeotrakID`
        break
      case 'WorkdayID':
        keyName = `InternalPerson.WorkdayID`
        break
      case 'Status':
        keyName = `Person.IsActive`
        break
      default:
        break
    }
    if (sortKey !== '' && orderType !== '') {
      const sort = { field: keyName, orderBy: orderType.toLocaleUpperCase() }
      setSorter(sort)
      setCurrentPage(1)
    }
  }

  useEffect(() => {
    if (data?.getPagingPerson.items?.length) {
      const newSelectedRolePerson = data?.getPagingPerson.items.find(e => e.ID === selectedRoleItem?.ID)
      selectedRoleItem?.roles?.length !== newSelectedRolePerson?.roles?.length &&
        newSelectedRolePerson &&
        setSelectedRoleItem(newSelectedRolePerson as Person)
    }
  }, [data, selectedRoleItem])

  const handleClose = () => {
    setSelectedImpersonateUser(null)
    setPortal(Portal.LinkedSite)
  }

  return (
    <>
      <Grid item xs={12}>
        <Table<Person>
          isLoading={loading}
          columns={columns}
          data={(data?.getPagingPerson.items as Person[]) || []}
          option={{
            pageSize: currentPageSize
          }}
          actions={[
            {
              icon: () => <PersonIcon />,
              tooltip: 'Impersonate User',
              onClick: (e, rowData) => {
                if (!Array.isArray(rowData)) {
                  setSelectedImpersonateUser(rowData as Person)
                }
              },
              hidden: !canImpersonateUser
            },
            {
              icon: () => <EditIcon />,
              tooltip: 'Edit Account',
              onClick: (e, rowData) => {
                if (!Array.isArray(rowData)) {
                  setSelectedEditUserItem(rowData as Person)
                }
              }
            },
            {
              icon: () => <SecurityIcon />,
              tooltip: 'Manage Account',
              onClick: (e, rowData) => {
                if (!Array.isArray(rowData)) {
                  setSelectedItem(rowData as Person)
                }
              }
            },
            {
              icon: () => <ListAltIcon />,
              tooltip: 'Manage Roles',
              onClick: (e, rowData) => {
                if (!Array.isArray(rowData)) {
                  setSelectedRoleItem(rowData as Person)
                }
              }
            }
          ]}
          onSearchChange={e => {
            const search: string = e.toString()
            setFilterChange(search)
          }}
          onOrderChange={(columnIndex, orderDirection) => {
            const columnPath = columnIndex < 0 ? null : columns[columnIndex].title
            setSortChange(columnPath || '', orderDirection)
          }}
          components={{
            Pagination: props => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 20, 50]}
                  rowsPerPage={currentPageSize}
                  count={data?.getPagingPerson.pagination?.total || 0}
                  page={currentPage - 1}
                  onChangePage={(e, page) => {
                    setCurrentPage(page + 1)
                  }}
                  onChangeRowsPerPage={e => {
                    props.onChangeRowsPerPage(e)
                    setCurrentPageSize(Number(e.target.value))
                  }}
                />
              )
            }
          }}
        />
      </Grid>

      {selectedItem && (
        <UserModal
          selectedItem={selectedItem}
          onCloseModal={onCloseModal}
          setAutoHideFeedback={setAutoHideFeedback}
          onDone={() => {
            onCloseModal()
            refetchPersonList()
          }}
        />
      )}

      {selectedRoleItem && (
        <RoleAccessModal
          selectedRoleItem={selectedRoleItem}
          onCloseModal={onCloseModalManageRole}
          refetchQuery={GET_PAGING_PERSONS}
          refetchOption={queryHookOptions}
        />
      )}

      {selectedEditUserItem && (
        <EditUserModal
          selectedItem={selectedEditUserItem}
          onCloseModal={onCloseModalEditUser}
          onRefetch={refetchPersonList}
          setAutoHideFeedback={setAutoHideFeedback}
        />
      )}

      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setAutoHideFeedback(null)}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />

      {selectedImpersonateUser && (
        <ConfirmDialog
          buttonText="Yes"
          handleClose={handleClose}
          open={!!selectedImpersonateUser}
          onConfirm={onImpersonateUser}
          title="Impersonate User"
        >
          <FormRadioGroup
            row
            value={portal}
            onChange={e => {
              setPortal(e.target.value as Portal)
            }}
          >
            {[Portal.LinkedSite, Portal.LinkedOffice].map(portal => (
              <FormControlLabel key={portal} value={portal} control={<Radio />} label={portal} />
            ))}
          </FormRadioGroup>
          <DialogContentText>
            <Box
              mt={1}
            >{`Please confirm to start impersonating this user ${selectedImpersonateUser?.Firstname} ${selectedImpersonateUser?.Lastname}?`}</Box>
          </DialogContentText>
        </ConfirmDialog>
      )}
    </>
  )
}
