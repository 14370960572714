import { SetStateAction } from 'react'

export type HolcimForm = {
  projectId: string
  username: string
  clientId: string
  password?: string
  clientSecret?: string
  customerCode: string
}

export type KeyDownProps = {
  field: string
  isInitValue: boolean
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any
  setIsInitValue: (value: SetStateAction<boolean>) => void
}

export enum IntegrationProviderName {
  Holcim = 'Holcim',
  Hanson = 'Hanson'
}
