import { SvgIconProps, SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import {
  AddBox,
  AddCircle,
  ArrowDownward,
  Cancel,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
  Person,
  Remove,
  SaveAlt,
  Search,
  Update,
  ViewColumn,
  PersonAdd,
  EnhancedEncryption,
  Visibility,
  Security,
  ListAlt,
  Business,
  Close,
  GetApp,
  Add,
  PhoneIphone,
  TabletMac,
  Fullscreen,
  FullscreenExit,
  DragHandle,
  IndeterminateCheckBox,
  Tv,
  HelpOutline,
  FileCopyOutlined,
  PublishOutlined,
  FileCopyRounded,
  PublishRounded
} from '@material-ui/icons'
import { createElement, forwardRef, Ref } from 'react'
import { ReactComponent as PhotoSVG } from 'images/icons/photo.svg'
import { ReactComponent as VideoSVG } from 'images/icons/video-play.svg'
import { ReactComponent as EditSVG } from 'images/icons/edit.svg'
import { ReactComponent as DeleteSVG } from 'images/icons/trash.svg'
import { ReactComponent as CalendarSVG } from 'images/icons/calendar.svg'
import { ReactComponent as HelpSVG } from 'images/icons/help.svg'
import { ReactComponent as ExcelSVG } from 'images/icons/excel.svg'
import { ReactComponent as WarningSVG } from 'images/icons/warning-icon.svg'
/*
  in order to avoid error  Component definition is missing display name
  more details: https://github.com/yannickcr/eslint-plugin-react/issues/2269
*/
// to be refactored in a helper method that allows this displayName configuration
const getIcon = (svg: OverridableComponent<SvgIconTypeMap<NonNullable<any>, 'svg'>>, displayName: string) => {
  const icon = forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => {
    const SVG = createElement(svg, props, ref)
    return SVG
  })
  icon.displayName = displayName
  return icon
}

export const AddBoxIcon = getIcon(AddBox, 'AddBoxIcon')
export const AddCircleIcon = getIcon(AddCircle, 'AddCircleIcon')
export const CancelIcon = getIcon(Cancel, 'CancelIcon')
export const CheckIcon = getIcon(Check, 'CheckIcon')
export const ClearIcon = getIcon(Clear, 'ClearIcon')
export const AddIcon = getIcon(Add, 'AddIcon')
export const DeleteIcon = getIcon(DeleteOutline, 'DeleteIcon')
export const DetailPanelIcon = getIcon(ChevronRight, 'DetailPanelIcon')
export const EditIcon = getIcon(Edit, 'EditIcon')
export const ExportIcon = getIcon(SaveAlt, 'ExportIcon')
export const FilterIcon = getIcon(FilterList, 'FilterIcon')
export const FirstPageIcon = getIcon(FirstPage, 'FirstPageIcon')
export const LastPageIcon = getIcon(LastPage, 'LastPageIcon')
export const NavigateBeforeIcon = getIcon(NavigateBefore, 'NavigateBeforeIcon')
export const NavigateNextIcon = getIcon(NavigateNext, 'NavigateNextIcon')
export const NextPageIcon = getIcon(ChevronRight, 'NextPageIcon')
export const PersonIcon = getIcon(Person, 'PersonIcon')
export const ListAltIcon = getIcon(ListAlt, 'ListAltIcon')
export const ViewDetail = getIcon(Visibility, 'ViewDetailIcon')
export const SecurityIcon = getIcon(Security, 'SecurityIcon')
export const CreatePerson = getIcon(PersonAdd, 'PersonAddIcon')
export const ResetPassword = getIcon(EnhancedEncryption, 'ResetPasswordIcon')
export const PreviousPageIcon = getIcon(ChevronLeft, 'PreviousPageIcon')
export const ResetSearchIcon = getIcon(Clear, 'ResetSearchIcon')
export const SearchIcon = getIcon(Search, 'SearchIcon')
export const SortArrowIcon = getIcon(ArrowDownward, 'SortArrowIcon')
export const ThirdStateCheckIcon = getIcon(Remove, 'ThirdStateCheckIcon')
export const UpdateIcon = getIcon(Update, 'UpdateIcon')
export const ViewColumnIcon = getIcon(ViewColumn, 'ViewColumnIcon')
export const BusinessIcon = getIcon(Business, 'BusinessIcon')
export const CloseIcon = getIcon(Close, 'CloseIcon')
export const DownLoadIcon = getIcon(GetApp, 'DownLoadIcon')
export const PhoneIphoneIcon = getIcon(PhoneIphone, 'PhoneIphoneIcon')
export const TabletMacIcon = getIcon(TabletMac, 'TabletMacIcon')
export const FullScreenIcon = getIcon(Fullscreen, 'FullScreenIcon')
export const FullscreenExitIcon = getIcon(FullscreenExit, 'FullscreenExitIcon')
export const DragHandleIcon = getIcon(DragHandle, 'DragHandleIcon')
export const MinusIcon = getIcon(IndeterminateCheckBox, 'MinusIcon')
export const TvIcon = getIcon(Tv, 'TvIcon')
export const HelpIcon = getIcon(HelpOutline, 'HelpIcon')
export const FileCopyIcon = getIcon(FileCopyOutlined, 'FileCopyIcon')
export const PublishedIcon = getIcon(PublishOutlined, 'PublishedIcon')
export const FileCopyRoundedIcon = getIcon(FileCopyRounded, 'FileCopyRoundedIcon')
export const PublishedRoundedIcon = getIcon(PublishRounded, 'PublishedRoundedIcon')
export const PhotoIcon = PhotoSVG
export const VideoIcon = VideoSVG
export const EditSVGIcon = EditSVG
export const DeleteSVGIcon = DeleteSVG
export const CalendarIcon = CalendarSVG
export const HelpSVGIcon = HelpSVG
export const ExcelSVGIcon = ExcelSVG
export const WarningIcon = WarningSVG
