import React from 'react'
import { Box, FormControlLabel, Radio, Tooltip, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { CMSCreatorValues, PreviewTemplateValue } from 'pages/Publication/cms/types'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { PhotoIcon, VideoIcon } from 'components/common/icons'

export interface ContentTemplateProps {
  index: number
  classes: ClassNameMap<'root' | 'icon' | 'checkedIcon' | 'disableTransform'>
  templateTitle: string
  skeletonCount?: number
  value: PreviewTemplateValue
  columns?: number
  fileColumns?: number
  isHalfText?: boolean
  isImageOnly?: boolean
  isImageOnLeft?: boolean
  isVideo?: boolean
  hasTextAndImage?: boolean
  isHeader?: boolean
  description?: string
}

export default function ContentTemplate({
  index,
  classes,
  templateTitle,
  skeletonCount = 4,
  value,
  columns = 1,
  fileColumns = 1,
  isHalfText = false,
  isImageOnly = false,
  isImageOnLeft = false,
  hasTextAndImage = false,
  isVideo = false,
  isHeader = false,
  description = ''
}: ContentTemplateProps) {
  const { values } = useFormikContext<CMSCreatorValues>()

  return (
    <Tooltip title={<span className="text-sm">{description}</span>} enterDelay={0}>
      <Box
        className={classNames(
          'flex border-2 border-solid relative cursor-pointer rounded-lg h-40',
          {
            'bg-error-color-light': values?.content?.[index]?.template === value,
            'bg-color-neutral-300': index === 0 && !isHeader
          },
          values?.content?.[index]?.template === value ? 'border-color-primary-700' : 'border-neutral-200'
        )}
      >
        <Box>
          <FormControlLabel
            className="absolute w-full h-full z-50"
            value={value}
            control={<Radio className={classes.root} disableRipple color="default" checkedIcon="" icon="" />}
            label=""
            disabled={index === 0 && !isHeader}
          />
        </Box>
        <Box className="flex flex-col items-center w-full mx-4 my-2">
          <Box className="flex justify-center bg-color-neutral-200 py-2 w-full rounded-md px-2">
            {isHeader && (
              <Box height={84} width="95%" className="flex flex-col items-center bg-color-neutral-300 my-1 rounded-md">
                <Box className="w-full my-1 ml-2">
                  <Box height={8} width="80%" className="bg-color-white my-1 rounded-md" />
                  <Box height={8} width="50%" className="bg-color-white my-1 rounded-md" />
                </Box>
                <PhotoIcon width={30} height={30} />
              </Box>
            )}

            {hasTextAndImage && isImageOnLeft && (
              <Box
                height={84}
                width="90%"
                className="flex justify-center items-center bg-color-neutral-300 my-1 rounded-md"
              >
                {!isVideo ? <PhotoIcon width={30} height={30} /> : <VideoIcon width={30} height={30} />}
              </Box>
            )}

            {!isImageOnly &&
              !isHeader &&
              Array.from(Array(columns).keys()).map((_, wrapperIndex) => (
                <Box key={String(wrapperIndex)} className="flex flex-col items-center w-full">
                  {Array.from(Array(skeletonCount).keys()).map((_, index) => (
                    <Box key={String(index)} height={15} width="95%" className="bg-color-neutral-300 my-1 rounded-md" />
                  ))}
                </Box>
              ))}

            {isHalfText && <Box height={15} width="90%" />}

            {(isImageOnly || (!isImageOnLeft && hasTextAndImage)) && (
              <>
                {Array.from(Array(fileColumns).keys()).map((_, wrapperIndex) => (
                  <Box key={String(wrapperIndex)} className="flex flex-col items-center w-full">
                    <Box
                      height={84}
                      width="95%"
                      className="flex justify-center items-center bg-color-neutral-300 my-1 rounded-md"
                    >
                      {!isVideo ? <PhotoIcon width={30} height={30} /> : <VideoIcon width={30} height={30} />}
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
          <Typography className="mt-3 text-base font-semibold color-neutral-700">{templateTitle}</Typography>
        </Box>
      </Box>
    </Tooltip>
  )
}
