import { Box, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import InnerHTML from 'components/common/InnerHTML'
import { Position } from 'components/common/TextInImagePositionSelector'
import React from 'react'
import { getTextInHTMLString } from 'utils/tools'
import { useCMSList } from '../../styles'
import { TextPosition } from '../../types'
import { removeCMSEmptyElements, removeParagraphTag } from '../../utils'

interface TextInImageBoxProps {
  description?: string
  position?: Position
}

export default function TextInImageBox({ description, position }: TextInImageBoxProps) {
  const classes = useCMSList()
  return (
    <>
      {description && position?.ID === TextPosition.Below && (
        <Tooltip
          title={
            getTextInHTMLString(description)?.trim() ? (
              <InnerHTML html={getTextInHTMLString(description)} className="text-sm" />
            ) : (
              ''
            )
          }
          enterTouchDelay={0}
          placement="bottom-start"
        >
          <Box className={classNames('m-1 mt-0 w-full', classes.textOfImage)}>
            <InnerHTML html={`<p>${removeParagraphTag(removeCMSEmptyElements(description))}</p>`} className="pl-1" />
          </Box>
        </Tooltip>
      )}
    </>
  )
}
