import { makeStyles, Theme } from '@material-ui/core'

interface ReleaseNoteStyleProps {
  hasError?: boolean
}

export const useReleaseNotesCommonStyles = makeStyles<Theme, ReleaseNoteStyleProps>({
  menuList: {
    minWidth: '10rem'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& img': {
      marginLeft: '0.5rem'
    }
  },
  dialogContent: {
    marginBottom: '6rem'
  },
  dialogTitle: {
    '& h2': {
      fontSize: '1.5rem',
      fontWeight: 600
    }
  },
  box: ({ hasError }) => ({
    borderRadius: '6px',
    border: '1px solid #667085',
    backgroundColor: '#F2F4F7',
    color: '#667085',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1.5rem',
    cursor: 'pointer',
    marginBottom: '3rem',
    minHeight: '3.5rem',
    '& > .browse-text': {
      color: '#344054'
    },
    borderColor: `${!!hasError ? 'var(--error-color)' : 'var(--color-neutral-500)'}`
  }),
  subtitle: {
    fontWeight: 600
  },
  input: {
    display: 'none'
  },
  actions: {
    padding: '8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  uploadedFile: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '& img': {
      marginRight: '0.5rem'
    }
  },
  dialogUploadTitle: {
    '& h2': {
      fontWeight: 600
    }
  },
  fullWidth: {
    width: '100%'
  },
  colorPrimary: {
    color: '#f6ac6d',
    backgroundColor: '#fff8e7',
    borderColor: '#f6ac6d',
    fontSize: '0.9rem',
    borderRadius: '10px',
    fontWeight: 600
  },
  colorSecondary: {
    color: '#35c757',
    backgroundColor: '#e6f9ec',
    borderColor: '#35c757',
    fontSize: '0.9rem',
    borderRadius: '10px',
    fontWeight: 600
  },
  fontSizeSmall: {
    width: '0.8rem',
    height: '0.8rem',
    marginLeft: '8px !important'
  },
  clickHere: {
    color: 'var(--color-blue)',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  form: {
    width: '100%',
    padding: 7,
    marginBottom: 20
  },
  uploadFileContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fileIconContainer: {
    display: 'flex',
    marginTop: 20,
    alignItems: 'center'
  }
})
