import { Box, Button, FormHelperText, Popover, TextField } from '@material-ui/core'
import { ListImage } from 'components/common/ListImage'
import { useFormikContext } from 'formik'
import map from 'lodash/map'
import { CMSCreatorValues } from 'pages/Publication/cms/types'
import React, { useMemo, useRef } from 'react'

import BulkUploadImages from 'components/common/BulkUploadImages'
import { POSITIONS } from 'components/common/TextInImagePositionSelector'
import { encodeS3URI } from 'config/imageHost'
import { GetFileQuery, GetFileQueryVariables } from 'generated/graphql'
import { GET_FILE } from 'graphql/common'
import useFileUrl from 'hooks/useFileUrl'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { useImagePreviews, usePopover } from 'pages/Publication/cms/hooks'
import { checkURLOrEmbedLink, filterData, getVarBasedOnCMSForm } from 'pages/Publication/cms/utils'

interface UploadImagesContainerProps {
  index: number
}

export default function UploadImagesContainer({ index }: UploadImagesContainerProps) {
  const getFileUrl = useFileUrl<GetFileQuery, GetFileQueryVariables>()
  const { setErrorMessage } = useSnackbarContext()
  const { values, setFieldValue, errors } = useFormikContext<CMSCreatorValues>()

  const { anchor, onClose, onOpen, open } = usePopover()
  const embedText = useRef('')

  const hasAtLeastOneImage = !!values?.content?.[index]?.images?.length

  const { accept, isShowVideo, limitSize, isMultiImages, uploadTitle, disabledImage } = getVarBasedOnCMSForm(
    values,
    index
  )
  const { renderImages } = useImagePreviews(
    values?.content?.[index]?.images,
    values?.content?.[index]?.imagePreviews,
    uploadImages => setFieldValue(`content.${index}.imagePreviews`, uploadImages)
  )

  const imageData = useMemo(
    () =>
      renderImages?.length
        ? renderImages
        : map(
            values?.content?.[index]?.images,
            image => values?.content?.[index]?.imagePreviews?.find(url => url?.includes(encodeS3URI(image))) ?? ''
          ),
    [values, renderImages, index]
  )

  const handleReturnValue = async (uploadValues: string[]) => {
    try {
      if (!uploadValues?.length) {
        setFieldValue(`content.${index}.images`, [])
        setFieldValue(`content.${index}.imagePreviews`, [])
        return
      }

      const imageData = [...values?.content?.[index]?.images, ...uploadValues]

      const fileUrls: (
        | PromiseFulfilledResult<string>
        | (PromiseRejectedResult & { value?: string })
      )[] = await Promise.allSettled(
        imageData
          ?.filter(
            image =>
              !values?.content?.[index]?.imagePreviews?.some(url => url.includes(encodeS3URI(image))) &&
              !image?.includes('https')
          )
          ?.map(async image => {
            const {
              data: {
                getFile: { Url }
              }
            } = await getFileUrl({ query: GET_FILE, variables: { fileKey: image } })
            return Url
          })
      )

      setFieldValue(`content.${index}.imagePreviews`, [
        ...values?.content?.[index]?.imagePreviews,
        ...fileUrls?.filter(item => item?.status === 'fulfilled')?.map(item => item?.value)
      ])
      setFieldValue(`content.${index}.images`, imageData)

      if (isShowVideo) {
        setFieldValue(`content.${index}.textEditors`, [
          ...(values?.content?.[index]?.textEditors ?? []),
          ...uploadValues.map(() => ({ description: '', template: values?.content?.[index]?.template }))
        ])
      } else {
        setFieldValue(`content.${index}.textInImages`, [
          ...(values?.content?.[index]?.textInImages ?? []),
          ...uploadValues.map(() => ({ description: '', position: POSITIONS[1] }))
        ])
      }
    } catch (error) {
      setErrorMessage((error as Error)?.message)
    }
  }

  const handleRemoveImage = (removeIndex: number) => {
    setFieldValue(`content.${index}.images`, filterData(values?.content?.[index]?.images, removeIndex))
    setFieldValue(`content.${index}.imagePreviews`, filterData(values?.content?.[index]?.imagePreviews, removeIndex))
    setFieldValue(
      `content.${index}.textInImages`,
      filterData(values?.content?.[index]?.textInImages ?? [], removeIndex)
    )
    setFieldValue(`content.${index}.textEditors`, filterData(values?.content?.[index]?.textEditors ?? [], removeIndex))
  }

  const handleInsertURLOrEmbed = () => {
    if (values?.content?.[index]?.images?.length >= 2) {
      setErrorMessage(`You can only upload a maximum of 2 videos`)
    } else if (!checkURLOrEmbedLink(embedText.current)) {
      setErrorMessage(`Please enter a valid URL`)
    } else {
      setFieldValue(`content.${index}.images`, [...(values?.content?.[index]?.images ?? []), embedText.current])
      setFieldValue(`content.${index}.imagePreviews`, [
        ...(values?.content?.[index]?.imagePreviews ?? []),
        embedText.current
      ])
      setFieldValue(`content.${index}.textEditors`, [
        ...(values?.content?.[index]?.textEditors ?? []),
        { description: '', template: values?.content?.[index]?.template }
      ])
    }

    embedText.current = ''
    onClose()
  }

  return (
    <Box className="flex flex-col w-full">
      <Box className="w-full flex">
        <Box className="flex flex-col gap-1">
          {isShowVideo && (
            <Box>
              <Button size="small" variant="outlined" onClick={onOpen}>
                Enter a URL or Embed
              </Button>
              <Popover
                open={open}
                anchorEl={anchor}
                onClose={onClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center'
                }}
              >
                <Box className="flex w-fit h-fit p-4 gap-2">
                  <TextField
                    className="flex w-96 border-thin-rounded-input rounded-input"
                    placeholder="Title"
                    variant="outlined"
                    onChange={event => (embedText.current = event.target.value)}
                  />
                  <Button className="text-base font-semibold" variant="text" onClick={handleInsertURLOrEmbed}>
                    Apply
                  </Button>
                </Box>
              </Popover>
            </Box>
          )}
          <BulkUploadImages
            disabled={disabledImage}
            id={values?.content?.[index]?.id}
            onReturnValue={handleReturnValue}
            accept={accept}
            uploadTitle={uploadTitle}
            limitSize={limitSize}
            isMultiImages={isMultiImages}
            isShowVideo={isShowVideo}
            maximumImage={isShowVideo ? 2 : 50}
            defaultValue={values?.content?.[index]?.images?.[0]}
            value={values?.content?.[index]?.images}
          />
        </Box>
        {hasAtLeastOneImage && isMultiImages && (
          <ListImage imageData={imageData} onRemoveImage={handleRemoveImage} isShowVideo={isShowVideo} />
        )}
      </Box>
      {!hasAtLeastOneImage && (
        <FormHelperText className="primary-color-500">
          {!isShowVideo ? (errors.content?.[index] as any)?.images : 'Please upload at least one video'}
        </FormHelperText>
      )}
    </Box>
  )
}
