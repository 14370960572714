import { useApolloClient } from '@apollo/react-hooks'
import {
  GetSystemAnnouncementsQuery,
  GetSystemAnnouncementsQueryVariables,
  SystemAnnouncement
} from 'generated/graphql'
import { gql } from 'graphql.macro'

const GET_SYSTEM_ANNOUNCEMENTS = gql`
  query getSystemAnnouncements {
    getSystemAnnouncements {
      IsActive
      Status
      Description
    }
  }
`

const useUpdateSystemAnnouncement = () => {
  const client = useApolloClient()
  const systemAnnouncement = client.readQuery<GetSystemAnnouncementsQuery, GetSystemAnnouncementsQueryVariables>({
    query: GET_SYSTEM_ANNOUNCEMENTS,
    variables: {
      sortBy: 'CreatedDate'
    }
  })?.getSystemAnnouncements?.[0] as SystemAnnouncement

  const updateSystemAnnouncement = (data: SystemAnnouncement[]) => {
    client.writeQuery<GetSystemAnnouncementsQuery, GetSystemAnnouncementsQueryVariables>({
      query: GET_SYSTEM_ANNOUNCEMENTS,
      variables: {
        sortBy: 'CreatedDate'
      },
      data: {
        getSystemAnnouncements: data
      }
    })
  }

  return { systemAnnouncement, updateSystemAnnouncement }
}

export default useUpdateSystemAnnouncement
