import React, { FC } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { Disclaimer } from './Disclaimer'
import { useStyles } from './styles'
import { authConfig } from '../../config/authConfig'
import question from '../../images/question.svg'
import logo from '../../images/logo.svg'
import { Banner } from '../../components/Banner'

interface LoginProps {
  signIn: (username: string, password: string) => void
  errorMessage: string | null
}

export const Login: FC<LoginProps> = ({ errorMessage }) => {
  const classes = useStyles()

  return (
    <div className={classes.containerWrapper}>
      <Banner />
      <Box className={classes.container} data-testid="login">
        <img className={classes.logo} src={logo} alt="Acciona logo" />
        <Typography variant="h4" align="center">
          Welcome to Linked Site
        </Typography>
        <form className={classes.form} data-testid="login-form">
          {errorMessage && (
            <Typography className={classes.error} data-testid="login-form-error" variant="h6">
              {errorMessage}
            </Typography>
          )}

          <Button
            href={`https://${authConfig.oauth.domain}/login?client_id=${authConfig.oauth.client_id}&scope=${
              authConfig.oauth.scope
            }&response_type=${authConfig.oauth.responseType}&redirect_uri=${encodeURIComponent(
              authConfig.oauth.redirectSignIn
            )}`}
            color="secondary"
            data-testid="single-sign-on-form-submit"
            type="submit"
            variant="contained"
          >
            Login to LinkedSite
          </Button>
          <img className={classes.question} src={question} alt="question-mark" />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://accionageotech.atlassian.net/wiki/spaces/UG/pages/720764943/How+to+access+LinkedSite"
            className={classes.instructionLink}
          >
            <Typography variant="subtitle2">Login Instructions</Typography>
          </a>
        </form>
        <Disclaimer />
      </Box>
    </div>
  )
}
