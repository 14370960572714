import React, { FC } from 'react'

import { makeStyles, Backdrop, CircularProgress, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    flex: 1,
    justifyContent: 'center',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1
  }
}))

interface SimpleLoaderProps {
  isLoading: boolean
  text?: string
}

export const SimpleLoader: FC<SimpleLoaderProps> = ({ isLoading, text }) => {
  const classes = useStyles()

  return isLoading ? (
    <Backdrop className={classes.root} data-testid="loader" open={true}>
      <CircularProgress color="secondary" />
      {text ? <Typography>{text}</Typography> : null}
    </Backdrop>
  ) : null
}
