export const imageHost = process.env.REACT_APP_IMAGE_HOST as string

export const checkExitsHost = (value: string) => {
  return value.includes('http') ? value : `${imageHost}/${value}`
}

const encodings = {
  '+': '%2B',
  '!': '%21',
  '"': '%22',
  '#': '%23',
  $: '%24',
  '&': '%26',
  "'": '%27',
  '(': '%28',
  ')': '%29',
  '*': '%2A',
  ',': '%2C',
  ':': '%3A',
  ';': '%3B',
  '=': '%3D',
  '?': '%3F',
  '@': '%40'
}

type SpecialCharacter = keyof typeof encodings

function isValidSpecialCharacter(key: string): key is SpecialCharacter {
  return key in encodings
}

export const encodeS3URI = (filename: string) => {
  return encodeURI(filename).replace(/(\+|!|"|#|\$|&|'|\(|\)|\*|\+|,|:|;|=|\?|@)/gim, (match: string) => {
    return isValidSpecialCharacter(match) ? encodings[match] : ''
  })
}

export const getImageUrl = (value: string | null | undefined) => {
  return value && value.includes('http') ? value : `${imageHost}/${encodeS3URI(value as string)}`
}
