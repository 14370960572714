import { Box, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { CMSCreatorValues, HeaderBackgroundOptions } from 'pages/Publication/cms/types'
import React from 'react'

import omit from 'lodash/omit'
import { defaultContentSection } from 'pages/Publication/cms/constants'
import { getVarBasedOnCMSForm } from 'pages/Publication/cms/utils'
import ColorSettingSection from './ColorSettingSection'
import UploadImagesContainer from './UploadImagesContainer'
import { SelectorObjectField } from 'components/common/SelectorObjectField'
import { ImageSizeSelector } from 'components/common/ImageSizeSelector'
import { ImagePositionSelector } from 'components/common/ImagePositionSelector'
import TextInImage from './TextInImage'
import { validateNumericOnly } from 'utils/tools'
import { GridLayoutInputNumber } from 'components/common/GridLayoutInputNumber'

interface HeaderFormProps {
  index: number
}

export default function HeaderForm({ index }: HeaderFormProps) {
  const { values, setFieldValue } = useFormikContext<CMSCreatorValues>()
  const {
    isHideUploadImg,
    isShowSelectOption,
    uploadTitle,
    isShowVideo,
    isHideContent,
    isMultiImages,
    isOnlyImages
  } = getVarBasedOnCMSForm(values, index)

  const handleBackgroundColorChange = (value: string) => {
    setFieldValue(
      'content',
      values?.content?.map((item, itemIndex) =>
        itemIndex === index
          ? {
              ...item,
              ...omit(defaultContentSection, ['id', 'isExpanded', 'sectionName', 'isLayoutExpanded']),
              headerBackgroundOption: value as HeaderBackgroundOptions
            }
          : item
      )
    )
  }

  return (
    <Box>
      {!isHideUploadImg && !isShowVideo && (
        <Box>
          <Box className="w-full mb-4">
            <Typography className="text-base font-semibold mb-2 color-neutral-700">
              {uploadTitle} <span className="primary-color-500"> *</span>
            </Typography>
            {isShowSelectOption && (
              <FormControl className="ml-2" component="fieldset">
                <RadioGroup
                  name={`content.${index}.backgroundColor`}
                  value={values?.content?.[index]?.headerBackgroundOption}
                  onChange={(_, value) => handleBackgroundColorChange(value)}
                >
                  <Box className="flex">
                    {[HeaderBackgroundOptions.Image, HeaderBackgroundOptions.Color].map(background => (
                      <FormControlLabel
                        key={background}
                        className={classNames(
                          'pr-8 rounded-lg w-25',
                          values?.content?.[index]?.headerBackgroundOption === background
                            ? 'border-primary-700 bg-error-color-light primary-color-700'
                            : 'border-neutral-200'
                        )}
                        value={background}
                        control={<Radio />}
                        label={background}
                      />
                    ))}
                  </Box>
                </RadioGroup>
              </FormControl>
            )}
          </Box>
          <Box className="flex">
            {values?.content?.[index]?.headerBackgroundOption === HeaderBackgroundOptions.Color ? (
              <ColorSettingSection index={index} />
            ) : (
              <UploadImagesContainer index={index} />
            )}
          </Box>
          {isMultiImages && (
            <>
              {isOnlyImages && (
                <Grid container className="mt-3" spacing={1}>
                  <GridLayoutInputNumber
                    type="number"
                    name={`content.${index}.imagesPerRow`}
                    label="Images per row"
                    isVersion2
                    onKeyDown={validateNumericOnly}
                    inputProps={{
                      max: 6,
                      min: 1
                    }}
                    decimalScale={0}
                    textFieldBreakpoints={{ xs: 12, md: 4 }}
                  />
                  <SelectorObjectField
                    label="Size"
                    isVersion2
                    name={`content.${index}.size`}
                    selector={ImageSizeSelector}
                    textFieldBreakpoints={{ xs: 12, md: 4 }}
                    disableClearable
                  />
                  <SelectorObjectField
                    label="Position"
                    isVersion2
                    name={`content.${index}.position`}
                    selector={ImagePositionSelector}
                    textFieldBreakpoints={{ xs: 12, md: 4 }}
                    disableClearable
                  />
                </Grid>
              )}
              {values?.content?.[index]?.textInImages?.map((_, textIndex) => (
                <TextInImage key={String(textIndex)} textIndex={textIndex} index={index} />
              ))}
            </>
          )}
          {!isHideContent && <Divider className="mt-5 mb-4" />}
        </Box>
      )}
    </Box>
  )
}
