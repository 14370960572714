import { getTextInHTMLString } from 'utils/tools'
import { CMSCreatorValues, Devices, PreviewTemplateValue, TextInImages } from '../types'

import { VIDEO_SUFFIX } from 'utils/Constants'

export const getGridSize = (device: Devices) => {
  switch (device) {
    case Devices.Desktop:
      return 8
    case Devices.Tablet:
      return 10
    default:
      return 12
  }
}

export const filterData = <T>(data: T[] | [], removeIndex: number) => {
  return data?.filter((_, itemIndex) => removeIndex !== itemIndex)
}

export const getUploadTitle = (template?: PreviewTemplateValue) => {
  switch (template) {
    case PreviewTemplateValue.OnlyVideo:
      return 'Upload Videos'
    case PreviewTemplateValue.Images:
    case PreviewTemplateValue.ImageAndText:
    case PreviewTemplateValue.TextAndImage:
      return 'Upload Images'
    case PreviewTemplateValue.Header:
      return 'Header Background'
    default:
      return 'Upload Image'
  }
}

export const getVarBasedOnCMSForm = (values: CMSCreatorValues, index: number) => {
  const { template: sectionTemplate = PreviewTemplateValue.Header, images = [] } = values?.content?.[index]

  const isHideContent = [PreviewTemplateValue.Images, PreviewTemplateValue.OnlyVideo]?.includes(sectionTemplate)
  const isHideUploadImg = [PreviewTemplateValue.OnlyText, PreviewTemplateValue.TwoColumns].includes(sectionTemplate)

  const accept = sectionTemplate === PreviewTemplateValue.OnlyVideo ? VIDEO_SUFFIX : undefined
  const isShowVideo = sectionTemplate === PreviewTemplateValue.OnlyVideo
  const limitSize = sectionTemplate === PreviewTemplateValue.OnlyVideo ? 1024 : 10

  const isMultiImages = [
    PreviewTemplateValue.ImageAndText,
    PreviewTemplateValue.TextAndImage,
    PreviewTemplateValue.Images,
    PreviewTemplateValue.OnlyVideo
  ].includes(sectionTemplate)

  const isTwoColumnTemplate = sectionTemplate === PreviewTemplateValue.TwoColumns
  const isShowSelectOption = sectionTemplate === PreviewTemplateValue.Header

  const maximumContentLength = sectionTemplate === PreviewTemplateValue.Header ? 250 : 2000
  const isOnlyImages = sectionTemplate === PreviewTemplateValue.Images

  const uploadTitle = getUploadTitle(sectionTemplate)
  const disabledImage = images?.length >= 50

  return {
    isHideContent,
    isHideUploadImg,
    accept,
    isShowVideo,
    limitSize,
    isMultiImages,
    isShowSelectOption,
    maximumContentLength,
    uploadTitle,
    disabledImage,
    isTwoColumnTemplate,
    isOnlyImages
  }
}

export const getTemplateTitle = (name: string) => {
  switch (name) {
    case 'ImagesText':
      return 'Images + Text'
    case 'TextImages':
      return 'Text + Images'
    case 'TwoColumns':
      return 'Two Columns'
    case 'Video':
      return 'Videos'
    default:
      return name
  }
}

export const getExtraProperties = (name: string) => {
  switch (name) {
    case 'Header':
      return { isHeader: true }
    case 'Images':
      return { isImageOnly: true }
    case 'ImagesText':
      return { hasTextAndImage: true, isImageOnLeft: true }
    case 'TextImages':
      return { hasTextAndImage: true }
    case 'TwoColumns':
      return { columns: 2 }
    case 'Video':
      return { isImageOnly: true, isVideo: true }
    default:
      return {}
  }
}

export const removeParagraphTag = (text = '') => text.replace(/<p( *\w+=("[^"]*"|'[^']'|[^ >]))*>|<\/p>/g, '')

export const getSplitImage = (
  images: {
    image: string
    textInImage?: TextInImages
  }[],
  splitThreshold = 2,
  maximumImage = 50
) => {
  const temp = []
  for (let i = 0; i < maximumImage / splitThreshold; i++) {
    const imagesSliced = images.slice(i * splitThreshold, i * splitThreshold + splitThreshold)
    imagesSliced.length && temp.push(imagesSliced)
  }
  return temp
}

export const checkURLOrEmbedLink = (text: string) => {
  const regex = /(https?:\/\/[^\s]+)|(<([^>]+)>)/g
  return regex.test(text)
}

export const replaceToEmbedLink = (text: string) =>
  text?.replace('youtu.be', 'youtube.com/embed')?.replace('shorts', 'embed')

export const removeCMSEmptyElements = (text = '') =>
  getTextInHTMLString(text)?.trim() ? text : text.replace(/<br>/, '')
