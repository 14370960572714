import { ImagePosition } from 'components/common/ImagePositionSelector'
import { Size } from 'components/common/ImageSizeSelector'
import { Position } from 'components/common/TextInImagePositionSelector'
import { UploadImageCountValue } from 'components/common/UploadImageCountSelector'

export enum HeaderBackgroundOptions {
  Image = 'Image',
  Color = 'Colour'
}

export enum SettingTheme {
  DarkMode = 'Dark',
  LightMode = 'Light'
}

export enum Devices {
  Desktop = 'Desktop',
  Tablet = 'Tablet',
  Mobile = 'Mobile'
}

export enum TextPosition {
  Overlay = 'Overlay',
  Below = 'Below'
}

export enum UploadImageCountCategories {
  GridOne = '1',
  GridTwo = '2',
  GridThree = '3',
  GridFour = '4',
  GridSix = '6',
  GridTwelve = '12'
}

export enum PreviewTemplateValue {
  Header = 1,
  Images,
  ImageAndText,
  OnlyText,
  TextAndImage,
  TwoColumns,
  OnlyVideo
}

export enum CMSBriefStatus {
  Published = 'P',
  Draft = 'D'
}

export interface TextEditor {
  order?: number
  description: string
  template?: PreviewTemplateValue
  isExpanded?: boolean
}

export interface CMSHeaderValues {
  title?: string
  backgroundImg: string[] | []
  backgroundImgPreview: string[] | []
  headerBackgroundOption: HeaderBackgroundOptions | string
  backgroundColor?: string
}

export interface TextInImages {
  description?: string
  position?: Position
  isExpanded?: boolean
}

export interface CMSContentValues {
  id: string
  isExpanded?: boolean
  isLayoutExpanded?: boolean
  description?: string
  secondDescription?: string
  images: string[] | []
  imagePreviews: string[] | []
  template?: PreviewTemplateValue
  templateComponent?: JSX.Element
  textInImages?: TextInImages[]
  maximumImage?: UploadImageCountValue
  headerBackgroundOption: HeaderBackgroundOptions
  backgroundColor?: string
  backgroundColorHeight?: number
  sectionName: string
  textEditors?: TextEditor[]
  imagesPerRow?: number | null
  size?: Size | null
  position?: ImagePosition | null
}

export interface CMSFooterValues {
  images: string[] | []
  imagePreviews: string[] | []
}

export interface CMSSettingValues {
  theme: SettingTheme
  device: Devices
}

export interface CMSCreatorValues {
  title: string
  content: CMSContentValues[]
  setting: CMSSettingValues
}
