import { useQuery, useMutation } from '@apollo/react-hooks'
import { Button, Grid, Typography, TablePagination, makeStyles } from '@material-ui/core'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { Table } from 'components/common/Table'
import usePagination from 'hooks/usePagination'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { StandardDivider } from 'components/common/StandardDivider'
import { DeleteIcon, EditIcon } from 'components/common/icons'
import { NOAUTOFILL } from 'components/common/AutocompleteInput'
import ReactQuill from 'react-quill'
import React, { FC, useState, createRef } from 'react'
import { Formik } from 'formik'
import { REQUIRED, ROW_PER_PAGE_OPTIONS } from 'utils/Constants'
import { isEmptyHTML, formatHTMLSpace, scrollToTop } from 'utils/tools'
import * as yup from 'yup'
import {
  PrintingTemplate,
  GetPagingPrintingTemplatesQuery,
  GetPagingPrintingTemplatesQueryVariables,
  GetPrintingTemplateQuery,
  GetPrintingTemplateQueryVariables,
  UpsertPrintingTemplateMutation,
  UpsertPrintingTemplateMutationVariables,
  DeletePrintingTemplateMutation,
  DeletePrintingTemplateMutationVariables
} from '../../generated/graphql'
import {
  GET_PRINTING_TEMPLATE,
  GET_PAGING_PRINTING_TEMPLATES,
  UPSERT_PRINTING_TEMPLATE,
  DELETE_PRINTING_TEMPLATE
} from './graphql'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'

const CONTENT_MAX = 5000
const NAME_MAX = 128
const DESCRIPTION_MAX = 255

const useStyles = makeStyles({
  wrapper: {
    '& .MuiToggleButton-root.Mui-disabled': {
      borderColor: 'rgba(0, 0, 0, 0.12)'
    }
  }
})

const quillSettings = {
  toolbar: {
    container: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline'],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }]
    ],
    handlers: {}
  },
  clipboard: {
    matchVisual: false
  }
}

const printingTemplateValidationSchema = yup.object().shape({
  TemplateContent: yup
    .string()
    .max(CONTENT_MAX, `Maximum ${CONTENT_MAX} characters long`)
    .trim()
    .test('length', `Template Content ${REQUIRED}`, val => val && !isEmptyHTML(val))
    .required(`Template Content ${REQUIRED}`),
  TemplateName: yup
    .string()
    .max(NAME_MAX, `Maximum ${NAME_MAX} characters long`)
    .trim()
    .required(`Template Name ${REQUIRED}`),
  Description: yup
    .string()
    .max(DESCRIPTION_MAX, `Maximum ${DESCRIPTION_MAX} characters long`)
    .nullable()
})

// Fuction Component
export const ManagePrintingTemplates: FC<NonNullable<any>> = () => {
  const classes = useStyles()
  const { currentPageSize, onChangeRowsPerPage, currentPage, setCurrentPage, onFilter } = usePagination(
    'pageSizePrintingTemplates'
  )
  // Declare variables
  const [autoHideFeedback, setAutoHideFeedback] = useState<Feedback | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteID, setDeleteID] = useState('')
  const [currentID, setCurrentID] = useState('')

  const { data: currentData } = useQuery<GetPrintingTemplateQuery, GetPrintingTemplateQueryVariables>(
    GET_PRINTING_TEMPLATE,
    {
      skip: !currentID,
      variables: {
        ID: currentID
      },
      fetchPolicy: 'no-cache'
    }
  )

  const { loading, data, refetch } = useQuery<
    GetPagingPrintingTemplatesQuery,
    GetPagingPrintingTemplatesQueryVariables
  >(GET_PAGING_PRINTING_TEMPLATES, {
    variables: {
      pagination: {
        pageNumber: currentPage,
        pageSize: currentPageSize
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  const [deletePrintingTemplate] = useMutation<DeletePrintingTemplateMutation, DeletePrintingTemplateMutationVariables>(
    DELETE_PRINTING_TEMPLATE
  )

  const [upsertPrintingTemplate] = useMutation<UpsertPrintingTemplateMutation, UpsertPrintingTemplateMutationVariables>(
    UPSERT_PRINTING_TEMPLATE
  )

  const initialValues = {
    ID: currentData?.getPrintingTemplate?.ID || '',
    TemplateName: currentData?.getPrintingTemplate?.TemplateName || '',
    TemplateContent: currentData?.getPrintingTemplate?.TemplateContent || '',
    Description: currentData?.getPrintingTemplate?.Description || ''
  }

  const quillRef = createRef<ReactQuill>()

  const checkCharacterCount = () => {
    if (quillRef && quillRef.current) {
      const editor = quillRef.current.getEditor()
      if (editor.getLength() > CONTENT_MAX) {
        quillRef.current.getEditor().deleteText(CONTENT_MAX, editor.getLength())
      }
    }
  }

  const columns = [
    {
      field: 'TemplateName',
      title: 'Template Name',
      sorting: false
    },
    {
      field: 'TemplateContent',
      title: 'Template Content',
      sorting: false,
      render: (rowData: PrintingTemplate) => (
        <div style={{ wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: rowData?.TemplateContent || '' }} />
      )
    },
    {
      field: 'Description',
      title: 'Description',
      sorting: false
    }
  ]

  const onCloseDeletePopup = () => {
    setDeleteID('')
  }

  const onDelete = () => {
    if (deleteID) {
      deletePrintingTemplate({
        variables: {
          ID: deleteID
        }
      })
        .then(() => {
          setAutoHideFeedback(getSuccessFeedback(`Printing Template deleted successfully`))
          refetch()
        })
        .catch(error => {
          setAutoHideFeedback(getErrorFeedback(error.message))
        })
    }
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={printingTemplateValidationSchema}
        onSubmit={async ({ ID, TemplateName, TemplateContent, Description }, { resetForm, setSubmitting }) => {
          setIsLoading(true)
          setSubmitting(true)
          await upsertPrintingTemplate({
            variables: {
              input: {
                ...(ID && {
                  ID
                }),
                TemplateName,
                TemplateContent: formatHTMLSpace(TemplateContent),
                Description
              }
            }
          })
            .then(() => {
              setAutoHideFeedback(
                getSuccessFeedback(
                  ID ? 'Updated Printing Template Successfully!' : 'Printing Template created successfully!'
                )
              )
              setSubmitting(false)
              setIsLoading(false)
              resetForm()
              setCurrentID('')
              refetch()
            })
            .catch(error => {
              setSubmitting(false)
              setIsLoading(false)
              setAutoHideFeedback(getErrorFeedback(error.message))
            })
        }}
      >
        {props => {
          const { values, errors, touched, setFieldValue, resetForm, handleBlur, handleSubmit, handleChange } = props
          return (
            <form onSubmit={handleSubmit} className={classes.wrapper}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography variant="h5">Printing Template</Typography>
                </Grid>
                <GridLayoutInput
                  fullWidth
                  id="TemplateName"
                  name="TemplateName"
                  label="TemplateName"
                  disabled={!!currentID}
                  autoComplete={NOAUTOFILL}
                  textFieldBreakpoints={{ xs: 6, md: 6 }}
                  value={values?.TemplateName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.TemplateName && !!touched.TemplateName}
                  helperText={touched.TemplateName ? errors.TemplateName : null}
                />
                <GridLayoutInput
                  fullWidth
                  id="Description"
                  name="Description"
                  label="Description"
                  autoComplete={NOAUTOFILL}
                  textFieldBreakpoints={{ xs: 6, md: 6 }}
                  value={values?.Description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.Description && !!touched.Description}
                  helperText={touched.Description ? errors.Description : null}
                />
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Template Content</Typography>
                  <ReactQuill
                    theme={'snow'}
                    id="TemplateContent"
                    style={{ marginTop: 10 }}
                    ref={quillRef}
                    onKeyUp={checkCharacterCount}
                    onKeyDown={checkCharacterCount}
                    onKeyPress={checkCharacterCount}
                    value={values?.TemplateContent}
                    onChange={content => {
                      setFieldValue('TemplateContent', content)
                    }}
                    modules={quillSettings}
                    formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'indent', 'link', 'align']}
                    bounds={'.app'}
                    placeholder={'Please enter template content'}
                  />
                  {!!touched.TemplateContent && !!errors.TemplateContent && (
                    <Typography variant="caption" color="error">
                      {errors.TemplateContent}
                    </Typography>
                  )}
                </Grid>
                <Grid container alignItems="center" direction="row" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    onClick={() => {
                      currentID && setCurrentID('')
                      resetForm()
                    }}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button disabled={isLoading} color="secondary" variant="contained" type="submit">
                    {currentID ? 'Save' : 'Create'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
      <StandardDivider />
      <Grid style={{ marginTop: 20 }}>
        <Table
          isLoading={loading}
          columns={columns}
          data={(data?.getPagingPrintingTemplates?.items as PrintingTemplate[]) || []}
          option={{
            pageSize: 10
          }}
          onSearchChange={e => {
            const search: string = e.toString()
            onFilter(search)
          }}
          actions={[
            {
              icon: () => (
                <Grid container justifyContent="center" direction="row" alignItems="center">
                  <EditIcon color="primary" style={{ fontSize: 15 }} />
                </Grid>
              ),
              tooltip: 'Edit',
              onClick: (_event, rowData: PrintingTemplate | PrintingTemplate[]) => {
                if (!Array.isArray(rowData)) {
                  setCurrentID(rowData.ID)
                  scrollToTop()
                }
              }
            },
            {
              icon: () => <DeleteIcon spacing={4} color="secondary" fontSize="small" />,
              tooltip: 'Remove',
              onClick: (_event, rowData: PrintingTemplate | PrintingTemplate[]) => {
                if (!Array.isArray(rowData)) {
                  if (rowData.ID === currentID) setCurrentID('')
                  setDeleteID(rowData.ID)
                }
              }
            }
          ]}
          components={{
            Pagination: props => {
              return (
                <TablePagination
                  {...props}
                  count={data?.getPagingPrintingTemplates.pagination?.total || 0}
                  rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                  rowsPerPage={currentPageSize}
                  page={currentPage - 1}
                  onChangePage={(e, page) => setCurrentPage(page + 1)}
                  onChangeRowsPerPage={e => onChangeRowsPerPage(e, props)}
                />
              )
            }
          }}
        />
      </Grid>
      <ConfirmDialog
        buttonText="Delete"
        handleClose={onCloseDeletePopup}
        open={!!deleteID}
        onConfirm={onDelete}
        title="Delete Printing Template"
        description="Are you sure to delete this printing template?"
      />
      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setAutoHideFeedback(null)}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />
    </>
  )
}
