import { Box } from '@material-ui/core'
import { SelectorObjectField } from 'components/common/SelectorObjectField'
import { TextInImagePositionSelector } from 'components/common/TextInImagePositionSelector'
import { useFormikContext } from 'formik'
import { CMSCreatorValues } from 'pages/Publication/cms/types'
import React from 'react'
import ContentEditor from '../../ContentEditor'
import TextInImageAccordion from './TextInImageAccordion'

interface TextInImageProps {
  textIndex: number
  index: number
}

export default function TextInImage({ textIndex, index }: TextInImageProps) {
  const { values, setFieldValue, setFieldTouched, errors } = useFormikContext<CMSCreatorValues>()

  return (
    <TextInImageAccordion
      index={textIndex}
      label="Text of image"
      isExpanded={values?.content?.[index]?.textInImages?.[textIndex]?.isExpanded}
      onClick={() =>
        setFieldValue(
          `content.${index}.textInImages.${textIndex}.isExpanded`,
          !values?.content?.[index]?.textInImages?.[textIndex]?.isExpanded
        )
      }
    >
      <Box>
        <SelectorObjectField
          label="Position"
          name={`content.${index}.textInImages.${textIndex}.position`}
          className="mb-3"
          selector={TextInImagePositionSelector}
          disableClearable
          isVersion2
        />
        <ContentEditor
          className="w-full rounded-md custom-quill-container"
          textLabel=""
          value={values?.content?.[index]?.textInImages?.[textIndex]?.description}
          onChange={e => setFieldValue(`content.${index}.textInImages.${textIndex}.description`, e)}
          onBlur={() => setFieldTouched(`content.${index}.textInImages.${textIndex}.description`)}
          contentValidation={(errors.content?.[index] as any)?.textInImages?.[textIndex]?.description}
        />
      </Box>
    </TextInImageAccordion>
  )
}
