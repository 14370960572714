import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  RadioGroup,
  Typography
} from '@material-ui/core'
import classNames from 'classnames'
import { SortableList } from 'components/common/dnd-kit/sortable-list/SortableList'
import { AddBoxIcon, DeleteSVGIcon, DragHandleIcon, MinusIcon } from 'components/common/icons'
import { FieldArrayRenderProps, useFormikContext } from 'formik'
import omit from 'lodash/omit'
import { TEMPLATE_COMPONENTS, defaultContentSection } from 'pages/Publication/cms/constants'
import { useArticleSectionLayouts } from 'pages/Publication/cms/hooks'
import { useCMSCreatorStyles } from 'pages/Publication/cms/styles'
import { CMSContentValues, CMSCreatorValues, PreviewTemplateValue } from 'pages/Publication/cms/types'
import React from 'react'
import { getLimitString } from 'utils/tools'
import ContentTemplate from './template-section/ContentTemplate'
import CMSForm from './left-content-creator/components/CMSForm'

type ContentSortableListProps = {
  section: CMSContentValues
  sectionIndex: number
  fieldArrayProps: FieldArrayRenderProps
}

export default function ContentSortableList({ section, sectionIndex, fieldArrayProps }: ContentSortableListProps) {
  const { values, setFieldValue, setValues, errors } = useFormikContext<CMSCreatorValues>()
  const classes = useCMSCreatorStyles()
  const { layouts, loading } = useArticleSectionLayouts()

  const handleLayoutChange = (value: string, sectionIndex: number) => {
    const template = Number(value)
    const textEditors = [{ order: 1, description: '', template }]
    template === PreviewTemplateValue.TwoColumns && textEditors.push({ order: 2, description: '', template })
    const content = values?.content?.map((item, itemIndex) =>
      itemIndex === sectionIndex
        ? {
            ...item,
            ...omit(defaultContentSection, ['id', 'isExpanded', 'sectionName']),
            template,
            templateComponent: TEMPLATE_COMPONENTS?.[template - 1],
            textEditors: template === PreviewTemplateValue.OnlyVideo ? [] : textEditors,
            sectionName: layouts[template - 1].templateTitle
          }
        : item
    )
    setValues({
      ...values,
      content
    })
  }

  const handleSectionAccordionClick = (sectionIndex: number) => {
    values?.content?.[sectionIndex] &&
      setFieldValue(`content.${sectionIndex}.isExpanded`, !values?.content?.[sectionIndex]?.isExpanded)
  }

  const handleLayoutSectionAccordionClick = (sectionIndex: number) => {
    values?.content?.[sectionIndex] &&
      setFieldValue(`content.${sectionIndex}.isLayoutExpanded`, !values?.content?.[sectionIndex]?.isLayoutExpanded)
  }

  return (
    <SortableList.Item key={section?.id} id={section?.id} disabled={sectionIndex === 0}>
      <Accordion
        className={classNames(
          'border-solid w-full text-base rounded-md mt-0',
          errors?.content?.[sectionIndex] ? 'border-color-primary-700 border-2' : 'border-neutral-300 border'
        )}
        expanded={values?.content?.[sectionIndex]?.isExpanded}
      >
        <AccordionSummary className="border-bottom border-bottom-solid border-bottom-neutral-300 rounded-md max-h-8 min-h-12">
          <Box className="w-full flex justify-between items-center">
            <Box
              onClick={() => handleSectionAccordionClick(sectionIndex)}
              className="w-full h-full min-h-12 flex items-center"
            >
              {sectionIndex !== 0 ? (
                <SortableList.DragHandle
                  className="color-neutral-500"
                  onPointerDown={() => setFieldValue(`content.${sectionIndex}.isExpanded`, false)}
                />
              ) : (
                <DragHandleIcon className="color-grey mr-1" />
              )}
              <Typography className="text-base font-semibold color-neutral-700 overflow-x-hidden">
                {getLimitString(values?.content?.[sectionIndex]?.sectionName, 80)}
              </Typography>
            </Box>
            <Box className="flex justify-between items-center">
              <IconButton
                color="primary"
                onClick={() => fieldArrayProps.remove(sectionIndex)}
                disabled={sectionIndex === 0}
              >
                <DeleteSVGIcon
                  width={20}
                  height={20}
                  className={classNames(sectionIndex !== 0 ? 'color-neutral-500' : 'color-grey')}
                />
              </IconButton>
              <IconButton className={classes.disableRipple} onClick={() => handleSectionAccordionClick(sectionIndex)}>
                {values?.content?.[sectionIndex]?.isExpanded ? (
                  <MinusIcon className="color-neutral-500" />
                ) : (
                  <AddBoxIcon className="color-neutral-500" />
                )}
              </IconButton>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          className={classNames('flex-col my-4 overflow-auto overflow-x-hidden', classes.sectionLayout)}
        >
          <Accordion
            className="border border-solid border-neutral-300 w-full text-base rounded-md m-0"
            expanded={!!values?.content?.[sectionIndex]?.isLayoutExpanded ?? false}
            defaultExpanded={false}
          >
            <AccordionSummary
              className={classNames(
                'border-bottom border-bottom-solid border-bottom-neutral-300 rounded-md max-h-6 min-h-12',
                classes.disableTransform
              )}
              expandIcon={
                !values?.content?.[sectionIndex]?.isLayoutExpanded ? (
                  <AddBoxIcon className="color-neutral-500" />
                ) : (
                  <MinusIcon className="color-neutral-500" />
                )
              }
              onClick={() => handleLayoutSectionAccordionClick(sectionIndex)}
            >
              <Typography className="text-base font-semibold color-neutral-700">Select Layout</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                value={values?.content?.[sectionIndex]?.template ?? ''}
                onChange={(_, value) => handleLayoutChange(value, sectionIndex)}
              >
                <Grid container className="w-full" spacing={2}>
                  {!loading
                    ? layouts.map(layout => (
                        <Grid key={layout.id} item xs={12} lg={6} xl={4}>
                          <ContentTemplate index={sectionIndex} classes={classes} {...layout} />
                        </Grid>
                      ))
                    : Array.from(Array(6).keys()).map((_, index) => (
                        <Grid key={String(index)} item xs={12} lg={6} xl={4} className="w-full">
                          <Box
                            className={classNames(
                              'flex border border-solid rounded-lg my-1 h-40 min-w-50 bg-color-neutral-300 border-neutral-200'
                            )}
                          />
                        </Grid>
                      ))}
                </Grid>
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
          {!section?.template && (
            <FormHelperText className="primary-color-500">
              {(errors.content?.[sectionIndex] as any)?.template}
            </FormHelperText>
          )}
          <Divider className="mt-5 mb-4" />
          {section?.template && layouts && <CMSForm index={sectionIndex} />}
        </AccordionDetails>
      </Accordion>
    </SortableList.Item>
  )
}
