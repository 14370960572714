import { loader } from 'graphql.macro'
import React from 'react'
import { GetSuppliersQuery, Supplier } from '../../generated/graphql'
import { EntitySelectorWrapperProps } from './EntitySelector'
import { InfiniteSelector } from './InfiniteSelector'

const GET_SUPPLIERS = loader('../../graphql/common/getSuppliers.graphql')

export type SupplierSelectorProps = EntitySelectorWrapperProps<Supplier, GetSuppliersQuery>

export const labeller = (supplier?: Supplier | null) => {
  if (!supplier) return ''
  if (supplier?.ABN) {
    return `${supplier?.ABN} - ${supplier.Name}`
  }
  return `${supplier.Name}`
}

const compileData = (data: GetSuppliersQuery) => ({
  data: data.suppliers.items || [],
  pagination: data?.suppliers?.pagination
})

export const SupplierSelector = ({ label = 'Supplier', ...props }: SupplierSelectorProps) => {
  return (
    <InfiniteSelector<Supplier>
      label={label}
      labeller={labeller}
      compileData={compileData}
      query={GET_SUPPLIERS}
      {...props}
    />
  )
}
