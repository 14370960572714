import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { AddBoxIcon, MinusIcon } from 'components/common/icons'
import { useCMSCreatorStyles } from 'pages/Publication/cms/styles'
import React, { PropsWithChildren, ReactChild } from 'react'

type TextInImageAccordionProps = {
  label: string
  index: number
  isExpanded?: boolean
  onClick: () => void
  children: ReactChild
}

export default function TextInImageAccordion({
  label,
  index,
  isExpanded = false,
  onClick,
  children
}: PropsWithChildren<TextInImageAccordionProps>) {
  const classes = useCMSCreatorStyles()

  return (
    <Accordion className="border border-solid border-neutral-300 w-full text-base rounded-md mt-2">
      <AccordionSummary
        className={classNames(
          'border-bottom border-bottom-solid border-bottom-neutral-300 rounded-md m-0 max-h-8 min-h-12',
          classes.disableTransform
        )}
        expandIcon={
          !isExpanded ? <AddBoxIcon className="color-neutral-500" /> : <MinusIcon className="color-neutral-500" />
        }
        onClick={onClick}
      >
        <Typography className="text-base font-semibold color-neutral-700">{`${label} ${index + 1}`}</Typography>
      </AccordionSummary>
      <AccordionDetails className="flex-col my-2">{children}</AccordionDetails>
    </Accordion>
  )
}
