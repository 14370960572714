import { useQuery } from '@apollo/react-hooks'
import { Dialog, DialogContent, Link, TablePagination } from '@material-ui/core'
import { PlatFormOptions } from 'components/common/PlatformSelector'
import { StandardDivider } from 'components/common/StandardDivider'
import { Table } from 'components/common/Table'
import { Formik } from 'formik'
import {
  Audit,
  GetAuditTypeQuery,
  GetAuditTypeQueryVariables,
  GetPaginateAuditAdminQuery,
  GetPaginateAuditAdminQueryVariables,
  PaginateAuditAdminFilterObject,
  Sorter
} from 'generated/graphql'
import { GET_AUDIT_TYPE } from 'graphql/common'
import useRouter from 'hooks/useRouter'
import { keyBy, uniq } from 'lodash'
import React, { useState } from 'react'
import { formatDateInHHDDMMYY, toDisplayIsoDateTimeUtc } from 'utils/dates'
import { emailValidator } from 'utils/tools'
import * as yup from 'yup'
import { FormManageAudit } from './AuditFormFilter'
import { GET_PAGINATE_AUDIT_ADMIN } from './graphql'
import { getTagIDsInMesssage, replacetagIDsText, useGetTaggedResources } from './helper'
import omitBy from 'lodash/omitBy'

export interface AuditItemInputs {
  EndDate?: Date
  StartDate?: Date
  PersonID?: string
  AuditType?: string
  EventType?: string
  ReferenceID?: string
  Email?: string
  ID?: string
  platform?: PlatFormOptions
}
const initialValues: AuditItemInputs = {
  EndDate: undefined,
  StartDate: undefined,
  PersonID: '',
  AuditType: '',
  EventType: '',
  ReferenceID: '',
  Email: '',
  ID: '',
  platform: undefined
}

interface AuditState {
  filter?: PaginateAuditAdminFilterObject
  sorter: Sorter
  currentPage: number
  currentPageSize: number
}

const auditValidationSchema = yup.object({
  EndDate: yup.date().nullable(),
  StartDate: yup.date().nullable(),
  AuditType: yup.object().nullable(),
  Email: yup
    .string()
    .nullable()
    .test('is-email-valid', 'Email address is not valid', emailValidator)
    .max(255, 'Email maximum 255 characters long'),
  ID: yup.number().typeError('ID must be a number')
})

export const ManageAudit = () => {
  const { history } = useRouter()
  const getTaggedResources = useGetTaggedResources()
  const [state, setState] = useState<AuditState>({
    currentPage: 1,
    currentPageSize: 10,
    filter: undefined,
    sorter: { field: 'Audit.UTCtime', orderBy: 'DESC' }
  })
  const [HTML, setHTML] = useState<string>('')
  const { currentPage, currentPageSize, filter, sorter } = state
  const [audits, setAudits] = useState<GetPaginateAuditAdminQuery | null>(null)

  const { loading } = useQuery<GetPaginateAuditAdminQuery, GetPaginateAuditAdminQueryVariables>(
    GET_PAGINATE_AUDIT_ADMIN,
    {
      variables: {
        filters: omitBy(filter, value => !value),
        sorter,
        pagination: {
          pageSize: currentPageSize,
          pageNumber: currentPage
        }
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: async ({ getPaginateAuditAdmin }) => {
        setAudits({ getPaginateAuditAdmin })
        const auditItems = getPaginateAuditAdmin.items
        const tagIDs = uniq(
          auditItems.reduce(
            (ids: string[], item) => [...ids, ...getTagIDsInMesssage(item?.Message), `USER_#${item.PersonID}`],
            []
          )
        )
        const { data } = await getTaggedResources(tagIDs)
        const matchName = keyBy(data.getTaggedResources, 'ID')
        const formatterAudits = auditItems.map(item => ({
          ...item,
          PersonID: `<span> ${item.PersonID}, <span style="font-weight:bold;font-style:italic">${
            matchName[`USER_#${item.PersonID}`]?.Name
          } </span></span>`,
          Message: item.AuditType.trim() !== 'EmailSent' ? replacetagIDsText(matchName, item.Message) : item.Message
        }))
        setAudits({
          getPaginateAuditAdmin: {
            ...getPaginateAuditAdmin,
            items: formatterAudits
          }
        })
      }
    }
  )
  const { data: dataAuditTypes } = useQuery<GetAuditTypeQuery, GetAuditTypeQueryVariables>(GET_AUDIT_TYPE)
  const auditTypes = dataAuditTypes?.getAuditType || []

  const setFilterChange = (filterValues: AuditItemInputs) => {
    const { StartDate, EndDate, PersonID, Email, ReferenceID, AuditType, EventType, platform, ID } = filterValues ?? {}

    const dateRangeFilter = {
      FromDate: StartDate,
      ToDate: EndDate
    }

    const filter: PaginateAuditAdminFilterObject = {
      ID,
      AuditType,
      ReferenceID,
      PersonID,
      Email,
      AuditTypeText: EventType,
      Platform: platform?.ID,
      DateRangeFilter: Object.values(dateRangeFilter).filter(date => !!date).length ? dateRangeFilter : undefined
    }

    setState(prev => ({ ...prev, filter, currentPage: 1 }))
  }

  const setSortChange = (sortKey: string | '', orderType: string | '') => {
    setState(prev => ({
      ...prev,
      sorter: {
        field: `Audit.${sortKey}`,
        orderBy: orderType
      }
    }))
  }

  const columns = [
    {
      title: 'ID',
      field: 'ID',
      width: '7%'
    },
    {
      title: 'EVENT',
      field: 'AuditType',
      width: '10%'
    },
    {
      title: 'PLATFORM',
      field: 'Platform',
      width: '10%'
    },
    {
      title: 'PERSON',
      field: 'PersonID',
      width: '15%',
      render: (row: Audit) => {
        return <div dangerouslySetInnerHTML={{ __html: row.PersonID || '' }} />
      }
    },
    {
      title: 'EMAIL',
      field: 'Email'
    },
    {
      title: 'REFERENCE ID',
      field: 'ReferenceID',
      width: '10%'
    },
    {
      title: 'MESSAGE',
      field: 'Message',
      render: (row: Audit) => {
        return row.AuditType.trim() !== 'EmailSent' ? (
          <div className="word-break" dangerouslySetInnerHTML={{ __html: row.Message || '' }} />
        ) : (
          ''
        )
      },
      headerStyle: {
        minWidth: 500
      },
      cellStyle: {
        minWidth: 500
      }
    },
    {
      title: 'TIMESTAMP UTC',
      field: 'UTCTime',
      render: (row: Audit) => {
        return formatDateInHHDDMMYY(toDisplayIsoDateTimeUtc(new Date(row.UTCTime)))
      }
    },
    {
      title: 'HTML',
      field: 'Message',
      width: '7%',
      render: (row: Audit) => {
        if (row.AuditType.trim() === 'EmailSent') {
          return <Link onClick={() => setHTML(row.Message as string)}>View HTML</Link>
        }
        return ''
      }
    }
  ]

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={auditValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setFilterChange(values)
          history.push(`/manage-audit?q=${JSON.stringify(values)}`)
          setSubmitting(false)
        }}
      >
        {props => {
          return <FormManageAudit form={props} auditTypes={auditTypes} setFilterChange={setFilterChange} />
        }}
      </Formik>
      <StandardDivider />
      <Table
        isLoading={loading}
        columns={columns}
        data={(audits?.getPaginateAuditAdmin?.items as Audit[]) || []}
        option={{ search: false, pageSize: currentPageSize }}
        onOrderChange={(columnIndex, orderDirection) => {
          const columnPath = columnIndex < 0 ? null : columns[columnIndex].field
          setSortChange(columnPath || '', orderDirection)
        }}
        components={{
          Pagination: props => {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={[10, 20, 50]}
                rowsPerPage={currentPageSize}
                count={audits?.getPaginateAuditAdmin.pagination?.total || 0}
                page={currentPage - 1}
                onChangePage={(e, page) => {
                  setState({ ...state, currentPage: page + 1 })
                }}
                onChangeRowsPerPage={e => {
                  props.onChangeRowsPerPage(e)
                  setState({ ...state, currentPageSize: Number(e.target.value) })
                }}
              />
            )
          }
        }}
      />
      <Dialog open={!!HTML} onClose={() => setHTML('')} maxWidth={'md'} fullWidth>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: HTML || '' }} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ManageAudit
