import React, { FC } from 'react'
import { ModuleCategory, GetModuleCategoriesQuery, GetModuleCategoriesQueryVariables } from 'generated/graphql'
import { EntitySelector, EntitySelectorWrapperProps } from 'components/common/EntitySelector'
import { GET_MODULE_CATEGORIES } from 'pages/Modules/graphql'

type ModuleCategorySelectorProps = EntitySelectorWrapperProps<
  ModuleCategory,
  GetModuleCategoriesQuery,
  GetModuleCategoriesQueryVariables
>

const labelEntity = ({ Name }: ModuleCategory) => `${Name}`

const extractEntities = (data: GetModuleCategoriesQuery) => data.getModuleCategories

export const ModuleCategorySelector: FC<ModuleCategorySelectorProps> = ({ label = 'Module Category', ...props }) => {
  return (
    <EntitySelector<ModuleCategory, GetModuleCategoriesQuery, GetModuleCategoriesQueryVariables>
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={GET_MODULE_CATEGORIES}
      {...props}
    />
  )
}
