import React, { PointerEventHandler, createContext, useContext, useMemo } from 'react'
import { CSSProperties, PropsWithChildren } from 'react'
import { DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import './sortableItem.css'
import { DragHandleIcon } from 'components/common/icons'
import classNames from 'classnames'

interface Props {
  id: UniqueIdentifier
  disabled?: boolean
}

interface Context {
  attributes: Record<string, any>
  listeners: DraggableSyntheticListeners
  ref(node: HTMLElement | null): void
}

interface DragHandleProps {
  className?: string
  onPointerDown?: PointerEventHandler<HTMLButtonElement>
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {
    return
  }
})

export function SortableItem({ children, id, disabled = false }: PropsWithChildren<Props>) {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
    disabled
  })
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef
    }),
    [attributes, listeners, setActivatorNodeRef]
  )
  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition
  }

  return (
    <SortableItemContext.Provider value={context}>
      <li className="list-none" ref={setNodeRef} style={style}>
        {children}
      </li>
    </SortableItemContext.Provider>
  )
}

export function DragHandle({ className, onPointerDown }: DragHandleProps) {
  const { attributes, listeners, ref } = useContext(SortableItemContext)

  return (
    <button
      className={classNames('drag-handle cursor-grab', className)}
      {...attributes}
      {...listeners}
      onPointerDown={event => {
        onPointerDown?.(event)
        listeners?.onPointerDown?.(event)
      }}
      ref={ref}
    >
      <DragHandleIcon />
    </button>
  )
}
