import React, { MouseEvent, SetStateAction, Dispatch } from 'react'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { styled } from '@material-ui/core'

const NoWrapToggleButtonGroup = styled(ToggleButtonGroup)({
  whiteSpace: 'nowrap'
})

export interface ToggleGroupProps<T = string> {
  center?: T
  disabled?: boolean
  id?: string
  left: T
  right: T
  value: T
  setValue?: Dispatch<SetStateAction<T>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  onChange?: () => void
}

export const ToggleGroup = <T,>({
  center,
  disabled,
  id,
  left,
  right,
  setFormValue,
  setValue,
  value,
  onChange
}: ToggleGroupProps<T>) => {
  const handleChange = (_event: MouseEvent<HTMLElement>, newValue: T) => {
    if (newValue !== null) {
      if (onChange) onChange()
      if (setValue) setValue(newValue)
      if (setFormValue && id) setFormValue(id, newValue)
    }
  }

  return (
    <NoWrapToggleButtonGroup id={id} value={value} exclusive onChange={handleChange} aria-label="text alignment">
      <ToggleButton aria-label={`${left} aligned`} disabled={disabled} value={left}>
        {left}
      </ToggleButton>
      {center ? (
        <ToggleButton aria-label={`${center} aligned`} disabled={disabled} value={center}>
          {center}
        </ToggleButton>
      ) : null}
      <ToggleButton aria-label={`${right} aligned`} disabled={disabled} value={right}>
        {right}
      </ToggleButton>
    </NoWrapToggleButtonGroup>
  )
}
