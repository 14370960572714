import { CreateNewsArticleInput, NewsArticle } from 'generated/graphql'
import { convertBase64toUTF8, convertUTF8ToBase64 } from 'utils/tools'
import { TEMPLATE_COMPONENTS } from '../constants'
import {
  CMSCreatorValues,
  Devices,
  HeaderBackgroundOptions,
  PreviewTemplateValue,
  SettingTheme,
  TextPosition,
  UploadImageCountCategories
} from '../types'
import { getTemplateTitle } from '../utils'

export const formatCMSCreatorForGraphql = ({ content, setting, title }: CMSCreatorValues): CreateNewsArticleInput => {
  return {
    Theme: setting.theme,
    Title: title,
    URL: '',
    sections: content.map((section, index) => ({
      HeaderBackgroundSolidColor: section.backgroundColor?.trim() || undefined,
      HeaderHeightPercentage: section.backgroundColor?.trim() ? section.backgroundColorHeight : undefined,
      SequenceNo: index,
      NewsArticleSectionLayoutID: String(section.template),
      images:
        section.images?.length && section.template !== PreviewTemplateValue.OnlyVideo
          ? section.images?.map((image, index) => ({
              ResourcePath: image,
              EncodedHTMLText: convertUTF8ToBase64(section.textInImages?.[index]?.description ?? ''),
              TextPosition: section.textInImages?.[index]?.position?.ID ?? TextPosition.Below,
              SequenceNo: index
            }))
          : undefined,
      texts: section.textEditors?.map((text, index) => ({
        SequenceNo: index,
        EncodedHTMLText: convertUTF8ToBase64(text?.description)
      })),
      VideoResourcePath:
        section.template === PreviewTemplateValue.OnlyVideo
          ? convertUTF8ToBase64(section.images?.join(','))
          : undefined,
      ImagesPerRow: [PreviewTemplateValue.Images].includes(section?.template as PreviewTemplateValue)
        ? section.imagesPerRow ?? 1
        : undefined,
      Size:
        [PreviewTemplateValue.Images, PreviewTemplateValue.OnlyVideo].includes(
          section?.template as PreviewTemplateValue
        ) && section.size?.ID !== 'Default'
          ? section.size?.ID
          : undefined,
      Position: [PreviewTemplateValue.Images, PreviewTemplateValue.OnlyVideo].includes(
        section?.template as PreviewTemplateValue
      )
        ? section?.position?.ID
        : undefined
    }))
  }
}

export const formatCMSCreatorForClient = (values: NewsArticle): CMSCreatorValues => ({
  title: values?.Title,
  setting: {
    theme: values?.Theme as SettingTheme,
    device: Devices.Tablet
  },
  content:
    values?.sections?.map((section, index) => {
      const uploadImageCountCategories = (String(section.images?.length) as unknown) as UploadImageCountCategories
      return {
        id: section.ID,
        headerBackgroundOption: section.images?.length ? HeaderBackgroundOptions.Image : HeaderBackgroundOptions.Color,
        template: section.NewsArticleSectionLayoutID
          ? Number(section.NewsArticleSectionLayoutID)
          : PreviewTemplateValue.Header,
        templateComponent: section.NewsArticleSectionLayoutID
          ? TEMPLATE_COMPONENTS[Number(section.NewsArticleSectionLayoutID) - 1]
          : TEMPLATE_COMPONENTS[PreviewTemplateValue.Header - 1],
        images: section.images?.length
          ? section.images?.map(image => image.ResourcePath)
          : section.VideoResourcePath
          ? convertBase64toUTF8(section.VideoResourcePath)?.split(',')
          : [],
        description: convertBase64toUTF8(section.texts?.[0]?.EncodedHTMLText ?? ''),
        sectionName: getTemplateTitle(section?.layout?.Name ?? ''),
        backgroundColor: section.HeaderBackgroundSolidColor ?? '',
        backgroundColorHeight: section.HeaderHeightPercentage ?? 0,
        isExpanded: index === 0,
        isLayoutExpanded: false,
        maximumImage: section.images?.length
          ? { ID: uploadImageCountCategories, label: uploadImageCountCategories }
          : undefined,
        textEditors: section.texts?.map(text => ({
          description: convertBase64toUTF8(text.EncodedHTMLText),
          order: text.SequenceNo,
          template: section.NewsArticleSectionLayoutID
            ? Number(section.NewsArticleSectionLayoutID)
            : PreviewTemplateValue.Header
        })),
        textInImages: section.images?.map(image => ({
          description: convertBase64toUTF8(image.EncodedHTMLText ?? ''),
          position: {
            ID: (image.TextPosition as TextPosition) ?? TextPosition.Below,
            label: (image.TextPosition as TextPosition) ?? TextPosition.Below
          },
          isExpanded: false
        })),
        imagePreviews: [],
        imagesPerRow: section.ImagesPerRow ?? 1,
        size: { ID: section.Size ?? 'Default', label: section.Size ?? 'Default' },
        position: { ID: section.Position ?? 'Centre', label: section.Position ?? 'Centre' }
      }
    }) ?? []
})
