import { useEffect, useState } from 'react'
import { GetFileQuery, GetFileQueryVariables } from 'generated/graphql'
import useFileUrl from 'hooks/useFileUrl'
import { GET_FILE } from 'graphql/common'
import { PromiseSettledResult } from 'utils/types'

export const useImagePreviews = (
  images: string[],
  imagePreviews: string[],
  callback?: (uploadImages: string[]) => void
) => {
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const getFileUrl = useFileUrl<GetFileQuery, GetFileQueryVariables>()

  useEffect(() => {
    const handleImagePreviews = async () => {
      try {
        if (!imagePreviews?.length) {
          const uploadedImages: PromiseSettledResult[] = await Promise.allSettled(
            images.map(async image => {
              if (image?.includes('https')) {
                return image
              }
              const {
                data: {
                  getFile: { Url }
                }
              } = await getFileUrl({ query: GET_FILE, variables: { fileKey: image }, fetchPolicy: 'cache-first' })

              return Url
            })
          )
          const imageValues = uploadedImages
            .filter(image => image.status === 'fulfilled')
            .map(image => image.value ?? '')
          setImageUrls(imageValues)
          callback?.(imageValues)
        }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
    handleImagePreviews()
    // eslint-disable-next-line
  }, [images])

  return {
    renderImages: imagePreviews.length
      ? imagePreviews
      : images.map(image => {
          const index = imageUrls.findIndex(fileUrl => fileUrl.includes(image))
          return imageUrls[index]
        }),
    loading
  }
}
