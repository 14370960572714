import React from 'react'
import { Box, FormControlLabel, Radio, Typography } from '@material-ui/core'
import { CMSCreatorValues, SettingTheme } from 'pages/Publication/cms/types'
import classNames from 'classnames'
import { useCMSCreatorStyles } from 'pages/Publication/cms/styles'
import { useFormikContext } from 'formik'

interface SettingTemplateProps {
  theme: SettingTheme
}

export function SettingTemplate({ theme }: SettingTemplateProps) {
  const classes = useCMSCreatorStyles()
  const { values } = useFormikContext<CMSCreatorValues>()

  return (
    <Box>
      <Box
        className={classNames(
          'flex relative cursor-pointer rounded-lg my-1 border-2 border-solid',
          theme === SettingTheme.DarkMode ? 'bg-primary-color-900' : 'bg-color-white',
          values?.setting?.theme === theme ? 'primary-color-700' : 'color-neutral-200'
        )}
        height="9rem"
      >
        <Box>
          <FormControlLabel
            className="absolute w-full h-full z-50"
            value={theme}
            control={
              <Radio
                className={classes.root}
                disableRipple
                color="default"
                checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
              />
            }
            label=""
          />
        </Box>
        <Box className="flex justify-center items-center w-full">
          <Typography
            className={classNames(
              'text-xl font-semibold',
              theme === SettingTheme.DarkMode ? 'color-white' : 'primary-color-900'
            )}
          >
            Abc
          </Typography>
        </Box>
      </Box>
      <Typography className="text-base text-center mt-2">
        {theme === SettingTheme.DarkMode ? 'Dark Mode' : 'Light Mode'}
      </Typography>
    </Box>
  )
}
