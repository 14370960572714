import { Box, Grid } from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import classNames from 'classnames'
import TooltipToggleButton from 'components/common/mui/TooltipToggleButton'
import { useFormikContext } from 'formik'
import { CMSCreatorValues, Devices, SettingTheme } from 'pages/Publication/cms/types'
import React from 'react'
import { DEVICES } from '../../constants'
import ContentPreview from './components/ContentPreview'

export default function RightPreviewContent() {
  const { values, setFieldValue } = useFormikContext<CMSCreatorValues>()
  const handleDeviceChange = (device: string | null) => {
    if (!!device) {
      setFieldValue('setting.device', device)
    }
  }

  return (
    <Grid
      item
      xs={12}
      md={values?.setting?.device !== Devices.Desktop ? 7 : 12}
      className="flex flex-col items-center min-h-full w-full overflow-hidden"
    >
      <Box
        className={classNames('min-h-full', values?.setting?.device === Devices.Mobile ? 'w-96' : 'w-full', {
          'bg-primary-color-900 color-white': values?.setting?.theme === SettingTheme.DarkMode,
          'border-neutral-200':
            values?.setting?.theme === SettingTheme.LightMode && values?.setting?.device === Devices.Tablet
        })}
      >
        <ContentPreview />
      </Box>
      <Box className="flex justify-end fixed mr-1 bottom-8 right-8">
        <ToggleButtonGroup
          className="border-neutral-200"
          value={values?.setting?.device}
          onChange={(_, value) => handleDeviceChange(value)}
          exclusive
          orientation="vertical"
          aria-label="device"
        >
          {DEVICES.map(({ label, title, icon: Icon }) => (
            <TooltipToggleButton
              key={title}
              className="color-grey"
              TooltipProps={{ title: <span className="text-sm">{label}</span>, placement: 'left' }}
              value={title}
            >
              <Icon />
            </TooltipToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </Grid>
  )
}
