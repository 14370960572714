import { makeStyles } from '@material-ui/core'

export const useGlobalStyles = makeStyles(theme => ({
  showUpAndDownArrows: {
    "& input[type='number']::-webkit-inner-spin-button,input[type='number']::-webkit-outer-spin-button": {
      opacity: 1
    }
  },
  toolbars: {
    display: 'flex',
    padding: '6px',
    justifyContent: 'flex-end'
  },
  selectAll: {
    fontSize: '1.3rem',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    outline: 'none',
    position: 'relative',
    color: 'var(--color-blue)',
    cursor: 'pointer',
    fontWeight: 500,
    height: '36px',
    minWidth: '80px',
    marginLeft: '4px',
    padding: '0 8px',
    textDecoration: 'none',
    whiteSpace: 'pre-wrap',
    '&:hover': {
      background: 'var(--color-neutral-200)',
      borderRadius: '4px',
      outline: '1px solid transparent',
      transform: 'scale(1)',
      transitionProperty: 'transform,opacity',
      transitionDuration: '0.15s'
    }
  },
  section: {
    padding: '20px 0'
  },
  greenBtn: {
    backgroundColor: theme?.palette?.success?.main,
    color: 'var(--color-white)',
    fontWeight: 600,
    transition: 'all 0.5s ease',
    '&:hover': {
      backgroundColor: theme?.palette?.success?.dark
    }
  },
  redBtn: {
    backgroundColor: theme?.palette?.error?.main,
    color: 'var(--color-white)',
    fontWeight: 600,
    transition: 'all 0.5s ease',
    '&:hover': {
      backgroundColor: theme?.palette?.error?.dark
    }
  },
  neutralBtn: {
    color: 'var(--color-neutral-500)',
    fontWeight: 600,
    transition: 'all 0.5s ease',
    background: 'var(--color-neutral-200)'
  },
  orangeBtn: {
    background: 'var(--alert-color)',
    color: 'var(--color-white)',
    fontWeight: 600,
    transition: 'all 0.5s ease',
    '&:hover': {
      backgroundColor: theme?.palette?.warning?.dark
    }
  },
  greenOutlinedBtn: {
    border: '1px solid var(--success-color)',
    color: 'var(--success-color)',
    fontWeight: 600,
    transition: 'all 0.5s ease',
    background: 'var(--success-color-soft)'
  },
  redOutlinedBtn: {
    border: '1px solid var(--primary-color-700)',
    color: 'var(--primary-color-700)',
    fontWeight: 600,
    transition: 'all 0.5s ease',
    background: 'var(--error-color-soft)'
  },

  roundedInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .MuiInputBase-root, & .keyboardTextColor .Mui-disabled, & .keyboardTimePicker': {
      backgroundColor: 'var(--color-white)',
      borderRadius: '0.5rem !important'
    },
    '& input': {
      color: 'var(--color-neutral)'
    },
    '& fieldset': {
      borderRadius: '0.5rem !important'
    },
    '& .MuiIconButton-root': {
      padding: '0.5rem'
    },
    '& .Mui-disabled': {
      backgroundColor: 'var(--color-neutral-100)',
      borderRadius: '0.5rem !important'
    },
    '& .MuiInput-input.MuiInputBase-inputAdornedEnd': {
      padding: '11px',
      borderRadius: '0.5rem !important'
    },
    '& .MuiInput-underline': {
      border: '1px solid var(--color-neutral-300)'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:after, & .MuiInput-underline:before ': {
      borderBottom: 0
    },
    '& .MuiInputAdornment-positionEnd .Mui-disabled': {
      backgroundColor: 'transparent'
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'unset'
    },
    '& .MuiAutocomplete-endAdornment button': {
      padding: '0.1rem'
    }
  },
  innerHTML: {
    '& > p': {
      margin: 0
    }
  },
  table: {
    '& > div': {
      border: '1px solid var(--color-neutral-400)'
    },
    '& > div > div': {
      borderRadius: '0.5rem !important'
    },
    '& .MuiPaper-elevation2': {
      boxShadow: 'none'
    }
  }
}))
