import React, { createContext, Component, useRef } from 'react'
import { createPortal } from 'react-dom'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { Feedback, getSuccessFeedback, getErrorFeedback } from 'utils/feedback'
import { Dialog, DialogContent, Button, Typography, DialogTitle, DialogActions } from '@material-ui/core'

interface SetNoticeMessageParams {
  title?: string
  message?: string
  handleClick?: () => void
}

export interface SnackbarDataInterface {
  setSuccessMessage: (message?: string) => void | null
  setErrorMessage: (message?: string) => void | null
  setNoticeMessage: (params: SetNoticeMessageParams) => void | null
}
const DEFAULT_SNACKBAR_DATA = {
  setSuccessMessage: () => null,
  setErrorMessage: () => null,
  setNoticeMessage: () => null
}

interface SnackbarProviderProps {
  children: any
}

export const SnackbarContext = createContext<SnackbarDataInterface>(DEFAULT_SNACKBAR_DATA)

type SnackBarState = {
  feedback: Feedback | null
  notice: string | null
  title: string | null
  isOpenNotice: boolean
  handleClick?: () => void
}

type SnackBarProps = NonNullable<any>

class SnackBar extends Component<NonNullable<any>, SnackBarState> {
  constructor(props: SnackBarProps) {
    super(props)
    this.state = {
      feedback: null,
      notice: null,
      title: null,
      handleClick: undefined,
      isOpenNotice: false
    }
  }

  setFeedback = (feedback: Feedback | null) => {
    this.setState({
      feedback
    })
  }

  setNotice = ({
    message = '',
    title = '',
    handleClick
  }: {
    title?: string
    message?: string
    handleClick?: () => void
  }) => {
    this.setState({
      notice: message || '',
      title,
      isOpenNotice: true,
      handleClick: handleClick
    })
  }

  handleClose = () => {
    this.setFeedback(null)
  }

  render() {
    const { feedback, notice, isOpenNotice, title, handleClick } = this.state
    return (
      <>
        {createPortal(
          <AutoHideSnackBar
            open={!!feedback}
            message={feedback?.message || ''}
            severity={feedback?.type}
            handleClose={this.handleClose}
          />,
          document.body
        )}
        <Dialog onClose={() => this.setState({ isOpenNotice: false })} open={isOpenNotice}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              <span dangerouslySetInnerHTML={{ __html: notice || '' }} />
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClick?.()
                this.setState({ isOpenNotice: false })
              }}
              color="secondary"
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export const SnackbarProvider = (props: SnackbarProviderProps) => {
  const ref = useRef<any>(null)
  const snackbarData = {
    setSuccessMessage: (message?: string) => ref?.current?.setFeedback(getSuccessFeedback(message as string)),
    setErrorMessage: (message?: string) => ref?.current?.setFeedback(getErrorFeedback(message as string)),
    setNoticeMessage: (params: SetNoticeMessageParams) => ref?.current?.setNotice(params)
  }
  return (
    <SnackbarContext.Provider value={snackbarData}>
      {props.children}
      <SnackBar ref={ref} />
    </SnackbarContext.Provider>
  )
}
