import React, { useState } from 'react'
import { Button, Grid, Typography, TablePagination } from '@material-ui/core'
import { Table } from 'components/common/Table'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_PAGING_PROJECT_LOGO, UPSERT_PROJECT_LOGO, REMOVE_PROJECT_LOGO } from './graphql'
import {
  GetPagingProjectLogoQuery,
  GetPagingProjectLogoQueryVariables,
  UpsertProjectLogoMutation,
  UpsertProjectLogoMutationVariables,
  RemoveProjectLogoMutation,
  RemoveProjectLogoMutationVariables,
  ProjectLogo
} from 'generated/graphql'
import usePagination from 'hooks/usePagination'
import { ListImage } from 'components/common/ListImage'
import { Formik } from 'formik'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import UploadImage from 'components/common/UploadImage'
import { EditIcon, DeleteIcon } from 'components/common/icons'
import * as yup from 'yup'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { scrollToTop } from 'utils/tools'
import { withPermissionGuard } from 'HoCs'
import { Privileges } from 'utils/rolesAndPrivileges'

const columns = [
  {
    title: 'ID',
    field: 'ID'
  },
  {
    title: 'Logo Name',
    field: 'Name'
  },
  {
    title: 'Preview',
    render: (rowData: ProjectLogo) => rowData.ID !== '1' && <ListImage imageData={[rowData?.Url || '']} />
  }
]

interface UpsertLogoForm {
  name: string
  ImageData: string
}

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .trim()
    .max(1000, 'Image name must be less than 1000 characters')
    .required(),
  ImageData: yup
    .string()
    .nullable()
    .required('Logo is required')
})

export const ManageLogo = withPermissionGuard(
  () => {
    const { currentPageSize, onChangeRowsPerPage, currentPage, setCurrentPage } = usePagination('pageSizeProjectLogo')
    const [selectedItem, setSelectedItem] = useState<ProjectLogo | null>(null)
    const [deletedItem, setDeletedItem] = useState<ProjectLogo | null>(null)

    const [isRemovePreviewImage, setIsRemovePreviewImage] = useState<boolean>(false)
    const { setSuccessMessage, setErrorMessage } = useSnackbarContext()

    const { data: tableData, loading: tableLoading, refetch } = useQuery<
      GetPagingProjectLogoQuery,
      GetPagingProjectLogoQueryVariables
    >(GET_PAGING_PROJECT_LOGO, {
      variables: {
        pagination: {
          pageNumber: currentPage,
          pageSize: currentPageSize
        }
      },
      fetchPolicy: 'cache-and-network'
    })

    const [upsertProjectLogo, { loading: upsertLogoLoading }] = useMutation<
      UpsertProjectLogoMutation,
      UpsertProjectLogoMutationVariables
    >(UPSERT_PROJECT_LOGO)

    const [removeProjectLogo, { loading: removeLogoLoading }] = useMutation<
      RemoveProjectLogoMutation,
      RemoveProjectLogoMutationVariables
    >(REMOVE_PROJECT_LOGO)

    const handleDelete = () => {
      removeProjectLogo({
        variables: {
          ID: String(deletedItem?.ID)
        }
      })
        .then(_ => {
          setSuccessMessage('Delete logo successfully!')
          if (selectedItem?.ID === deletedItem?.ID) {
            setSelectedItem(null)
          }
          setDeletedItem(null)
          refetch()
        })
        .catch(error => setErrorMessage(error.message))
    }

    const initForm: UpsertLogoForm = {
      name: selectedItem?.Name || '',
      ImageData: selectedItem?.ImageData || ''
    }

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={initForm}
          validationSchema={validationSchema}
          onSubmit={({ name, ImageData }, { resetForm }) => {
            upsertProjectLogo({
              variables: {
                input: {
                  ID: selectedItem?.ID,
                  Name: name.trim(),
                  ImageData: ImageData
                }
              }
            })
              .then(() => {
                setIsRemovePreviewImage(true)
                resetForm()
                setSuccessMessage(!selectedItem?.ID ? 'Create logo successfully!' : 'Update logo successfully!')
                setSelectedItem(null)
                refetch()
              })
              .catch(err => setErrorMessage(err.message))
              .finally(() => setIsRemovePreviewImage(false))
          }}
        >
          {({ values, setFieldValue, handleSubmit, errors }) => {
            return (
              <form onSubmit={handleSubmit} style={{ marginBottom: '1.5rem' }}>
                <Grid container alignItems="center">
                  <Grid item md={6} xs={12} style={{ marginRight: '1rem' }}>
                    <GridLayoutInput
                      onChange={e => setFieldValue('name', e.target.value)}
                      name="name"
                      label="Name*"
                      value={values?.name}
                      fullWidth
                      error={!!errors?.name}
                      helperText={errors?.name?.includes('required') ? 'Name is a required field' : ''}
                    />
                  </Grid>

                  <Grid item style={{ marginRight: '1rem' }}>
                    <UploadImage
                      defaultValue={selectedItem?.Url}
                      onReturnValue={value => {
                        setFieldValue('ImageData', value)
                      }}
                      isRemovePreviewImage={isRemovePreviewImage}
                    />
                    {errors?.ImageData && <Typography color="error">{errors?.ImageData}</Typography>}
                  </Grid>
                  <Grid item xs={1}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button
                        color="secondary"
                        type="submit"
                        style={{ marginRight: '1rem' }}
                        variant="contained"
                        disabled={upsertLogoLoading}
                      >
                        {!selectedItem ? 'Add' : 'Save'}
                      </Button>
                      {!!selectedItem && (
                        <Button variant="contained" disabled={upsertLogoLoading} onClick={() => setSelectedItem(null)}>
                          Cancel
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        </Formik>

        <Table<ProjectLogo>
          actions={[
            rowData => ({
              icon: () => <EditIcon color="primary" />,
              tooltip: 'Edit',
              hidden: rowData.ID === '1',
              onClick: (_event, rowData: ProjectLogo | ProjectLogo[]) => {
                if (!Array.isArray(rowData)) {
                  setSelectedItem(rowData)
                  scrollToTop()
                }
              }
            }),
            rowData => ({
              icon: () => <DeleteIcon spacing={4} color="secondary" />,
              tooltip: 'Remove',
              hidden: rowData.ID === '1',
              onClick: (_event, rowData: ProjectLogo | ProjectLogo[]) => {
                if (!Array.isArray(rowData)) {
                  setDeletedItem(rowData)
                }
              }
            })
          ]}
          isLoading={tableLoading}
          data={(tableData?.getPagingProjectLogo?.items as ProjectLogo[]) || []}
          columns={columns}
          search={false}
          components={{
            Pagination: props => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 20, 50]}
                  rowsPerPage={currentPageSize}
                  count={tableData?.getPagingProjectLogo?.pagination?.total || 0}
                  page={currentPage - 1}
                  onChangePage={(e, page) => {
                    setCurrentPage(page + 1)
                  }}
                  onChangeRowsPerPage={e => {
                    onChangeRowsPerPage(e, props)
                  }}
                />
              )
            }
          }}
        />

        {!!deletedItem && (
          <ConfirmDialog
            fullWidth
            open={!!deletedItem}
            handleClose={() => !removeLogoLoading && setDeletedItem(null)}
            onConfirm={handleDelete}
            description="Are you sure want to delete this logo?"
            title="Confirm delete logo"
            buttonText="Delete"
          />
        )}
      </>
    )
  },
  { page: 'Project Logo', privileges: [Privileges.ManageProjectLogo] }
)
