import React from 'react'
import { BatchProcess } from 'generated/graphql'
import { Column } from 'material-table'
import { formatDateInDDMMYYYY, toDisplayIsoDateTimeUtc } from 'utils/dates'
import { CircularProgress } from '@material-ui/core'
import { UploadTableStatus } from 'utils/types'

const getUploadStatus = (status: string, fileUrl?: string | null) => {
  switch (status) {
    case UploadTableStatus.CompletedWithError:
    case UploadTableStatus.Error:
      return (
        <span className="primary-color-700">
          <b>{status}</b>
        </span>
      )
    case UploadTableStatus.ProcessingWithError:
      return (
        <span className="alert-color">
          <b>
            {status} {fileUrl ? '' : <CircularProgress className="w-3 h-3 alert-color" size="small" />}
          </b>
        </span>
      )
    case UploadTableStatus.Completed:
      return (
        <span className="success-color">
          <b>{status}</b>
        </span>
      )
    case UploadTableStatus.Processing:
    default:
      return (
        <span className="color-blue">
          <b>
            {status} {fileUrl ? '' : <CircularProgress className="w-3 h-3 color-blue" size="small" />}
          </b>
        </span>
      )
  }
}

const uploadColumns: Column<BatchProcess>[] = [
  {
    title: 'ID',
    field: 'ID',
    sorting: false
  },
  {
    title: 'Created Date',
    field: 'CreatedDate',
    render: row => {
      return formatDateInDDMMYYYY(toDisplayIsoDateTimeUtc(new Date(row.CreatedDate)))
    },
    sorting: false
  },
  {
    title: 'Status',
    field: 'Status',
    render: row => getUploadStatus(row.Status, row.FileUrl),
    sorting: false
  },
  {
    title: 'Error Count',
    field: 'ErrorCount',
    sorting: false
  },
  {
    title: 'Success Count',
    field: 'SuccessCount',
    sorting: false
  },
  {
    title: 'Total Count',
    field: 'TotalCount',
    sorting: false
  }
]

export default uploadColumns
