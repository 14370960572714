import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Column } from 'material-table'
import { Formik } from 'formik'
import { useQuery } from '@apollo/react-hooks'
import * as yup from 'yup'
import { Table } from '../../../components/common/Table'
import { EditIcon } from 'components/common/icons'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { StandardDivider } from 'components/common/StandardDivider'
import { GET_PAYROLL_SUB_CLASSIFICATIONS } from './graphql/index'
import {
  PayrollSubClassification,
  GetPayrollSubClassificationsQuery,
  GetPayrollSubClassificationsQueryVariables
} from '../../../generated/graphql'
import useRouter from 'hooks/useRouter'
import { Feedback } from 'utils/feedback'
import { REQUIRED } from 'utils/Constants'
import FormFilterEBASetup from './FormFilterEBASetup'
import { FormPayrollSubClassification } from './FormPayrollSubClassification'

const validationSchema = yup.object({
  PayrollClassificationID: yup
    .string()
    .nullable()
    .required(`EBA ${REQUIRED}`),
  CompanyID: yup
    .string()
    .nullable()
    .required(`Company ${REQUIRED}`)
})

const columns: Column<PayrollSubClassification>[] = [
  {
    title: 'Active?',
    field: 'IsActive',
    render: (row: PayrollSubClassification) => <span>{row?.IsActive ? 'Yes' : 'No'}</span>
  },
  {
    title: 'Subclassification',
    field: 'SubClassificationDisplayName',
    render: (row: PayrollSubClassification) => row?.SubClassificationDisplayName
  },
  {
    title: 'Group',
    field: 'GroupName',
    render: (row: PayrollSubClassification) => row?.GroupName
  },
  {
    title: 'Rank',
    field: 'RankWithinGroup',
    render: (row: PayrollSubClassification) => row?.RankWithinGroup
  }
]

type EBASetupFiltersAndFormProps = {
  PayrollClassificationID: string
  CompanyID: string
}

export const EBASetup = () => {
  const { history } = useRouter()
  const [selectedItem, setSelectedItem] = useState<PayrollSubClassification>()
  const [autoHideFeedback, setAutoHideFeedback] = React.useState<Feedback | null>(null)
  const [filter, setFilter] = useState<EBASetupFiltersAndFormProps>({
    PayrollClassificationID: '',
    CompanyID: ''
  })

  const initFormValues: EBASetupFiltersAndFormProps = {
    PayrollClassificationID: '',
    CompanyID: ''
  }

  const { data, loading, refetch } = useQuery<
    GetPayrollSubClassificationsQuery,
    GetPayrollSubClassificationsQueryVariables
  >(GET_PAYROLL_SUB_CLASSIFICATIONS, {
    variables: {
      PayrollClassificationID: filter.PayrollClassificationID,
      CompanyID: filter.CompanyID
    },
    skip: !(filter.PayrollClassificationID && filter.CompanyID)
  })

  const setFilterChange = (filterValues: any) => {
    setFilter({
      PayrollClassificationID: filterValues.PayrollClassificationID,
      CompanyID: filterValues.CompanyID
    })
    refetch({
      PayrollClassificationID: filterValues.PayrollClassificationID ?? '',
      CompanyID: filterValues.CompanyID ?? ''
    })
  }

  return (
    <>
      <Formik
        initialValues={initFormValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setFilterChange(values)
          history.push(`/payroll?q=${JSON.stringify(values)}`)
          setSubmitting(false)
        }}
      >
        {props => {
          return <FormFilterEBASetup form={props} setFilterChange={setFilterChange} />
        }}
      </Formik>
      <StandardDivider />
      <Grid item xs={12}>
        <Table<PayrollSubClassification>
          columns={columns}
          isLoading={loading}
          data={data?.getPayrollSubClassifications || []}
          option={{
            search: false,
            pageSize: 10
          }}
          actions={[
            rowData => ({
              icon: () => (
                <Grid container justifyContent="center" direction="row" alignItems="center">
                  <EditIcon color="secondary" style={{ fontSize: 15 }} />
                  <Typography color="secondary" variant="body2">
                    Edit
                  </Typography>
                </Grid>
              ),
              hidden: !rowData.IsActive,
              tooltip: 'Edit',
              position: 'row',
              onClick: (_event, rowData: PayrollSubClassification | PayrollSubClassification[]) => {
                if (!Array.isArray(rowData)) setSelectedItem(rowData)
              }
            })
          ]}
        />
      </Grid>
      {selectedItem && (
        <FormPayrollSubClassification
          handleClose={() => setSelectedItem(undefined)}
          item={selectedItem}
          setAutoHideFeedback={setAutoHideFeedback}
          refetch={refetch}
        />
      )}
      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setAutoHideFeedback(null)}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />
    </>
  )
}
export default EBASetup
