import { useState } from 'react'
import { Sorter } from 'generated/graphql'
import { debounce } from 'lodash'
import { getLocalStorageItem, setLocalStorageItem } from 'utils/tools'

const usePagination = (key: string) => {
  const pageSize = getLocalStorageItem(key)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize ? parseInt(pageSize) : 10)
  const [currentPage, setCurrentPage] = useState(1)
  const [sorter, setSorter] = useState<Sorter | undefined>(undefined)
  const [filter, setFilter] = useState('')

  const onChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    props?: { onChangeRowsPerPage: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void }
  ) => {
    props?.onChangeRowsPerPage(e)
    setCurrentPageSize(Number(e.target.value))
    setLocalStorageItem(key, e.target.value)
    setCurrentPage(1)
    setLocalStorageItem(key, e.target.value)
  }

  const onChangePage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setCurrentPage(page + 1)
  }

  const onFilter = debounce(search => {
    setCurrentPage(1)
    setFilter(search)
  }, 500)

  return {
    onChangeRowsPerPage,
    currentPageSize,
    filter,
    onFilter,
    sorter,
    setSorter,
    setCurrentPageSize,
    currentPage,
    setCurrentPage,
    onChangePage
  }
}

export default usePagination
