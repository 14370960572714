import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  logo: {
    width: '7.5rem',
    marginBottom: '2rem',
    alignSelf: 'center'
  },
  question: {
    width: '2.25rem',
    marginTop: '2.5rem',
    alignSelf: 'center'
  },
  container: {
    maxWidth: 460,
    borderColor: theme.palette.background.default,
    borderStyle: 'solid',
    borderWeight: '1px',
    boxShadow: '0 1px 3px 0 rgba(162, 162, 162, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: '2rem'
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: '1rem',
    alignSelf: 'center',
    fontWeight: 'bold'
  },
  form: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    padding: '2rem 0',
    '& .MuiFormControl-root': {
      marginBottom: '2rem'
    }
  },
  containerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  instructionLink: {
    marginTop: 10,
    color: 'black',
    alignSelf: 'center'
  }
}))
