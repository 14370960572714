import React from 'react'
import { Box, Typography } from '@material-ui/core'
import EmptyIcon from 'images/icons/empty-icon.svg'

interface EmptyTableBodyProps {
  description?: string
}

export default function EmptyTableBody({ description = '' }: EmptyTableBodyProps) {
  return (
    <Box
      position="absolute"
      width="100%"
      height="calc(100% - 5rem)"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <img src={EmptyIcon} alt="Empty" />
      <Typography className="color-neutral-400">{description}</Typography>
    </Box>
  )
}
