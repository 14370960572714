import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import { ImagePreview } from 'components/ImagePreview'
import {
  GetFileQuery,
  GetFileQueryVariables,
  UploadImageMutation,
  UploadImageMutationVariables
} from 'generated/graphql'
import { GET_FILE, UPLOAD_IMAGE } from 'graphql/common'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { FILE_TYPE } from 'utils/Constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    input: {
      display: 'none'
    },
    uploadWrapper: {
      background: 'var(--surface-secondary)',
      width: 120,
      height: 120,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    },
    icon: {
      padding: 0
    }
  })
)

interface UploadImageProps {
  onReturnValue?: (value: string) => void
  disabled?: boolean
  defaultValue?: string | null
  isRemovePreviewImage?: boolean
  id?: string
  isMultiImages?: boolean
  limitSize?: number
  accept?: string
  uploadTitle?: string
  isShowVideo?: boolean
}

const UploadImage = ({
  onReturnValue,
  disabled,
  defaultValue,
  isRemovePreviewImage,
  id = '',
  isMultiImages = false,
  limitSize = 10,
  accept = 'image/*',
  uploadTitle = 'Add Image',
  isShowVideo: defaultIsShowVideo = false
}: UploadImageProps) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [previewImage, setPreviewImage] = useState<string | null | undefined>(defaultValue)
  const [isShowVideo, setIsShowVideo] = useState(defaultIsShowVideo)
  const { setErrorMessage } = useSnackbarContext()

  const inputId = `icon-button-file-${id}`

  const { data } = useQuery<GetFileQuery, GetFileQueryVariables>(GET_FILE, {
    variables: {
      fileKey: previewImage || ''
    },
    skip: !previewImage || previewImage?.includes('https'),
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    setPreviewImage(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (isRemovePreviewImage) {
      setPreviewImage(null)
    }
  }, [isRemovePreviewImage])

  const [uploadImage] = useMutation<UploadImageMutation, UploadImageMutationVariables>(UPLOAD_IMAGE)
  const onChange = async ({
    target: {
      files: [file]
    }
  }: any) => {
    setIsShowVideo(file.type.includes(FILE_TYPE.video))
    setLoading(true)
    try {
      const isLargeThanLimitSize = file.size / 1024 / 1024 > limitSize
      if (isLargeThanLimitSize) {
        throw new Error(`Image must smaller than ${limitSize}MB!`)
      }
      const presignData = await uploadImage({
        variables: {
          input: {
            Type: file.type,
            Name: file.name,
            Module: 'dpi'
          }
        }
      })
      if (presignData.data?.uploadImage) {
        const response = await fetch(presignData.data?.uploadImage.UploadUrl, {
          method: 'PUT',
          body: file
        })
        if (response.status === 200) {
          setLoading(false)
          setPreviewImage(presignData.data?.uploadImage.FileKey)
          onReturnValue && onReturnValue(presignData.data?.uploadImage.FileKey)
        }
      }
    } catch (error) {
      setErrorMessage((error as Error)?.message)
      setLoading(false)
    }
  }

  if (previewImage && !isMultiImages) {
    return (
      <ImagePreview
        src={data?.getFile?.Url || previewImage || ''}
        onRemoveImage={() => {
          setPreviewImage(null)
          onReturnValue && onReturnValue('')
        }}
        isShowVideo={isShowVideo}
      />
    )
  }

  return (
    <React.Fragment>
      <input
        accept={accept}
        className={classes.input}
        id={inputId}
        type="file"
        multiple={isMultiImages}
        onChange={onChange}
        onClick={event => {
          event.currentTarget.value = ''
        }}
        disabled={disabled}
      />
      {loading && (
        <label className={classes.uploadWrapper} htmlFor={inputId}>
          <IconButton
            className={classes.icon}
            color="primary"
            aria-label="upload picture"
            component="span"
            disabled={disabled}
          >
            <CircularProgress size={30} />
          </IconButton>
        </label>
      )}
      {!loading && (
        <label className={classes.uploadWrapper} htmlFor={inputId}>
          <IconButton
            className={classes.icon}
            color="primary"
            aria-label="upload picture"
            component="span"
            disabled={disabled}
          >
            <PhotoCamera />
          </IconButton>
          {uploadTitle}
          <span>(Up to {limitSize}MB size)</span>
        </label>
      )}
    </React.Fragment>
  )
}

export default UploadImage
