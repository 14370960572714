import { ApolloProvider, useMutation } from '@apollo/react-hooks'
import DateFnsUtils from '@date-io/date-fns'
import { Box, makeStyles, responsiveFontSizes, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { CognitoUser } from 'amazon-cognito-identity-js'
import Amplify, { Auth, Hub } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { CurrentUser, extractCurrentUser, NULL_USER } from './auth'
import { SimpleLoader } from './components/common/SimpleLoader'
import { Main } from './components/Main'
import { authConfig } from './config/authConfig'
import { CurrentUserContext } from './contexts'
import { client } from './graphql/client'
import { Login } from './pages/login/Login'
import { createThemeWithPalleteType, mainTheme } from './themes'
import * as Sentry from '@sentry/browser'
import { useUserInfo } from './hooks/useUserInfo'
import { errorHandler } from './utils/errorHandler'
import { loader } from 'graphql.macro'
import { AddLoginHistoryMutation, AddLoginHistoryMutationVariables } from './generated/graphql'
import { SnackbarProvider } from 'contexts/snackbar'
import { getLocalStorageItem, setLocalStorageItem } from 'utils/tools'

Amplify.configure({
  Auth: authConfig,
  oauth: authConfig.oauth
})

const useStyles = makeStyles({
  app: {
    display: 'flex',
    height: 'auto',
    minHeight: '100%',
    width: '100%'
  }
})
const ADD_LOGIN_HISTORY = loader('./graphql/common/addLoginHistory.graphql')

export const AppContainer = () => {
  const { refetch } = useUserInfo({ skip: true })
  const [currentUser, setCurrentUser] = useState<CurrentUser>(NULL_USER)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isLight, setLightTheme] = useState<boolean>(false)
  const theme = responsiveFontSizes(createThemeWithPalleteType(mainTheme))
  const classes = useStyles()

  const [addLoginHistory] = useMutation<AddLoginHistoryMutation, AddLoginHistoryMutationVariables>(ADD_LOGIN_HISTORY)
  const handleCognitoUser = async (user: CognitoUser | null) => {
    try {
      if (getLocalStorageItem('isAuth') === 'true') {
        setCurrentUser({ loggedIn: true })
      }
      if (user) {
        await refetch()
        setCurrentUser({ loggedIn: true })
        setLoading(false)
        const currentUser = await extractCurrentUser(user)
        setCurrentUser(currentUser)
        Sentry.setUser(currentUser)
        setLocalStorageItem('isAuth', 'true')
      } else {
        setCurrentUser(NULL_USER)
      }
      setErrorMessage(null)
    } catch (error) {
      setLocalStorageItem('isAuth', 'false')
      setCurrentUser(NULL_USER)
      setErrorMessage(
        'You do not have a valid authorised account to use this application. Please contact Service Desk.'
      )
      errorHandler(error)
    }
    setLoading(false)
  }

  const toggleTheme = () => {
    setLightTheme(!isLight)
  }

  useEffect(() => {
    if (!window.location.hash || window.location.hash?.indexOf('id_token') === -1) {
      Auth.currentAuthenticatedUser()
        .then(handleCognitoUser)
        .catch(() => {
          setLoading(false)
          setErrorMessage(null) //When No Current User, we don't show the error
          const isErrorAuth = getLocalStorageItem('isErrorAuth') === 'true'
          if (isErrorAuth) {
            setErrorMessage(
              'You do not have a valid authorised account to use this application. Please contact Service Desk.'
            )
            setLocalStorageItem('isErrorAuth', 'false')
          }
        })
    }

    Hub.listen('auth', async ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          setLoading(true)
          const _user = await Auth.currentAuthenticatedUser()
          handleCognitoUser(_user)
          await addLoginHistory()
          break
        case 'signOut':
          break
      }
    })
    // eslint-disable-next-line
  }, [])

  const handleError = (error: Error) => {
    error && setErrorMessage(error.message)
    setLoading(false)
    errorHandler(error)
  }

  const signIn = (username: string, password: string) => {
    const emptyField = !username.trim() ? 'Email' : !password.trim() ? 'Password' : ''
    if (!emptyField) {
      setLoading(true)
      Auth.signIn({ username, password })
        .then(handleCognitoUser)
        .catch(handleError)
    } else {
      setErrorMessage(`${emptyField} cannot be empty`)
    }
  }

  const signOut = () => {
    setLoading(true)
    Auth.signOut()
      .then(() => {
        setLocalStorageItem('isAuth', 'false')
        handleCognitoUser(null)
      })
      .catch(() => {
        setLoading(false)
        setErrorMessage(null)
      })
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider>
          <Box className={classes.app}>
            <CurrentUserContext.Provider value={currentUser}>
              <SimpleLoader {...{ isLoading }} />
              {currentUser.loggedIn ? <Main {...{ signOut }} /> : <Login {...{ errorMessage, signIn, toggleTheme }} />}
            </CurrentUserContext.Provider>
          </Box>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export const App = () => (
  <Router>
    <ApolloProvider client={client}>
      <AppContainer />
    </ApolloProvider>
  </Router>
)
