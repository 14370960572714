import React from 'react'
import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.hint,
    margin: '0 auto',
    maxWidth: '20rem'
  }
}))

export const Disclaimer = () => {
  const classes = useStyles()
  const phone = <Link href="tel:+61396244236">(03) 9624 4236</Link>
  // const email = <Link href="mailto:servicenowqueries.au@acciona.com">ServiceNowQueries.au@acciona.com</Link>
  const website = (
    <Link rel="noopener noreferrer" href="https://acciona.service-now.com/">
      ServiceNow Portal
    </Link>
  )
  return (
    <div className={classes.root}>
      <Typography variant="body2">Forgot your password or having trouble signing in?</Typography>
      <br />
      <Typography variant="body2">
        Contact the Service Desk on {phone}, or raise an incident via {website}
      </Typography>
    </div>
  )
}
