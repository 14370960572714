import React from 'react'
import { AppBar, Hidden, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { DropdownMenu } from './common/DropdownMenu'
import { Navigation } from './common/Navigation'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '1.5rem',
    '& a': {
      alignItems: 'center',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      display: 'flex',
      textDecoration: 'none'
    },
    '& .MuiListItem-button': {
      padding: '0 1.5rem'
    },
    '& .MuiList-root': {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      height: '100%',
      justifyContent: 'flex-end',
      padding: 0,
      '& a': {
        borderBottomColor: 'transparent',
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        height: '100%',
        '& .MuiButtonBase-root': {
          height: '100%',
          paddingBottom: 0,
          paddingTop: 0
        },
        opacity: 0.8,
        '&:hover': {
          opacity: 1,
          borderBottomColor: 'rgba(0, 0, 0, 0.04)'
        }
      },
      '& a.active': {
        borderBottomColor: theme.palette.secondary.main,
        opacity: 1
      },
      '& a.active:hover': {
        borderBottomColor: theme.palette.secondary.main
      }
    }
  },
  title: {
    alignSelf: 'center',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1.25rem',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.94px',
    color: theme.palette.text.primary,
    '& span': {
      fontWeight: 'bold'
    },
    [theme.breakpoints.up('lg')]: {
      alignSelf: 'center',
      left: 0,
      position: 'absolute',
      right: 0,
      textAlign: 'center'
    }
  },
  toolbar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    minHeight: '3rem',
    padding: 0
  }
}))

interface TopBarProps {
  signOut: () => void
}

export const TopBar = (props: TopBarProps) => {
  const classes = useStyles()

  const Logo = () => (
    <Typography className={classes.title} variant="h4">
      Linked<span>Site</span>
    </Typography>
  )

  return (
    <AppBar className={classes.appBar} position="static">
      <Logo />
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <Navigation isTop={true} />
        </Hidden>
        <DropdownMenu {...props} />
      </Toolbar>
    </AppBar>
  )
}
