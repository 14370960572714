import React from 'react'
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format'

interface NumberFormatCustomProps {
  inputRef?: (instance: NumericFormatProps | null) => void
  onChange?: (event: { target: { name: string; value: number | null } }) => void
  name: string
  prefix?: string
  decimalScale?: number
  allowNegative?: boolean
  thousandSeparator?: string | boolean
}

export const InputNumberFormat = (props: NumberFormatCustomProps) => {
  const {
    inputRef,
    onChange,
    decimalScale = 2,
    prefix = '$',
    allowNegative = false,
    thousandSeparator = true,
    ...other
  } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: NumberFormatValues) => {
        const value = values?.floatValue ?? null
        onChange &&
          onChange({
            target: {
              name: props.name,
              value: value
            }
          })
      }}
      thousandSeparator={thousandSeparator}
      valueIsNumericString
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      fixedDecimalScale
      prefix={prefix}
    />
  )
}
