import { useQuery, useMutation } from '@apollo/react-hooks'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { loader } from 'graphql.macro'
import React, { FC, useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { SimpleLoader } from '../../../components/common/SimpleLoader'
import {
  GetMobileVersionConfigurationsQuery,
  UpdateMobileVersionConfigurationMutation,
  UpdateMobileVersionConfigurationMutationVariables
} from '../../../generated/graphql'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'

// GraphQL
const GET_MOBILE_VERSIONS_QUERY = loader('./getMobileVersionConfigurations.graphql')
const UPDATE_MOBILE_VERSION_CONFIGURATION = loader('./updateMobileVersionConfiguration.graphql')

// Style
const useStyles = makeStyles({
  contentDetail: {
    textAlign: 'right',
    marginBottom: 40
  },
  bold: {
    fontWeight: 700,
    marginBottom: 10
  },
  label: {
    fontStyle: 'italic'
  }
})

const REQUIRED = `is a required field`

const manageMobileVersionsValidationSchema = yup.object({
  MinimumVersion: yup
    .string()
    .max(100, 'Maximum 100 characters long')
    .trim()
    .nullable(),
  Note: yup
    .string()
    .max(2000, 'Maximum 2000 characters long')
    .trim()
    .nullable(),
  LatestVersion: yup
    .string()
    .nullable()
    .trim()
    .max(100, 'Maximum 100 characters long')
    .required(`LatestVersion ${REQUIRED}`)
})

export interface ManageMobileVersionsInputs {
  MinimumVersion: string
  AppName: string
  LatestVersion: string
  Note?: string
  ID: string
}

//Props
interface ManageMobileVersionsTabProps {
  tab: number
  appStoreName: string
}

// Fuction Component
export const ManageMobileVersionsTab: FC<ManageMobileVersionsTabProps> = ({ tab, appStoreName }) => {
  // Declare variables
  const classes = useStyles()
  const [autoHideFeedback, setAutoHideFeedback] = React.useState<Feedback | null>(null)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const { loading, error, data, refetch } = useQuery<GetMobileVersionConfigurationsQuery>(GET_MOBILE_VERSIONS_QUERY, {
    fetchPolicy: 'cache-and-network'
  })
  const [updateMobileVersionConfiguration] = useMutation<
    UpdateMobileVersionConfigurationMutation,
    UpdateMobileVersionConfigurationMutationVariables
  >(UPDATE_MOBILE_VERSION_CONFIGURATION)
  const initialValues: ManageMobileVersionsInputs = {
    MinimumVersion: data?.getMobileVersionConfigurations?.[tab]?.MinimumVersion || '',
    AppName: data?.getMobileVersionConfigurations?.[tab]?.AppName || '',
    LatestVersion: data?.getMobileVersionConfigurations?.[tab]?.LatestVersion || '',
    Note: data?.getMobileVersionConfigurations?.[tab]?.Note || '',
    ID: data?.getMobileVersionConfigurations?.[tab]?.ID || ''
  }

  return loading && !error ? (
    <SimpleLoader isLoading={true} />
  ) : (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={manageMobileVersionsValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          await updateMobileVersionConfiguration({
            variables: {
              input: {
                ID: Number(values?.ID),
                LatestVersion: values?.LatestVersion,
                MinimumVersion: values?.MinimumVersion,
                Note: values?.Note
              }
            }
          })
            .then(() => {
              resetForm()
              setIsUpdate(false)
              setAutoHideFeedback(getSuccessFeedback('Manage Mobile Versions has been updated successfully'))
              refetch()
            })
            .catch(error => {
              setAutoHideFeedback(getErrorFeedback(error.message))
            })
        }}
      >
        {props => {
          const { values, errors, touched, setFieldValue, resetForm, handleSubmit, handleChange } = props
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} className={classes.contentDetail}>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.bold} variant="body1">
                    Latest Version
                  </Typography>
                  <Typography className={classes.label} variant="body1">
                    This is the latest version of the app in {appStoreName}
                  </Typography>
                  <Typography className={classes.label} variant="body1">
                    Mobile apps will use this to compare with its existing version <br /> and display warning message
                    underneath if its older.
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <GridLayoutInput
                    fullWidth
                    id="LatestVersion"
                    name="LatestVersion"
                    textFieldBreakpoints={{ xs: 12 }}
                    value={values?.LatestVersion}
                    disabled={!isUpdate}
                    onChange={e => setFieldValue('LatestVersion', e.target.value)}
                    error={!!errors.LatestVersion && !!touched.LatestVersion}
                    helperText={touched.LatestVersion ? errors.LatestVersion : null}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.contentDetail}>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.bold} variant="body1">
                    Minimum Supported Version
                  </Typography>
                  <Typography className={classes.label} variant="body1">
                    This is minimum version of the app that API can support.
                  </Typography>
                  <Typography className={classes.label} variant="body1">
                    Mobile apps will use this to compare with its existing version <br /> and display warning message
                    underneath if its older.
                  </Typography>
                  <Typography className={classes.label} variant="body1">
                    In addition, API will entirely block the old apps so that it won&apos;t work any longer.
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <GridLayoutInput
                    fullWidth
                    id="MinimumVersion"
                    name="MinimumVersion"
                    textFieldBreakpoints={{ xs: 12 }}
                    value={values?.MinimumVersion}
                    disabled={!isUpdate}
                    onChange={handleChange}
                    error={!!errors.MinimumVersion && !!touched.MinimumVersion}
                    helperText={touched.MinimumVersion ? errors.MinimumVersion : null}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.contentDetail}>
                <Grid item xs={6} md={6}>
                  <Typography className={classes.bold} variant="body1">
                    Warning Notes
                  </Typography>
                  <Typography className={classes.label} variant="body1">
                    Note to be displayed in the warning popup of the{' '}
                    {data?.getMobileVersionConfigurations?.[tab]?.AppName} app if its really in an older version.
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <GridLayoutInput
                    multiline
                    rows={4}
                    fullWidth
                    id="Note"
                    name="Note"
                    textFieldBreakpoints={{ xs: 12 }}
                    value={values?.Note}
                    disabled={!isUpdate}
                    onChange={handleChange}
                    error={!!errors.Note && !!touched.Note}
                    helperText={touched.Note ? errors.Note : null}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" direction="row" justifyContent="flex-end">
                {isUpdate ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => {
                        resetForm()
                        setIsUpdate(false)
                      }}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button color="secondary" variant="contained" type="submit">
                      Save
                    </Button>
                  </>
                ) : (
                  <Button color="secondary" variant="contained" onClick={() => setIsUpdate(true)}>
                    Edit
                  </Button>
                )}
              </Grid>
            </form>
          )
        }}
      </Formik>
      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setAutoHideFeedback(null)}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />
    </>
  )
}
