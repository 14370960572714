import React from 'react'
import { Selector } from './EntitySelector'

export interface Size {
  ID: string
  label: string
}

interface Props {
  onChange: (newValue?: Size | null) => void
  value?: string
  label?: string
}

export const SIZES: Size[] = [
  {
    ID: 'Default',
    label: 'Default'
  },
  {
    ID: 'Large',
    label: 'Large'
  },
  {
    ID: 'Medium',
    label: 'Medium'
  },
  {
    ID: 'Small',
    label: 'Small'
  }
]

export const getLabelPosition = (position: string) => SIZES?.find((item: Size) => item.ID === position)?.label || ''

export const ImageSizeSelector = ({ value, label = 'Size', onChange, ...props }: Props) => {
  return (
    <Selector<Size>
      {...props}
      label={label}
      value={value}
      labelEntity={item => item.label}
      options={SIZES}
      onChange={onChange}
    />
  )
}
