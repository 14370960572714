import {
  Country,
  GetAdminCountriesQuery,
  GetAdminCountriesQueryVariables,
  Person,
  Scalars,
  UpdatePersonInfoMutation,
  UpdatePersonInfoMutationVariables
} from 'generated/graphql'
import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, TextField } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import * as yup from 'yup'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Formik } from 'formik'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { GET_ADMIN_COUNTRIES, UPDATE_PERSON_INFO } from 'graphql/common'
import { CountriesSelector } from 'components/common/CountriesSelector'

interface EditUserModalProps {
  onCloseModal: () => void
  selectedItem: Person
  onRefetch?: () => void
  setAutoHideFeedback: (value: Feedback) => void
}

interface FormValues {
  PersonID: Scalars['ID']
  MfaPhone: string
  IsEnableMFA: boolean | null | undefined
}

export const EditUserModal: FC<EditUserModalProps> = ({
  onCloseModal,
  selectedItem,
  onRefetch,
  setAutoHideFeedback
}) => {
  const [countries, setCountries] = React.useState<Country | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data } = useQuery<GetAdminCountriesQuery, GetAdminCountriesQueryVariables>(GET_ADMIN_COUNTRIES)
  const personSchema = yup.object({
    MfaPhone:
      countries?.CountryPhoneCode === null || countries?.CountryPhoneCode === undefined
        ? yup
            .string()
            .nullable()
            .matches(/^[0-9, + ]+$/, 'Phone number must contain digits only')
            .matches(/^[^\s]+(\s+[^\s]+)*$/, 'Phone number must not contain leading or trailing spaces')
            .when('IsEnableMFA', {
              is: true,
              then: yup.string().required(`MFA phone number is required`)
            })
        : yup
            .string()
            .nullable()
            .matches(/^[0-9]+$/, 'Phone number must contain digits only')
            .matches(/^[^\s]+(\s+[^\s]+)*$/, 'Phone number must not contain leading or trailing spaces')
            .when('IsEnableMFA', {
              is: true,
              then: yup.string().required(`MFA phone number is required`)
            })
  })

  useEffect(() => {
    const isContries = data?.getAdminCountries?.filter(
      item => item?.CountryPhoneCode === selectedItem?.MfaPhone?.slice(0, 3)
    )
    setCountries(isContries?.[0] || countries)
    // eslint-disable-next-line
  }, [data])

  const initialValues: FormValues = {
    IsEnableMFA: selectedItem?.IsEnableMFA,
    PersonID: selectedItem?.ID as Scalars['ID'],
    MfaPhone:
      countries?.CountryPhoneCode === null || countries?.CountryPhoneCode === undefined
        ? ''
        : countries?.CountryPhoneCode && countries?.CountryPhoneCode === '+84'
        ? selectedItem?.MfaPhone?.slice(3) || ''
        : selectedItem?.MfaPhone?.slice(3) || ''
  }

  const [updatePersonInfo] = useMutation<UpdatePersonInfoMutation, UpdatePersonInfoMutationVariables>(
    UPDATE_PERSON_INFO
  )

  const handleSubmit = ({ PersonID, MfaPhone }: FormValues) => {
    if (PersonID) {
      setIsSubmitting(true)
      updatePersonInfo({
        variables: {
          input: {
            PersonID: selectedItem.ID,
            MfaPhone: MfaPhone
              ? countries?.CountryPhoneCode === null || countries?.CountryPhoneCode === undefined
                ? MfaPhone
                : countries?.CountryPhoneCode && countries?.CountryPhoneCode === '+84'
                ? `${countries?.CountryPhoneCode || ''}${MfaPhone?.replace(/\s+/g, '')}`
                : `${countries?.CountryPhoneCode || ''}${MfaPhone?.replace(/\s+/g, '')}`
              : ''
          }
        }
      })
        .then(({ data }) => {
          if (data?.updatePersonInfo) {
            onRefetch && onRefetch()
            onCloseModal()
            setAutoHideFeedback(getSuccessFeedback('User has been updated successfully'))
            setIsSubmitting(false)
          }
        })
        .catch(error => {
          setIsSubmitting(false)
          setAutoHideFeedback(getErrorFeedback(error.message))
        })
    }
  }

  return (
    <>
      <Dialog onClose={onCloseModal} open={!!selectedItem} maxWidth={'md'} fullWidth>
        <DialogTitle>{`${selectedItem?.Firstname} ${selectedItem?.Lastname}`}</DialogTitle>
        <DialogContent style={{ padding: 20 }}>
          <Formik
            onSubmit={values => {
              handleSubmit(values)
            }}
            initialValues={initialValues}
            validationSchema={personSchema}
          >
            {props => {
              const { values, errors, handleBlur, handleChange, touched, handleSubmit } = props
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item xs={6} sm={6}>
                      <GridLayoutInput
                        autoComplete={'off'}
                        fullWidth
                        id="email"
                        label="Email"
                        name="Email"
                        value={selectedItem.Email}
                        textFieldBreakpoints={{ xs: 12 }}
                        disabled
                      />
                    </Grid>
                    <Grid container item xs={6} sm={6} spacing={2}>
                      <Grid item xs={6}>
                        <CountriesSelector value={countries} onChange={setCountries} />
                      </Grid>
                      <Grid item xs={6}>
                        <GridLayoutInput
                          autoComplete={'off'}
                          fullWidth
                          id="MfaPhone"
                          label="MFA Phone"
                          name="MfaPhone"
                          textFieldBreakpoints={{ xs: 12 }}
                          value={values.MfaPhone || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!errors.MfaPhone && !!touched.MfaPhone}
                          helperText={touched.MfaPhone ? errors.MfaPhone : null}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          disabled
                          id="company"
                          label="Company"
                          name="Company"
                          value={selectedItem?.internalPerson?.company.Name}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          disabled
                          id="Phone"
                          label="Phone"
                          name="Phone"
                          value={selectedItem.Phone}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid container item xs={12} justifyContent="flex-end">
                      <Button variant="contained" onClick={onCloseModal}>
                        Close
                      </Button>
                      &nbsp;&nbsp;
                      <Button color="secondary" variant="contained" type="submit" disabled={isSubmitting}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}
