import { loader } from 'graphql.macro'
import { isEmpty } from 'lodash'
import React from 'react'
import { FindManyAdminRolesQuery, FindManyAdminRolesQueryVariables, Role } from '../../generated/graphql'
import { EntitySelector, EntitySelectorWrapperProps } from './EntitySelector'

const FIND_MANY_ROLES = loader('../../graphql/common/findManyAdminRoles.graphql')

export type RoleSelectorProps = EntitySelectorWrapperProps<
  Role,
  FindManyAdminRolesQuery,
  FindManyAdminRolesQueryVariables
> & {
  extractEntities?: (data: FindManyAdminRolesQuery) => Role[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  refetch?: boolean
  roleType: 'All' | 'GlobalRole' | 'ProjectLevelRole' | 'CompanyLevelRole'
  isExternalRole?: boolean
}

const labelEntity = ({ Name }: Role) => Name

const extractEntitiesDefault = (data: FindManyAdminRolesQuery) => data.findManyAdminRoles.items as Role[]

const ROLE_FILTERS = {
  GlobalRole: {
    op: 'EQ',
    values: ['true'],
    field: 'GlobalRole'
  },
  ProjectLevelRole: {
    op: 'EQ',
    values: ['true'],
    field: 'ProjectLevelRole'
  },
  CompanyLevelRole: {
    op: 'EQ',
    values: ['true'],
    field: 'CompanyLevelRole'
  },
  All: {}
}

export const RoleSelector = ({
  label = 'Add Role',
  refetch,
  isExternalRole,
  roleType = 'All',
  extractEntities = extractEntitiesDefault,
  ...props
}: RoleSelectorProps) => {
  const checkExternalPersonFilter =
    isExternalRole && roleType === 'ProjectLevelRole' ? { op: 'EQ', values: ['false'], field: 'SupplierRole' } : {}

  const queryHookOptions = {
    variables: {
      filter: {
        operator: 'AND',
        sorters: [
          {
            field: 'Role.Name',
            orderBy: 'ASC'
          }
        ],
        filters: [
          {
            op: 'EQ',
            values: ['true'],
            field: isExternalRole ? 'ExternalRole' : 'InternalRole'
          },
          {
            op: 'EQ',
            values: ['true'],
            field: 'AdminPortalRole'
          },
          ROLE_FILTERS[roleType],
          checkExternalPersonFilter
        ].filter(item => !isEmpty(item))
      }
    }
  }
  return (
    <EntitySelector<Role, FindManyAdminRolesQuery, FindManyAdminRolesQueryVariables>
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={FIND_MANY_ROLES}
      queryHookOptions={queryHookOptions}
      refetch={refetch}
      {...props}
    />
  )
}
