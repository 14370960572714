import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'

export interface CurrentUser {
  email?: string
  phoneNumber?: string
  loggedIn: boolean
}

export const getIdToken = async () => (await Auth.currentSession()).getIdToken().getJwtToken()

export const NULL_USER = { loggedIn: false }

const findUserAttribute = (attributes: CognitoUserAttribute[]) => (key: string) =>
  attributes?.find(value => value.getName() === key)?.getValue()

export const extractCurrentUser = (cognitoUser: CognitoUser) =>
  new Promise<CurrentUser>((resolve, reject) => {
    cognitoUser.getUserAttributes((err?: Error, result?: CognitoUserAttribute[]) => {
      if (err) {
        reject(err)
      } else {
        if (result) {
          const finder = findUserAttribute(result)
          const email = finder('email')
          const phoneNumber = finder('phone_number')
          // const phoneNumber = finder('phone_number')
          resolve({ loggedIn: true, email, phoneNumber })
        } else {
          reject(new Error('No user attributes'))
        }
      }
    })
  })
