import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Alert, Color } from '@material-ui/lab'
import map from 'lodash/map'
import lowerCase from 'lodash/lowerCase'
import {
  GetSystemAnnouncementsQuery,
  GetSystemAnnouncementsQueryVariables,
  SystemAnnouncement
} from 'generated/graphql'
import { useQuery } from '@apollo/react-hooks'
import { GET_SYSTEM_ANNOUNCEMENTS } from 'graphql/common'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { formatHTMLSpace } from 'utils/tools'

const useStyles = makeStyles({
  banner: {
    color: 'black',
    textAlign: 'left',
    fontWeight: 'bold'
  },
  alert: {
    borderRadius: '0px',
    '& *': {
      marginTop: '0px',
      marginBottom: '0px'
    }
  }
})

export const Banner = () => {
  const classes = useStyles()
  const { data } = useQuery<GetSystemAnnouncementsQuery, GetSystemAnnouncementsQueryVariables>(
    GET_SYSTEM_ANNOUNCEMENTS,
    {
      variables: {
        sortBy: 'CreatedDate'
      }
    }
  )
  const systemAnnouncements = data?.getSystemAnnouncements?.filter(e => e.IsActive) ?? []

  return (
    <>
      {process.env.REACT_APP_NODE_ENV !== 'prod' && (
        <Alert className={classes.alert} severity="error">
          <Typography className={classes.banner}>
            LIVE SYSTEM TEST SYSTEM ONLY. PLEASE GO TO{' '}
            <a href="https://linkedsiteadmin.acciona.com.au/">LinkedSite Admin</a> FOR THE LIVE SYSTEM
          </Typography>
        </Alert>
      )}
      {systemAnnouncements &&
        map(systemAnnouncements, (item: SystemAnnouncement) => (
          <Alert
            className={classes.alert}
            key={`system-announcement-${item.ID}`}
            iconMapping={{ success: <NotificationsNoneIcon /> }}
            severity={item.Status === 'Notification' ? ('success' as Color) : (lowerCase(item.Status) as Color)}
          >
            <Typography
              className={classes.banner}
              dangerouslySetInnerHTML={{ __html: formatHTMLSpace(item.Description) }}
            />
          </Alert>
        ))}
    </>
  )
}
