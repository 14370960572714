import { Tabs, Tab } from '@material-ui/core'
import React, { FC, ChangeEvent, useState } from 'react'
import { StandardDivider } from 'components/common/StandardDivider'
import { a11yProps } from '../../utils/a11y'
import { MyProfileTabPanel } from '../../components/common/my-profile/MyProfileTabPanel'
import { ManageMobileVersionsTab } from './ManageMobileVersionsTab/ManageMobileVersionsTab'

export const ManageMobileVersions: FC = () => {
  const [currentTab, setCurrentTab] = useState<number>(0)
  const handleChange = (_event: ChangeEvent<NonNullable<any>>, newTab: number) => {
    setCurrentTab(newTab)
  }

  return (
    <>
      <Tabs value={currentTab || 0} onChange={handleChange} aria-label="projects tabs">
        <Tab label="Android" {...a11yProps(0)} />
        <Tab label="IOS" {...a11yProps(1)} />
      </Tabs>
      <StandardDivider />
      <MyProfileTabPanel value={currentTab} index={0}>
        <ManageMobileVersionsTab tab={0} appStoreName="Google Play" />
      </MyProfileTabPanel>
      <MyProfileTabPanel value={currentTab} index={1}>
        <ManageMobileVersionsTab tab={1} appStoreName="App Store" />
      </MyProfileTabPanel>
    </>
  )
}
