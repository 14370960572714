import { useQuery } from '@apollo/react-hooks'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { DialogTitle, Dialog, DialogContent, Grid, TablePagination, IconButton } from '@material-ui/core'
import { Table, customFilterAndSearchColumn } from 'components/common/Table'
import { Column } from 'material-table'
import { ROW_PER_PAGE_OPTIONS } from 'utils/Constants'
import usePagination from 'hooks/usePagination'
import { formatDateInHHDDMMYY, toDisplayIsoDateTimeUtc } from 'utils/dates'
import { GET_PAGING_USER_PRIVACY_HISTORIES } from './graphql'
import {
  GetPagingUserPrivacyHistoriesQuery,
  GetPagingUserPrivacyHistoriesQueryVariables,
  UserPrivacyHistory
} from '../../generated/graphql'

interface UserPrivacyHistoriesDialogProps {
  onClose: () => void
  open: boolean
}

export const UserPrivacyHistoriesDialog = ({ onClose, open }: UserPrivacyHistoriesDialogProps) => {
  const { currentPageSize, onChangeRowsPerPage, currentPage, setCurrentPage, filter, onFilter } = usePagination(
    'pageSizeUserPrivacyHistories'
  )

  const { loading, data } = useQuery<GetPagingUserPrivacyHistoriesQuery, GetPagingUserPrivacyHistoriesQueryVariables>(
    GET_PAGING_USER_PRIVACY_HISTORIES,
    {
      variables: {
        filter,
        pagination: {
          pageNumber: currentPage,
          pageSize: currentPageSize
        },
        sorter: {
          field: 'UserPrivacyHistory.CreatedDate',
          orderBy: 'DESC'
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  )

  const columns: Column<UserPrivacyHistory>[] = [
    {
      field: 'person',
      title: 'User',
      sorting: false,
      customFilterAndSearch: (filter: string, rowData: UserPrivacyHistory) =>
        customFilterAndSearchColumn(
          filter,
          rowData,
          rowData => `${rowData?.person?.Firstname || ''} ${rowData?.person?.Lastname}`
        ),
      render: rowData => `${rowData?.person?.Firstname || ''} ${rowData?.person?.Lastname}`
    },
    {
      field: 'privacyConfiguration',
      title: 'Privacy Notice Version',
      sorting: false,
      customFilterAndSearch: (filter: string, rowData: UserPrivacyHistory) =>
        customFilterAndSearchColumn(filter, rowData, rowData => `${rowData?.privacyConfiguration?.Version}`),
      render: rowData => rowData?.privacyConfiguration?.Version || ''
    },
    {
      field: 'Status',
      title: 'Status',
      sorting: false
    },
    {
      field: 'CreatedDate',
      title: 'Date/Time',
      render: row => formatDateInHHDDMMYY(toDisplayIsoDateTimeUtc(new Date(row.CreatedDate)))
    }
  ]

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
      <Grid item={true} container direction="row" xs={12} justifyContent="space-between" alignItems="center">
        <DialogTitle>User Privacy History</DialogTitle>
        <IconButton aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <Grid style={{ marginTop: 20 }}>
          <Table<UserPrivacyHistory>
            isLoading={loading}
            columns={columns}
            data={(data?.getPagingUserPrivacyHistories?.items as UserPrivacyHistory[]) || []}
            onSearchChange={e => {
              const search: string = e.toString()
              onFilter(search)
              return ''
            }}
            option={{
              pageSize: currentPageSize
            }}
            components={{
              Pagination: props => {
                return (
                  <TablePagination
                    {...props}
                    count={data?.getPagingUserPrivacyHistories.pagination?.total || 0}
                    rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                    rowsPerPage={currentPageSize}
                    page={currentPage - 1}
                    onChangePage={(e, page) => setCurrentPage(page + 1)}
                    onChangeRowsPerPage={e => onChangeRowsPerPage(e, props)}
                  />
                )
              }
            }}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
