import { Box } from '@material-ui/core'
import React from 'react'

import ContentForm from './ContentForm'
import HeaderForm from './HeaderForm'
import UploadImagesForm from './UploadImagesForm'

interface CMSFormProps {
  className?: string
  index: number
}

export default function CMSForm({ className, index }: CMSFormProps) {
  return (
    <Box className={className}>
      <HeaderForm index={index} />
      <UploadImagesForm index={index} />
      <ContentForm index={index} />
    </Box>
  )
}
