import { Tabs, Tab, Typography } from '@material-ui/core'
import React, { useState, ChangeEvent, useMemo } from 'react'
import { StandardDivider } from 'components/common/StandardDivider'
import { PayrollTabPanel } from 'components/common/payroll/PayrollTabPanel'
import { a11yProps } from 'utils/a11y'
import { Privilege } from 'generated/graphql'
import { useUserInfo } from 'hooks/useUserInfo'
import TimesheetLocking from './TimesheeLocking/TimesheetLocking'
import EBASetup from './EBASetup/EBASetup'
import useRouter from 'hooks/useRouter'
import { ForemanCodesTab } from './ForemanCodes/ForemanCodesTab'

export const Payroll = () => {
  const [currentTab, setCurrentTab] = useState<number>(0)
  const { history } = useRouter()
  const handleChange = (_event: ChangeEvent<NonNullable<any>>, newTab: number) => {
    setCurrentTab(newTab)
    history.push('/payroll')
  }
  const { personInfoData } = useUserInfo()

  const privileges: Privilege[] = []
  personInfoData?.personInfo?.roles?.forEach((e: { privileges: Privilege[] }) => {
    privileges.push(...e.privileges)
  })
  const hasLockStaffTimeSheets = useMemo(() => {
    return !!privileges?.find((arr: { Name: string }) => arr.Name === 'LockStaffTimesheets')
  }, [privileges])

  const hasManageEBASetup = useMemo(() => {
    return !!privileges?.find((arr: { Name: string }) => arr.Name === 'ManageEBASetup')
  }, [privileges])

  return (
    <>
      {hasLockStaffTimeSheets ? (
        <div>
          <Tabs value={currentTab} onChange={handleChange} aria-label="users tabs">
            <Tab label="Timesheet Locking" {...a11yProps(0)} />
            <Tab label="EBA Setup" {...a11yProps(1)} />
            <Tab label="Supervisor Codes" {...a11yProps(2)} />
          </Tabs>
          <StandardDivider />
          <PayrollTabPanel
            noHorizontalSpacing
            value={currentTab}
            page="Timesheet Locking"
            accessGranted={hasLockStaffTimeSheets}
            index={0}
          >
            {currentTab === 0 && <TimesheetLocking />}
          </PayrollTabPanel>
          <PayrollTabPanel
            noHorizontalSpacing
            value={currentTab}
            page="EBA"
            accessGranted={hasManageEBASetup}
            index={1}
          >
            {currentTab === 1 && <EBASetup />}
          </PayrollTabPanel>
          <PayrollTabPanel
            noHorizontalSpacing
            value={currentTab}
            page="Supervisor Codes"
            index={2}
            accessGranted={true}
          >
            {currentTab === 2 && <ForemanCodesTab />}
          </PayrollTabPanel>
        </div>
      ) : (
        <Typography variant="h6">No Lock Staff Timesheets</Typography>
      )}
    </>
  )
}
export default Payroll
