import { makeStyles } from '@material-ui/core'

export const useCMSCreatorStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    position: 'absolute',
    top: 0,
    right: -12
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    backgroundColor: 'var(--color-grey--ultralight)',
    'input:hover ~ &': {
      backgroundColor: 'var(--color-grey--light)'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'var(--color-neutral-300)'
    }
  },
  checkedIcon: {
    backgroundColor: 'var(--primary-color-700)',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(var(--color-white),var(--color-white) 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: 'var(--primary-color-700)'
    }
  },
  disableTransform: {
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg)'
    }
  },
  sectionLayout: {
    maxHeight: 'calc(95vh - 24rem)'
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
})
