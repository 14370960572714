import { loader } from 'graphql.macro'
import React, { FC } from 'react'
import { Company, FindManyCompanyQuery, FindManyCompanyQueryVariables } from '../../generated/graphql'
import { EntitySelector, EntitySelectorWrapperProps } from './EntitySelector'

const FIND_MANY_COMPANY = loader('../../graphql/common/findManyCompany.graphql')

export type CompanySelectorProps = EntitySelectorWrapperProps<
  Company,
  FindManyCompanyQuery,
  FindManyCompanyQueryVariables
> & {
  extractEntities?: (data: FindManyCompanyQuery) => Company[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  refetch?: boolean
}

const labelEntity = ({ Name }: Company) => Name

const extractEntitiesDefault = (data: FindManyCompanyQuery) => data.getCompanies

export const CompanySelector: FC<CompanySelectorProps> = ({
  label = 'Add Company',
  refetch,
  extractEntities = extractEntitiesDefault,
  ...props
}) => {
  return (
    <EntitySelector<Company, FindManyCompanyQuery, FindManyCompanyQueryVariables>
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={FIND_MANY_COMPANY}
      refetch={refetch}
      {...props}
    />
  )
}
