import { Alert, AlertTitle, Color } from '@material-ui/lab'
import { Project } from 'generated/graphql'
import { useCheckPrivilegesInGlobalRoles } from 'hooks/useCheckPrivilegesInGlobalRoles'
import React, { ComponentType, PropsWithChildren } from 'react'

interface AlertProps {
  severity?: Color
  title?: string
  message?: string
}

interface AdditionalParam {
  alert?: AlertProps
  privileges: string[]
  page: string
}

interface GuardInjectedProps {
  project: Project
}

type PropsWithGeneric<P> = PropsWithChildren<P>

export const withPermissionGuard = <P extends GuardInjectedProps>(
  Component: ComponentType<P>,
  { privileges, page, alert }: AdditionalParam
) => {
  const WithPermissionGuard = (props: PropsWithGeneric<Omit<P, 'project'>>) => {
    const canAccess = useCheckPrivilegesInGlobalRoles(privileges)
    const { severity = 'info', title = 'Access Denied', message = `You don't have permission to manage ${page}` } =
      alert ?? {}

    if (!canAccess) {
      return (
        <Alert severity={severity}>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      )
    }

    return <Component {...(props as P)} />
  }
  return WithPermissionGuard
}
