import React from 'react'
import HeaderTemplate from '../components/template-section/HeaderTemplate'
import ImagesAndTextTemplate from '../components/template-section/ImagesAndTextTemplate'
import ImagesTemplate from '../components/template-section/ImagesTemplate'
import OnlyVideoTemplate from '../components/template-section/OnlyVideoTemplate'
import TextAndImagesTemplate from '../components/template-section/TextAndImagesTemplate'
import TextsTemplate from '../components/template-section/TextsTemplate'

export const TEMPLATE_COMPONENTS = [
  <HeaderTemplate key="1" />,
  <ImagesTemplate key="2" />,
  <ImagesAndTextTemplate key="3" />,
  <TextsTemplate key="4" />,
  <TextAndImagesTemplate key="5" />,
  <TextsTemplate key="6" columnCount={2} />,
  <OnlyVideoTemplate key="7" />
]
