import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { FieldAttributes, useField } from 'formik'
import { useGlobalStyles } from 'hooks/useGlobalStyles'
import { HelpIconButtonTooltip } from './mui'
import classNames from 'classnames'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectorObjectField = ({
  selector: Selector,
  onChange: extraOnChange,
  disabled,
  setState,
  label,
  className,
  isVersion2 = false,
  isRequired = false,
  tooltipDescription,
  readOnly = false,
  textFieldBreakpoints,
  ...props
}: FieldAttributes<any>) => {
  const classes = useGlobalStyles()
  const [field, { error, touched }, { setValue }] = useField(props)
  const onChange = (newValue: { ID: string }) => {
    extraOnChange?.(newValue, field?.value)
    setValue(newValue || null)
  }

  return (
    <Grid item {...textFieldBreakpoints} className={isVersion2 ? classes.roundedInput : ''}>
      {isVersion2 ? (
        <Box display="flex" alignItems="center">
          <Typography className="color-neutral-700 d-block mb-1 text-sm font-semibold">
            {label} {isRequired && <span className="primary-color-500"> *</span>}
          </Typography>
          {!!tooltipDescription && (
            <HelpIconButtonTooltip imgSize={16} className="ml-2 mb-1" title={tooltipDescription} />
          )}
        </Box>
      ) : null}
      <Selector
        disabled={disabled}
        error={!!touched && !!error}
        helperText={!!touched && error}
        className={classNames(
          { 'read-only-selector-field border-thin-rounded-input rounded-input': readOnly && isVersion2 },
          className
        )}
        readOnly={readOnly}
        {...props}
        {...field}
        label={isVersion2 ? '' : label}
        setValue={setValue}
        onChange={onChange}
        setState={setState}
      />
    </Grid>
  )
}
