import { WHITE_SPACES_MESSAGE } from 'utils/Constants'
import { hasWhiteSpace } from 'utils/tools'
import * as yup from 'yup'

function checkWhiteSpaces(value = '') {
  return !hasWhiteSpace(value)
}

export const holcimConfigurationSchema = yup.object({
  username: yup
    .string()
    .nullable()
    .label('Username')
    .test('check-white-spaces', `Username ${WHITE_SPACES_MESSAGE}`, checkWhiteSpaces)
    .required(),
  clientId: yup
    .string()
    .nullable()
    .label('Client Id')
    .test('check-white-spaces', `Client Id ${WHITE_SPACES_MESSAGE}`, checkWhiteSpaces)
    .required(),
  password: yup
    .string()
    .nullable()
    .label('Password')
    .test('check-white-spaces', `Password ${WHITE_SPACES_MESSAGE}`, checkWhiteSpaces)
    .min(4, 'minimum 4 characters long')
    .required(),
  clientSecret: yup
    .string()
    .nullable()
    .label('Client Secret')
    .test('check-white-spaces', `Client Secret ${WHITE_SPACES_MESSAGE}`, checkWhiteSpaces)
    .min(4, 'minimum 4 characters long')
    .required(),
  customerCode: yup
    .string()
    .nullable()
    .label('Customer Code')
    .test('check-white-spaces', `Customer Code ${WHITE_SPACES_MESSAGE}`, checkWhiteSpaces)
    .matches(/^[0-9]*$/, 'Customer Code only allows numeric characters')
    .required()
})
