import React, { FC, ReactNode } from 'react'
import { Box, Tab, Typography, styled } from '@material-ui/core'
import { tabPanelId, tabIndex } from '../../utils/a11y'

export interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
  style?: any
  isNoHorizontalPadding?: boolean
}
const noHorizontalSpacingStyle = {
  paddingLeft: 0,
  paddingRight: 0
}

export const StyleTabTitle = styled(Tab)({
  minWidth: 'auto',
  fontSize: 16
})

export const TabPanel: FC<TabPanelProps> = ({
  isNoHorizontalPadding,
  children,
  index,
  value,
  style = noHorizontalSpacingStyle
}) => (
  <Typography
    style={{ width: '100%' }}
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={tabPanelId(index)}
    aria-labelledby={tabIndex(index)}
    className={value === index ? 'd-block' : 'd-none'}
  >
    <Box style={isNoHorizontalPadding ? style || noHorizontalSpacingStyle : style} p={2}>
      {children}
    </Box>
  </Typography>
)
