import { useQuery } from '@apollo/react-hooks'
import { GetNewsArticleSectionLayoutsQuery, GetNewsArticleSectionLayoutsQueryVariables } from 'generated/graphql'
import { GET_NEWS_ARTICLE_SECTION_LAYOUTS } from '../graphql'
import { useMemo } from 'react'
import { getExtraProperties, getTemplateTitle } from '../utils'

export type Layouts = {
  id: string
  value: number
  description: string
  templateTitle: string
  isHeader?: boolean
  isImageOnly?: boolean
  hasTextAndImage?: boolean
  isImageOnLeft?: boolean
  isVideo?: boolean
  columns?: number
}

type ArticleSectionLayouts = {
  layouts: Layouts[]
  loading: boolean
}

export const useArticleSectionLayouts = (): ArticleSectionLayouts => {
  const { data, loading } = useQuery<GetNewsArticleSectionLayoutsQuery, GetNewsArticleSectionLayoutsQueryVariables>(
    GET_NEWS_ARTICLE_SECTION_LAYOUTS,
    { fetchPolicy: 'cache-and-network' }
  )

  const layouts = useMemo(
    () =>
      data?.getNewsArticleSectionLayouts?.map(layout => ({
        id: layout.ID,
        value: Number(layout.ID),
        templateTitle: getTemplateTitle(layout.Name),
        description: layout.Description,
        ...getExtraProperties(layout.Name)
      })) ?? [],
    [data]
  )

  return { layouts, loading }
}
