import { MouseEventHandler, useState } from 'react'

export function usePopover() {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const open = !!anchor

  const onOpen: MouseEventHandler<HTMLElement> = e => setAnchor(e.currentTarget)

  const onClose = () => setAnchor(null)

  return {
    open,
    anchor,
    onOpen,
    onClose
  }
}
