import { makeStyles, Tabs, Tab } from '@material-ui/core'
import React, { FC, useState, ChangeEvent } from 'react'
import { a11yProps } from '../../utils/a11y'
import { UserTabPanel } from '../../components/common/users/UserTabPanel'
import { StandardDivider } from '../../components/common/StandardDivider'
import { RouteChildrenProps } from 'react-router-dom'
import { Internal } from './Internal/Internal'
import { External } from './External/External'

const useStyles = makeStyles(theme => ({
  container: {},
  label: {
    color: theme.palette.text.hint
  }
}))
type UsersProps = RouteChildrenProps

export const Users: FC<UsersProps> = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const classes = useStyles()
  const handleChange = (_event: ChangeEvent<NonNullable<any>>, newTab: number) => setCurrentTab(newTab)

  return (
    <div className={classes.container}>
      <Tabs value={currentTab} onChange={handleChange} aria-label="users tabs">
        <Tab label="Acciona Users" {...a11yProps(0)} />
        <Tab label="External Users" {...a11yProps(1)} />
      </Tabs>
      <StandardDivider />
      <UserTabPanel noHorizontalSpacing value={currentTab} index={0}>
        {currentTab === 0 && <Internal />}
      </UserTabPanel>
      <UserTabPanel noHorizontalSpacing value={currentTab} index={1}>
        {currentTab === 1 && <External />}
      </UserTabPanel>
    </div>
  )
}
export default Users
