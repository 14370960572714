import { Box, Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import { a11yProps } from 'utils/a11y'
import { ManageLogo } from './ManageLogo'
import { Integrations } from './integrations/Integrations'

export const ManageProjects = () => {
  const [currentTab, setCurrentTab] = useState<number>(0)

  const handleChange = (newTab: number) => {
    setCurrentTab(newTab)
  }

  return (
    <>
      <Tabs value={currentTab} onChange={(_, tab) => handleChange(tab)} aria-label="users tabs">
        <Tab label="Project Logo" {...a11yProps(0)} />
        <Tab label="Integrations" {...a11yProps(1)} />
      </Tabs>
      <Box className="mt-10">
        {currentTab === 0 && <ManageLogo />}
        {currentTab === 1 && <Integrations />}
      </Box>
    </>
  )
}
