import React from 'react'
import { makeStyles } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { CheckIcon, ClearIcon } from 'components/common/icons'

const useStyles = makeStyles({
  switch: {
    marginLeft: 10
  },
  switchItem: {}
})

export interface SwitchData {
  text: string
  value: string | boolean | number
  activeColor: string
  icon: any
}

interface SwitchButtonProps {
  data?: SwitchData[]
  selected: string | undefined
  onChange: (e: string | number) => void
}

const STATUS: SwitchData[] = [
  {
    text: 'Active',
    value: true,
    activeColor: 'Green',
    icon: <CheckIcon />
  },
  {
    text: 'Inactive',
    value: false,
    activeColor: 'red',
    icon: <ClearIcon />
  }
]

export const SwitchButton = ({ data = STATUS, selected, onChange }: SwitchButtonProps) => {
  const classes = useStyles()
  return (
    <ToggleButtonGroup
      value={selected}
      className={classes.switch}
      exclusive
      onChange={(e, newValue) => onChange(newValue)}
      aria-label="text alignment"
    >
      {data.map(e => (
        <ToggleButton className={classes.switchItem} key={`${e.value}`} value={`${e.value}`} aria-label={e.text}>
          {e.icon}
          {e.text}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
