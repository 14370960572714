import React, { FC } from 'react'
import { Publication, GetPagingPublicationsQuery, GetPagingPublicationsQueryVariables } from 'generated/graphql'
import { EntitySelectorWrapperProps } from '../EntitySelector'
import { InfiniteSelector } from '../InfiniteSelector'
import { QueryHookOptions } from '@apollo/react-hooks'
import { GET_PAGING_PUBLICATIONS } from '../../../graphql/common/index'

export type ProjectSelectorProps = EntitySelectorWrapperProps<
  Publication,
  GetPagingPublicationsQuery,
  GetPagingPublicationsQueryVariables
> & {
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  queryHookOptions: QueryHookOptions
}

const compileData = (data: GetPagingPublicationsQuery) => {
  return {
    data: data?.getPagingPublications?.items || [],
    pagination: data?.getPagingPublications?.pagination
  }
}

export const labeller = (value: Publication) => `${value.PublicationTitle}`

export const PublicationSelector: FC<ProjectSelectorProps> = ({
  label = 'Please select the publication',
  queryHookOptions,
  ...props
}) => {
  return (
    <InfiniteSelector<Publication>
      {...props}
      label={label}
      labeller={labeller}
      compileData={compileData}
      query={GET_PAGING_PUBLICATIONS}
      queryHookOptions={queryHookOptions}
    />
  )
}
