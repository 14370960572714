import { useMutation } from '@apollo/react-hooks'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles
} from '@material-ui/core'
import { GridLayoutToggle } from 'components/common/GridLayoutToggle'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import {
  SystemConfiguration,
  UpdateSystemConfigurationMutation,
  UpdateSystemConfigurationMutationVariables,
  WorkerType
} from 'generated/graphql'
import { GET_SYSTEM_CONFIGURATIONS, UPSERT_SYSTEM_CONFIGURATIONS } from '../graphql'
import { Transition } from 'utils/modal'
import { LabourTypeSelector } from 'components/common/LabourTypeSelector'
import { FrequencyLevel } from 'utils/Constants'
import { JSONParse } from 'utils/tools'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { IsActiveToggle } from 'utils/Constants'

interface LabourFrequencyModalProps {
  handleClose: () => void
  labourItem?: SystemConfiguration
  dialogTitle?: string
}

const useStyles = makeStyles({
  labourChip: {
    display: 'flex',
    gap: '0.5rem',
    flexWrap: 'wrap'
  },
  chipColor: {
    backgroundColor: 'var(--success-color-light)',
    '&:focus': {
      backgroundColor: 'var(--success-color-light)'
    }
  }
})

export const LabourFrequencyModal = ({
  handleClose,
  labourItem,
  dialogTitle = 'Edit Low Frequency Labour Types'
}: LabourFrequencyModalProps) => {
  const classes = useStyles()
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext()

  const initialValues = useMemo(() => {
    return JSONParse<WorkerType[]>(labourItem?.SettingValue || '[]')
  }, [labourItem])
  const [selectedWorkerTypes, setSelectedWorkerTypes] = useState(initialValues)

  const isLow = labourItem?.SettingName === FrequencyLevel.Low

  const [upsertModule] = useMutation<UpdateSystemConfigurationMutation, UpdateSystemConfigurationMutationVariables>(
    UPSERT_SYSTEM_CONFIGURATIONS,
    {
      refetchQueries: [{ query: GET_SYSTEM_CONFIGURATIONS }]
    }
  )

  const formik = useFormik({
    initialValues: {
      ID: labourItem?.ID || '',
      SettingName: labourItem?.SettingName || '',
      SettingValue: labourItem?.SettingValue || '',
      IsActive: labourItem?.IsActive
    },
    onSubmit: async ({ ID, IsActive }, { resetForm }) => {
      try {
        await upsertModule({
          variables: {
            input: {
              ID,
              SettingValue: JSON.stringify(
                selectedWorkerTypes?.map(labourItem => {
                  return {
                    ID: labourItem.ID,
                    Description: labourItem.Description,
                    IsActive: labourItem.IsActive
                  }
                }) || '[]'
              ),
              IsActive: !!IsActive
            }
          }
        })
        resetForm()
        handleClose()
        setSuccessMessage('System Configuration has been updated successfully')
      } catch (error) {
        setErrorMessage((error as Error)?.message)
      }
    }
  })

  const { handleSubmit, setValues, values, setFieldValue, isSubmitting } = formik

  useEffect(() => {
    setValues({
      ID: labourItem?.ID || '',
      SettingName: labourItem?.SettingName || '',
      SettingValue: labourItem?.SettingValue || '',
      IsActive: labourItem?.IsActive
    })
  }, [labourItem, setValues])

  const handleDelete = (filterValue: string) => {
    setSelectedWorkerTypes(selectedWorkerTypes?.filter(workerType => workerType.Description !== filterValue))
  }

  return (
    <>
      <Dialog open={!!labourItem} TransitionComponent={Transition} keepMounted fullWidth onClose={handleClose}>
        <DialogTitle id="adelete-site-diary-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form>
              <Grid container xs={12}>
                <LabourTypeSelector
                  onChange={workerType => {
                    setSelectedWorkerTypes([...(workerType ? [workerType] : []), ...(selectedWorkerTypes || [])])
                  }}
                  isLow={isLow}
                  selectedWorkerTypes={selectedWorkerTypes || []}
                  blurOnSelect
                  clearOnBlur
                />
              </Grid>
              <br />
              <Grid item xs={12} sm={12}>
                <Box className={classes.labourChip}>
                  {selectedWorkerTypes?.map((workerType, index) => (
                    <Chip
                      key={index}
                      label={workerType.Description}
                      onDelete={() => handleDelete(workerType.Description || '')}
                      className={classes.chipColor}
                    />
                  ))}
                </Box>
              </Grid>
              <br />
              <GridLayoutToggle<IsActiveToggle>
                toggleBreakpoints={{ xs: 12, sm: 12 }}
                left={IsActiveToggle.Active}
                right={IsActiveToggle.InActive}
                value={values?.IsActive ? IsActiveToggle.Active : IsActiveToggle.InActive}
                setValue={value => setFieldValue('IsActive', value === IsActiveToggle.Active)}
              />
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            color="secondary"
            variant="contained"
            onClick={() => handleSubmit()}
            aria-label="button-modal-confirm"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
