import { copyFromClipboard } from 'utils/tools'
import { useSnackbarContext } from './useSnackbarContext'

export const useCopyFromClipboard = () => {
  const { setSuccessMessage } = useSnackbarContext()
  return (url: string) => {
    copyFromClipboard(url)
    setSuccessMessage('Copied to clipboard!')
  }
}
