import React, { useState, useMemo } from 'react'
import { Button, Grid, TablePagination, Typography } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import debounce from 'lodash/debounce'
import { Table } from '../../../components/common/Table'
import { EditIcon } from 'components/common/icons'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { Feedback } from '../../../utils/feedback'
import { GET_PAGING_EMPLOYEE_BENEFITS } from '../../../graphql/common/index'
import {
  GetPagingEmployeeBenefitsQuery,
  GetPagingEmployeeBenefitsQueryVariables,
  EmployeeBenefit
} from 'generated/graphql'
import { EmployeeBenefitForm } from './EmployeeBenefitForm'
import { useUserInfo } from 'hooks/useUserInfo'
import { getLocalStorageItem, setLocalStorageItem } from 'utils/tools'

interface NewsAlertState {
  filter: string
  currentPage: number
  currentPageSize: number
  autoHideFeedback: Feedback | null
  isShowEmployeeBenefitForm: boolean
}

export const EmployeeBenefits = () => {
  const pageSizePublications = getLocalStorageItem('pageSizePublications')
  const [state, setState] = useState<NewsAlertState>({
    filter: '',
    currentPage: 1,
    currentPageSize: pageSizePublications ? parseInt(pageSizePublications) : 10,
    autoHideFeedback: null,
    isShowEmployeeBenefitForm: false
  })
  const [selectedItem, setSelectedItem] = useState<EmployeeBenefit | null>(null)
  const { filter, currentPage, currentPageSize, autoHideFeedback, isShowEmployeeBenefitForm } = state

  const { personInfoData } = useUserInfo()

  const hasMarketingRole = useMemo(() => {
    return !!personInfoData?.personInfo?.roles?.find((item: any) => item.Name === 'Marketing')
  }, [personInfoData])

  const setFilterChange = debounce(search => {
    setState({ ...state, filter: search, currentPage: 1 })
  }, 500)

  const columns = [
    {
      field: 'ID',
      title: 'Order ID'
    },
    {
      field: 'employeeBenefitCategory.CategoryName',
      title: 'Category',
      width: '10%'
    },
    {
      field: 'Title',
      title: 'Title',
      width: '50%'
    },
    {
      field: 'URL',
      title: 'URL',
      width: '30%'
    },
    {
      field: 'IsActive',
      title: 'Status',
      render: (rowData: EmployeeBenefit) => (rowData.IsActive ? 'Active' : 'Inactive')
    }
  ]

  const { data: dataEmployeeBenefits, loading: loadingData, refetch: refetchData } = useQuery<
    GetPagingEmployeeBenefitsQuery,
    GetPagingEmployeeBenefitsQueryVariables
  >(GET_PAGING_EMPLOYEE_BENEFITS, {
    variables: {
      filter: filter,
      sorter: { field: 'EmployeeBenefit.ID', orderBy: 'ASC' },
      pagination: {
        pageNumber: currentPage,
        pageSize: currentPageSize
      }
    }
  })

  return (
    <>
      {isShowEmployeeBenefitForm ? (
        <EmployeeBenefitForm
          selectedItem={selectedItem}
          onBack={() => {
            setState({ ...state, isShowEmployeeBenefitForm: false })
            setSelectedItem(null)
          }}
          refetchData={refetchData}
        />
      ) : (
        <>
          <Grid container direction="row">
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid>
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    setState({
                      ...state,
                      isShowEmployeeBenefitForm: true
                    })
                  }
                >
                  Create Benefit
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 20 }}>
            <Table
              isLoading={loadingData}
              columns={columns}
              data={(dataEmployeeBenefits?.getPagingEmployeeBenefits?.items as EmployeeBenefit[]) || []}
              onSearchChange={e => {
                const search: string = e.toString()
                setFilterChange(search)
              }}
              option={{
                pageSize: currentPageSize
              }}
              actions={[
                {
                  icon: () => (
                    <Grid container justifyContent="center" direction="row" alignItems="center">
                      <EditIcon color="secondary" style={{ fontSize: 15 }} />
                      <Typography color="secondary" variant="body2">
                        Edit
                      </Typography>
                    </Grid>
                  ),
                  tooltip: 'Edit',
                  hidden: !hasMarketingRole,
                  onClick: (_event, rowData: EmployeeBenefit | EmployeeBenefit[]) => {
                    if (!Array.isArray(rowData)) {
                      setState({ ...state, isShowEmployeeBenefitForm: true })
                      setSelectedItem(rowData)
                    }
                  }
                }
              ]}
              components={{
                Pagination: props => {
                  return (
                    <TablePagination
                      {...props}
                      rowsPerPageOptions={[10, 20, 50]}
                      rowsPerPage={currentPageSize}
                      count={dataEmployeeBenefits?.getPagingEmployeeBenefits.pagination?.total || 0}
                      page={currentPage - 1}
                      onChangePage={(e, page) => {
                        setState({ ...state, currentPage: page + 1 })
                      }}
                      onChangeRowsPerPage={e => {
                        props.onChangeRowsPerPage(e)
                        setState({ ...state, currentPage: 1, currentPageSize: Number(e.target.value) })
                        setLocalStorageItem('pageSizePublications', e.target.value)
                      }}
                    />
                  )
                }
              }}
            />
          </Grid>

          <AutoHideSnackBar
            autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
            handleClose={() => setState({ ...state, autoHideFeedback: null })}
            message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
            severity={autoHideFeedback?.type}
            open={!!autoHideFeedback}
          />
        </>
      )}
    </>
  )
}
