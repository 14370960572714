import React from 'react'
import { Tooltip, TooltipProps } from '@material-ui/core'
import { ToggleButton, ToggleButtonProps } from '@material-ui/lab'

type TooltipToggleButtonProps = ToggleButtonProps & {
  TooltipProps: Omit<TooltipProps, 'children'>
}

const TooltipToggleButton = ({ TooltipProps, ...props }: TooltipToggleButtonProps) => {
  return (
    <Tooltip {...TooltipProps}>
      <ToggleButton {...props} />
    </Tooltip>
  )
}

export default TooltipToggleButton
