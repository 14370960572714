import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  BatchProcess,
  DeleteBatchProcessMutation,
  DeleteBatchProcessMutationVariables,
  GetBatchProcessQuery,
  GetBatchProcessQueryVariables,
  GetFileQuery,
  GetFileQueryVariables
} from 'generated/graphql'
import { DELETE_BATCH_PROCESS, GET_BATCH_PROCESS } from 'graphql/common'
import useFileUrl from 'hooks/useFileUrl'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UploadTableStatus } from 'utils/types'
import { ConfirmDialog } from '../ConfirmDialog'
import { Table } from '../Table'
import uploadActions from './uploadActions'
import uploadColumns from './uploadColumns'

interface BatchProcessTableProps {
  processType: string
  processID: string
  setProcessID: Dispatch<SetStateAction<string>>
  onSuccess?: () => void
}

export default function BatchProcessTable({ processType, processID, setProcessID, onSuccess }: BatchProcessTableProps) {
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext()
  const [deletedItem, setDeletedItem] = useState('')
  const getFileUrl = useFileUrl<GetFileQuery, GetFileQueryVariables>()

  const [deleteBatchProcess] = useMutation<DeleteBatchProcessMutation, DeleteBatchProcessMutationVariables>(
    DELETE_BATCH_PROCESS
  )

  const { data: batchProcessData, refetch } = useQuery<GetBatchProcessQuery, GetBatchProcessQueryVariables>(
    GET_BATCH_PROCESS,
    {
      variables: {
        ProcessType: processType,
        ProcessID: processID
      },
      fetchPolicy: 'cache-and-network'
    }
  )

  useEffect(() => {
    if (batchProcessData?.getBatchProcess && [batchProcessData?.getBatchProcess]?.[0]?.FileUrl === null) {
      const interval = setInterval(() => {
        refetch()
      }, 5000)
      return () => clearInterval(interval)
    }

    if (
      processID &&
      batchProcessData?.getBatchProcess &&
      batchProcessData?.getBatchProcess.Status === UploadTableStatus.Completed
    )
      onSuccess?.()
    // eslint-disable-next-line
  }, [batchProcessData])

  const handleDelete = (processID: string) => {
    deleteBatchProcess({
      variables: {
        ProcessID: processID
      }
    })
      .then(({ data }) => {
        if (data?.deleteBatchProcess) {
          setProcessID('')
          setSuccessMessage(`Template deleted successfully`)
          refetch()
        }
      })
      .catch(err => {
        setErrorMessage(err.message)
        refetch()
      })
  }

  return (
    <>
      <Table<BatchProcess>
        search={false}
        columns={uploadColumns}
        data={!!batchProcessData?.getBatchProcess ? [batchProcessData?.getBatchProcess] : []}
        actions={uploadActions({
          batchProcess: batchProcessData?.getBatchProcess,
          setDeletedItem,
          getFileUrl
        })}
        option={{
          toolbar: false,
          paging: false
        }}
      />
      {!!deletedItem && (
        <ConfirmDialog
          buttonText="Delete"
          handleClose={() => setDeletedItem('')}
          open={!!deletedItem}
          onConfirm={() => handleDelete(deletedItem)}
          title="Delete Template"
          description="Are you sure you want to delete this uploaded template?"
        />
      )}
    </>
  )
}
