import { useQuery } from '@apollo/react-hooks'
import { Button, Grid } from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { FormAwareGridLayoutAutocomplete } from 'components/common/FormAwareGridLayoutAutoComplete'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { Field, FormikProps } from 'formik'
import { GetFailedEventMessageTypeQuery, GetFailedEventMessageTypeQueryVariables } from 'generated/graphql'
import useRouter from 'hooks/useRouter'
import React, { FC, useEffect } from 'react'
import { DD_MM_YYY_HH } from 'utils/dates'
import { GET_FAILED_EVENT_MESSAGE_TYPE } from './graphql'

export interface FormValues {
  EndDate?: Date | null
  StartDate?: Date | null
  MessageType?: string
  ID?: string
}

interface FormManageAuditProps {
  form: FormikProps<any>
  setFilterChange: (filterValues: FormValues) => void
}

const FormFilterFailEvents: FC<FormManageAuditProps> = ({ form, setFilterChange }) => {
  const { values, errors, touched, setFieldValue, resetForm, handleSubmit, setValues } = form

  const { data: eventMessageTypes } = useQuery<GetFailedEventMessageTypeQuery, GetFailedEventMessageTypeQueryVariables>(
    GET_FAILED_EVENT_MESSAGE_TYPE
  )
  const { history, query } = useRouter()

  useEffect(() => {
    if (query.q) {
      const data = JSON.parse(query.q)
      setFilterChange(data)
      setValues({
        ...data
      })
    } else {
      setFilterChange({})
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [query.q])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
          <GridLayoutInput
            fullWidth
            id="ID"
            name="ID"
            defaultValue={query.q ? JSON.parse(query.q).ID : ''}
            textFieldBreakpoints={{ xs: 3 }}
            label="ID"
          />
          <Field
            component={FormAwareGridLayoutAutocomplete}
            breakpoints={{
              autoCompleteBreakpoints: { xs: 3 }
            }}
            label="Please select event type"
            getOptionLabel={(messageType: string) => {
              return messageType || ''
            }}
            name="MessageType"
            options={eventMessageTypes?.getFailedEventMessageType || []}
          />
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <KeyboardDateTimePicker
              value={values.StartDate || null}
              format={DD_MM_YYY_HH}
              style={{ width: '100%' }}
              inputVariant="outlined"
              onChange={(date: Date | null) => {
                setFieldValue('StartDate', date as Date)
              }}
              type="keyboard"
              label="Start Date"
              maxDate={new Date()}
              error={!!errors.StartDate && !!touched.StartDate}
              helperText={!!errors.StartDate && !!touched.StartDate ? 'Invalid Date' : null}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDateTimePicker
              value={values.EndDate || null}
              format={DD_MM_YYY_HH}
              style={{ width: '100%' }}
              inputVariant="outlined"
              onChange={(date: Date | null) => {
                setFieldValue('EndDate', date as Date)
              }}
              label="End Date"
              minDate={values.StartDate}
            />
          </Grid>
          <Grid item>
            <>
              <Button color="secondary" variant="contained" type="submit">
                Search
              </Button>
              &nbsp;&nbsp;
            </>
            <Button
              variant="contained"
              onClick={() => {
                resetForm()
                history.push('/manage-failed-events')
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default FormFilterFailEvents
