import { IconButton } from '@material-ui/core'
import { FileCopyIcon } from 'components/common/icons'
import { NewsArticle } from 'generated/graphql'
import { Column } from 'material-table'
import React from 'react'
import { LINKED_SITE_WEB_ENDPOINT } from 'utils/Constants'

type ColumnsProps = {
  copyFromClipboard: (text: string) => void
}

export const columns = ({ copyFromClipboard }: ColumnsProps): (Column<NewsArticle> & { width?: string })[] => [
  {
    title: 'Title',
    render: row => row.Title
  },
  {
    title: 'URL',
    render: row => (
      <div>
        <span className="mr-1">{`/article/${row.ID}`}</span>
        <IconButton
          size="small"
          onClick={() => {
            copyFromClipboard(`${LINKED_SITE_WEB_ENDPOINT}/article/${row.ID}`)
          }}
        >
          <FileCopyIcon fontSize="small" />
        </IconButton>
      </div>
    )
  }
]
