import * as Sentry from '@sentry/browser'

export const expectedError = [
  'Non-Error promise rejection captured with value: No current user',
  'The notification permission was not granted and blocked instead',
  'The security token included in the request is expired',
  'Cannot retrieve a new session'
]

export const errorHandler = (error: any) => {
  if (error?.code === 'UserNotFoundException')
    return {
      isAuthError: true
    }
  const errorMessage = error.originalError || error.error || error
  if (expectedError.includes(errorMessage)) return
  Sentry.captureException(errorMessage)
}
