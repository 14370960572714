import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, DialogContentText, Grid } from '@material-ui/core'
import { AllProjectsActiveSelector } from 'components/common/AllProjectsActiveSelector'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { SelectorObjectField } from 'components/common/SelectorObjectField'
import { DeleteIcon } from 'components/common/icons'
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik'
import {
  AddRoleForPersonMutation,
  AddRoleForPersonMutationVariables,
  DeleteRolePersonMutation,
  DeleteRolePersonMutationVariables,
  FindManyAdminRolesQuery,
  GetUserRoleProjectAccessQuery,
  GetUserRoleProjectAccessQueryVariables,
  Person,
  Project,
  Role,
  UserProjectAccess
} from 'generated/graphql'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import React, { useState } from 'react'
import * as yup from 'yup'
import { RoleSelector } from '../../../components/common/RolesSelector'
import { Table } from '../../../components/common/Table'
import { projectColumns } from './columns'
import { ADD_ROLE_FOR_PERSON, DELETE_ROLE_FOR_PERSON, GET_USER_ROLE_PROJECT_ACCESS } from './graphql'
import { Roles } from 'utils/rolesAndPrivileges'
import { getPersonLabelEntity } from 'utils/label'

interface ProjectRolesProps {
  selectedRoleItem?: Person | null
  isExternalRole?: boolean
}

interface AddRoleFormProps {
  project: Project | null
  role: Role | null
}

interface AssignRoleConfirmation {
  shouldShowAssignRoleConfirmation: boolean
  userRole: 'staff' | 'labour'
}

export const ProjectRoles = ({ selectedRoleItem, isExternalRole = false }: ProjectRolesProps) => {
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext()

  const [deletedItem, setDeletedItem] = useState<UserProjectAccess | null>(null)
  const [assignRoleConfirmation, setAssignRoleConfirmation] = useState<AssignRoleConfirmation | null>(null)

  const { data: userAccessData, loading: isLoadingProjectAccess } = useQuery<
    GetUserRoleProjectAccessQuery,
    GetUserRoleProjectAccessQueryVariables
  >(GET_USER_ROLE_PROJECT_ACCESS, {
    skip: !selectedRoleItem,
    variables: {
      PersonID: selectedRoleItem?.ID || '0'
    },
    fetchPolicy: 'cache-and-network'
  })

  const projectRoles = userAccessData?.getUserRoleProjectAccess?.filter(
    projectRole => !(projectRole.role?.RequiresCostCode || projectRole.role?.RequiresAmount)
  ) as UserProjectAccess[]

  const [deleteRolePerson] = useMutation<DeleteRolePersonMutation, DeleteRolePersonMutationVariables>(
    DELETE_ROLE_FOR_PERSON,
    {
      refetchQueries: [
        {
          query: GET_USER_ROLE_PROJECT_ACCESS,
          variables: {
            PersonID: selectedRoleItem?.ID || '0'
          }
        }
      ]
    }
  )

  const [addRoleForPerson] = useMutation<AddRoleForPersonMutation, AddRoleForPersonMutationVariables>(
    ADD_ROLE_FOR_PERSON,
    {
      refetchQueries: [
        {
          query: GET_USER_ROLE_PROJECT_ACCESS,
          variables: {
            PersonID: selectedRoleItem?.ID || '0'
          }
        }
      ]
    }
  )

  const extractProjects = (data: Project[]): Project[] => {
    return data.filter(e => !projectRoles?.find(project => project.ID === e.ID))
  }

  const handleDelete = (roleToDelete: UserProjectAccess) => {
    setDeletedItem(roleToDelete)
  }

  const onDelete = () => {
    deleteRolePerson({
      variables: {
        input: {
          PersonID: selectedRoleItem?.ID || '',
          RoleID: deletedItem?.role?.ID || '',
          ProjectID: deletedItem?.ProjectID || ''
        }
      }
    })
      .then(({ data }) => {
        if (data?.deleteRolePersonAdminPage) {
          setDeletedItem(null)
          setSuccessMessage('Job roles has been updated successfully')
        }
      })
      .catch(error => {
        setErrorMessage(error.message)
      })
  }

  const addRole = async (values: AddRoleFormProps, { resetForm }: FormikHelpers<AddRoleFormProps>) => {
    try {
      const { data } = await addRoleForPerson({
        variables: {
          input: {
            PersonID: selectedRoleItem?.ID || '',
            ProjectID: values.project?.ID || '',
            RoleID: values.role?.ID || ''
          }
        }
      })
      if (data?.addRoleForPersonAdminPage) {
        setSuccessMessage('Job roles has been updated successfully')
        resetForm()
      }
    } catch (error) {
      setErrorMessage((error as Error)?.message)
    }
  }

  const initialValues: AddRoleFormProps = {
    project: null,
    role: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      project: yup
        .object()
        .label('Job')
        .nullable()
        .required(),
      role: yup
        .object()
        .label('Role')
        .nullable()
        .required()
    }),
    onSubmit: addRole
  })

  const { isSubmitting, values, handleSubmit } = formik

  const extractEntities = (data: FindManyAdminRolesQuery): Role[] => {
    return data.findManyAdminRoles.items
      .filter(
        e =>
          !values.project ||
          !projectRoles?.find(
            projectAccess => values?.project?.ID === projectAccess.ProjectID && projectAccess.role?.ID === e.ID
          )
      )
      .map(e => e as Role)
  }

  const handleAddRole = () => {
    if (values.project && values.role) {
      const { StaffRole, LabourRole } = values.role

      if (isExternalRole) {
        const hasJVStaffRole = projectRoles.some(
          projectRole => projectRole.project?.ID === values.project?.ID && projectRole.role?.Name === Roles.JVStaff
        )

        // In External: if user have JVStaff role, they are Staff user, otherwise they are Labour user
        const shouldShowConfirmForExternal = (!LabourRole && !hasJVStaffRole) || (!StaffRole && hasJVStaffRole)

        if (shouldShowConfirmForExternal) {
          setAssignRoleConfirmation({
            shouldShowAssignRoleConfirmation: true,
            userRole: hasJVStaffRole ? 'staff' : 'labour'
          })
          return
        }
      } else {
        const payrollClassification = selectedRoleItem?.internalPerson?.payrollClassification
        const isWagesStaff = payrollClassification?.IsWagesStaff

        const isLabourUser = isWagesStaff || payrollClassification === null || isWagesStaff === null
        const isStaffUser = isWagesStaff === false

        const shouldShowConfirmForInternal = (!LabourRole && isLabourUser) || (!StaffRole && isStaffUser)

        if (shouldShowConfirmForInternal) {
          setAssignRoleConfirmation({
            shouldShowAssignRoleConfirmation: true,
            userRole: isLabourUser ? 'labour' : 'staff'
          })
          return
        }
      }
    }

    handleSubmit()
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <Grid container justifyContent="space-between" direction="row" spacing={2}>
            <Grid item xs={6}>
              <SelectorObjectField
                name="project"
                label="Job*"
                extractData={extractProjects}
                selector={AllProjectsActiveSelector}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectorObjectField
                name="role"
                label="Role*"
                isExternalRole={isExternalRole}
                extractEntities={extractEntities}
                roleType="ProjectLevelRole"
                selector={RoleSelector}
              />
            </Grid>
            <Grid item xs={2}>
              <Button disabled={isSubmitting} variant="contained" color="secondary" onClick={handleAddRole}>
                Add Role
              </Button>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <br />
      <Grid>
        <Table<UserProjectAccess>
          isLoading={isLoadingProjectAccess}
          columns={projectColumns}
          data={projectRoles || []}
          actions={[
            {
              icon: () => <DeleteIcon style={{ marginRight: 10 }} spacing={4} color="secondary" fontSize="small" />,
              tooltip: 'Remove',
              onClick: (_event, rowData: UserProjectAccess | UserProjectAccess[]) => {
                if (!Array.isArray(rowData)) handleDelete(rowData)
              }
            }
          ]}
          option={{
            toolbar: false
          }}
        />
      </Grid>
      <ConfirmDialog
        buttonText="Delete"
        handleClose={() => setDeletedItem(null)}
        open={!!deletedItem}
        onConfirm={onDelete}
        title="Delete Role"
        description="Are you sure to delete this role from Job roles?"
      />

      {assignRoleConfirmation && (
        <ConfirmDialog
          buttonText="Add"
          handleClose={() => setAssignRoleConfirmation(null)}
          open={assignRoleConfirmation.shouldShowAssignRoleConfirmation}
          onConfirm={() => handleSubmit()}
          title="Add Role"
        >
          <DialogContentText>
            Are you sure you want to add the <span className="font-bold">{values.role?.Name}</span> role to{' '}
            <span className="font-bold">{getPersonLabelEntity(selectedRoleItem)} </span> ? This role is not designed to
            be allocated to {assignRoleConfirmation.userRole} employees.
          </DialogContentText>
        </ConfirmDialog>
      )}
    </>
  )
}
