import { useQuery } from '@apollo/react-hooks'
import React, { useState } from 'react'
import {
  PayrollClassification,
  GetPayrollClassificationsQuery,
  GetPayrollClassificationsQueryVariables
} from '../../../generated/graphql'
import { Table } from '../../../components/common/Table'
import { Column } from 'material-table'
import { FormTimesheetLockingModal } from './FormTimesheetLockingModal'
import { EditIcon } from 'components/common/icons'
import { formatDateInDDMMYYYY } from 'utils/dates'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { Feedback } from 'utils/feedback'
import { customFilterAndSearchColumn } from 'utils/tools'
import { GET_PAYROLL_CLASSIFICATIONS } from 'graphql/common'

const columns: Column<PayrollClassification>[] = [
  {
    title: 'Company',
    field: 'Company',
    sorting: false,
    render: (row: PayrollClassification) => row?.company?.Name,
    customFilterAndSearch: (filter: string, rowData: PayrollClassification) =>
      customFilterAndSearchColumn(filter, rowData, (row: PayrollClassification) => `${row.company?.Name}`)
  },
  {
    title: 'Name',
    field: 'ClassificationName',
    sorting: false,
    customFilterAndSearch: (filter: string, rowData: PayrollClassification) =>
      customFilterAndSearchColumn(filter, rowData, (row: PayrollClassification) => `${row.ClassificationName}`)
  },
  {
    title: 'Lock Date',
    field: 'LockDate',
    sorting: false,
    render: (row: PayrollClassification) => row?.LockDate && formatDateInDDMMYYYY(new Date(row.LockDate))
  }
]

export const TimesheetLocking = () => {
  const [selectedItem, setSelectedItem] = useState<PayrollClassification>()
  const [autoHideFeedback, setAutoHideFeedback] = React.useState<Feedback | null>(null)

  const handleClose = () => {
    setSelectedItem(undefined)
  }
  const options = {
    search: true
  }

  const { data, refetch } = useQuery<GetPayrollClassificationsQuery, GetPayrollClassificationsQueryVariables>(
    GET_PAYROLL_CLASSIFICATIONS
  )

  return (
    <>
      <Table<PayrollClassification>
        columns={columns}
        data={data?.getPayrollClassifications || []}
        option={options}
        actions={[
          {
            icon: () => <EditIcon color="secondary" style={{ fontSize: 15 }} />,
            tooltip: 'Change Lock',
            onClick: (_event, rowData: PayrollClassification | PayrollClassification[]) => {
              if (!Array.isArray(rowData)) setSelectedItem(rowData)
            }
          }
        ]}
      />
      {selectedItem && (
        <FormTimesheetLockingModal
          handleClose={handleClose}
          item={selectedItem}
          setAutoHideFeedback={setAutoHideFeedback}
          refetch={refetch}
        />
      )}
      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setAutoHideFeedback(null)}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />
    </>
  )
}
export default TimesheetLocking
