import { makeStyles } from '@material-ui/core/styles'
import MaterialTable, { Action, Column, Components, DetailPanel, Options, Filter } from 'material-table'
import { ROW_PER_PAGE_OPTIONS } from 'utils/Constants'
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react'
import {
  AddBoxIcon,
  CheckIcon,
  ClearIcon,
  DeleteIcon,
  DetailPanelIcon,
  EditIcon,
  ExportIcon,
  FilterIcon,
  FirstPageIcon,
  LastPageIcon,
  NextPageIcon,
  PreviousPageIcon,
  ResetSearchIcon,
  SearchIcon,
  SortArrowIcon,
  ThirdStateCheckIcon,
  ViewColumnIcon
} from './icons'
import EmptyTableBody from './EmptyTableBody'
import classNames from 'classnames'

const useStyles = makeStyles({
  table: {
    marginBottom: 25
  }
})
const tableIcons = {
  Add: AddBoxIcon,
  Check: CheckIcon,
  Clear: ClearIcon,
  Delete: DeleteIcon,
  DetailPanel: DetailPanelIcon,
  Edit: EditIcon,
  Export: ExportIcon,
  Filter: FilterIcon,
  FirstPage: FirstPageIcon,
  LastPage: LastPageIcon,
  NextPage: NextPageIcon,
  PreviousPage: PreviousPageIcon,
  ResetSearch: ResetSearchIcon,
  Search: SearchIcon,
  SortArrow: SortArrowIcon,
  ThirdStateCheck: ThirdStateCheckIcon,
  ViewColumn: ViewColumnIcon
}

type EditableAction<RowData extends object> = {
  isEditable?: (rowData: RowData) => boolean
  isDeletable?: (rowData: RowData) => boolean
  onRowAdd?: (newData: RowData) => Promise<void | RowData | RowData[]>
  onRowUpdate?: (newData: RowData, oldData?: RowData) => Promise<void | RowData | RowData[]>
  onRowDelete?: (oldData: RowData) => Promise<void | RowData | RowData[]>
}

export const customFilterAndSearchColumn = <T,>(filter: string, rowData: T, extractFilter: (rowData: T) => string) =>
  extractFilter(rowData)
    .toLowerCase()
    .indexOf(filter.toLowerCase()) > -1

export const customFilterAndSearchColumnNumber = <T,>(
  filter: string,
  rowData: T,
  extractFilter: (rowData: T) => number
) => extractFilter(rowData)
interface RefObj {
  deselectAllRow: () => void
}

interface TableProps<T extends object> {
  actions?: (Action<T> | ((rowData: T) => Action<T>))[]
  columns?: Column<T>[]
  data: T[]
  isLoading?: boolean | false
  handleEditable?: EditableAction<T>
  pageSize?: number
  search?: boolean
  toolbar?: boolean
  option?: Options
  title?: any
  style?: React.CSSProperties
  components?: Components | undefined
  onSearchChange?: (searchText: string) => void
  onFilterChange?: ((filters: Filter<T>[]) => void) | undefined
  onSelectionChange?: ((data: T[], rowData?: T | undefined) => void) | undefined
  onOrderChange?: ((orderBy: number, orderDirection: 'desc' | 'asc') => void) | undefined
  detailPanel?: ((rowData: T) => React.ReactNode) | (DetailPanel<T> | ((rowData: T) => DetailPanel<T>))[] | undefined
  isShowCustomEmptyData?: boolean
  emptyDescription?: string
  className?: string
}

const TableElement = <T extends object>(
  {
    search = true,
    actions,
    pageSize,
    columns = [],
    data,
    handleEditable,
    option,
    components,
    detailPanel,
    onSelectionChange,
    onSearchChange,
    onOrderChange,
    onFilterChange,
    isLoading,
    style,
    toolbar = true,
    title = '',
    isShowCustomEmptyData = false,
    emptyDescription,
    className
  }: TableProps<T> & React.RefAttributes<T>,
  ref: React.Ref<RefObj>
) => {
  const classes = useStyles()
  const tableRef = useRef<any>(null)
  const [tableData, setTableData] = useState<T[]>(data)
  const [tableColumns, setTableColumns] = useState<Column<T>[]>(columns || [])

  const defaultOptions = {
    actionsColumnIndex: -1,
    pageSize: pageSize || 10,
    pageSizeOptions: pageSize ? [pageSize, 10, 20, 50] : ROW_PER_PAGE_OPTIONS,
    search: search,
    toolbar: toolbar,
    emptyRowsWhenPaging: false,
    draggable: false,
    ...option
  }

  useEffect(() => {
    setTableData(data)
    setTableColumns(columns)
    // eslint-disable-next-line
  }, [data, columns])

  const deselectAllRow = () => {
    tableRef?.current?.onAllSelected(false)
  }
  const renderColumns = () => {
    setTableColumns(columns)
  }

  useImperativeHandle(ref, () => ({ deselectAllRow, renderColumns }))

  return (
    <div className={classNames(classes.table, className)} style={style}>
      <MaterialTable
        isLoading={isLoading}
        actions={actions}
        editable={handleEditable}
        columns={tableColumns}
        data={tableData}
        icons={tableIcons}
        options={defaultOptions}
        components={{
          ...components,
          ...(isShowCustomEmptyData
            ? {
                Body: () => <EmptyTableBody description={emptyDescription} />
              }
            : {})
        }}
        title={title}
        detailPanel={detailPanel}
        tableRef={tableRef}
        onSelectionChange={onSelectionChange}
        onFilterChange={onFilterChange}
        onSearchChange={onSearchChange}
        onOrderChange={onOrderChange}
      />
    </div>
  )
}

export const Table = React.forwardRef(TableElement) as typeof TableElement
