import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { OperationVariables } from 'apollo-boost'
import { DocumentNode } from 'graphql'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Scalars } from '../../generated/graphql'
import { AutocompleteInput, NOAUTOFILL, AutocompleteInputProps } from './AutocompleteInput'
import { SelectorSkeleton } from './SelectorSkeleton'

interface BaseEntity {
  ID: Scalars['ID']
}

export type Value<Entity> = Entity | Scalars['ID'] | null | undefined

interface RequiredProps<Entity extends BaseEntity> {
  onChange: (newValue: Entity | null) => void
}

interface BaseProps<Entity extends BaseEntity> extends RequiredProps<Entity> {
  error?: AutocompleteInputProps<Entity>['error']
  helperText?: AutocompleteInputProps<Entity>['helperText']
  label: AutocompleteInputProps<Entity>['label']
  onBlur?: AutocompleteInputProps<Entity>['blurHandler']
  labelEntity: (value: Entity) => string
  disabled?: boolean
  value?: Value<Entity>
  blurOnSelect?: boolean
  freeSolo?: boolean
  clearOnBlur?: boolean
  placeholder?: string
  getOptionSelected?: (option: Entity, value: Entity) => boolean
}

interface SelectorProps<Entity extends BaseEntity> extends BaseProps<Entity> {
  labelEntity: (value: Entity) => string
  options: Entity[]
}

interface EntitySelectorProps<Entity extends BaseEntity, TData, TVariables> extends BaseProps<Entity> {
  extractEntities: (data: TData) => Entity[]
  query: DocumentNode
  queryHookOptions?: QueryHookOptions<TData, TVariables>
  refetch?: boolean
  setState?: (data: Entity | Entity[]) => Dispatch<SetStateAction<Entity | Entity[]>>
}

export type EntitySelectorWrapperProps<Entity extends BaseEntity, TData, TVariables = OperationVariables> = Partial<
  EntitySelectorProps<Entity, TData, TVariables>
> &
  RequiredProps<Entity>

const currentOption = <Entity extends BaseEntity>(options: Entity[], value: Value<Entity>) => {
  if (typeof value === 'string') {
    return options?.find(({ ID }) => ID === value) || null
  }
  if (typeof value === 'object' && value !== null) {
    return options?.find(({ ID }) => ID === value.ID) || value
  }
  return value ?? null
}

export const Selector = <Entity extends BaseEntity>({
  value,
  onChange,
  options,
  labelEntity,
  onBlur,
  getOptionSelected = (option, value) => option.ID === value.ID,
  ...props
}: SelectorProps<Entity>) => {
  const option = currentOption(options, value)

  return (
    <AutocompleteInput<Entity>
      autoComplete={NOAUTOFILL}
      clearOnEscape
      changeHandler={(_, value) => onChange(value)}
      getOptionLabel={labelEntity}
      getOptionSelected={getOptionSelected}
      options={options}
      value={option}
      blurHandler={onBlur}
      {...props}
    />
  )
}

export const EntitySelector = <Entity extends BaseEntity, TData, TVariables = OperationVariables>({
  query,
  extractEntities,
  queryHookOptions,
  ...props
}: EntitySelectorProps<Entity, TData, TVariables>) => {
  const { loading, data } = useQuery<TData, TVariables>(query, {
    ...queryHookOptions,
    fetchPolicy: 'network-only'
  })
  if (queryHookOptions?.skip) {
    props = { ...props, disabled: true }
  }
  const { setState } = props
  useEffect(() => {
    if (setState && data) setState(extractEntities(data))
  }, [setState, data, extractEntities])

  return loading ? (
    <SelectorSkeleton>
      <Selector {...props} disabled options={[]} />
    </SelectorSkeleton>
  ) : (
    <Selector<Entity> {...props} options={data ? extractEntities(data) : []} />
  )
}
