import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton, makeStyles } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CancelIcon from '@material-ui/icons/Cancel'
import { getImageUrl } from 'config/imageHost'

type ImagePreviewProps = {
  src: string
  title?: string
  onRemoveImage?: () => void
  isShowVideo?: boolean
}

const useStyles = makeStyles({
  wrapper: {
    textAlign: 'center'
  },
  imageWrapper: {
    height: 100,
    width: 100,
    borderRadius: 5,
    position: 'relative',
    textAlign: 'center',
    marginBottom: 5,
    '& .overlay': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 5,
      background: 'rgba(0,0,0,0.3)',
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white'
    },
    '& img': {
      height: 100,
      width: 100,
      objectFit: 'cover',
      borderRadius: 5,
      marginLeft: 0
    },
    '& iframe': {
      height: 100,
      width: 100,
      objectFit: 'cover',
      borderRadius: 5,
      marginLeft: 0
    },
    '&:hover': {
      '& .overlay': {
        display: 'flex'
      }
    }
  },
  image: {
    width: '90%',
    height: 'auto',
    margin: '5%'
  },
  iframe: {
    width: '90%',
    height: 300,
    margin: '5%'
  },
  icon: {
    color: 'white'
  },
  close: {
    position: 'absolute',
    right: 5,
    top: 5,
    width: 0,
    height: 0
  },
  deleteButton: {
    position: 'absolute',
    top: -5,
    right: 2,
    zIndex: 100,
    color: 'red',
    cursor: 'pointer'
  }
})

export const ImagePreview = ({ src, title, onRemoveImage, isShowVideo }: ImagePreviewProps) => {
  const [isShow, setIsShow] = useState(false)
  const classes = useStyles()
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.imageWrapper}>
          <span
            onClick={() => {
              onRemoveImage && onRemoveImage()
            }}
            className={classes.deleteButton}
          >
            &times;
          </span>
          {isShowVideo
            ? src?.includes('https') && <iframe title="myFrame" src={getImageUrl(src)} frameBorder={0} />
            : src?.includes('https') && <img src={getImageUrl(src)} alt="" />}
          <div className="overlay">
            <IconButton onClick={() => setIsShow(true)}>
              <VisibilityIcon className={classes.icon} />
            </IconButton>
          </div>
        </div>
        {title}
      </div>
      {isShow && !!src && (
        <Dialog maxWidth="md" fullWidth onClose={() => setIsShow(false)} open={isShow}>
          <DialogContent>
            {isShowVideo ? (
              <iframe
                title="myFrame"
                className={classes.iframe}
                src={getImageUrl(src)}
                frameBorder={0}
                allowFullScreen
              />
            ) : (
              <img className={classes.image} src={getImageUrl(src)} alt="" />
            )}
            <IconButton className={classes.close} onClick={() => setIsShow(false)}>
              <CancelIcon />
            </IconButton>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
