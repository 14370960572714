import React, { ChangeEvent, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import { ImagePreview } from 'components/ImagePreview'
import {
  BulkUploadImagesMutation,
  BulkUploadImagesMutationVariables,
  GetFileQuery,
  GetFileQueryVariables
} from 'generated/graphql'
import { BULK_UPLOAD_IMAGES, GET_FILE } from 'graphql/common'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import map from 'lodash/map'
import { Box } from '@material-ui/core'
import some from 'lodash/some'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    input: {
      display: 'none'
    },
    uploadWrapper: {
      background: 'var(--color-neutral-200)',
      width: 130,
      height: 120,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },
    icon: {
      padding: 0,
      marginBottom: '0.25rem'
    }
  })
)

interface BulkUploadImageProps {
  onReturnValue?: (value: string[]) => Promise<void>
  disabled?: boolean
  defaultValue?: string | null
  isRemovePreviewImage?: boolean
  id?: string
  isMultiImages?: boolean
  limitSize?: number
  accept?: string
  uploadTitle?: string
  isShowVideo?: boolean
  maximumImage?: number
  value?: string[]
}

const BulkUploadImages = ({
  onReturnValue,
  disabled,
  defaultValue,
  value = [],
  isRemovePreviewImage,
  id = '',
  isMultiImages = false,
  limitSize = 10,
  accept = 'image/*',
  uploadTitle = 'Add Image',
  isShowVideo = false,
  maximumImage
}: BulkUploadImageProps) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [previewImage, setPreviewImage] = useState<string | null | undefined>(defaultValue)
  const { setErrorMessage } = useSnackbarContext()

  const inputId = `icon-button-file-${id}`

  const { data } = useQuery<GetFileQuery, GetFileQueryVariables>(GET_FILE, {
    variables: {
      fileKey: previewImage || ''
    },
    skip: !previewImage || previewImage?.includes?.('https') || isMultiImages,
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    setPreviewImage(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    if (isRemovePreviewImage) {
      setPreviewImage(null)
    }
  }, [isRemovePreviewImage])

  const [bulkUploadImages] = useMutation<BulkUploadImagesMutation, BulkUploadImagesMutationVariables>(
    BULK_UPLOAD_IMAGES
  )
  const onChange = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (!!maximumImage && value?.length + (files?.length ?? 0) > maximumImage) {
      setErrorMessage(
        `You can only upload a maximum of ${maximumImage} ${
          maximumImage > 1 ? `${isShowVideo ? 'videos' : 'images'}` : `${isShowVideo ? 'video' : 'image'}`
        }`
      )
      return
    }

    const hasOversizedImage = some(files, file => {
      const fileSizeInMB = Math.round(file.size / 1024 / 1024)
      return fileSizeInMB > limitSize
    })

    if (hasOversizedImage) {
      setErrorMessage(`${isShowVideo ? 'Video' : 'Image'} must be smaller than ${limitSize}MB!`)
      return
    }

    setLoading(true)

    try {
      const uploadedImages = await bulkUploadImages({
        variables: {
          inputs: map(files, file => ({
            Type: file.type,
            Name: file.name
          }))
        }
      })

      if (uploadedImages?.data?.bulkUploadImages) {
        await Promise.allSettled(
          uploadedImages?.data?.bulkUploadImages?.map(async (image, index) => {
            const response = await fetch(image.UploadUrl, {
              method: 'PUT',
              body: files?.[index]
            })
            return response
          })
        )
        onReturnValue && onReturnValue(uploadedImages?.data?.bulkUploadImages?.map(image => image?.FileKey))
      }
    } catch (error) {
      setErrorMessage((error as Error)?.message)
    } finally {
      setLoading(false)
    }
  }

  if (previewImage && !isMultiImages) {
    return (
      <ImagePreview
        src={data?.getFile?.Url || previewImage || ''}
        onRemoveImage={() => {
          setPreviewImage(null)
          onReturnValue && onReturnValue([])
        }}
        isShowVideo={isShowVideo}
      />
    )
  }

  return (
    <Box width={150}>
      <input
        accept={accept}
        className={classes.input}
        id={inputId}
        type="file"
        multiple={isMultiImages}
        onChange={onChange}
        onClick={event => {
          event.currentTarget.value = ''
        }}
        disabled={disabled}
      />
      {loading && (
        <label className={classes.uploadWrapper} htmlFor={inputId}>
          <IconButton
            className={classes.icon}
            color="primary"
            aria-label="upload picture"
            component="span"
            disabled={disabled}
          >
            <CircularProgress size={30} />
          </IconButton>
        </label>
      )}
      {!loading && (
        <label className={classes.uploadWrapper} htmlFor={inputId}>
          <IconButton
            className={classes.icon}
            color="primary"
            aria-label="upload picture"
            component="span"
            disabled={disabled}
          >
            <PhotoCamera />
          </IconButton>
          {uploadTitle}
          <span>(Up to {limitSize / 1024 >= 1 ? `${limitSize / 1024}GB` : `${limitSize}MB`} size)</span>
        </label>
      )}
    </Box>
  )
}

export default BulkUploadImages
