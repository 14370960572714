import React, { FC } from 'react'
import { WatchQueryFetchPolicy } from 'apollo-boost'
import { EntitySelector, EntitySelectorWrapperProps } from 'components/common/EntitySelector'
import { ShiftType, ConfigShiftTypesQuery, ConfigShiftTypesQueryVariables } from 'generated/graphql'
import { CONFIG_SHIFT_TYPES } from './graphql'

export type ConfigShiftTypesSelectorProps = EntitySelectorWrapperProps<
  ShiftType,
  ConfigShiftTypesQuery,
  ConfigShiftTypesQueryVariables
> & {
  extractEntities?: (data: ConfigShiftTypesQuery) => ShiftType[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  refetch?: boolean
}

const labelEntity = (value: ShiftType) => value?.ShiftType

const extractEntities = (data: ConfigShiftTypesQuery) => data.configShiftTypes

export const ConfigShiftTypesSelector: FC<ConfigShiftTypesSelectorProps> = ({ label = 'Shift Type', ...props }) => {
  const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  return (
    <EntitySelector<ShiftType, ConfigShiftTypesQuery, ConfigShiftTypesQueryVariables>
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={CONFIG_SHIFT_TYPES}
      queryHookOptions={{
        fetchPolicy: fetchPolicy
      }}
      {...props}
    />
  )
}
