import ApolloClient, { Operation } from 'apollo-boost'
import { Auth } from 'aws-amplify'
import { apolloConfig } from '../config/apolloConfig'
import { ErrorResponse } from 'apollo-link-error'
import { setLocalStorageItem } from 'utils/tools'

const setCredentials = async (operation: Operation) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken()
  const { headers } = operation.getContext()
  operation.setContext({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      platform: 'ADMIN'
    }
  })
}

const errorIntercepter = (error: ErrorResponse) => {
  // Logout when user code is invalid
  if (error.networkError?.message === 'Cannot retrieve a new session. Please authenticate.') {
    Auth.signOut()
  }
  error.graphQLErrors?.forEach(value => {
    if (value.extensions?.code === 'UNAUTHENTICATED') {
      setLocalStorageItem('isErrorAuth', 'true')
      Auth.signOut()
    }
  })
}

export const client = new ApolloClient({
  ...apolloConfig,
  request: setCredentials,
  onError: errorIntercepter
})
