import { SetStateAction } from 'react'

const MAX_INDEX_OF_MONTH = 11
const MIN_INDEX_OF_MONTH = 0

export const increaseMonth = (
  currentMonth: number,
  setMonth: (value: SetStateAction<number>) => void,
  setYear: (value: SetStateAction<number>) => void
) => {
  if (currentMonth === MAX_INDEX_OF_MONTH) {
    setMonth(MIN_INDEX_OF_MONTH)
    setYear(prev => prev + 1)
  } else {
    setMonth(prev => prev + 1)
  }
}

export const decreaseMonth = (
  currentMonth: number,
  setMonth: (value: SetStateAction<number>) => void,
  setYear: (value: SetStateAction<number>) => void
) => {
  if (currentMonth === MIN_INDEX_OF_MONTH) {
    setMonth(MAX_INDEX_OF_MONTH)
    setYear(prev => prev - 1)
  } else {
    setMonth(prev => prev - 1)
  }
}
