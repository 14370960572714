import { Grid } from '@material-ui/core'
import SliderInput from 'components/common/SliderInput'
import { useFormikContext } from 'formik'
import { BG_COLOR_HEIGHT_SLIDER, PRESET_COLORS } from 'pages/Publication/cms/constants'
import { CMSCreatorValues } from 'pages/Publication/cms/types'
import React from 'react'
import { SketchPicker } from 'react-color'

interface ColorSettingSectionProps {
  index: number
}

export default function ColorSettingSection({ index }: ColorSettingSectionProps) {
  const { values, setFieldValue } = useFormikContext<CMSCreatorValues>()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={5}>
        <SketchPicker
          styles={{ default: { color: { backgroundColor: '#fff' } } }}
          className="mr-5"
          color={values?.content?.[index]?.backgroundColor}
          onChangeComplete={(color: { hex: string }) => setFieldValue(`content.${index}.backgroundColor`, color.hex)}
          presetColors={PRESET_COLORS}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={7}>
        <SliderInput
          label="Height"
          className="w-full"
          data={BG_COLOR_HEIGHT_SLIDER}
          step={10}
          valueLabelDisplay="on"
          value={values?.content?.[index]?.backgroundColorHeight}
          onHandleChange={(_, value) => {
            setFieldValue(`content.${index}.backgroundColorHeight`, value)
          }}
        />
      </Grid>
    </Grid>
  )
}
