import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import 'react-quill/dist/quill.snow.css'
import * as Sentry from '@sentry/browser'
import { SentryCredentials } from './config/sentryConfig'

const UnsupportBrower = React.lazy(() =>
  import('./UnsupportBrower').then(({ UnsupportBrower }) => ({ default: UnsupportBrower }))
)

const UnsupportBrowerComponent = () => {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <UnsupportBrower />
      </Suspense>
    </div>
  )
}
//Initialize Sentry
Sentry.init(SentryCredentials)
const Browser = navigator.userAgent
ReactDOM.render(
  Browser.indexOf('MSIE ') > -1 || Browser.indexOf('Trident/') > -1 ? UnsupportBrowerComponent() : <App />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
