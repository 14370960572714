import React, { FC, useState, MouseEvent } from 'react'
import { makeStyles, MenuItem, Hidden, Menu, Typography, Theme } from '@material-ui/core'
import { AccountCircle, KeyboardArrowDown } from '@material-ui/icons'
import { useUserInfo } from '../../hooks/useUserInfo'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    alignItems: 'center',
    height: '100%',
    display: 'flex',
    '& .MuiButtonBase-root': {
      height: '100%'
    },
    '& .MuiTypography-root': {
      fontWeight: 'bold'
    }
  },
  dropDown: {
    '& a': {
      color: theme.palette.text.primary,
      textDecoration: 'none'
    },
    '& .MuiMenu-paper': {
      left: 'initial !important',
      right: '1.5rem'
    }
  },
  greeting: {
    fontWeight: 'bold',
    padding: '0 .5rem'
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: 'inherit'
    }
  },
  option: {
    opacity: 0.8,
    padding: '.5rem 1.5rem',
    '&:hover': {
      opacity: 1
    }
  }
}))

interface DropdownMenuProps {
  signOut: () => void
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ signOut }) => {
  const { personInfoData } = useUserInfo()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const classes = useStyles()

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.container}>
      <MenuItem
        aria-label="current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={handleOpen}
      >
        <AccountCircle />
        <Hidden mdDown>
          <Typography className={classes.greeting} variant="body1">{`Hi, ${personInfoData?.personInfo.Firstname ||
            ''}`}</Typography>
        </Hidden>
        <KeyboardArrowDown />
      </MenuItem>
      <Menu
        anchorEl={anchorEl}
        className={classes.dropDown}
        id="dropdown-menu"
        keepMounted
        onClick={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem className={classes.option}>
          <Link to="/my-profile">My Details</Link>
        </MenuItem>
        <MenuItem className={classes.option} onClick={signOut}>
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  )
}
