import { Privilege } from 'generated/graphql'
import { useUserInfo } from './useUserInfo'
import { useMemo } from 'react'
import flattenDeep from 'lodash/flattenDeep'

export const useCheckPrivilegesInGlobalRoles = (privileges: string[]) => {
  const { personInfoData } = useUserInfo()

  const checkPrivilegesInGlobalRoles = useMemo(() => {
    const entirePrivileges: Privilege[] =
      personInfoData?.personInfo?.roles?.map((e: { privileges: Privilege[] }) => e.privileges) ?? []

    return flattenDeep(entirePrivileges)?.some(privilege => privileges.includes(privilege.Name))
  }, [personInfoData, privileges])

  return checkPrivilegesInGlobalRoles
}
