import React, { FC } from 'react'
import { WatchQueryFetchPolicy } from 'apollo-boost'
import { EntitySelector, EntitySelectorWrapperProps } from 'components/common/EntitySelector'
import {
  TimesheetLeaveType,
  GetAllTimesheetLeaveTypesQuery,
  GetAllTimesheetLeaveTypesQueryVariables
} from 'generated/graphql'
import { GET_ALL_TIMESHEET_LEAVE_TYPES } from './graphql'

export type TimesheetLeaveTypesSelectorProps = EntitySelectorWrapperProps<
  TimesheetLeaveType,
  GetAllTimesheetLeaveTypesQuery,
  GetAllTimesheetLeaveTypesQueryVariables
> & {
  extractEntities?: (data: GetAllTimesheetLeaveTypesQuery) => TimesheetLeaveType[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  refetch?: boolean
}

const labelEntity = (value: TimesheetLeaveType) => value?.Description

const extractEntities = (data: GetAllTimesheetLeaveTypesQuery) => data.getAllTimesheetLeaveTypes

export const TimesheetLeaveTypesSelector: FC<TimesheetLeaveTypesSelectorProps> = ({
  label = 'Leave Code',
  ...props
}) => {
  const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  return (
    <EntitySelector<TimesheetLeaveType, GetAllTimesheetLeaveTypesQuery, GetAllTimesheetLeaveTypesQueryVariables>
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={GET_ALL_TIMESHEET_LEAVE_TYPES}
      queryHookOptions={{
        fetchPolicy: fetchPolicy
      }}
      {...props}
    />
  )
}
