export const authConfig = {
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID as string,

  // REQUIRED - Amazon Cognito Region
  region: process.env.REACT_APP_AWS_PROJECT_REGION as string,

  // // OPTIONAL - Amazon Cognito Federated Identity Pool Region
  // // Required only if it's different from Amazon Cognito Region
  // identityPoolRegion: 'XX-XXXX-X',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID as string,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID as string,

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,

  // // OPTIONAL - Configuration for cookie storage
  // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  // cookieStorage: {
  //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
  //   domain: '.yourdomain.com',
  //   // OPTIONAL - Cookie path
  //   path: '/',
  //   // OPTIONAL - Cookie expiration in days
  //   expires: 365,
  //   // OPTIONAL - Cookie secure flag
  //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
  //   secure: true
  // },
  s3Bucket: {
    name: process.env.REACT_APP_S3_BUCKET_NAME as string,
    region: process.env.REACT_APP_S3_BUCKET_REGION as string,
    accessKey: process.env.REACT_APP_S3_BUCKET_ACCESS_KEY as string,
    accessSecret: process.env.REACT_APP_S3_BUCKET_ACCESS_SECRET as string
  },
  // // OPTIONAL - customized storage object
  // storage: new MyStorage(),

  // // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  // authenticationFlowType: 'USER_PASSWORD_AUTH',

  // // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  // clientMetadata: { myCustomKey: 'myCustomValue' },

  // // OPTIONAL - Hosted UI configuration
  oauth: {
    domain: process.env.REACT_APP_AUTH_DOMAIN as string,
    responseType: process.env.REACT_APP_AUTH_RESPONSE_TYPE as string,
    scope: process.env.REACT_APP_AUTH_SCOPES as string,
    redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT_SIGNIN as string,
    redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT_SIGNOUT as string,
    // eslint-disable-next-line
    client_id: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID as string,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID as string
  }
}
