import React from 'react'
import logo from '../images/logo-inverted.svg'
import { Box, Hidden, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  box: {
    background: theme.palette.primary.dark,
    color: theme.palette.text.primary,
    display: 'flex',
    height: '3rem',
    justifyContent: 'space-between',
    lineHeight: '3rem',
    padding: '0 1.5rem'
  },
  image: {
    alignSelf: 'center',
    height: '2rem',
    width: 'auto'
  }
}))

export const Footer = () => {
  const classes = useStyles()
  return (
    <Hidden lgUp>
      <Box className={classes.box}>
        <img src={logo} className={classes.image} alt="Acciona logo" />
      </Box>
    </Hidden>
  )
}
