import { useMutation } from '@apollo/react-hooks'
import { Button, Dialog, DialogActions, DialogContent, Slide, Grid, Typography, makeStyles } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { Formik } from 'formik'
import React, { forwardRef, Ref } from 'react'
import {
  PayrollSubClassification,
  EditPayrollSubClassificationMutation,
  EditPayrollSubClassificationMutationVariables
} from '../../../generated/graphql'
import { EDIT_PAYROLL_SUBCLASSIFICATION } from './graphql/index'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import * as yup from 'yup'
import { REQUIRED } from 'utils/Constants'

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

// Style
const useStyles = makeStyles(() => ({
  plantHeader: {
    fontSize: '1rem'
  },
  plantTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center'
  }
}))

interface FormTimesheetLockingModalProps {
  handleClose: () => void
  item?: PayrollSubClassification | null
  setAutoHideFeedback: (value: Feedback) => void
  refetch: () => void
}

interface FormValues {
  groupName: string
  rank: number
}

export const FormPayrollSubClassification = ({
  handleClose,
  item,
  setAutoHideFeedback,
  refetch
}: FormTimesheetLockingModalProps) => {
  const initialValues: FormValues = {
    groupName: item?.GroupName ?? '',
    rank: item?.RankWithinGroup ?? 0
  }
  const [editPayrollSubClassification] = useMutation<
    EditPayrollSubClassificationMutation,
    EditPayrollSubClassificationMutationVariables
  >(EDIT_PAYROLL_SUBCLASSIFICATION)
  const classes = useStyles()

  const validationSchema = (item?: PayrollSubClassification | null) =>
    item &&
    yup.object({
      groupName: yup
        .string()
        .nullable()
        .required(`Group Name ${REQUIRED}`)
        .trim()
        .matches(/^[0-9A-Z](|[-0-9A-Z]{0,61}[0-9A-Z])$/, 'Group name can only be capital letters'),
      rank: yup
        .number()
        .nullable()
        .required(`Rank ${REQUIRED}`)
        .min(0, 'Rank must be a positive number')
    })

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema(item)}
      onSubmit={async (values, { resetForm }) => {
        editPayrollSubClassification({
          variables: {
            input: {
              PayrollSubClassificationID: item?.ID as string,
              GroupName: values.groupName,
              RankWithinGroup: values.rank
            }
          }
        })
          .then(() => {
            resetForm()
            handleClose()
            setAutoHideFeedback(getSuccessFeedback('The payroll sub classification is updated successfully'))
            refetch()
          })
          .catch(error => {
            setAutoHideFeedback(getErrorFeedback(error.message))
          })
      }}
    >
      {props => {
        const { values, errors, touched, isSubmitting, handleSubmit, handleChange, handleBlur } = props
        return (
          <form onSubmit={handleSubmit}>
            <Dialog
              open={!!item}
              TransitionComponent={Transition}
              keepMounted
              fullWidth
              onClose={handleClose}
              aria-labelledby="delete-site-diary-dialog-title"
              aria-describedby="delete-site-diary-dialog-description"
            >
              <DialogContent>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" className={classes.plantTitle}>
                      Subclassification
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    {item?.SubClassificationDisplayName}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" className={classes.plantTitle}>
                      Group Name
                    </Typography>
                  </Grid>
                  <GridLayoutInput
                    fullWidth
                    textFieldBreakpoints={{ xs: 12, sm: 8 }}
                    name="groupName"
                    id="groupName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.groupName}
                    error={!!errors.groupName && !!touched.groupName}
                    helperText={touched.groupName ? errors.groupName : null}
                  />
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" className={classes.plantTitle}>
                      Rank
                    </Typography>
                  </Grid>
                  <GridLayoutInput
                    fullWidth
                    textFieldBreakpoints={{ xs: 12, sm: 8 }}
                    name="rank"
                    id="rank"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.rank}
                    error={!!errors.rank && !!touched.rank}
                    helperText={touched.rank ? errors.rank : null}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="contained">
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  color="secondary"
                  variant="contained"
                  type="submit"
                  onClick={() => handleSubmit()}
                  aria-label="button-modal-confirm"
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )
      }}
    </Formik>
  )
}
