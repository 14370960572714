import { makeStyles } from '@material-ui/core'

export const useReleaseNotesStyles = makeStyles({
  downloadBtn: {
    padding: '0.5rem 1.5rem',
    fontWeight: 'bolder',
    color: '#000000ab',
    maxHeight: '2.7rem'
  },
  straightLine: {
    width: '2.5rem',
    height: '1px',
    background: '#000000ab',
    transform: 'rotate(90deg)',
    position: 'relative',
    top: '1.6rem'
  },
  marginBottom20: {
    marginBottom: '2rem'
  },
  selectAllBtn: {
    fontSize: '1.1rem',
    borderColor: '#fff'
  },
  marginLeft10: { marginLeft: '10px' }
})
