import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { List, ListItem, makeStyles, Divider, Paper } from '@material-ui/core'
import { Privilege } from '../../generated/graphql'
import { useUserInfo } from '../../hooks/useUserInfo'
import { Privileges } from 'utils/rolesAndPrivileges'

const useStyles = makeStyles({
  customList: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%'
  },
  listItem: {
    paddingRight: '1.5rem !important',
    paddingLeft: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingBottom: '1rem !important',
    transform: 'inherit !important',
    borderRadius: '0 !important',
    flexDirection: 'row'
  },
  top: {
    flexDirection: 'column'
  },
  space: {
    backgroundColor: 'rgba(255,255,255,0.3)',
    marginBottom: '10px'
  },
  bottom: {
    flexDirection: 'column-reverse',
    marginBottom: '20px'
  },
  scrollSideBar: {
    overflow: 'auto',
    backgroundColor: '#262626'
  }
})

const menuItems = [
  { item: 'My Profile', path: '/my-profile', permission: '', place: 'top' },
  { item: 'Manage Comms', path: '/manage-comms', permission: Privileges.ManageCommunications, place: 'top' },
  { item: 'Manage Users', path: '/manage-users', permission: Privileges.ManageUsers, place: 'top' },
  {
    item: 'Manage Projects',
    path: '/manage-projects',
    permission: [Privileges.ManageProjectLogo, Privileges.ManageIntegrations],
    place: 'top'
  },
  {
    item: 'Manage Modules',
    path: '/manage-modules',
    permission: [Privileges.ManageModule, Privileges.ManageIntegrations],
    place: 'top'
  },
  {
    item: 'Manage Mobile Versions',
    path: '/manage-mobile-versions',
    permission: Privileges.ManageMobileVersions,
    place: 'bottom'
  },
  { item: 'Manage Audits', path: '/manage-audit', permission: Privileges.ManageAudit, place: 'bottom' },
  {
    item: 'System Configurations',
    path: '/system-configurations',
    permission: Privileges.ManageAudit,
    place: 'bottom'
  },
  { item: 'Manage Failed Events', path: '/manage-failed-events', permission: Privileges.ManageAudit, place: 'bottom' },
  { item: 'Manage Payrolls', path: '/payroll', permission: Privileges.ViewPayrollSettings, place: 'top' },
  { item: 'Manage Policies', path: '/manage-policies', permission: Privileges.ManagePrivacy, place: 'top' },
  {
    item: 'Manage Printing Templates',
    path: '/manage-printing-templates',
    permission: Privileges.ManagePrintingTemplate,
    place: 'top'
  }
]

interface NavigationProps {
  isTop: boolean
}

export const Navigation: FC<NavigationProps> = ({ isTop }) => {
  const classes = useStyles()
  const { personInfoData } = useUserInfo()
  const privileges: Privilege[] = []
  personInfoData?.personInfo?.roles?.forEach((e: { privileges: Privilege[] }) => {
    privileges.push(...e.privileges)
  })

  return isTop ? (
    <List>
      <NavLink key={menuItems[0].item} to={menuItems[0].path}>
        <ListItem button className={classes.listItem}>
          {menuItems[0].item}
        </ListItem>
      </NavLink>
      {menuItems.map(menuItem => {
        const access = privileges?.find((arr: { Name: string }) => menuItem.permission.includes(arr.Name as Privileges))
        return (
          access && (
            <NavLink key={menuItem.item} to={menuItem.path}>
              <ListItem button className={classes.listItem}>
                {menuItem.item}
              </ListItem>
            </NavLink>
          )
        )
      })}
    </List>
  ) : (
    <Paper className={classes.scrollSideBar}>
      <List className={classes.customList}>
        <div className={classes.top}>
          <NavLink key={menuItems[0].item} to={menuItems[0].path}>
            <ListItem button className={classes.listItem}>
              {menuItems[0].item}
            </ListItem>
          </NavLink>
          {menuItems
            .filter(item => item.place === 'top')
            .map(menuItem => {
              const access = privileges?.find((arr: { Name: string }) =>
                menuItem.permission.includes(arr.Name as Privileges)
              )
              return (
                access && (
                  <NavLink key={menuItem.item} to={menuItem.path}>
                    <ListItem button className={classes.listItem}>
                      {menuItem.item}
                    </ListItem>
                  </NavLink>
                )
              )
            })}
        </div>
        <div className={classes.bottom}>
          <Divider className={classes.space} variant="middle" />
          {menuItems
            .filter(item => item.place === 'bottom')
            .map(menuItem => {
              const access = privileges?.find((arr: { Name: string }) =>
                menuItem.permission.includes(arr.Name as Privileges)
              )
              return (
                access && (
                  <NavLink key={menuItem.item} to={menuItem.path}>
                    <ListItem button className={classes.listItem}>
                      {menuItem.item}
                    </ListItem>
                  </NavLink>
                )
              )
            })}
        </div>
      </List>
    </Paper>
  )
}
