import { FieldAttributes, useField } from 'formik'
import React, { FC } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectorField: FC<FieldAttributes<any>> = React.forwardRef(
  ({ selector: Selector, onChange: extraOnChange, disabled, setState, ...props }, ref) => {
    const [field, { error, touched }, { setValue }] = useField(props)
    const onChange = (newValue: { ID: string }) => {
      extraOnChange?.(newValue)
      setValue(newValue?.ID || '')
    }

    return (
      <Selector
        disabled={disabled}
        error={!!touched && !!error}
        helperText={!!touched && error}
        {...props}
        ref={ref}
        {...field}
        setValue={setValue}
        onChange={onChange}
        setState={setState}
      />
    )
  }
)
