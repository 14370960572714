import { Skeleton, SkeletonProps } from '@material-ui/lab'
import React, { FC } from 'react'

export const SelectorSkeleton: FC<SkeletonProps> = ({
  children,
  width = 'auto',
  height = 'auto',
  variant = 'rect',
  ...props
}) => (
  <Skeleton variant={variant} width={width} height={height} {...props}>
    {children}
  </Skeleton>
)
