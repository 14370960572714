import React from 'react'
import logo from '../images/logo-inverted.svg'
import { Navigation } from './common/Navigation'
import { Box, Hidden, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
    width: '18.75rem',
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      '& a': {
        color: theme.palette.background.default,
        fontWeight: 'bold',
        textDecoration: 'none',
        textTransform: 'uppercase'
      },
      '& .MuiListItem-root': {
        alignItems: 'center',
        fontSize: '1.125rem',
        boxPack: 'end',
        justifyContent: 'flex-end',
        padding: '2.5rem 3rem',
        opacity: 0.8,
        textAlign: 'right',
        '&:hover': {
          opacity: 1
        }
      },
      '& .active': {
        '& .MuiListItem-root': {
          backgroundColor: theme.palette.secondary.main,
          borderRadius: '0 6px 6px 0',
          paddingRight: '3.75rem',
          opacity: 1,
          transform: 'translate(0.75rem)'
        }
      }
    }
  },
  logo: {
    width: '8rem',
    height: 'auto',
    margin: '3rem'
  }
}))

export const SideBar = () => {
  const classes = useStyles()

  return (
    <Hidden mdDown>
      <Box className={classes.box}>
        <img className={classes.logo} src={logo} alt="Acciona logo" />
        <Navigation isTop={false} />
      </Box>
    </Hidden>
  )
}
