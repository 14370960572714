import React, { MouseEvent, useState } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { ActionsType, OptionsType } from 'pages/Publication/types'
import { useReleaseNotesCommonStyles } from 'pages/Publication/styles'

interface BulkActionsBtnProps {
  onGetOption: (selectedOption: string) => void
  disabled?: boolean
}

const options: OptionsType[] = [
  {
    title: ActionsType.Publish,
    value: ActionsType.Publish
  },
  {
    title: ActionsType.Unpublish,
    value: ActionsType.UnPublish
  },
  {
    title: ActionsType.ExportExcel,
    value: ActionsType.ExportExcel
  },
  {
    title: ActionsType.Delete,
    value: ActionsType.Delete
  }
]

export default function BulkActionsBtn({ onGetOption, disabled = false }: BulkActionsBtnProps) {
  const classes = useReleaseNotesCommonStyles({})
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(null)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (selectedOption: string) => {
    onGetOption(selectedOption)
    handleClose()
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
        fullWidth
        disabled={disabled}
        variant="outlined"
        size="medium"
        endIcon={<ArrowDropDownIcon />}
      >
        Bulk Actions
      </Button>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleClose}
        classes={{
          list: classes.menuList
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={String(index)} onClick={() => handleSelect(option.value)}>
            <ListItemText primary={option.title} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
