import React from 'react'
import { CSSProperties, ReactNode } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import classNames from 'classnames'

import { HelpIcon, HelpSVGIcon } from '../icons'

interface HelpIconButtonTooltipProps {
  title: ReactNode
  fontSize?: 'small' | 'inherit' | 'default' | 'large'
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  onClick?: () => void
  style?: CSSProperties
  className?: string
  isButton?: boolean
  imgSize?: number
  iconClassName?: string
}

export const HelpIconButtonTooltip = ({
  title,
  fontSize,
  placement,
  onClick,
  style,
  className,
  isButton = false,
  imgSize = 30,
  iconClassName
}: HelpIconButtonTooltipProps) => {
  return (
    <Tooltip
      title={title ?? ''}
      placement={placement || 'right-start'}
      className={classNames(className, 'cursor-pointer')}
    >
      {isButton ? (
        <IconButton aria-label="help" style={style}>
          <HelpIcon fontSize={fontSize || 'small'} onClick={onClick} />
        </IconButton>
      ) : (
        <HelpSVGIcon
          width={imgSize}
          height={imgSize}
          onClick={onClick}
          className={classNames(iconClassName, 'cursor-pointer')}
        />
      )}
    </Tooltip>
  )
}
