import React, { ChangeEvent } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider, { Mark, SliderProps } from '@material-ui/core/Slider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300
    },
    margin: {
      height: theme.spacing(3)
    }
  })
)

interface SliderInputProps extends SliderProps {
  label?: string
  data: Mark[]
  onHandleChange?: (event: ChangeEvent<object>, value: number | number[]) => void
}

export default function SliderInput({ label, data, onHandleChange, ...props }: SliderInputProps) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {label && <Typography gutterBottom>{label}</Typography>}
      <Slider {...props} marks={data} onChange={onHandleChange} />
    </div>
  )
}
