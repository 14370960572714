import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { Privilege, Role } from 'generated/graphql'
import { PERSON_INFO_QUERY } from 'graphql/common'

export const useUserInfo = (options = {}) => {
  const { data: personInfoData, error, refetch } = useQuery(PERSON_INFO_QUERY, {
    ...options
  })

  const getGlobalRole = (roleName: string): Role | undefined =>
    personInfoData?.personInfo?.roles?.find((item: Role) => item.Name === roleName)

  const hasPrivilegeInGlobalRole = (roleName: string, privilegeName: string) => {
    const role = getGlobalRole(roleName)

    return !!role?.privileges?.find((item: Privilege) => item.Name === privilegeName)
  }

  return { personInfoData, refetch, error, hasPrivilegeInGlobalRole }
}

export const useUserInfoLazy = () => {
  const [getUserInfo, { loading, data, error }] = useLazyQuery(PERSON_INFO_QUERY)
  return { getUserInfo, data, loading, error }
}
