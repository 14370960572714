import { EntitySelectorWrapperProps } from 'components/common/EntitySelector'
import { InfiniteSelector } from 'components/common/InfiniteSelector'
import { Project, GetAllProjectsQuery, GetAllProjectsQueryVariables } from 'generated/graphql'
import { GET_ALL_PROJECTS } from 'graphql/common'
import React, { FC } from 'react'

export type Props = EntitySelectorWrapperProps<Project, GetAllProjectsQuery, GetAllProjectsQueryVariables>
const labelEntity = (value: Project) => `${value.Name} (${value.ProjectNo}) - ${value.company?.Name}`

const compileData = (data: GetAllProjectsQuery) => {
  return {
    data: data?.getAllProjects?.items || [],
    pagination: data?.getAllProjects?.pagination
  }
}

export const JobAllProjectsSelector: FC<Props> = ({ label = 'Job', ...props }) => {
  return (
    <InfiniteSelector<Project>
      {...props}
      label={label}
      labeller={labelEntity}
      compileData={compileData}
      query={GET_ALL_PROJECTS}
      extractData={data => data}
      queryHookOptions={{
        variables: {
          IsActiveOnly: true
        }
      }}
    />
  )
}
