import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import {
  GetSystemConfigurationsQuery,
  GetSystemConfigurationsQueryVariables,
  SystemConfiguration
} from '../../generated/graphql'
import { Table } from '../../components/common/Table'
import { Column } from 'material-table'
import { GET_SYSTEM_CONFIGURATIONS } from './graphql'
import { EditIcon } from 'components/common/icons'
import { FormSystemConfigurationsModal } from './system-configuration-modal/FormSystemConfigurationsModal'
import { LabourFrequencyModal } from './system-configuration-modal/LabourFrequencyModal'
import { FrequencyLevel, LABOUR_TYPE } from 'utils/Constants'

export const formatEmailList = (settingValue: string) => {
  if (
    (settingValue.startsWith('{') && settingValue.endsWith('}')) ||
    (settingValue.startsWith('[') && settingValue.endsWith(']'))
  )
    return JSON.parse(settingValue)
  return [settingValue]
}

const useStyles = makeStyles(theme => ({
  container: {},
  label: {
    color: theme.palette.text.hint
  }
}))

const handleDataParsing = (data: string) => {
  if (
    (data.startsWith('{') && data.endsWith('}')) ||
    (data.startsWith('[') && data.endsWith(']') && (data.includes('Description') || data.includes('PlantType')))
  ) {
    return JSON.parse(data)
      ?.map((item: any) => item?.Description || item?.PlantType || '')
      ?.join(', ')
  }
  if ((data.startsWith('{') && data.endsWith('}')) || (data.startsWith('[') && data.endsWith(']'))) {
    return ''
  }
  return [data]
}

const columns: (Column<SystemConfiguration> & { width?: string })[] = [
  {
    title: 'Name',
    field: 'SettingName',
    width: '20%'
  },
  {
    title: 'Value',
    field: 'SettingValue',
    render: row => <span className="break-words">{handleDataParsing(row?.SettingValue) ?? row?.SettingValue}</span>,
    headerStyle: {
      maxWidth: 200
    },
    cellStyle: {
      maxWidth: 200
    }
  },
  {
    title: 'Status',
    field: 'IsActive',
    render: row => (row.IsActive === true ? 'Active' : 'Inactive'),
    width: '10%'
  }
]

export const SystemConfigurations = () => {
  const [updateItem, onUpdate] = useState<SystemConfiguration>()
  const [labourTitle, setLabourTitle] = useState('')
  const [labourItem, setLabourItem] = useState<SystemConfiguration>()
  const { data } = useQuery<GetSystemConfigurationsQuery, GetSystemConfigurationsQueryVariables>(
    GET_SYSTEM_CONFIGURATIONS
  )
  const classes = useStyles()

  const handleClose = () => {
    onUpdate(undefined)
    setLabourItem(undefined)
  }
  const handleIsLabourType = (configuration: SystemConfiguration) =>
    LABOUR_TYPE.find(labour => labour.value === configuration.SettingName)

  return (
    <div className={classes.container}>
      <Table<SystemConfiguration>
        columns={columns}
        data={data?.getSystemConfigurations || []}
        option={{
          rowStyle: rowData => ({
            backgroundColor: rowData.PublicationStatus === 'D' ? '#EEE' : '#FFF'
          }),
          pageSize: 10
        }}
        actions={[
          rows => ({
            icon: () => <EditIcon color="secondary" className="text-base" />,
            tooltip: 'Edit',
            onClick: (_event, rowData) => {
              if (!Array.isArray(rowData)) {
                if (handleIsLabourType(rowData)) {
                  setLabourItem(rowData)
                  setLabourTitle(handleIsLabourType(rowData)?.label || '')
                } else {
                  onUpdate(rowData)
                }
              }
            },
            hidden: rows.SettingName === FrequencyLevel.Default
          })
        ]}
      />
      {!!updateItem && <FormSystemConfigurationsModal handleClose={handleClose} item={updateItem} />}
      {labourItem && (
        <LabourFrequencyModal handleClose={handleClose} labourItem={labourItem} dialogTitle={labourTitle} />
      )}
    </div>
  )
}
export default SystemConfigurations
