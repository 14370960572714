import { Box, Theme, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { CMSCreatorValues, Devices, HeaderBackgroundOptions, TextEditor } from 'pages/Publication/cms/types'
import React, { Dispatch, SetStateAction } from 'react'
import Slider from 'react-slick'

import { Skeleton } from '@material-ui/lab'
import InnerHTML from 'components/common/InnerHTML'
import map from 'lodash/map'
import { useImagePreviews } from '../../hooks'

interface HeaderTemplateProps {
  images?: string[]
  imagePreviews?: string[]
  backgroundColor?: string
  backgroundOption?: HeaderBackgroundOptions
  textEditors?: TextEditor[]
  backgroundColorHeight?: number
  setImagesShow?: Dispatch<SetStateAction<string[]>>
  setCurrentImageShowIndex?: Dispatch<SetStateAction<number>>
  index?: number
}

const useStyles = makeStyles<Theme, { color?: string; height?: number }>({
  headerBackground: {
    backgroundColor: ({ color }) => color,
    height: ({ height }) => `calc(100vh * ${height} / 100)`
  },
  mobileMode: {
    '& > p > span': {
      fontSize: '1.5rem !important'
    }
  },
  imagePosition: {
    '&:hover': {
      '& .overlay': {
        display: 'flex !important'
      }
    }
  }
})

export default function HeaderTemplate({
  images = [],
  imagePreviews = [],
  backgroundColor,
  textEditors,
  backgroundColorHeight,
  setImagesShow,
  setCurrentImageShowIndex,
  index = 0
}: HeaderTemplateProps) {
  const { values } = useFormikContext<CMSCreatorValues>()
  const classes = useStyles({ color: backgroundColor?.trim(), height: backgroundColorHeight })
  const hasMobileScreen = values?.setting?.device === Devices.Mobile
  const hasTabletScreen = values?.setting?.device === Devices.Tablet
  const hasDesktopScreen = values?.setting?.device === Devices.Desktop
  const { renderImages, loading } = useImagePreviews(images, imagePreviews)

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    autoplay: false,
    cssEase: 'linear',
    arrows: false
  }

  return (
    <Box className={classNames('relative', index ? 'mt-3' : 'mb-4')}>
      {renderImages?.length > 0 && (
        <Box className={classNames('relative w-full h-full', classes.imagePosition)}>
          <Slider className=" w-full h-full" {...settings}>
            {map(renderImages, (img, imageIndex) => (
              <Box
                className={classNames('w-full relative cursor-pointer', {
                  'h-[300px]': hasMobileScreen,
                  'h-[600px]': hasTabletScreen,
                  'h-screen': hasDesktopScreen
                })}
                key={String(imageIndex)}
                onClick={() => {
                  setImagesShow?.(renderImages)
                  setCurrentImageShowIndex?.(imageIndex)
                }}
              >
                {loading ? (
                  <Skeleton variant="rect" width="100%" height="100%" className="bg-color-neutral-300" />
                ) : (
                  <img height="100%" width="100%" src={img} alt="preview-img" />
                )}
              </Box>
            ))}
          </Slider>
        </Box>
      )}
      {backgroundColor && <Box className={classes.headerBackground} />}
      <Box className="absolute font-semibold w-10/12 top-16 left-12">
        <InnerHTML className={hasMobileScreen ? classes.mobileMode : ''} html={textEditors?.[0]?.description ?? ''} />
      </Box>
    </Box>
  )
}
