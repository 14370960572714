import { HolcimProjectDetail, SaveHolcimConfigInput } from 'generated/graphql'
import { HolcimForm } from '../types'
import omit from 'lodash/omit'

export const convertHolcimConfigFormToGraphql = (values: HolcimForm): SaveHolcimConfigInput => {
  return {
    Username: values.username,
    Password: values.password,
    ProjectID: values.projectId,
    ClientID: values.clientId,
    ClientSecret: values.clientSecret,
    CustomerCode: values.customerCode
  }
}

export const convertHolcimConfigFormToClient = (holcimConfig?: HolcimProjectDetail, projectID?: string): HolcimForm => {
  if (holcimConfig) {
    return {
      ...omit(holcimConfig, '__typename'),
      projectId: String(holcimConfig.projectId),
      customerCode: holcimConfig.customerCode[0]
    }
  }

  return {
    projectId: projectID ?? '',
    username: '',
    clientId: '',
    password: '',
    clientSecret: '',
    customerCode: ''
  }
}
