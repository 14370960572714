import React from 'react'
import { PlantType, GetAllPlantTypesQueryVariables } from 'generated/graphql'
import { EntitySelector, EntitySelectorWrapperProps } from './EntitySelector'
import { GetAllPlantTypesQuery } from 'generated/graphql'
import { GET_PLANT_TYPES } from 'pages/SystemConfigurations/graphql'

export type PlantTypeSelectorProps = EntitySelectorWrapperProps<PlantType, GetAllPlantTypesQuery> & {
  selectedPlantTypes: PlantType[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
}
const labelEntity = ({ PlantType }: PlantType) => PlantType

export const PlantTypesSelector = ({ label = 'Plant Type', selectedPlantTypes, ...props }: PlantTypeSelectorProps) => {
  const extractEntities = (data: GetAllPlantTypesQuery) => {
    return data?.getAllPlantTypes?.filter(item => !selectedPlantTypes?.find(type => type?.ID === item?.ID))
  }

  return (
    <EntitySelector<PlantType, GetAllPlantTypesQuery, GetAllPlantTypesQueryVariables>
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={GET_PLANT_TYPES}
      {...props}
    />
  )
}
