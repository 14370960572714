import { Column } from 'material-table'
import React from 'react'
import StatusIcon from './StatusIcon'
import { ReleaseNotes } from 'generated/graphql'
import { formatDateInDMY } from 'utils/dates'

export const releaseNotesTableColumns: Column<ReleaseNotes>[] = [
  {
    field: 'status',
    title: 'Status',
    render: ({ Status }) => (Status ? <StatusIcon status={Status} /> : ''),
    sorting: false
  },
  {
    field: 'ReleaseNotesDescription',
    title: 'Release Notes',
    sorting: false,
    headerStyle: {
      minWidth: 30
    },
    cellStyle: {
      minWidth: 30
    }
  },
  {
    field: 'ReleaseDate',
    title: 'Release Date',
    render: ({ ReleaseDate }) => `${formatDateInDMY(new Date(ReleaseDate))}`,
    sorting: false
  },
  {
    field: 'CategoryName',
    title: 'Category',
    sorting: false
  },
  {
    field: 'Platform',
    title: 'Platform',
    sorting: false
  },
  {
    field: 'ExternalURL',
    title: 'External URL Link',
    sorting: false,
    headerStyle: {
      minWidth: 30
    },
    cellStyle: {
      minWidth: 30
    }
  }
]
