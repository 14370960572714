import format from 'date-fns/fp/format'
import isWeekend from 'date-fns/isWeekend'
import isBefore from 'date-fns/fp/isBefore'
import addDays from 'date-fns/addDays'
import addMinutes from 'date-fns/addMinutes'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import formatISOWithOptions from 'date-fns/fp/formatISOWithOptions'
// import { PayPeriod } from '../generated/graphql'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { set } from 'date-fns'

export const DMY = 'dd/MM/yyyy'

export const SHORT_DATE = 'eee d MMM y'

export const DD_MM_YYYY = 'dd-MM-yyy'

export const DDMY = 'eee dd/MM/yyyy'

export const HDDMY = 'h:mmaaaa dd/MM/yyyy'

export const DD_MM_YYY_HH = 'yyyy-MM-dd hh:mm:ss a'

export const YYYY_MM_DD = 'yyyy-MM-dd'

export const DD_MMM_YYYY = 'dd MMM yyyy'

// eg. 10:00a.m., Tue 24 Mar, 2020
export const formatDateTime = format(`h:mmaaaa, ${SHORT_DATE}`)

// eg. 10:00a.m., Tue 24 Mar, 2020
export const formatTime = format(`h:mm aa`)

// eg. Tue 24 Mar
export const formatShortDate = format(SHORT_DATE)

// Format to 16-12-2020
export const formatDateInDDMMYYYY = format(DD_MM_YYYY)

export const formatDateInHHDDMMYY = format(DD_MM_YYY_HH)

//eg. 01 Aug 2023
export const formatDateInDDMMMYYYY = format(DD_MMM_YYYY)

// Format UTC
export const toDisplayIsoDateTimeUtc = (date: Date) => {
  return addMinutes(date, date.getTimezoneOffset())
}

// Represents the given date in ISO 8601 format, time only:
// eg. formatIsoTime(new Date(2019, 8, 18, 19, 0, 52))
//  =>'19:00:52Z'

export const formatIsoTime = formatISOWithOptions({ representation: 'time' })

export const formatDateTimeISO = (date: Date) => {
  if (!date) return ''
  return `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? '' : '0'}${date.getMonth() + 1}-${
    date.getDate() > 9 ? '' : '0'
  }${date.getDate()}T${date.getHours() > 9 ? '' : '0'}${date.getHours()}:${
    date.getMinutes() > 9 ? '' : '0'
  }${date.getMinutes()}:00.000Z`
}

// Represents the given date in ISO 8601 format, date only:
// eg. formatIsoDate(new Date(2019, 8, 18, 19, 0, 52))
//  =>'2020-04-17'
export const formatIsoDate = formatISOWithOptions({ representation: 'date' })

// Represents the given date-time in ISO 8601 format
// eg. formatIsoTime(new Date(2019, 8, 18, 19, 0, 52))
//  =>'2020-04-17T19:00:52Z'
export const formatIsoDateTime = formatISOWithOptions({ representation: 'complete' })

export const isoTimeToDate = (timeString: string) => new Date('1970-01-01T' + timeString)

export const ensureTimeIsDate = (value: string | Date) => (typeof value == 'string' ? isoTimeToDate(value) : value)

// export const getPayPeriodInterval = (payPeriod: PayPeriod) => ({
//   start: new Date(payPeriod.StartDate),
//   end: new Date(payPeriod.EndDate)
// })

export const toDate = (date: ParsableDate): Date | null | undefined => {
  switch (typeof date) {
    case 'string':
    case 'number':
      return new Date(date)
    case 'undefined':
      return undefined
    case 'object':
      return date ? (date as Date) : null
  }
}

export const isValidDate = (date?: Date | null): date is Date => {
  return date ? !isNaN(date.getTime()) : false
}

export const isWeekendDate = (date?: Date | undefined): date is Date => {
  return date ? isWeekend(date) : false
}

export const getDuringTime = (end: Date, start: Date) => {
  return differenceInMinutes(isoTimeToDate(formatIsoTime(end)), isoTimeToDate(formatIsoTime(start)))
}

export const getDuringTimeInHour = (end: Date, start: Date) => {
  if (!isBefore(end, start)) {
    const totalMinute = differenceInMinutes(
      addDays(isoTimeToDate(formatIsoTime(end)), 1),
      isoTimeToDate(formatIsoTime(start))
    )
    return `${Math.floor(totalMinute / 60)}:${totalMinute % 60} hours`
  } else {
    const totalMinute = differenceInMinutes(isoTimeToDate(formatIsoTime(end)), isoTimeToDate(formatIsoTime(start)))
    return `${Math.floor(totalMinute / 60)}:${totalMinute % 60} hours`
  }
}

export const formatIsoTimeUtc = (date: Date) => {
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  )
  return formatISOWithOptions({ representation: 'time' }, utcDate)
}

// EADT = Australian Eastern Daylight Time
const getSundayOfAEDTDate = (d: Date): Date => {
  return d.getDay() === 0 ? d : set(d, { date: d.getDate() + (7 - d.getDay()) })
}

const getCurrentMelbourneTimezone = (d: Date): number => {
  const startAEDTDate = getSundayOfAEDTDate(new Date(d.getFullYear(), 3, 1)) //01 April
  const endAEDTDate = getSundayOfAEDTDate(new Date(d.getFullYear(), 9, 1)) //01 October
  const inputDate = set(d, { hours: 0, minutes: 0, seconds: 0 })
  return inputDate >= startAEDTDate && inputDate < endAEDTDate ? -10 : -11
}

//TODO: hard coded timezone difference for Melbourne time. Need to handle global timezone when we go global
export const toDisplayServerIsoDateTimeUtc = (date: Date) => {
  return addMinutes(date, getCurrentMelbourneTimezone(date) * 60)
}

export const formatDateInYYYYMMDD = format(YYYY_MM_DD)
export const formatDateInDMY = format(DMY)
