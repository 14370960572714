import { Box, Grid, GridSize, Tooltip } from '@material-ui/core'
import classNames from 'classnames'
import { ImagePosition } from 'components/common/ImagePositionSelector'
import { Size } from 'components/common/ImageSizeSelector'
import InnerHTML from 'components/common/InnerHTML'
import map from 'lodash/map'
import { CMSCreatorValues, TextEditor } from 'pages/Publication/cms/types'
import React, { useMemo } from 'react'
import { getTextInHTMLString } from 'utils/tools'
import { useImagePreviews } from '../../hooks'
import { useCMSList } from '../../styles'
import { getGridSize, getSplitImage, removeCMSEmptyElements, removeParagraphTag, replaceToEmbedLink } from '../../utils'
import { useFormikContext } from 'formik'

interface OnlyVideoTemplateProps {
  description?: string
  images?: string[] | []
  imagePreviews?: string[] | []
  textEditors?: TextEditor[] | []
  imagesPerRow?: number
  size?: Size | null
  position?: ImagePosition | null
}

export default function OnlyVideoTemplate({
  images = [],
  imagePreviews = [],
  textEditors,
  size,
  position
}: OnlyVideoTemplateProps) {
  const classes = useCMSList()
  const { renderImages } = useImagePreviews(images, imagePreviews)
  const isS3URL = renderImages?.some(item => item?.includes('linked-site'))

  const { values } = useFormikContext<CMSCreatorValues>()
  const gridSize = getGridSize(values?.setting?.device)
  const spacingGridSize = ((12 - gridSize) / 2) as GridSize

  const isFullWidth = size?.ID === 'Large'
  const isMediumSize = size?.ID === 'Medium'
  const isSmallSize = size?.ID === 'Small'

  const positionLeft = position?.ID === 'Left'
  const positionRight = position?.ID === 'Right'

  const renderImageAndText = useMemo(
    () => renderImages.map((image, index) => ({ image, textInImage: textEditors?.[index] })),
    [renderImages, textEditors]
  )

  return (
    <Grid
      container
      className={classNames('flex flex-col mt-3', {
        'items-start': positionLeft,
        'items-center': !positionLeft && !positionRight,
        'items-end': positionRight,
        'pl-1': !isFullWidth && positionLeft,
        'pr-1': !isFullWidth && positionRight
      })}
    >
      {!isFullWidth && <Grid item xs={1} lg={spacingGridSize} />}
      <Grid
        container
        item
        xs={isFullWidth ? 12 : 10}
        lg={isFullWidth ? 12 : gridSize}
        spacing={1}
        className={classNames('flex flex-col gap-1', {
          'items-start': positionLeft,
          'items-center': !positionLeft && !positionRight,
          'items-end': positionRight
        })}
      >
        {map(getSplitImage(renderImageAndText, 2), (item, wrapIndex) => (
          <Box
            key={String(wrapIndex)}
            className={classNames('flex flex-col gap-1 w-full', {
              'max-w-[600px]': isMediumSize,
              'max-w-[300px]': isSmallSize
            })}
          >
            <Box className="flex gap-1">
              {map(item, ({ image }, index) => (
                <Box
                  key={String(index)}
                  className={classNames('flex flex-col', {
                    [`w-1/${item?.length}`]: item?.length > 1,
                    'w-full': item?.length === 1
                  })}
                >
                  {image?.includes('<iframe') ? (
                    <InnerHTML
                      className="w-full"
                      html={image
                        ?.replace('width=', 'width="100%" ')
                        ?.replace(
                          'height= ',
                          `${
                            isS3URL ? 'height="100%" ' : renderImages?.length === 1 ? 'height="500" ' : 'height="320" '
                          }`
                        )}
                    />
                  ) : image?.includes('linked-site') ? (
                    <video height="100%" width="100%" src={image} controls className="cursor-pointer" />
                  ) : (
                    <iframe
                      height={isS3URL ? '100%' : renderImages?.length === 1 ? 500 : 320}
                      src={replaceToEmbedLink(image)}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      title="video"
                    />
                  )}
                </Box>
              ))}
            </Box>
            <Box className="flex gap-1">
              {map(item, ({ textInImage }, index) => (
                <Box
                  key={String(index)}
                  className={classNames('flex flex-col w-full', {
                    [`w-1/${item?.length}`]: item?.length > 1,
                    'w-full': item?.length === 1
                  })}
                >
                  {textInImage?.description && (
                    <Tooltip
                      title={
                        getTextInHTMLString(textInImage?.description)?.trim() ? (
                          <InnerHTML html={getTextInHTMLString(textInImage?.description)} className="text-sm" />
                        ) : (
                          ''
                        )
                      }
                      enterTouchDelay={0}
                      placement="bottom-start"
                    >
                      <Box className={classNames('m-1 mt-0 w-full', classes.textOfImage)}>
                        <InnerHTML
                          html={`<p>${removeParagraphTag(removeCMSEmptyElements(textInImage?.description))}</p>`}
                          className="pl-1"
                        />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Grid>
      {!isFullWidth && <Grid item xs={1} lg={spacingGridSize} />}
    </Grid>
  )
}
