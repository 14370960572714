import React, { ChangeEvent, ReactElement, ReactNode } from 'react'
import {
  Grid,
  FormControl,
  TextField,
  GridSize,
  BaseTextFieldProps,
  OutlinedTextFieldProps,
  Typography,
  InputAdornment,
  Box
} from '@material-ui/core'

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { useField } from 'formik'
import { useGlobalStyles } from 'hooks/useGlobalStyles'
import { HelpIconButtonTooltip } from './mui'
import classNames from 'classnames'
import { NOAUTOFILL } from './AutocompleteInput'

interface CustomInputProps extends BaseTextFieldProps {
  textFieldBreakpoints?: Partial<Record<Breakpoint, boolean | GridSize>>
  titleBreakpoints?: Partial<Record<Breakpoint, boolean | GridSize>>
  adornment?: string | ReactElement
  adornmentPosition?: 'start' | 'end'
  placeholder?: string
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  isVersion2?: boolean
  isRequired?: boolean
  tooltipDescription?: ReactNode | string
  readOnly?: boolean
  wrapClassName?: string
  customLabelV2?: string
  customValue?: string
}
export type GridLayoutInputProps = CustomInputProps & Omit<OutlinedTextFieldProps, 'variant'>

export const GridLayoutTextField = ({
  fullWidth = true,
  InputProps,
  adornment,
  adornmentPosition = 'end',
  variant = 'outlined',
  className,
  title,
  textFieldBreakpoints = { xs: 12 },
  titleBreakpoints,
  readOnly = false,
  autoComplete = NOAUTOFILL,
  ...props
}: GridLayoutInputProps) => {
  return (
    <>
      {title ? (
        <Grid item {...titleBreakpoints}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
      ) : null}
      <Grid item {...textFieldBreakpoints}>
        <FormControl fullWidth={fullWidth}>
          <TextField
            {...props}
            variant={variant}
            className={`${readOnly ? 'read-only-textfield' : ''} ${className}`}
            autoComplete={autoComplete}
            InputProps={{
              endAdornment:
                adornment && adornmentPosition === 'end' ? (
                  <InputAdornment className="mr-2" position={adornmentPosition}>
                    {adornment}
                  </InputAdornment>
                ) : null,
              startAdornment:
                adornment && adornmentPosition === 'start' ? (
                  <InputAdornment position={adornmentPosition}>{adornment}</InputAdornment>
                ) : null,
              readOnly: readOnly,
              ...InputProps
            }}
          />
        </FormControl>
      </Grid>
    </>
  )
}

export const FormInput = ({
  fullWidth = true,
  textFieldBreakpoints = { xs: 12 },
  InputProps,
  adornment,
  adornmentPosition = 'end',
  onChange: extraOnChange,
  variant = 'outlined',
  label,
  className,
  isVersion2 = false,
  isRequired = false,
  tooltipDescription,
  readOnly = false,
  autoComplete = NOAUTOFILL,
  type,
  inputProps,
  wrapClassName,
  customLabelV2,
  customValue,
  ...props
}: GridLayoutInputProps) => {
  const classes = useGlobalStyles()
  const [{ value, ...field }, { error, touched }, { setValue }] = useField(props as any)
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    extraOnChange?.(e)
    if (type === 'number' && e.target.value) {
      let value = Number(e.target.value)
      const max = Number(inputProps?.max || 0)
      const min = Number(inputProps?.min || 0)

      if (value > max) value = max
      if (value < min) value = min
      setValue(value)
    } else {
      setValue(e.target.value)
    }
  }

  return (
    <Grid
      item
      {...textFieldBreakpoints}
      className={classNames(wrapClassName, isVersion2 ? classes.roundedInput : '', 'h-full')}
    >
      {isVersion2 ? (
        <Box display="flex" alignItems="center">
          <Typography className="font-semibold text-sm color-neutral-700 mb-1 d-block">
            {label} {isRequired && <span className="primary-color-500"> *</span>}
          </Typography>
          {!!tooltipDescription && (
            <HelpIconButtonTooltip imgSize={16} className="ml-2 mb-1" title={tooltipDescription} />
          )}
        </Box>
      ) : null}
      <FormControl fullWidth={fullWidth}>
        <TextField
          {...props}
          {...field}
          value={customValue || value}
          type={type}
          className={classNames(
            {
              'read-only-textfield': readOnly,
              'border-thin-rounded-input rounded-input': readOnly && isVersion2
            },
            className
          )}
          label={isVersion2 ? customLabelV2 : label}
          onChange={onChange}
          error={!!touched && !!error}
          helperText={!!touched && error}
          variant={variant}
          autoComplete={autoComplete}
          inputProps={inputProps}
          InputProps={{
            ...InputProps,
            endAdornment:
              adornment && adornmentPosition === 'end' ? (
                <InputAdornment position={adornmentPosition}>{adornment}</InputAdornment>
              ) : null,
            startAdornment:
              adornment && adornmentPosition === 'start' ? (
                <InputAdornment position={adornmentPosition}>{adornment}</InputAdornment>
              ) : null
          }}
        />
      </FormControl>
    </Grid>
  )
}

export const GridLayoutInput = ({ ...props }: GridLayoutInputProps) => {
  if (props.name) {
    return <FormInput {...props} />
  }
  return <GridLayoutTextField {...props} />
}
