import React, { FC } from 'react'
import { WatchQueryFetchPolicy } from 'apollo-boost'
import { EntitySelector, EntitySelectorWrapperProps } from 'components/common/EntitySelector'
import {
  TimesheetAllowanceType,
  GetAllTimesheetAllowanceTypesQuery,
  GetAllTimesheetAllowanceTypesQueryVariables
} from 'generated/graphql'
import { GET_ALL_TIMESHEET_ALLOWANCE_TYPES } from './graphql'

export type TimesheetAllowanceTypesSelectorProps = EntitySelectorWrapperProps<
  TimesheetAllowanceType,
  GetAllTimesheetAllowanceTypesQuery,
  GetAllTimesheetAllowanceTypesQueryVariables
> & {
  extractEntities?: (data: GetAllTimesheetAllowanceTypesQuery) => TimesheetAllowanceType[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  refetch?: boolean
}

const labelEntity = (value: TimesheetAllowanceType) => value?.Description

const extractEntities = (data: GetAllTimesheetAllowanceTypesQuery) => data.getAllTimesheetAllowanceTypes

export const TimesheetAllowanceTypesSelector: FC<TimesheetAllowanceTypesSelectorProps> = ({
  label = 'Allowance Type',
  ...props
}) => {
  const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  return (
    <EntitySelector<
      TimesheetAllowanceType,
      GetAllTimesheetAllowanceTypesQuery,
      GetAllTimesheetAllowanceTypesQueryVariables
    >
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={GET_ALL_TIMESHEET_ALLOWANCE_TYPES}
      queryHookOptions={{
        fetchPolicy: fetchPolicy
      }}
      {...props}
    />
  )
}
