import { Sorter } from 'generated/graphql'

export const ROW_PER_PAGE_OPTIONS: number[] = [10, 20, 50]

export const currencySetting = {
  locale: 'en-AU',
  currencyCode: 'AUD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 5
}
export const S4 = 4

export const TAB_CHAR_CODE = 9

export const REQUIRED = `is a required field`

export const WHITE_SPACES_MESSAGE = 'should not contain white spaces'

export const VIDEO_SUFFIX = '.mp4, .webm, .avi, .wmv'

export const LINKED_SITE_WEB_ENDPOINT = process.env.REACT_APP_WEB_ENDPOINT

export const getMaxLengthMessage = (maxLength: number) => `${maxLength} character limit reached`

export interface AuditType {
  Label: string
  Value: string
}

export interface Sorters {
  sorters: Sorter[]
}

export interface Filter {
  operator: string
  filters: FilterItems[]
}

interface FilterItems {
  op: string
  values: any[] | ['']
  field: string
}

export const AUDIT_TYPE: AuditType[] = [
  { Label: 'Login', Value: 'Login' },
  { Label: 'Logout', Value: 'Logout' },
  { Label: 'Forot Password', Value: 'ForgotPassword' },
  { Label: 'Email Sent', Value: 'EmailSent' },
  { Label: 'Missing Timesheets Notification', Value: 'MisingTimesheetsNotification' },
  { Label: 'Missing Timeseets Notification Started', Value: 'MisingTimesheetsNotificationStart' },
  { Label: 'Mising Timesheets Notification Completed', Value: 'MisingTimesheetsNotificationFinish' },
  { Label: 'Mising Timesheets Notification Failed', Value: 'MisingTimesheetsNotificationError' }
]

export enum FrequencyLevel {
  Low = 'LowFrequencyLabourTypes',
  Default = 'DefaultFrequencyLabourTypes',
  High = 'HighFrequencyLabourTypes'
}

export enum IsActiveToggle {
  Active = 'Active',
  InActive = 'Inactive'
}

export interface LabourType {
  label: string
  value: string
}

export const LABOUR_TYPE: LabourType[] = [
  { label: 'Low Frequency Labour Types', value: FrequencyLevel.Low },
  { label: 'Default Frequency Labour Types', value: FrequencyLevel.Default },
  { label: 'High Frequency Labour Types', value: FrequencyLevel.High }
]

export enum Portal {
  LinkedSite = 'Linked Site',
  LinkedOffice = 'Linked Office'
}

export enum BriefPortal {
  LinkedSite = 'LS',
  LinkedOffice = 'LO'
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const FILE_TYPE = {
  video: 'video',
  image: 'image'
}
