import { useQuery } from '@apollo/react-hooks'
import { Button, makeStyles } from '@material-ui/core'
import React, { FC, useState } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import { GetModulesQuery, GetModulesQueryVariables, Module } from '../../generated/graphql'
import { EditIcon } from '../../components/common/icons'
import { Table } from '../../components/common/Table'
import { Column } from 'material-table'
import { FormModuleModal } from './FormModuleModal'
import { checkExitsHost } from 'config/imageHost'
import { GET_MODULES } from './graphql'
// import { ConfirmDialog } from 'components/common/ConfirmDialog'

const useStyles = makeStyles(theme => ({
  container: {},
  label: {
    color: theme.palette.text.hint
  }
}))
type PublicationProps = RouteChildrenProps

const columns: Column<Module>[] = [
  {
    title: 'ImageUrl',
    field: 'ImageUrl',
    render: ({ ImageUrl }: Module) =>
      ImageUrl && <img alt="" style={{ width: 30, height: 30 }} src={checkExitsHost(ImageUrl)} />
  },
  {
    title: 'Name',
    field: 'Name'
  },
  {
    title: 'Module Category',
    field: 'moduleCategory.Name'
  },

  {
    title: 'Slug',
    field: 'Slug'
  },
  {
    title: 'Fixed Url',
    field: 'FixedUrl'
  },
  {
    title: 'Module Order',
    field: 'ModuleOrder'
  },
  {
    title: 'IsFixedUrl',
    field: 'IsFixedUrl',
    render: ({ IsFixedUrl }: Module) => (IsFixedUrl ? 'True' : 'False')
  },
  {
    title: 'Status',
    field: 'IsActive',
    render: ({ IsActive }: Module) => (IsActive ? 'Active' : 'Inactive')
  }
]

export const Modules: FC<PublicationProps> = () => {
  // const [deleteItem, setDeleteItem] = useState<Module>()
  const [updateItem, onUpdate] = useState<Module>()
  const [isShowCreateNewModule, setShowCreateNewModule] = useState<boolean>(false)
  const { data } = useQuery<GetModulesQuery, GetModulesQueryVariables>(GET_MODULES)

  const classes = useStyles()

  const handleClose = () => {
    onUpdate(undefined)
    setShowCreateNewModule(false)
  }

  return (
    <div className={classes.container}>
      <Table<Module>
        columns={columns}
        title={
          <Button variant="contained" color="secondary" onClick={() => setShowCreateNewModule(true)}>
            Create New Module
          </Button>
        }
        data={(data?.getModules as Module[]) || []}
        option={{
          rowStyle: rowData => ({
            backgroundColor: rowData.PublicationStatus === 'D' ? '#EEE' : '#FFF'
          }),
          pageSize: 10
        }}
        actions={[
          {
            icon: () => <EditIcon color="secondary" style={{ fontSize: 15 }} />,
            tooltip: 'Edit',
            onClick: (_event, rowData: Module | Module[]) => {
              if (!Array.isArray(rowData)) {
                setShowCreateNewModule(true)
                onUpdate(rowData)
              }
            }
          }
        ]}
      />
      {isShowCreateNewModule && (
        <FormModuleModal isShowCreateNewModule={isShowCreateNewModule} handleClose={handleClose} item={updateItem} />
      )}
    </div>
  )
}
export default Modules
