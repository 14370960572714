import { Alert, AlertTitle } from '@material-ui/lab'
import { useUserInfo } from 'hooks/useUserInfo'
import React, { useMemo } from 'react'
import { Privileges, Roles } from 'utils/rolesAndPrivileges'
import ReleaseNotesMain from './ReleaseNotesMain'

export default function ReleaseNotes() {
  const { hasPrivilegeInGlobalRole } = useUserInfo()

  const hasManageReleaseNotesPrivilege = useMemo(
    () => hasPrivilegeInGlobalRole(Roles.ReleaseNotesWriter, Privileges.ManageReleaseNotes),
    [hasPrivilegeInGlobalRole]
  )

  if (!hasManageReleaseNotesPrivilege) {
    return (
      <Alert severity="info">
        <AlertTitle>Access Denied</AlertTitle>You don&rsquo;t have permission to access this page
      </Alert>
    )
  }
  return <ReleaseNotesMain />
}
