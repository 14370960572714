import { useMutation, useQuery } from '@apollo/react-hooks'
import { Dialog, DialogContent, DialogContentText, DialogTitle, TablePagination } from '@material-ui/core'
import ReplayIcon from '@material-ui/icons/Replay'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { DeleteIcon } from 'components/common/icons'
import { StandardDivider } from 'components/common/StandardDivider'
import { Formik } from 'formik'
import {
  DeleteFailedMessageMutation,
  DeleteFailedMessageMutationVariables,
  FailedEventMessage,
  FindManyAdminFailedEventMessagesQuery,
  FindManyAdminFailedEventMessagesQueryVariables,
  ReExecuteMessageMutation,
  ReExecuteMessageMutationVariables
} from 'generated/graphql'
import useRouter from 'hooks/useRouter'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import { Filter, Sorters } from 'utils/Constants'
import * as yup from 'yup'
import { Table } from '../../components/common/Table'
import FormFilterFailEvents from './FormFilterFailEvents'
import { DELETE_FAILED_MESSAGE, FIND_MANY_ADMIN_FAILED_EVENT_MESSAGES, RE_EXCUTE_MESSAGE } from './graphql'
import { formatDateInHHDDMMYY, toDisplayIsoDateTimeUtc } from 'utils/dates'

const validationSchema = yup.object({
  EndDate: yup.date().nullable(),
  StartDate: yup.date().nullable(),
  MessageType: yup.string().nullable(),
  ID: yup.number().typeError('ID must be a number')
})

const columns = [
  {
    title: 'ID',
    field: 'ID'
  },
  {
    title: 'MessageType',
    field: 'MessageType'
  },
  {
    title: 'CreatedDate UTC',
    field: 'CreatedDate',
    render: (row: FailedEventMessage) => {
      return formatDateInHHDDMMYY(toDisplayIsoDateTimeUtc(new Date(row.CreatedDate)))
    }
  }
]

export const FailedEvents = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const { history } = useRouter()
  const [filter, setFilter] = useState<Filter | null>(null)
  const [deletedItemID, setDeletedItemID] = useState<string | null>(null)
  const [retryItemID, setRetryItemID] = useState<string | null>(null)
  const [seletedItem, setSelectedItem] = useState<FailedEventMessage | null>(null)
  const { setErrorMessage, setNoticeMessage } = useSnackbarContext()

  const { data: eventMessages, loading, refetch } = useQuery<
    FindManyAdminFailedEventMessagesQuery,
    FindManyAdminFailedEventMessagesQueryVariables
  >(FIND_MANY_ADMIN_FAILED_EVENT_MESSAGES, {
    variables: {
      filter: {
        pagination: {
          pageSize: currentPageSize,
          pageNumber: currentPage
        },
        ...filter,
        sorters: [{ field: 'CreatedDate', orderBy: 'DESC' }]
      }
    }
  })

  const [deleteEventMessage] = useMutation<DeleteFailedMessageMutation, DeleteFailedMessageMutationVariables>(
    DELETE_FAILED_MESSAGE
  )
  const [reExcuteEventMessage] = useMutation<ReExecuteMessageMutation, ReExecuteMessageMutationVariables>(
    RE_EXCUTE_MESSAGE
  )

  const setFilterChange = (filterValues: any) => {
    const filterChange: Filter = {
      operator: 'AND',
      filters: []
    }
    const sorterDefault: Sorters = {
      sorters: [{ field: 'CreatedDate', orderBy: 'DESC' }]
    }

    if (filterValues.StartDate) {
      filterChange.filters.push({
        op: 'GTE',
        field: 'FailedEventMessage.CreatedDate',
        values: [new Date(filterValues.StartDate).toISOString()]
      })
    }
    if (filterValues.EndDate) {
      filterChange.filters.push({
        op: 'LTE',
        field: 'FailedEventMessage.CreatedDate',
        values: [new Date(filterValues.EndDate).toISOString()]
      })
    }
    ;['ID', 'MessageType'].forEach(key => {
      if (filterValues[key]) {
        filterChange.filters.push({
          op: 'EQ',
          field: `FailedEventMessage.${key}`,
          values: [filterValues[key]]
        })
      }
    })
    setFilter(filterChange)
    refetch({
      filter: {
        pagination: {
          pageSize: currentPageSize,
          pageNumber: currentPage
        },
        ...filterChange,
        ...sorterDefault
      }
    })
    setCurrentPage(1)
  }

  const onDelete = () => {
    if (deletedItemID) {
      deleteEventMessage({
        variables: {
          MessageID: deletedItemID
        }
      })
        .then(() => {
          setNoticeMessage({
            message: 'Delete success!'
          })
          refetch()
          setDeletedItemID(null)
        })
        .catch(error => {
          setErrorMessage(error?.message || '')
        })
    }
  }

  const onRetry = () => {
    if (retryItemID) {
      reExcuteEventMessage({
        variables: {
          MessageID: retryItemID
        }
      })
        .then(() => {
          setNoticeMessage({
            message: 'Retry success!'
          })
          refetch()
          setRetryItemID(null)
        })
        .catch(error => {
          setErrorMessage(error?.message || '')
        })
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          ID: '',
          MessageType: '',
          EndDate: null,
          StartDate: null
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setFilterChange(values)
          history.push(`/manage-failed-events?q=${JSON.stringify(values)}`)
          setSubmitting(false)
        }}
      >
        {props => {
          return <FormFilterFailEvents form={props} setFilterChange={setFilterChange} />
        }}
      </Formik>

      <StandardDivider />
      <Table
        columns={columns}
        isLoading={loading}
        option={{
          search: false,
          pageSize: currentPageSize
        }}
        actions={[
          {
            icon: () => <VisibilityIcon />,
            tooltip: 'View Detail Message',
            onClick: (e, rowData) => {
              if (!Array.isArray(rowData)) {
                setSelectedItem(rowData as FailedEventMessage)
              }
            }
          },
          {
            icon: () => <ReplayIcon />,
            tooltip: 'ReExcute Message Event',
            onClick: (e, rowData) => {
              if (!Array.isArray(rowData)) {
                setRetryItemID(rowData.ID as string)
              }
            }
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: 'Delete Message Event',
            onClick: (e, rowData) => {
              if (!Array.isArray(rowData)) {
                setDeletedItemID(rowData.ID as string)
              }
            }
          }
        ]}
        data={eventMessages?.findManyAdminFailedEventMessages?.items || []}
        components={{
          Pagination: props => {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={[10, 20, 50]}
                rowsPerPage={currentPageSize}
                count={eventMessages?.findManyAdminFailedEventMessages.pagination?.total || 0}
                page={currentPage - 1}
                onChangePage={(e, page) => {
                  setCurrentPage(page + 1)
                }}
                onChangeRowsPerPage={e => {
                  props.onChangeRowsPerPage(e)
                  setCurrentPage(1)
                  setCurrentPageSize(Number(e.target.value))
                }}
              />
            )
          }
        }}
      />
      {deletedItemID && (
        <ConfirmDialog
          buttonText="Delete"
          handleClose={() => setDeletedItemID(null)}
          open={!!deletedItemID}
          onConfirm={onDelete}
          title="Delete Faild Event Message"
          description="Are you sure to delete this event messsage?"
        />
      )}
      {retryItemID && (
        <ConfirmDialog
          buttonText="ReExcute"
          handleClose={() => setRetryItemID(null)}
          open={!!retryItemID}
          onConfirm={onRetry}
          title="Re excute Faild Event Message"
          description="Are you sure to re excute this event messsage?"
        />
      )}
      {seletedItem && (
        <Dialog open={!!seletedItem} keepMounted onClose={() => setSelectedItem(null)} maxWidth="md">
          <DialogTitle>Message event detail</DialogTitle>
          <DialogContent style={{ marginBottom: 20 }}>
            <DialogContentText>Body: </DialogContentText>
            <ReactJson src={seletedItem?.body} />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default FailedEvents
