import * as yup from 'yup'

export const editModalSchema = yup.object({
  releaseNote: yup
    .string()
    .trim()
    .label('Release Note')
    .required(),
  category: yup
    .string()
    .trim()
    .label('Category')
    .required(),
  releaseDate: yup
    .date()
    .label('Release Date')
    .required(),
  platform: yup
    .object()
    .nullable()
    .label('Platform')
    .required()
})
