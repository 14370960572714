import React, { Dispatch, SetStateAction } from 'react'
import { ChangeEvent, useState } from 'react'
import { Box, Button, CircularProgress, Grid, Tabs } from '@material-ui/core'
import { a11yProps } from 'utils/a11y'

import ContentToolCreator from './components/ContentToolCreator'
import SettingToolCreator from './components/SettingToolCreator'
import { StyleTabTitle, TabPanel } from 'components/common/TabPanel'
import { useFormikContext } from 'formik'
import { CMSCreatorValues, Devices } from 'pages/Publication/cms/types'
import classNames from 'classnames'
import { useGlobalStyles } from 'hooks/useGlobalStyles'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { ApolloQueryResult } from 'apollo-boost'
import { GetPagingNewsArticlesQuery, GetPagingNewsArticlesQueryVariables } from 'generated/graphql'

interface LeftContentCreatorProps {
  setIsShowCMSPage: Dispatch<SetStateAction<boolean>>
  disabledButtons: boolean
  newsArticleID: string | null
  refetchList: (
    variables?: GetPagingNewsArticlesQueryVariables
  ) => Promise<ApolloQueryResult<GetPagingNewsArticlesQuery>>
}

export default function LeftContentCreator({
  setIsShowCMSPage,
  disabledButtons,
  newsArticleID,
  refetchList
}: LeftContentCreatorProps) {
  const globalClasses = useGlobalStyles()
  const [currentTab, setCurrentTab] = useState(1)
  const handleChange = (_event: ChangeEvent<NonNullable<any>>, newTab: number) => setCurrentTab(newTab)
  const [{ isShowClosePopup, isShowChangeConfirmPopup }, setIsShowPopup] = useState({
    isShowClosePopup: false,
    isShowChangeConfirmPopup: false
  })

  const { values, handleSubmit } = useFormikContext<CMSCreatorValues>()

  return (
    <Grid item xs={12} md={5} className={values?.setting?.device !== Devices.Desktop ? 'flex flex-col' : 'hidden'}>
      <Box className="w-full h-full">
        <Tabs
          id="currentTab"
          name="currentTab"
          orientation="horizontal"
          value={currentTab}
          onChange={handleChange}
          aria-label="VQR Approvals Modal"
        >
          <StyleTabTitle value={1} label="Content" {...a11yProps(1)} />
          <StyleTabTitle value={2} label="Setting" {...a11yProps(2)} />
        </Tabs>
        <Box mt={1}>
          <TabPanel index={1} value={currentTab}>
            <ContentToolCreator />
          </TabPanel>
          <TabPanel index={2} value={currentTab}>
            <SettingToolCreator />
          </TabPanel>
        </Box>
        <Box className="w-full flex justify-end">
          {values?.setting?.device !== Devices.Desktop && (
            <Box>
              <Button
                className={classNames('rounded-md mr-3', globalClasses.greenBtn)}
                onClick={() =>
                  newsArticleID ? setIsShowPopup(prev => ({ ...prev, isShowChangeConfirmPopup: true })) : handleSubmit()
                }
                variant="contained"
                disabled={disabledButtons}
              >
                Save
                {disabledButtons && <CircularProgress className="color-white ml-2" size={14} />}
              </Button>
              <Button
                className={classNames('rounded-md', globalClasses.neutralBtn)}
                variant="contained"
                onClick={() => setIsShowPopup(prev => ({ ...prev, isShowClosePopup: true }))}
                disabled={disabledButtons}
              >
                Close
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <ConfirmDialog
        open={isShowChangeConfirmPopup}
        buttonText="Yes"
        onConfirm={() => handleSubmit()}
        handleClose={() => setIsShowPopup(prev => ({ ...prev, isShowChangeConfirmPopup: false }))}
        title="Confirmation"
        description="Are you sure you want change this article?"
        closeColor="default"
      />
      <ConfirmDialog
        open={isShowClosePopup}
        buttonText="Yes"
        onConfirm={() => setIsShowCMSPage(false)}
        handleClose={() => {
          setIsShowPopup(prev => ({ ...prev, isShowClosePopup: false }))
          refetchList?.()
        }}
        title="Close Confirmation"
        description="Any unsaved changes will be lost. Are you sure to close?"
        closeColor="default"
      />
    </Grid>
  )
}
