import { loader } from 'graphql.macro'

export const GET_PAGING_PRIVACY_CONFIGURATIONS = loader('./getPagingPrivacyConfigurations.graphql')

export const GET_PRIVACY_CONFIGURATION = loader('./getPrivacyConfiguration.graphql')

export const GET_PAGING_USER_PRIVACY_HISTORIES = loader('./getPagingUserPrivacyHistories.graphql')

export const UPSERT_PRIVACY_CONFIGURATION = loader('./upsertPrivacyConfiguration.graphql')

export const DELETE_PRIVACY_CONFIGURATION = loader('./deletePrivacyConfiguration.graphql')
