import React, { FC } from 'react'
import { WatchQueryFetchPolicy } from 'apollo-boost'
import { EntitySelector, EntitySelectorWrapperProps } from 'components/common/EntitySelector'
import { LahaType, GetAllLahaTypesQuery, GetAllLahaTypesQueryVariables } from 'generated/graphql'
import { GET_ALL_LAHA_TYPES } from './graphql'

export type LAHATypesSelectorProps = EntitySelectorWrapperProps<
  LahaType,
  GetAllLahaTypesQuery,
  GetAllLahaTypesQueryVariables
> & {
  extractEntities?: (data: GetAllLahaTypesQuery) => LahaType[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  refetch?: boolean
}

const labelEntity = (value: LahaType) => value?.Description

const extractEntities = (data: GetAllLahaTypesQuery) => data.getAllLAHATypes

export const LAHATypesSelector: FC<LAHATypesSelectorProps> = ({ label = 'LAHA Type', ...props }) => {
  const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  return (
    <EntitySelector<LahaType, GetAllLahaTypesQuery, GetAllLahaTypesQueryVariables>
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={GET_ALL_LAHA_TYPES}
      queryHookOptions={{
        fetchPolicy: fetchPolicy
      }}
      {...props}
    />
  )
}
