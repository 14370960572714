import React, { useRef, useState } from 'react'
import { Maybe, PaginationResponse } from 'generated/graphql'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import { MTableHeader } from 'material-table'

const useSelectionTable = <T extends { ID: string }>() => {
  const tableRef = useRef<any>(null)

  const [selectedItems, setSelectedItems] = useState<T[]>([])

  const [isSelectAllRecord, setIsSelectAllRecord] = useState<boolean>(false)
  const [isSelectAllRows, setIsSelectAllRows] = useState<boolean>(false)
  const [isShowSelectAllRecord, setIsShowSelectAllRecord] = useState<boolean>(false)

  const deleteAllSelection = () => {
    tableRef?.current?.deselectAllRow()
    setSelectedItems([])
    setIsSelectAllRows(false)
  }

  const selectAllSelection = () => {
    tableRef?.current?.selectAllRow()
    setIsSelectAllRows(true)
  }

  const handleSelectAllRecord = (data: T[]) => {
    setSelectedItems(data)
    setIsSelectAllRecord(true)
    setIsSelectAllRows(true)
  }

  const handleDeselectAllRecord = () => {
    deleteAllSelection()
    setIsSelectAllRecord(false)
    setIsSelectAllRows(false)
  }

  const onSelectionChange = (items: { ID: string }[] | [], rows: T[], clickedRow?: any) => {
    const displayedIds = map(items, item => item.ID)
    if (displayedIds.length === 0) return
    const selectedRowsNotDisplayed = selectedItems?.filter(selectedRow => {
      return !displayedIds?.includes(selectedRow.ID)
    })
    setSelectedItems([...selectedRowsNotDisplayed, ...rows])
    setIsShowSelectAllRecord(!!items?.length && rows?.length === items?.length)
    setIsSelectAllRows(!!items?.length && rows?.length === items?.length)
    isSelectAllRecord && !clickedRow?.tableData?.checked && setIsSelectAllRecord(false)
  }

  const isSelectAllRowInPage = (pagination?: Maybe<PaginationResponse>, shouldClearSelection?: boolean) => {
    return (
      isSelectAllRecord ||
      (!!shouldClearSelection && (isSelectAllRows || selectedItems?.length >= (pagination?.pageSize ?? 0)))
    )
  }

  const selectAllRowInPage = <Q extends { items?: Array<T>; pagination?: Maybe<PaginationResponse> }>(
    data: Q | undefined | null,
    shouldClearSelection?: boolean
  ) => {
    const isSelectAllRowsOfPage = isSelectAllRowInPage(data?.pagination, shouldClearSelection)
    setIsSelectAllRows(isSelectAllRowsOfPage)
    if (isSelectAllRowsOfPage) {
      setSelectedItems(data?.items || [])
      !isSelectAllRecord &&
        debounce(() => {
          selectAllSelection()
        }, 10)()
    } else {
      !!shouldClearSelection && deleteAllSelection()
    }
  }

  const selectionProps = (rowData: { ID: string }) => ({
    checked: !!selectedItems?.find(item => item.ID === rowData?.ID)
  })

  const Header = ({ selectedCount, ...props }: any) => (
    <MTableHeader
      {...{ ...props, ...(isSelectAllRecord && { onAllSelected: handleDeselectAllRecord }) }}
      selectedCount={selectedItems?.length === 0 ? 0 : selectedCount}
    />
  )

  return {
    selectedItems,
    setSelectedItems,
    onSelectionChange,
    selectionProps,
    tableRef,
    isSelectAllRowInPage,
    selectAllRowInPage,
    deleteAllSelection,
    selectAllSelection,
    Header,
    isSelectAllRecord,
    isShowSelectAllRecord,
    setIsSelectAllRecord,
    handleSelectAllRecord,
    handleDeselectAllRecord
  }
}

export default useSelectionTable
