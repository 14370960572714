import React, { FC } from 'react'
import { WatchQueryFetchPolicy } from 'apollo-boost'
import {
  GetPayrollClassificationsQuery,
  GetPayrollClassificationsQueryVariables,
  PayrollClassification
} from '../../../generated/graphql'
import { EntitySelector, EntitySelectorWrapperProps } from '../EntitySelector'
import { GET_PAYROLL_CLASSIFICATIONS } from 'graphql/common'

export type PayrollClassificationSelectorProps = EntitySelectorWrapperProps<
  PayrollClassification,
  GetPayrollClassificationsQuery,
  GetPayrollClassificationsQueryVariables
> & {
  extractEntities?: (data: GetPayrollClassificationsQuery) => PayrollClassification[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  companyID: string
  refetch?: boolean
}

const labelEntity = (value: PayrollClassification) => `${value.ClassificationName}`

const extractEntities = (data: GetPayrollClassificationsQuery) => data.getPayrollClassifications

export const PayrollClassificationSelector: FC<PayrollClassificationSelectorProps> = ({
  label = 'Select EBA',
  companyID,
  ...props
}) => {
  const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  return (
    <EntitySelector<PayrollClassification, GetPayrollClassificationsQuery, GetPayrollClassificationsQueryVariables>
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={GET_PAYROLL_CLASSIFICATIONS}
      queryHookOptions={{
        fetchPolicy: fetchPolicy,
        variables: {
          companyID: companyID
        },
        skip: !companyID
      }}
      {...props}
    />
  )
}
