import React, { FC, useEffect, useMemo } from 'react'
import { Button, Grid } from '@material-ui/core'
import { FormikProps } from 'formik'
import { SelectorField } from 'components/common/SelectorField'
import { PayrollClassificationSelector } from 'components/common/payroll/PayrollClassificationSelector'
import { Company, PayrollClassification } from '../../../generated/graphql'
import useRouter from 'hooks/useRouter'
import { CompanyEBASelector } from './CompanyEBASelector'

export interface FormValues {
  CompanyID?: string
  PayrollClassificationID?: string
}

interface FormFilterEBASetupProps {
  form: FormikProps<any>
  setFilterChange: (filterValues: FormValues) => void
}

const FormFilterEBASetup: FC<FormFilterEBASetupProps> = ({ form, setFilterChange }) => {
  const { values, errors, touched, resetForm, handleSubmit, setFieldValue, setValues } = form
  const { history, query } = useRouter()

  const isDisabled = useMemo(() => {
    return !(values.PayrollClassificationID && values.CompanyID)
  }, [values])

  useEffect(() => {
    if (query.q) {
      const data = JSON.parse(query.q)
      setFilterChange(data)
      setValues({
        ...data
      })
    } else {
      setFilterChange({})
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [query.q])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid item sm={3}>
            <SelectorField
              label="Select Company"
              name="CompanyID"
              onChange={(newValue: Company) => setFieldValue('CompanyID', newValue)}
              value={values.CompanyID}
              selector={CompanyEBASelector}
              error={!!errors.CompanyID && !!touched.CompanyID}
              helperText={touched.CompanyID ? errors.CompanyID : null}
            />
          </Grid>
          <Grid item sm={3}>
            <SelectorField
              label="Select EBA"
              name="PayrollClassificationID"
              companyID={values?.CompanyID || ''}
              onChange={(newValue: PayrollClassification) => setFieldValue('PayrollClassificationID', newValue)}
              value={values.PayrollClassificationID}
              selector={PayrollClassificationSelector}
              error={!!errors.PayrollClassificationID && !!touched.PayrollClassificationID}
              helperText={touched.PayrollClassificationID ? errors.PayrollClassificationID : null}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="contained" disabled={isDisabled} type="submit">
              Search
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="contained"
              onClick={() => {
                resetForm()
                history.push('/payroll')
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default FormFilterEBASetup
