import { makeStyles, Tabs } from '@material-ui/core'
import React, { useState, ChangeEvent } from 'react'
import { a11yProps } from '../../utils/a11y'
import { NewsAlert } from './NewsAlert/NewsAlert'
import { StandardDivider } from '../../components/common/StandardDivider'
import { PushNotification } from './PushNotification/PushNotification'
import { CreateSystemAnnouncement } from './CreateSystemAnnouncement/CreateSystemAnnouncement'
import { EmployeeBenefits } from './EmployeeBenefit/EmployeeBenefit'
import ReleaseNotes from './ReleaseNotes'
import { StyleTabTitle } from 'components/common/TabPanel'
import CMSList from './cms/CMSList'
import { NewsArticle } from 'generated/graphql'

const useStyles = makeStyles(theme => ({
  container: {},
  label: {
    color: theme.palette.text.hint
  }
}))

export const Publication = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const [newsArticle, setNewsArticle] = useState<NewsArticle | null>(null)

  const classes = useStyles()
  const handleChange = (_event: ChangeEvent<NonNullable<any>>, newTab: number) => setCurrentTab(newTab)

  return (
    <div className={classes.container}>
      <Tabs value={currentTab} onChange={handleChange} aria-label="projects tabs">
        <StyleTabTitle className="text-lg" label="Publications" {...a11yProps(0)} />
        <StyleTabTitle className="text-lg" label="CMS" {...a11yProps(1)} />
        <StyleTabTitle className="text-lg" label="Push Notifications" {...a11yProps(2)} />
        <StyleTabTitle className="text-lg" label="Announcement" {...a11yProps(3)} />
        <StyleTabTitle className="text-lg" label="Employee Benefits" {...a11yProps(4)} />
        <StyleTabTitle className="text-lg" label="Release notes" {...a11yProps(5)} />
      </Tabs>
      <StandardDivider />
      {currentTab === 0 && <NewsAlert setNewsArticle={setNewsArticle} newsArticle={newsArticle} />}
      {currentTab === 1 && <CMSList setNewsArticle={setNewsArticle} setCurrentTab={setCurrentTab} />}
      {currentTab === 2 && <PushNotification />}
      {currentTab === 3 && <CreateSystemAnnouncement />}
      {currentTab === 4 && <EmployeeBenefits />}
      {currentTab === 5 && <ReleaseNotes />}
    </div>
  )
}
export default Publication
