import { useApolloClient } from '@apollo/react-hooks'
import { FetchPolicy } from 'apollo-boost'
import { DocumentNode } from 'graphql'

export interface FileUrlProps<T> {
  query: DocumentNode
  variables?: T
  fetchPolicy?: FetchPolicy
}

export default function useFileUrl<T, U>() {
  const client = useApolloClient()

  return async ({ query, variables, fetchPolicy = 'no-cache' }: FileUrlProps<U>) => {
    try {
      return await client.query<T, U>({
        query,
        variables,
        fetchPolicy
      })
    } catch (_) {
      return {
        data: {
          getFile: {
            Url: '',
            FileName: ''
          }
        }
      }
    }
  }
}
