import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Grid, TablePagination, Button, Typography } from '@material-ui/core'
import { DeleteIcon, EditIcon } from 'components/common/icons'
import { StandardDivider } from 'components/common/StandardDivider'
import { Table } from 'components/common/Table'
import { Formik } from 'formik'
import {
  ForemanCode,
  Project,
  UpsertForemanCodeMutation,
  UpsertForemanCodeMutationVariables,
  GetPaginatingForemanCodeQuery,
  GetPaginatingForemanCodeQueryVariables,
  RemoveForemanCodeMutation,
  RemoveForemanCodeMutationVariables,
  LahaType,
  ShiftType,
  TimesheetAllowanceType,
  TimesheetLeaveType,
  Scalars,
  Sorter,
  ForemanCodePaginatingResponse
} from 'generated/graphql'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { Action } from 'material-table'
import React, { useEffect, useState } from 'react'
import { REQUIRED } from 'utils/Constants'
import * as yup from 'yup'
import { GET_PAGINATING_FOREMAN_CODE, REMOVE_FOREMAN_CODE, UPSERT_FOREMAN_CODE } from './graphql'
import { JobAllProjectsSelector } from './JobAllProjectsSelector'
import { Alert, AlertTitle } from '@material-ui/lab'
import { foremanCodesColumn } from './foreman-codes-table'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { isActiveToggleToValue, valueToInActiveToggle } from 'utils/toggles'
import { SelectorField } from 'components/common/SelectorField'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { NOAUTOFILL } from 'components/common/AutocompleteInput'
import { TimesheetLeaveTypesSelector } from './TimesheetLeaveTypesSelector'
import { ConfigShiftTypesSelector } from './ConfigShiftTypesSelector'
import { TimesheetAllowanceTypesSelector } from './TimesheetAllowanceTypesSelector'
import { LAHATypesSelector } from './LAHATypesSelector'
import { GridLayoutToggle } from 'components/common/GridLayoutToggle'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import differenceInMinutes from 'date-fns/differenceInMinutes'

const formatIsoTime = (date: Date) => {
  if (!date) return ''
  return `${date.getHours() > 9 ? '' : '0'}${date.getHours()}:${
    date.getMinutes() > 9 ? '' : '0'
  }${date.getMinutes()}:00.000Z`
}

const isoTimeToDate = (timeString: string) => {
  const timeBreak = timeString?.split(':')
  return new Date(new Date().setHours(Number(timeBreak?.[0]), Number(timeBreak?.[1]), 0, 0))
}

type FormForemanCodesValues = {
  ID?: string
  Code: string
  ProjectID: string
  TimesheetLeaveTypeID?: string | null
  ShiftTypeID?: string | null
  DefaultStartTime?: Scalars['Time'] | null
  DefaultEndTime?: Scalars['Time'] | null
  TimesheetAllowanceTypeID?: string | null
  LAHATypeID?: string | null
  IsActive: string
}
const foremanCodeSchema = yup.object({
  Code: yup
    .string()
    .max(50, 'maximum 50 characters long')
    .required(`Supervisor Code ${REQUIRED}`)
    .nullable(),
  DefaultStartTime: yup
    .date()
    .label('Default Start Time')
    .nullable()
    .typeError('Invalid Time'),

  DefaultEndTime: yup
    .date()
    .label('Default End Time')
    .nullable()
    .test('match', 'Default Start Time and Default End Time can not be the same', function(DefaultEndTime) {
      if (!this.parent.DefaultStartTime || !DefaultEndTime) {
        return true
      }
      const totalMinutes = differenceInMinutes(
        isoTimeToDate(formatIsoTime(DefaultEndTime)),
        isoTimeToDate(formatIsoTime(this.parent.DefaultStartTime))
      )
      return totalMinutes !== 0
    })
    .typeError('Invalid Time')
})

export const ForemanCodesTab = () => {
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [sorter, setSorter] = useState<Sorter | null>(null)
  const [project, setProject] = useState<Project | null>(null)
  const [deletedItem, setDeletedItem] = useState<ForemanCode | null>(null)
  const [selectedItem, setSelectedItem] = useState<ForemanCode | null>(null)
  const [foremanCodesList, setForemanCodesList] = useState<ForemanCodePaginatingResponse | null>(null)

  const [getForemanCodes, { loading, refetch }] = useLazyQuery<
    GetPaginatingForemanCodeQuery,
    GetPaginatingForemanCodeQueryVariables
  >(GET_PAGINATING_FOREMAN_CODE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setForemanCodesList(data?.getPaginatingForemanCode)
    },
    onError: error => {
      setErrorMessage(error?.message || '')
    }
  })

  useEffect(() => {
    if (project || sorter) {
      getForemanCodes({
        variables: {
          ProjectID: project?.ID as string,
          sorter: sorter,
          pagination: {
            pageSize: currentPageSize,
            pageNumber: currentPage
          }
        }
      })
    }
    // eslint-disable-next-line
  }, [project, sorter, currentPage])

  const [upsertForemanCode, { loading: upsertForemanCodeLoading }] = useMutation<
    UpsertForemanCodeMutation,
    UpsertForemanCodeMutationVariables
  >(UPSERT_FOREMAN_CODE)
  const [removeForemanCode] = useMutation<RemoveForemanCodeMutation, RemoveForemanCodeMutationVariables>(
    REMOVE_FOREMAN_CODE
  )

  const onDeleteForemanCode = () => {
    if (deletedItem) {
      removeForemanCode({
        variables: {
          ForemanCodeID: deletedItem?.ID
        }
      })
        .then(({ data }) => {
          if (data?.removeForemanCode) {
            refetch()
            setSuccessMessage(`Supervisor Code deleted successfully.`)
          }
        })
        .catch(error => setErrorMessage(error.message))
    }
  }

  const initData: FormForemanCodesValues = {
    ID: selectedItem?.ID,
    Code: selectedItem?.Code ?? '',
    ProjectID: project?.ID ?? '',
    TimesheetLeaveTypeID: selectedItem?.TimesheetLeaveTypeID ?? null,
    ShiftTypeID: selectedItem?.ShiftTypeID ?? null,
    DefaultStartTime: selectedItem?.DefaultStartTime ? isoTimeToDate(selectedItem?.DefaultStartTime) : null,
    DefaultEndTime: selectedItem?.DefaultEndTime ? isoTimeToDate(selectedItem?.DefaultEndTime) : null,
    TimesheetAllowanceTypeID: selectedItem?.TimesheetAllowanceTypeID ?? null,
    LAHATypeID: selectedItem?.LAHATypeID ?? null,
    IsActive: selectedItem ? valueToInActiveToggle(selectedItem?.IsActive) : 'Active'
  }
  const actions: Action<ForemanCode>[] = [
    {
      icon: () => <EditIcon />,
      tooltip: 'Edit',
      onClick: (_event, rowData: ForemanCode | ForemanCode[]) => {
        if (!Array.isArray(rowData)) {
          setSelectedItem(rowData)
        }
      }
    },
    {
      icon: () => <DeleteIcon />,
      tooltip: 'Delete',
      onClick: (_, rowData: ForemanCode | ForemanCode[]) => {
        if (!Array.isArray(rowData)) setDeletedItem(rowData)
      }
    }
  ]
  const setSortChange = (sortKey: string | '', orderType: string | '') => {
    let keyName = sortKey
    switch (sortKey) {
      case 'Supervisor Code':
        keyName = `ForemanCode.Code`
        break
      case 'Active':
        keyName = `ForemanCode.IsActive`
        break
      case 'Default Start Time':
        keyName = `ForemanCode.DefaultStartTime`
        break
      case 'Default Finish Time':
        keyName = `ForemanCode.DefaultEndTime`
        break
      default:
        break
    }
    if (sortKey !== '' && orderType !== '') {
      const sort = { field: keyName, orderBy: orderType.toLocaleUpperCase() }
      setSorter(sort)
      setCurrentPage(1)
    }
  }

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <JobAllProjectsSelector
          value={project}
          onChange={newValue => {
            setProject(newValue)
          }}
        />
      </div>
      {project ? (
        <>
          <Formik
            enableReinitialize
            initialValues={initData}
            validationSchema={foremanCodeSchema}
            onSubmit={async (value, { resetForm, setSubmitting }) => {
              setSubmitting(true)

              upsertForemanCode({
                variables: {
                  input: {
                    ID: selectedItem?.ID,
                    Code: value?.Code,
                    ProjectID: project?.ID,
                    TimesheetLeaveTypeID: value?.TimesheetLeaveTypeID || null,
                    ShiftTypeID: value?.ShiftTypeID || null,
                    DefaultStartTime: value?.DefaultStartTime ? formatIsoTime(value?.DefaultStartTime) : null,
                    DefaultEndTime: value?.DefaultEndTime ? formatIsoTime(value?.DefaultEndTime) : null,
                    TimesheetAllowanceTypeID: value?.TimesheetAllowanceTypeID || null,
                    LAHATypeID: value?.LAHATypeID || null,
                    IsActive: isActiveToggleToValue(value.IsActive)
                  }
                }
              })
                .then(({ data }) => {
                  if (data?.upsertForemanCode) {
                    const message = !selectedItem
                      ? `Supervisor Code added successfully.`
                      : `Supervisor Code updated successfully.`
                    setSuccessMessage(message)
                    refetch()
                    setSubmitting(false)
                    resetForm()
                    setSelectedItem(null)
                  }
                  resetForm()
                })
                .catch(error => {
                  setErrorMessage(error.message)
                  setSubmitting(false)
                })
            }}
          >
            {props => {
              const {
                values,
                errors,
                touched,
                handleSubmit,
                handleBlur,
                handleChange,
                isSubmitting,
                setFieldValue,
                setTouched
              } = props
              const onChangeStartTime = (date: MaterialUiPickersDate) => setFieldValue('DefaultStartTime', date)
              const onChangeEndTime = (date: MaterialUiPickersDate) => setFieldValue('DefaultEndTime', date)

              return (
                <form onSubmit={handleSubmit}>
                  <br />
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12} md={3}>
                        <GridLayoutInput
                          autoComplete={NOAUTOFILL}
                          fullWidth
                          id="Code"
                          label="Supervisor Code*"
                          name="Code"
                          value={values.Code}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!errors.Code && !!touched.Code}
                          helperText={touched.Code ? errors.Code : null}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <SelectorField
                          label="Leave Code"
                          name="TimesheetLeaveTypeID"
                          onChange={(newValue: TimesheetLeaveType) => setFieldValue('TimesheetLeaveTypeID', newValue)}
                          value={values.TimesheetLeaveTypeID}
                          selector={TimesheetLeaveTypesSelector}
                          error={!!errors.TimesheetLeaveTypeID && !!touched.TimesheetLeaveTypeID}
                          helperText={touched.TimesheetLeaveTypeID ? errors.TimesheetLeaveTypeID : null}
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <KeyboardTimePicker
                          fullWidth
                          error={!!errors?.DefaultStartTime && !!touched.DefaultStartTime}
                          helperText={touched.DefaultStartTime ? errors.DefaultStartTime : null}
                          label="Default Start Time"
                          placeholder="10:00 AM"
                          onChange={onChangeStartTime}
                          mask="__:__ _M"
                          onBlur={() => {
                            setTouched({ DefaultStartTime: true })
                          }}
                          value={values?.DefaultStartTime}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <KeyboardTimePicker
                          fullWidth
                          error={!!errors?.DefaultEndTime && !!touched.DefaultEndTime}
                          helperText={touched.DefaultEndTime ? errors.DefaultEndTime : null}
                          label="Default End Time"
                          placeholder="10:00 AM"
                          onChange={onChangeEndTime}
                          mask="__:__ _M"
                          onBlur={() => {
                            setTouched({ DefaultEndTime: true })
                          }}
                          value={values?.DefaultEndTime}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <SelectorField
                          label="Shift Type"
                          name="ShiftTypeID"
                          onChange={(newValue: ShiftType) => setFieldValue('ShiftTypeID', newValue)}
                          value={values.ShiftTypeID}
                          selector={ConfigShiftTypesSelector}
                          error={!!errors.ShiftTypeID && !!touched.ShiftTypeID}
                          helperText={touched.ShiftTypeID ? errors.ShiftTypeID : null}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <SelectorField
                          label="Allowance Type"
                          name="TimesheetAllowanceTypeID"
                          onChange={(newValue: TimesheetAllowanceType) =>
                            setFieldValue('TimesheetAllowanceTypeID', newValue)
                          }
                          value={values.TimesheetAllowanceTypeID}
                          selector={TimesheetAllowanceTypesSelector}
                          error={!!errors.TimesheetAllowanceTypeID && !!touched.TimesheetAllowanceTypeID}
                          helperText={touched.TimesheetAllowanceTypeID ? errors.TimesheetAllowanceTypeID : null}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <SelectorField
                          label="LAHA Type"
                          name="LAHATypeID"
                          onChange={(newValue: LahaType) => setFieldValue('LAHATypeID', newValue)}
                          value={values.LAHATypeID}
                          selector={LAHATypesSelector}
                          error={!!errors.LAHATypeID && !!touched.LAHATypeID}
                          helperText={touched.LAHATypeID ? errors.LAHATypeID : null}
                        />
                      </Grid>
                      <Grid container item xs={12} md={3} alignItems="center">
                        <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: 20 }}>
                          Status
                        </Typography>
                        <GridLayoutToggle
                          left="Active"
                          right="Inactive"
                          value={values?.IsActive}
                          setValue={value => setFieldValue('IsActive', value)}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs justifyContent="flex-end">
                      <Grid item>
                        <Button
                          disabled={isSubmitting || upsertForemanCodeLoading}
                          size="large"
                          color="secondary"
                          variant="contained"
                          type="submit"
                        >
                          Save
                        </Button>
                        &nbsp;&nbsp;
                        {selectedItem && (
                          <Button size="large" variant="contained" onClick={() => setSelectedItem(null)}>
                            Cancel
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
          <StandardDivider />
          <Grid container>
            <Grid item xs={12}>
              <Table<ForemanCode>
                isLoading={loading}
                data={foremanCodesList?.items || []}
                columns={foremanCodesColumn}
                actions={actions}
                onOrderChange={(columnIndex, orderDirection) => {
                  const columnPath = columnIndex < 0 ? null : foremanCodesColumn[columnIndex].title
                  setSortChange(columnPath || '', orderDirection)
                }}
                option={{
                  pageSize: currentPageSize
                }}
                components={{
                  Pagination: props => {
                    return (
                      <TablePagination
                        {...props}
                        rowsPerPageOptions={[10, 20, 50]}
                        rowsPerPage={currentPageSize}
                        count={foremanCodesList?.pagination?.total || 0}
                        page={currentPage - 1}
                        onChangePage={(e, page) => {
                          setCurrentPage(page + 1)
                        }}
                        onChangeRowsPerPage={e => {
                          props.onChangeRowsPerPage(e)
                          setCurrentPageSize(Number(e.target.value))
                        }}
                      />
                    )
                  }
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Alert severity="info">
          <AlertTitle>Select Job</AlertTitle>
          Select a job to create Supervisor Codes
        </Alert>
      )}
      {deletedItem && (
        <ConfirmDialog
          buttonText="Delete"
          handleClose={() => setDeletedItem(null)}
          open={!!deletedItem}
          onConfirm={onDeleteForemanCode}
          title="Delete Supervisor Code"
          description="Are you sure to delete this Supervisor Code item?"
        />
      )}
    </>
  )
}
