import { Dialog, DialogContent, Grid, IconButton, Tab, Tabs, Typography } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { StandardDivider } from 'components/common/StandardDivider'
import { UserTabPanel } from 'components/common/users/UserTabPanel'
import { DocumentNode } from 'graphql'
import React, { ChangeEvent, FC, useState } from 'react'
import { a11yProps } from 'utils/a11y'
import { GridLayoutInput } from '../../../components/common/GridLayoutInput'
import { Person } from '../../../generated/graphql'
import { CompanyRoles } from './CompanyRoles'
import { GlobalRoles } from './GlobalRoles'
import { ProjectRoles } from './ProjectRoles'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

interface ManageRoleModalProps {
  selectedRoleItem: Person
  onCloseModal: () => void
  refetchQuery: DocumentNode
  refetchOption: any
  isExternalRole?: boolean
}

export const RoleAccessModal: FC<ManageRoleModalProps> = ({
  refetchQuery,
  refetchOption,
  selectedRoleItem,
  onCloseModal,
  isExternalRole
}) => {
  const [currentTab, setCurrentTab] = useState(0)

  const handleChange = (_event: ChangeEvent<NonNullable<any>>, newTab: number) => setCurrentTab(newTab)

  const handleClose = () => {
    onCloseModal()
    setCurrentTab(0)
  }

  const formatPersonType = (str: string) => {
    return str === 'ADMIN' ? 'Admin' : 'Project Resource'
  }

  return (
    <>
      <Dialog onClose={handleClose} open={!!selectedRoleItem} maxWidth={'md'} fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {`${selectedRoleItem?.Firstname} ${selectedRoleItem?.Lastname}`}
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="space-between" direction="row" spacing={4}>
            <GridLayoutInput
              id="email"
              label="Email"
              value={selectedRoleItem?.Email}
              textFieldBreakpoints={{ xs: 6, sm: 6 }}
              fullWidth
              disabled
              required
            />
            <GridLayoutInput
              id="mobile"
              label="Phone number"
              value={selectedRoleItem?.Phone || 'No phone available'}
              textFieldBreakpoints={{ xs: 6, sm: 6 }}
              fullWidth
              disabled
              required
            />
            {isExternalRole && (
              <GridLayoutInput
                id="personType"
                label="Person Type"
                value={
                  selectedRoleItem?.externalPerson?.PersonType
                    ? formatPersonType(selectedRoleItem?.externalPerson?.PersonType)
                    : 'No person type available'
                }
                textFieldBreakpoints={{ xs: 6, sm: 6 }}
                fullWidth
                disabled
              />
            )}
            <Grid item xs={12}>
              <Tabs value={currentTab} onChange={handleChange} aria-label="users tabs">
                <Tab label="Global Roles" {...a11yProps(0)} />
                <Tab label="Company Roles" {...a11yProps(1)} />
                <Tab label="Job Roles" {...a11yProps(2)} />
              </Tabs>
              <StandardDivider />
              <UserTabPanel value={currentTab} index={0}>
                <GlobalRoles
                  refetchOption={refetchOption}
                  refetchQuery={refetchQuery}
                  selectedRoleItem={selectedRoleItem}
                  isExternalRole={isExternalRole}
                />
              </UserTabPanel>
              <UserTabPanel value={currentTab} index={1}>
                <CompanyRoles selectedRoleItem={selectedRoleItem} isExternalRole={isExternalRole} />
              </UserTabPanel>
              <UserTabPanel value={currentTab} index={2}>
                <ProjectRoles selectedRoleItem={selectedRoleItem} isExternalRole={isExternalRole} />
              </UserTabPanel>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}
