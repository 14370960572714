import { ApolloQueryResult } from 'apollo-boost'
import { GetFileQuery, GetFileQueryVariables } from 'generated/graphql'
import { FileUrlProps } from 'hooks/useFileUrl'

export interface ResMonth {
  monthName: string
  monthIndex: number
  year: number
}

export enum UploadTableStatus {
  ProcessingWithError = 'ProcessingWithError',
  Processing = 'Processing',
  CompletedWithError = 'CompletedWithError',
  Error = 'Error',
  Completed = 'Completed',
  ReadingExcelFile = 'ReadingExcelFile'
}

export type GetFileProps = ({
  query,
  variables
}: FileUrlProps<GetFileQueryVariables>) => Promise<
  | ApolloQueryResult<GetFileQuery>
  | {
      data: {
        getFile: {
          Url: string
          FileName: string
        }
      }
    }
>

export type PromiseSettledResult = PromiseFulfilledResult<string> | (PromiseRejectedResult & { value?: string })
