import React, { FC } from 'react'
import { WatchQueryFetchPolicy } from 'apollo-boost'
import { EntitySelector, EntitySelectorWrapperProps } from './EntitySelector'
import { EmployeeBenefitCategory } from 'generated/graphql'
import { GetEmployeeBenefitCategoriesQuery, GetEmployeeBenefitCategoriesQueryVariables } from '../../generated/graphql'
import { GET_EMPLOYEE_BENEFIT_CATEGORIES } from 'graphql/common'

export type EmployeeBenefitSelectorProps = EntitySelectorWrapperProps<
  EmployeeBenefitCategory,
  GetEmployeeBenefitCategoriesQuery,
  GetEmployeeBenefitCategoriesQueryVariables
> & {
  extractEntities?: (data: GetEmployeeBenefitCategoriesQuery) => EmployeeBenefitCategory[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  refetch?: boolean
}

const labelEntity = ({ CategoryName }: EmployeeBenefitCategory) => `${CategoryName}`

const extractEntities = (data: GetEmployeeBenefitCategoriesQuery) => data.getEmployeeBenefitCategories

export const EmployeeBenefitSelector: FC<EmployeeBenefitSelectorProps> = ({ label = 'Type of Benefit*', ...props }) => {
  const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  return (
    <EntitySelector<
      EmployeeBenefitCategory,
      GetEmployeeBenefitCategoriesQuery,
      GetEmployeeBenefitCategoriesQueryVariables
    >
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={GET_EMPLOYEE_BENEFIT_CATEGORIES}
      queryHookOptions={{
        fetchPolicy: fetchPolicy,
        variables: {
          IsActive: true
        }
      }}
      {...props}
    />
  )
}
