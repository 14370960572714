import React from 'react'
import { ForemanCode } from 'generated/graphql'
import { formatTime } from 'utils/dates'
import { customFilterAndSearchColumn } from 'utils/tools'

const isoTimeToDate = (timeString: string) => {
  const timeBreak = timeString?.split(':')
  return new Date(new Date().setHours(Number(timeBreak?.[0]), Number(timeBreak?.[1]), 0, 0))
}

export const foremanCodesColumn = [
  {
    title: 'Supervisor Code',
    field: 'Code',
    customFilterAndSearch: (filter: string, rowData: ForemanCode) =>
      customFilterAndSearchColumn(filter, rowData, (row: ForemanCode) => `${row.Code ?? ''}`)
  },
  {
    title: 'Leave Code',
    field: 'timesheetLeaveType',
    render: (row: ForemanCode) => row?.timesheetLeaveType?.LeaveCode ?? '',
    sorting: false,
    customFilterAndSearch: (filter: string, rowData: ForemanCode) =>
      customFilterAndSearchColumn(filter, rowData, (row: ForemanCode) => `${row?.timesheetLeaveType?.LeaveCode ?? ''}`)
  },
  {
    title: 'Default Start Time',
    field: 'DefaultStartTime',
    render: (row: ForemanCode) => (row?.DefaultStartTime ? formatTime(isoTimeToDate(row?.DefaultStartTime)) : ''),
    customFilterAndSearch: (filter: string, rowData: ForemanCode) =>
      customFilterAndSearchColumn(
        filter,
        rowData,
        (row: ForemanCode) => `${row?.DefaultStartTime ? formatTime(isoTimeToDate(row?.DefaultStartTime)) : ''}`
      )
  },
  {
    title: 'Default Finish Time',
    field: 'DefaultEndTime',
    render: (row: ForemanCode) => (row?.DefaultEndTime ? formatTime(isoTimeToDate(row?.DefaultEndTime)) : ''),
    customFilterAndSearch: (filter: string, rowData: ForemanCode) =>
      customFilterAndSearchColumn(
        filter,
        rowData,
        (row: ForemanCode) => `${row?.DefaultEndTime ? formatTime(isoTimeToDate(row?.DefaultEndTime)) : ''}`
      )
  },
  {
    title: 'Shift Type',
    field: 'shiftType',
    render: (row: ForemanCode) => row?.shiftType?.ShiftType,
    sorting: false,
    customFilterAndSearch: (filter: string, rowData: ForemanCode) =>
      customFilterAndSearchColumn(filter, rowData, (row: ForemanCode) => `${row?.shiftType?.ShiftType ?? ''}`)
  },
  {
    title: 'Time Allowance Type',
    field: 'timesheetAllowanceType',
    render: (row: ForemanCode) => row?.timesheetAllowanceType?.Description,
    sorting: false,
    customFilterAndSearch: (filter: string, rowData: ForemanCode) =>
      customFilterAndSearchColumn(
        filter,
        rowData,
        (row: ForemanCode) => `${row?.timesheetAllowanceType?.Description ?? ''}`
      )
  },
  {
    title: 'LAHA Code',
    field: 'lahaType',
    render: (row: ForemanCode) => row?.lahaType?.Description,
    sorting: false,
    customFilterAndSearch: (filter: string, rowData: ForemanCode) =>
      customFilterAndSearchColumn(filter, rowData, (row: ForemanCode) => `${row?.lahaType?.Description ?? ''}`)
  },
  {
    title: 'Active',
    field: 'IsActive',
    render: (row: ForemanCode) => <span>{row?.IsActive ? 'Active' : 'Inactive'}</span>,
    customFilterAndSearch: (filter: string, rowData: ForemanCode) =>
      customFilterAndSearchColumn(filter, rowData, (row: ForemanCode) => `${row?.IsActive ? 'Active' : 'Inactive'}`)
  }
]
