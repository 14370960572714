import { Box, Grid, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { CMSCreatorValues } from 'pages/Publication/cms/types'
import React from 'react'

import { ImagePositionSelector } from 'components/common/ImagePositionSelector'
import { ImageSizeSelector } from 'components/common/ImageSizeSelector'
import { SelectorObjectField } from 'components/common/SelectorObjectField'
import { getVarBasedOnCMSForm } from 'pages/Publication/cms/utils'
import ContentEditor from '../../ContentEditor'
import TextInImageAccordion from './TextInImageAccordion'
import UploadImagesContainer from './UploadImagesContainer'

interface HeaderFormProps {
  index: number
}

export default function UploadImagesForm({ index }: HeaderFormProps) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<CMSCreatorValues>()
  const { isShowVideo, uploadTitle } = getVarBasedOnCMSForm(values, index)

  return (
    <Box>
      {isShowVideo && (
        <Box>
          <Box className="w-full mb-4">
            <Typography className="text-base font-semibold mb-2 color-neutral-700">
              {uploadTitle} <span className="primary-color-500"> *</span>
            </Typography>
          </Box>
          <UploadImagesContainer index={index} />
          <Grid container className="mt-3" spacing={1}>
            <SelectorObjectField
              label="Size"
              isVersion2
              name={`content.${index}.size`}
              selector={ImageSizeSelector}
              textFieldBreakpoints={{ xs: 12, md: 4 }}
              disableClearable
            />
            <SelectorObjectField
              label="Position"
              isVersion2
              name={`content.${index}.position`}
              selector={ImagePositionSelector}
              textFieldBreakpoints={{ xs: 12, md: 4 }}
              disableClearable
            />
          </Grid>
          <Box>
            {values?.content?.[index]?.textEditors?.map((text, childIndex) => (
              <TextInImageAccordion
                key={String(childIndex)}
                index={childIndex}
                label="Text of video"
                isExpanded={text?.isExpanded}
                onClick={() =>
                  setFieldValue(`content.${index}.textEditors.${childIndex}.isExpanded`, !text?.isExpanded)
                }
              >
                <ContentEditor
                  className="w-full rounded-md custom-quill-container"
                  textLabel=""
                  value={text?.description ?? ''}
                  onChange={e => setFieldValue(`content.${index}.textEditors.${childIndex}.description`, e)}
                  onBlur={() => setFieldTouched(`content.${index}.textEditors.${childIndex}.description`)}
                />
              </TextInImageAccordion>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}
