export enum Roles {
  ReleaseNotesWriter = 'ReleaseNotesWriter',
  JVStaff = 'JVStaff'
}

export enum Privileges {
  ManageReleaseNotes = 'ManageReleaseNotes',
  ManageProjectLogo = 'ManageProjectLogo',
  ManageIntegrations = 'ManageIntegrations',
  ManageCommunications = 'ManageCommunications',
  ManageUsers = 'ManageUsers',
  ManageModule = 'ManageModule',
  ManageAudit = 'ManageAudit',
  ViewPayrollSettings = 'ViewPayrollSettings',
  ManagePrivacy = 'ManagePrivacy',
  ManagePrintingTemplate = 'ManagePrintingTemplate',
  ManageMobileVersions = 'ManageMobileVersions'
}
