import React from 'react'
import { GetPagingWorkerTypesQuery, GetPagingWorkerTypesQueryVariables, WorkerType } from 'generated/graphql'
import { EntitySelectorWrapperProps } from './EntitySelector'
import { GET_PAGING_WORKER_TYPES } from 'graphql/common'
import { QueryHookOptions } from '@apollo/react-hooks'
import { InfiniteSelector } from './InfiniteSelector'

export type LabourTypeSelectorProps = EntitySelectorWrapperProps<
  WorkerType,
  GetPagingWorkerTypesQuery,
  GetPagingWorkerTypesQueryVariables
> & {
  selectedWorkerTypes: WorkerType[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  isLow: boolean
}

const compileData = (data: GetPagingWorkerTypesQuery) => {
  return {
    data: data?.getPagingWorkerTypes?.items || [],
    pagination: data?.getPagingWorkerTypes?.pagination
  }
}

const labelEntity = ({ Description }: WorkerType) => Description

const labeller = (value: WorkerType) => value.Description || ''

export const LabourTypeSelector = ({
  label = 'Labour type',
  isLow,
  selectedWorkerTypes,
  ...props
}: LabourTypeSelectorProps) => {
  const queryHookOptions: QueryHookOptions<GetPagingWorkerTypesQuery, GetPagingWorkerTypesQueryVariables> = {
    variables: {
      IsHighFrequencyLabourTypes: !isLow,
      IsLowFrequencyLabourTypes: isLow
    }
  }

  const extractLabourTypes = (data: WorkerType[]): WorkerType[] => {
    return data?.filter(item => !selectedWorkerTypes?.find(type => type.ID === item.ID))
  }

  return (
    <InfiniteSelector<WorkerType>
      label={label}
      labelEntity={labelEntity}
      labeller={labeller}
      extractData={extractLabourTypes}
      query={GET_PAGING_WORKER_TYPES}
      queryHookOptions={queryHookOptions}
      compileData={compileData}
      {...props}
    />
  )
}
