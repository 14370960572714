import { Box, Dialog, DialogContent, IconButton, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { CancelIcon, NextPageIcon, PreviousPageIcon } from 'components/common/icons'
import { useFormikContext } from 'formik'
import map from 'lodash/map'
import { CMSCreatorValues } from 'pages/Publication/cms/types'
import React, { CSSProperties, cloneElement, useState } from 'react'
import Slider from 'react-slick'

interface ArrowSliderProps {
  className?: string
  style?: CSSProperties
  onClick?: () => void
}

export function SampleNextArrow({ className, style, onClick }: ArrowSliderProps) {
  return (
    <div aria-label="next" className={classNames(className, 'block right-8 z-50')} style={style}>
      <IconButton onClick={onClick}>
        <NextPageIcon fontSize="large" className="color-white bg-color-grey rounded-full" />
      </IconButton>
    </div>
  )
}

export function SamplePrevArrow({ className, style, onClick }: ArrowSliderProps) {
  return (
    <div aria-label="prev" className={classNames(className, 'block left-4 z-50')} style={style}>
      <IconButton onClick={onClick}>
        <PreviousPageIcon fontSize="large" className="color-white bg-color-grey rounded-full" />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles({
  wrapper: {
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      margin: '0px !important'
    }
  },
  slider: {
    width: '100%',
    height: '100%',
    '& .slick-list': {
      width: '100%',
      height: '100%'
    },
    '& .slick-list .slick-track': {
      width: '100%',
      height: '100%'
    },
    '& .slick-list .slick-slide > div': {
      width: '100%',
      height: '100%'
    }
  }
})

export default function ContentPreview() {
  const classes = useStyles()
  const { values } = useFormikContext<CMSCreatorValues>()
  const [imagesShow, setImagesShow] = useState<string[]>([])
  const [currentImageShowIndex, setCurrentImageShowIndex] = useState(0)

  const settings = {
    speed: 0,
    slidesToShow: 1,
    autoplay: false,
    cssEase: 'linear',
    nextArrow: imagesShow?.length > 1 ? <SampleNextArrow /> : undefined,
    prevArrow: imagesShow?.length > 1 ? <SamplePrevArrow /> : undefined
  }

  return (
    <Box>
      {values?.content?.map((content, index) => (
        <Box
          key={content?.id}
          className={classNames(classes.wrapper, index === values?.content?.length - 1 ? 'mb-32' : '')}
        >
          {content?.templateComponent &&
            cloneElement(content?.templateComponent, {
              key: content?.id,
              index,
              description: content?.description,
              images: content?.images,
              imagePreviews: content?.imagePreviews,
              secondDescription: content?.secondDescription,
              textInImages: content?.textInImages,
              maximumImage: Number(content?.maximumImage?.ID ?? 1),
              backgroundColor: content?.backgroundColor?.trim(),
              backgroundOption: content?.headerBackgroundOption,
              backgroundColorHeight: content?.backgroundColorHeight,
              setImagesShow,
              setCurrentImageShowIndex,
              textEditors: content?.textEditors,
              imagesPerRow: content?.imagesPerRow || 1,
              size: content?.size,
              position: content?.position
            })}
        </Box>
      ))}
      <Dialog fullScreen fullWidth onClose={() => setImagesShow([])} open={!!imagesShow?.length}>
        <DialogContent className="p-12">
          <Slider {...settings} className={classes.slider} initialSlide={currentImageShowIndex}>
            {map(imagesShow, (img, index) => (
              <Box key={String(index)} className="w-full h-full overflow-x-hidden flex justify-center">
                <img className="h-full" src={img} alt="" />
              </Box>
            ))}
          </Slider>

          <IconButton className="absolute top-0 right-1 text-xl" onClick={() => setImagesShow([])}>
            <CancelIcon fontSize="large" />
          </IconButton>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
