import { Box, Button, Grid, IconButton, TablePagination, Tooltip, Typography } from '@material-ui/core'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { CancelIcon, SearchIcon } from 'components/common/icons'
import MonthNavigator from 'components/common/MonthNavigator'
import { Table } from 'components/common/Table'
import usePagination from 'hooks/usePagination'
import excelIcon from 'images/icons/excel.svg'
import uploadIcon from 'images/icons/upload.svg'
import React, { useEffect, useMemo, useState } from 'react'
import BulkActionsButton from './components/BulkActionsButton'
import ActionsMenu from './components/ActionsMenu'
import UploadModal from './components/UploadModal'
import EditModal from './components/EditModal'
import { ActionsType } from '../types'
import { releaseNotesTableColumns } from './components/ColumnTable'
import { useReleaseNotesStyles } from '../styles'
import {
  ChangeReleaseNotesPublishStatusMutation,
  ChangeReleaseNotesPublishStatusMutationVariables,
  DeleteReleaseNotesMutation,
  DeleteReleaseNotesMutationVariables,
  ExportReleaseNotesQuery,
  ExportReleaseNotesQueryVariables,
  GetFileQuery,
  GetFileQueryVariables,
  GetPagingReleaseNotesAdminQuery,
  GetPagingReleaseNotesAdminQueryVariables,
  ReleaseNotes as ReleaseNotesType,
  ReleaseNotesPaginatedResponse
} from 'generated/graphql'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { GET_FILE } from 'graphql/common'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import {
  CHANGE_RELEASE_NOTES_PUBLISH_STATUS,
  DELETE_RELEASE_NOTES,
  EXPORT_RELEASE_NOTES,
  GET_PAGING_RELEASE_NOTES_ADMIN
} from './graphql'
import useSelectionTable from 'hooks/useSelectionTable'
import { Components } from 'material-table'
import { ResMonth } from 'utils/types'
import { clickToDownloadTemplate } from 'utils/tools'
import { months } from 'utils/Constants'

export default function ReleaseNotesMain() {
  const classes = useReleaseNotesStyles()
  const {
    currentPageSize,
    onChangeRowsPerPage,
    currentPage,
    setCurrentPage,
    onChangePage,
    filter,
    onFilter
  } = usePagination('pageSizeReleaseNotes')
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext()

  const {
    selectedItems,
    onSelectionChange,
    tableRef,
    deleteAllSelection,
    Header,
    isShowSelectAllRecord,
    isSelectAllRecord,
    handleSelectAllRecord,
    selectionProps,
    selectAllRowInPage
  } = useSelectionTable<ReleaseNotesType>()

  const [currentTime, setCurrentTime] = useState<{ month: string; year: string }>({
    month: '',
    year: ''
  })
  const [isShowUploadModal, setIsShowUploadModal] = useState(false)
  const [isShowEditModal, setIsShowEditModal] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState<ReleaseNotesType | null>(null)

  const { data, loading, refetch: refetchPagingReleaseNotes } = useQuery<
    GetPagingReleaseNotesAdminQuery,
    GetPagingReleaseNotesAdminQueryVariables
  >(GET_PAGING_RELEASE_NOTES_ADMIN, {
    fetchPolicy: 'cache-and-network',
    skip: !currentTime.month || !currentTime.year,
    variables: {
      dateFilter: {
        ReleaseMonth: Number(currentTime.month),
        ReleaseYear: Number(currentTime.year)
      },
      filter,
      Pagination: {
        pageNumber: currentPage,
        pageSize: currentPageSize
      }
    },
    onCompleted: data => {
      selectAllRowInPage<ReleaseNotesPaginatedResponse>(data?.getPagingReleaseNotesAdmin)
    },
    onError: error => setErrorMessage(error?.message)
  })

  const releaseNotes = useMemo(
    () =>
      data?.getPagingReleaseNotesAdmin?.items?.map(releaseNote => ({
        ...releaseNote,
        Status: releaseNote.Status === ActionsType.Published ? releaseNote.Status : ActionsType.Unpublished
      })) ?? [],
    [data]
  )
  const pagePagination = data?.getPagingReleaseNotesAdmin?.pagination

  const [exportReleaseNotes] = useLazyQuery<ExportReleaseNotesQuery, ExportReleaseNotesQueryVariables>(
    EXPORT_RELEASE_NOTES,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted(data) {
        setSuccessMessage(data?.exportReleaseNotes || '')
      },
      onError: error => {
        setErrorMessage((error as Error)?.message)
      }
    }
  )

  const [changeStatus] = useMutation<
    ChangeReleaseNotesPublishStatusMutation,
    ChangeReleaseNotesPublishStatusMutationVariables
  >(CHANGE_RELEASE_NOTES_PUBLISH_STATUS)

  const [deleteReleaseNotes] = useMutation<DeleteReleaseNotesMutation, DeleteReleaseNotesMutationVariables>(
    DELETE_RELEASE_NOTES
  )

  const [downloadTemplate] = useLazyQuery<GetFileQuery, GetFileQueryVariables>(GET_FILE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      if (res?.getFile?.Url) {
        clickToDownloadTemplate(res?.getFile?.Url || '')
      }
    },
    onError: error => setErrorMessage((error as Error)?.message)
  })

  const handleRowActions = (selectedOption: string) => {
    const isMultipleRows = selectedItems.length > 0
    let rows: string[] = []

    if (!isSelectAllRecord && !selectedRowData?.ID && isMultipleRows) {
      rows = selectedItems.map(row => row?.ID)
    } else if (selectedRowData?.ID) {
      rows = [selectedRowData?.ID]
    }

    const dateFilter = isSelectAllRecord
      ? {
          ReleaseMonth: Number(currentTime.month),
          ReleaseYear: Number(currentTime.year)
        }
      : null

    const onSuccess = (msg: string) => {
      setSuccessMessage(msg)
      setSelectedRowData(null)
      isMultipleRows && deleteAllSelection()
      refetchPagingReleaseNotes()
    }

    const onError = (msg: string) => {
      setErrorMessage(msg)
      setSelectedRowData(null)
    }

    switch (selectedOption) {
      case ActionsType.Edit:
        setIsShowEditModal(true)
        break
      case ActionsType.Publish:
        changeStatus({
          variables: {
            ReleaseNoteIDs: rows,
            Status: ActionsType.Published,
            dateFilter
          }
        })
          .then(res => onSuccess(res.data?.changeReleaseNotesPublishStatus || ''))
          .catch(error => onError((error as Error)?.message))
        break
      case ActionsType.UnPublish:
        changeStatus({
          variables: {
            ReleaseNoteIDs: rows,
            Status: ActionsType.UnPublished,
            dateFilter
          }
        })
          .then(res => onSuccess(res.data?.changeReleaseNotesPublishStatus || ''))
          .catch(error => onError((error as Error)?.message))
        break
      case ActionsType.Delete:
        deleteReleaseNotes({
          variables: {
            ReleaseNoteIDs: rows,
            dateFilter
          }
        })
          .then(res => onSuccess(res.data?.deleteReleaseNotes || ''))
          .catch(error => onError((error as Error)?.message))
        break
      case ActionsType.ExportExcel:
        exportReleaseNotes({
          variables: {
            ReleaseNoteIDs: rows,
            dateFilter
          }
        })
        break

      default:
        break
    }
  }

  const tableComponents: Components = {
    Header: props =>
      Header({
        ...props,
        selectedCount: isSelectAllRecord
          ? releaseNotes?.length
          : selectedItems.filter(item => releaseNotes.some(e => e.ID === item.ID)).length
      }),
    Toolbar: () => {
      return (
        <Box display="flex" alignItems="center" padding="10px">
          {!!selectedItems.length && (
            <>
              <Typography variant="h5" component="h5" className={classes.marginLeft10}>
                {`${isSelectAllRecord ? pagePagination?.total : selectedItems.length} ${
                  selectedItems.length > 1 ? 'rows' : 'row'
                } selected. `}
                <Tooltip title="Deselect all items from all pages" aria-label="clear">
                  <IconButton color="secondary" component="span" onClick={deleteAllSelection}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
              {!isSelectAllRecord && isShowSelectAllRecord && selectedItems.length < Number(pagePagination?.total) && (
                <Button
                  variant="outlined"
                  className={classes.selectAllBtn}
                  color="secondary"
                  onClick={() => handleSelectAllRecord(releaseNotes)}
                >
                  {`Select all ${pagePagination?.total} rows `}
                </Button>
              )}
            </>
          )}
        </Box>
      )
    },
    Pagination: props => {
      return (
        <TablePagination
          {...props}
          count={pagePagination?.total || 0}
          rowsPerPage={currentPageSize}
          page={currentPage - 1}
          onChangePage={onChangePage}
          onChangeRowsPerPage={e => {
            onChangeRowsPerPage(e, props)
            deleteAllSelection()
          }}
        />
      )
    }
  }

  useEffect(() => {
    if (filter) deleteAllSelection()
    // eslint-disable-next-line
  }, [filter])

  return (
    <>
      <Grid container className={classes.marginBottom20} justifyContent="space-between">
        <Grid container item xs={12} md={6} spacing={1}>
          <GridLayoutInput
            placeholder="Search"
            textFieldBreakpoints={{ xs: 12, md: 7 }}
            onChange={event => onFilter(event.target.value)}
            adornment={
              <IconButton edge="start">
                <SearchIcon />
              </IconButton>
            }
            adornmentPosition="start"
          />
          <Grid item xs={12} md={5}>
            <Button
              onClick={() =>
                downloadTemplate({
                  variables: {
                    fileKey: 'template/ReleaseNotesTemplate.xlsx'
                  }
                })
              }
              className={classes.downloadBtn}
              variant="outlined"
              size="medium"
              fullWidth
              startIcon={<img src={excelIcon} height={20} width={20} alt="excel icon" />}
            >
              Download Template
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6} spacing={1} justifyContent="flex-end">
          <Grid item xs={12} md={4}>
            <MonthNavigator
              onChange={(value: ResMonth) => {
                setCurrentTime({ month: String(value.monthIndex + 1), year: String(value.year) })
                deleteAllSelection()
                setCurrentPage(1)
              }}
            />
          </Grid>
          <span className={classes.straightLine} />
          <Grid item xs={12} md={3}>
            <BulkActionsButton disabled={!selectedItems.length} onGetOption={option => handleRowActions(option)} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              onClick={() => setIsShowUploadModal(true)}
              className={classes.downloadBtn}
              variant="outlined"
              size="medium"
              startIcon={<img src={uploadIcon} alt="upload" />}
              fullWidth
            >
              Upload Release Notes
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Table<ReleaseNotesType>
        isLoading={loading}
        columns={releaseNotesTableColumns}
        data={releaseNotes || []}
        ref={tableRef}
        isShowCustomEmptyData={!releaseNotes?.length}
        emptyDescription="No release notes found"
        option={{
          pageSize: currentPageSize,
          search: false,
          selection: true,
          selectionProps: selectionProps,
          minBodyHeight: `calc(80vh - 12rem)`
        }}
        onSelectionChange={rows => onSelectionChange(releaseNotes, rows)}
        actions={[
          {
            icon: () => (
              <ActionsMenu
                isPublish={selectedRowData?.Status === ActionsType.Published}
                onGetOption={selectedOption => handleRowActions(selectedOption)}
              />
            ),
            onClick: (_, rowData: ReleaseNotesType | ReleaseNotesType[]) => {
              !Array.isArray(rowData) && setSelectedRowData(rowData)
            },
            position: 'row'
          }
        ]}
        components={tableComponents}
      />

      {isShowUploadModal && (
        <UploadModal
          month={months[Number(currentTime.month) - 1]}
          year={currentTime.year}
          isShow={isShowUploadModal}
          onClose={() => setIsShowUploadModal(false)}
          onSuccess={() => refetchPagingReleaseNotes()}
        />
      )}

      {isShowEditModal && (
        <EditModal
          isShow={isShowEditModal}
          onClose={() => setIsShowEditModal(false)}
          onSuccess={() => {
            deleteAllSelection()
            refetchPagingReleaseNotes()
          }}
          selectedItem={selectedRowData}
        />
      )}
    </>
  )
}
