import { useMutation } from '@apollo/react-hooks'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Slide,
  Typography
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { GridLayoutToggle } from 'components/common/GridLayoutToggle'
import UploadImage from 'components/common/UploadImage'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { forwardRef, Ref, useEffect, useState } from 'react'
import { GridLayoutInput } from '../../components/common/GridLayoutInput'
import { Module, UpsertModuleMutation, UpsertModuleMutationVariables } from '../../generated/graphql'
import { GET_MODULES, UPSERT_MODULE } from './graphql'
import { ModuleCategorySelector } from '../../components/common/ModuleCategorySelector'
import { useSnackbarContext } from 'hooks/useSnackbarContext'

const useStyles = makeStyles({
  title: {
    fontWeight: 700,
    margin: '10px 0'
  },
  description: {
    margin: '10px 0',
    color: ' #8a8a8a',
    fontSize: 15,
    fontStyle: 'italic'
  }
})

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface FormModuleModalProps {
  handleClose: () => void
  item?: Module
  isShowCreateNewModule: boolean
}

export type IsActiveToggle = 'Active' | 'Inactive'
export type IsFixedToggle = 'True' | 'False'

export const FormModuleModal = ({ isShowCreateNewModule, handleClose, item }: FormModuleModalProps) => {
  const initialValues = {
    ID: item?.ID || '',
    Name: item?.Name || '',
    Slug: item?.Slug || '',
    ImageUrl: item?.ImageUrl || '',
    ModuleOrder: item?.ModuleOrder || 0,
    IsFixedUrl: item?.IsFixedUrl,
    IsActive: item?.IsActive,
    FixedUrl: item?.FixedUrl || '',
    ModuleCategoryID: item?.ModuleCategoryID || ''
  }
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext()

  const [upsertModule] = useMutation<UpsertModuleMutation, UpsertModuleMutationVariables>(UPSERT_MODULE, {
    refetchQueries: [{ query: GET_MODULES }]
  })
  const [isRemovePreviewImage, setIsRemovePreviewImage] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (
      { ID, Name, ImageUrl, Slug, ModuleOrder, IsFixedUrl, FixedUrl, IsActive, ModuleCategoryID },
      { resetForm }
    ) => {
      setLoading(true)
      upsertModule({
        variables: {
          input: {
            ID: ID || undefined,
            Name,
            ImageUrl,
            Slug,
            ModuleOrder: Number(ModuleOrder),
            IsFixedUrl,
            FixedUrl,
            IsActive,
            ModuleCategoryID: ModuleCategoryID ? Number(ModuleCategoryID) : undefined
          }
        }
      })
        .then(() => {
          setIsRemovePreviewImage(true)
          resetForm()
          handleClose()
          setSuccessMessage('Module has been updated successfully')
          setLoading(false)
        })
        .catch(error => {
          setErrorMessage(error?.message)
          setLoading(false)
        })
        .finally(() => setIsRemovePreviewImage(false))
    }
  })
  const { handleSubmit, setValues, values, setFieldValue } = formik

  useEffect(() => {
    setValues({
      ID: item?.ID || '',
      Name: item?.Name || '',
      ImageUrl: item?.ImageUrl || '',
      Slug: item?.Slug || '',
      ModuleOrder: item?.ModuleOrder || 0,
      IsFixedUrl: item?.IsFixedUrl,
      IsActive: item?.IsActive,
      FixedUrl: item?.FixedUrl || '',
      ModuleCategoryID: item?.ModuleCategoryID || ''
    })
  }, [item, setValues, isShowCreateNewModule])

  const handleUploadImage = (ImageUrl: string): void => {
    setValues({
      ...values,
      ImageUrl: ImageUrl
    })
  }

  return (
    <>
      <Dialog
        open={isShowCreateNewModule}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={handleClose}
        aria-labelledby="delete-site-diary-dialog-title"
        aria-describedby="delete-site-diary-dialog-description"
      >
        <DialogTitle id="adelete-site-diary-dialog-title">
          {isShowCreateNewModule ? 'Create New Module' : 'Edit Module'}
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form>
              <GridLayoutInput
                id="Slug"
                name="Slug"
                label="Slug"
                textFieldBreakpoints={{ xs: 12, sm: 12 }}
                disabled={!isShowCreateNewModule && !!item?.Slug}
              />
              <br />

              <GridLayoutInput id="Name" name="Name" label="Name" textFieldBreakpoints={{ xs: 12, sm: 12 }} />
              <br />
              <Grid item sm={12}>
                <ModuleCategorySelector
                  value={values.ModuleCategoryID}
                  onChange={value => {
                    setFieldValue('ModuleCategoryID', value?.ID)
                  }}
                />
              </Grid>
              <br />
              <UploadImage
                onReturnValue={handleUploadImage}
                defaultValue={values.ImageUrl}
                isRemovePreviewImage={isRemovePreviewImage}
              />
              <br />
              <GridLayoutInput
                id="ModuleOrder"
                name="ModuleOrder"
                label="ModuleOrder"
                type="number"
                textFieldBreakpoints={{ xs: 12, sm: 12 }}
                InputProps={{
                  inputProps: {
                    min: 0
                  }
                }}
              />
              <br />
              <Typography className={classes.title}>IsFixedUrl </Typography>
              <Typography className={classes.description}>
                If True, ensure there is Fixed URL value set underneath. User will be redirected to this URL from
                Linkedsite web if he/she clicks on this module
              </Typography>
              <GridLayoutToggle<IsFixedToggle>
                toggleBreakpoints={{ xs: 12, sm: 12 }}
                left="True"
                right="False"
                value={values.IsFixedUrl ? 'True' : 'False'}
                setValue={value => setFieldValue('IsFixedUrl', value === 'True' ? true : false)}
              />
              <br />
              <GridLayoutInput
                id="FixedUrl"
                name="FixedUrl"
                label="Fixed Url"
                textFieldBreakpoints={{ xs: 12, sm: 12 }}
              />

              <Typography className={classes.title}>Status </Typography>
              <Typography className={classes.description}>
                If Active, the module will be shown on Linkedsite Web. Otherwise, it&rsquo;s hidden{' '}
              </Typography>
              <GridLayoutToggle<IsActiveToggle>
                toggleBreakpoints={{ xs: 12, sm: 12 }}
                left="Active"
                right="Inactive"
                value={values.IsActive ? 'Active' : 'Inactive'}
                setValue={value => setFieldValue('IsActive', value === 'Active' ? true : false)}
              />
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => handleSubmit()}
            aria-label="button-modal-confirm"
            color="secondary"
            variant="contained"
          >
            Save
          </Button>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
