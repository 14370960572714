import { useQuery, useMutation } from '@apollo/react-hooks'
import { Button, Grid, Typography, TablePagination, Link, makeStyles } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { Table } from 'components/common/Table'
import usePagination from 'hooks/usePagination'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { StandardDivider } from 'components/common/StandardDivider'
import { DeleteIcon, EditIcon } from 'components/common/icons'
import { NOAUTOFILL } from 'components/common/AutocompleteInput'
import ReactQuill from 'react-quill'
import React, { FC, useState, createRef } from 'react'
import { Formik } from 'formik'
import { REQUIRED, ROW_PER_PAGE_OPTIONS } from 'utils/Constants'
import { validateNumericOnly, isEmptyHTML, formatHTMLSpace, scrollToTop, getUrl } from 'utils/tools'
import * as yup from 'yup'
import { Column } from 'material-table'
import {
  PrivacyConfiguration,
  GetPagingPrivacyConfigurationsQuery,
  GetPagingPrivacyConfigurationsQueryVariables,
  GetPrivacyConfigurationQuery,
  GetPrivacyConfigurationQueryVariables,
  UpsertPrivacyConfigurationMutation,
  UpsertPrivacyConfigurationMutationVariables,
  DeletePrivacyConfigurationMutation,
  DeletePrivacyConfigurationMutationVariables
} from '../../generated/graphql'
import {
  GET_PRIVACY_CONFIGURATION,
  GET_PAGING_PRIVACY_CONFIGURATIONS,
  UPSERT_PRIVACY_CONFIGURATION,
  DELETE_PRIVACY_CONFIGURATION
} from './graphql'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { UserPrivacyHistoriesDialog } from './UserPrivacyHistoriesDialog'

const TEXT_MAX = 2000

const useStyles = makeStyles({
  wrapper: {
    '& .MuiToggleButton-root.Mui-disabled': {
      borderColor: 'rgba(0, 0, 0, 0.12)'
    }
  }
})

const privacyConfigurationValidationSchema = yup.object().shape({
  Content: yup
    .string()
    .max(TEXT_MAX, `Maximum ${TEXT_MAX} characters long`)
    .trim()
    .test('length', `Privacy Notice ${REQUIRED}`, val => val && !isEmptyHTML(val))
    .required(`Privacy Notice ${REQUIRED}`),
  Reference: yup
    .string()
    .max(TEXT_MAX, `Maximum ${TEXT_MAX} characters long`)
    .nullable()
    .test('match', 'Invalid URL', function(url) {
      if (url) {
        const pattern = new RegExp(
          'https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,}'
        )
        return !!pattern.test(url)
      }
      return true
    }),
  Status: yup.string().nullable(),
  Version: yup.string().nullable()
})

// Fuction Component
export const ManagePolicies: FC<NonNullable<any>> = () => {
  const classes = useStyles()
  const { currentPageSize, onChangeRowsPerPage, currentPage, setCurrentPage, filter, onFilter } = usePagination(
    'pageSizePrivacyNotice'
  )
  // Declare variables
  const [autoHideFeedback, setAutoHideFeedback] = useState<Feedback | null>(null)
  const [isPublished, setIsPublished] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowHistory, setIsShowHistory] = useState(false)
  const [deleteID, setDeleteID] = useState('')
  const [currentID, setCurrentID] = useState('')

  const { data: currentData } = useQuery<GetPrivacyConfigurationQuery, GetPrivacyConfigurationQueryVariables>(
    GET_PRIVACY_CONFIGURATION,
    {
      skip: !currentID,
      variables: {
        ID: currentID
      },
      fetchPolicy: 'no-cache'
    }
  )

  const { loading, data, refetch } = useQuery<
    GetPagingPrivacyConfigurationsQuery,
    GetPagingPrivacyConfigurationsQueryVariables
  >(GET_PAGING_PRIVACY_CONFIGURATIONS, {
    variables: {
      filter,
      pagination: {
        pageNumber: currentPage,
        pageSize: currentPageSize
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  const [deletePrivacyConfiguration] = useMutation<
    DeletePrivacyConfigurationMutation,
    DeletePrivacyConfigurationMutationVariables
  >(DELETE_PRIVACY_CONFIGURATION)

  const [upsertPrivacyConfiguration] = useMutation<
    UpsertPrivacyConfigurationMutation,
    UpsertPrivacyConfigurationMutationVariables
  >(UPSERT_PRIVACY_CONFIGURATION)

  const initialValues = {
    ID: currentData?.getPrivacyConfiguration?.ID || '',
    Content: currentData?.getPrivacyConfiguration?.Content || '',
    Reference: currentData?.getPrivacyConfiguration?.Reference || '',
    Version: currentData?.getPrivacyConfiguration?.Version || '',
    Status: currentData?.getPrivacyConfiguration?.Status || 'Draft'
  }

  const quillRef = createRef<ReactQuill>()

  const checkCharacterCount = () => {
    if (quillRef && quillRef.current) {
      const editor = quillRef.current.getEditor()
      if (editor.getLength() > TEXT_MAX) {
        quillRef.current.getEditor().deleteText(TEXT_MAX, editor.getLength())
      }
    }
  }

  const quillSettings = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline'],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }]
      ],
      handlers: {}
    },
    clipboard: {
      matchVisual: false
    }
  }
  const columns: Column<PrivacyConfiguration>[] = [
    {
      field: 'Version',
      title: 'Version',
      sorting: false
    },
    {
      field: 'Content',
      title: 'Privacy Notice',
      sorting: false,
      render: rowData => <div dangerouslySetInnerHTML={{ __html: rowData?.Content || '' }} />
    },
    {
      field: 'Status',
      title: 'Status',
      sorting: false
    },
    {
      field: 'Reference',
      title: 'Reference',
      render: rowData => (
        <Link color="inherit" href={getUrl(rowData?.Reference)} target="_blank">
          <Typography variant="body2" style={{ fontWeight: 'bold' }} color="textPrimary">
            {rowData?.Reference}
          </Typography>
        </Link>
      )
    }
  ]

  const onCloseDeletePopup = () => {
    setDeleteID('')
  }

  const onDelete = () => {
    if (deleteID) {
      deletePrivacyConfiguration({
        variables: {
          ID: deleteID
        }
      })
        .then(() => {
          setAutoHideFeedback(getSuccessFeedback(`Privacy Notice deleted successfully`))
          refetch()
        })
        .catch(error => {
          setAutoHideFeedback(getErrorFeedback(error.message))
        })
    }
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={privacyConfigurationValidationSchema}
        onSubmit={async ({ ID, Status, Content, Reference }, { resetForm, setSubmitting }) => {
          setIsLoading(true)
          setSubmitting(true)
          await upsertPrivacyConfiguration({
            variables: {
              input: {
                ...(ID && {
                  ID
                }),
                Status,
                Content: formatHTMLSpace(Content),
                Reference
              }
            }
          })
            .then(() => {
              setAutoHideFeedback(
                getSuccessFeedback(
                  ID ? 'Updated Privacy Configuration Successfully!' : 'Privacy Configuration created successfully!'
                )
              )
              setSubmitting(false)
              setIsLoading(false)
              resetForm()
              setCurrentID('')
              refetch()
            })
            .catch(error => {
              setSubmitting(false)
              setIsLoading(false)
              setAutoHideFeedback(getErrorFeedback(error.message))
            })
        }}
      >
        {props => {
          const {
            values,
            errors,
            touched,
            setFieldValue,
            resetForm,
            handleBlur,
            handleSubmit,
            handleChange,
            getFieldProps
          } = props
          return (
            <form onSubmit={handleSubmit} className={classes.wrapper}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography variant="h5">Privacy Configuration</Typography>
                </Grid>
                <GridLayoutInput
                  fullWidth
                  id="Reference"
                  name="Reference"
                  label="Reference"
                  disabled={currentData?.getPrivacyConfiguration?.Status === 'Published'}
                  autoComplete={NOAUTOFILL}
                  textFieldBreakpoints={{ xs: 6, md: 6 }}
                  value={values?.Reference}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.Reference && !!touched.Reference}
                  helperText={touched.Reference ? errors.Reference : null}
                />
                {currentID && (
                  <GridLayoutInput
                    fullWidth
                    id="Version"
                    name="Version"
                    label="Version"
                    required
                    disabled
                    onKeyDown={validateNumericOnly}
                    autoComplete={NOAUTOFILL}
                    textFieldBreakpoints={{ xs: 6, md: 6 }}
                    value={values?.Version}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.Version && !!touched.Version}
                    helperText={touched.Version ? errors.Version : null}
                  />
                )}
                <Grid item xs={12}>
                  <b>Privacy Status&nbsp;&nbsp;&nbsp;</b>
                  <ToggleButtonGroup
                    exclusive
                    {...getFieldProps('Status')}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setFieldValue('Status', value)
                      }
                    }}
                    aria-label="type"
                    value={values?.Status}
                  >
                    <ToggleButton
                      disabled={currentData?.getPrivacyConfiguration?.Status === 'Published'}
                      value="Draft"
                      aria-label="display"
                      key="true"
                      id="true"
                    >
                      <Typography>Draft</Typography>
                    </ToggleButton>
                    <ToggleButton
                      disabled={currentData?.getPrivacyConfiguration?.Status === 'Published'}
                      value="Published"
                      aria-label="hide"
                      key="false"
                      id="false"
                    >
                      <Typography>Published</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Privacy Notice</Typography>
                  <ReactQuill
                    theme={'snow'}
                    id="Content"
                    style={{ marginTop: 10 }}
                    ref={quillRef}
                    readOnly={currentData?.getPrivacyConfiguration?.Status === 'Published'}
                    onKeyUp={checkCharacterCount}
                    onKeyDown={checkCharacterCount}
                    onKeyPress={checkCharacterCount}
                    value={values?.Content}
                    onChange={content => {
                      setFieldValue('Content', content)
                    }}
                    modules={quillSettings}
                    formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'indent', 'link', 'align']}
                    bounds={'.app'}
                    placeholder={'Please enter privacy notice'}
                  />
                  {!!touched.Content && !!errors.Content && (
                    <Typography variant="caption" color="error">
                      {errors.Content}
                    </Typography>
                  )}
                </Grid>
                {!currentID ? (
                  <Grid container alignItems="center" direction="row" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      onClick={() => {
                        resetForm()
                      }}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button disabled={isLoading} color="secondary" variant="contained" type="submit">
                      Create
                    </Button>
                  </Grid>
                ) : (
                  <Grid container alignItems="center" direction="row" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setCurrentID('')
                        setIsPublished(false)
                        resetForm()
                      }}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    {currentData?.getPrivacyConfiguration?.Status !== 'Published' && !isPublished && (
                      <Button disabled={isLoading} color="secondary" variant="contained" type="submit">
                        Save
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
            </form>
          )
        }}
      </Formik>
      <StandardDivider />
      <Button variant="contained" onClick={() => setIsShowHistory(true)}>
        View User Privacy History
      </Button>
      <Grid style={{ marginTop: 20 }}>
        <Table
          isLoading={loading}
          columns={columns}
          data={(data?.getPagingPrivacyConfigurations?.items as PrivacyConfiguration[]) || []}
          option={{
            rowStyle: rowData => ({
              backgroundColor: rowData.PrivacyConfigurationStatus === 'D' ? '#EEE' : '#FFF'
            }),
            pageSize: 10
          }}
          onSearchChange={e => {
            const search: string = e.toString()
            onFilter(search)
          }}
          actions={[
            {
              icon: () => (
                <Grid container justifyContent="center" direction="row" alignItems="center">
                  <EditIcon color="primary" style={{ fontSize: 15 }} />
                </Grid>
              ),
              tooltip: 'Edit',
              onClick: (_event, rowData: PrivacyConfiguration | PrivacyConfiguration[]) => {
                if (!Array.isArray(rowData)) {
                  setCurrentID(rowData.ID)
                  setIsPublished(rowData.Status === 'Published')
                  scrollToTop()
                }
              }
            },
            {
              icon: () => <DeleteIcon spacing={4} color="secondary" fontSize="small" />,
              tooltip: 'Remove',
              onClick: (_event, rowData: PrivacyConfiguration | PrivacyConfiguration[]) => {
                if (!Array.isArray(rowData)) {
                  if (rowData.ID === currentID) setCurrentID('')
                  setDeleteID(rowData.ID)
                }
              }
            }
          ]}
          components={{
            Pagination: props => {
              return (
                <TablePagination
                  {...props}
                  count={data?.getPagingPrivacyConfigurations.pagination?.total || 0}
                  rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                  rowsPerPage={currentPageSize}
                  page={currentPage - 1}
                  onChangePage={(e, page) => setCurrentPage(page + 1)}
                  onChangeRowsPerPage={e => onChangeRowsPerPage(e, props)}
                />
              )
            }
          }}
        />
      </Grid>
      <ConfirmDialog
        buttonText="Delete"
        handleClose={onCloseDeletePopup}
        open={!!deleteID}
        onConfirm={onDelete}
        title="Delete Privacy Configuration"
        description="Are you sure to delete this privacy notice?"
      />
      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setAutoHideFeedback(null)}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />
      {isShowHistory && <UserPrivacyHistoriesDialog onClose={() => setIsShowHistory(false)} open={isShowHistory} />}
    </>
  )
}
