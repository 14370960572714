import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import {
  CreateCognitoUserMutation,
  CreateCognitoUserMutationVariables,
  DeleteCognitoUserMutation,
  DeleteCognitoUserMutationVariables,
  EnablePersonByPersonIdMutation,
  EnablePersonByPersonIdMutationVariables,
  GetCognitoUsersQuery,
  GetCognitoUsersQueryVariables,
  SendEmailInviteAppMutation,
  SendEmailInviteAppMutationVariables
} from 'generated/graphql'
import {
  CREATE_COGNITO_USER,
  DELETE_COGNITO_USER,
  ENABLE_PERSON_BY_PERSON_ID,
  GET_COGNITO_USERS,
  SEND_EMAIL_INVITE
} from 'graphql/common'
import React, { FC, useMemo } from 'react'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import { ConfirmDialog } from '../../../components/common/ConfirmDialog'
import { ExternalPerson } from '../../../generated/graphql'
import { ResetPasswordModal } from '../ResetPassword'
import { useSnackbarContext } from 'hooks/useSnackbarContext'

interface UserModalProps {
  onCloseModal: () => void
  selectedItem?: ExternalPerson | null
  onRefetch?: () => void
  skipQueryCognito?: boolean
  setAutoHideFeedback: (value: Feedback) => void
  handleSuccessReEnableAccount: () => void
}

export const UserModal: FC<UserModalProps> = ({
  onCloseModal,
  selectedItem,
  onRefetch,
  skipQueryCognito,
  setAutoHideFeedback,
  handleSuccessReEnableAccount
}) => {
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext()

  const [createCognitoUser] = useMutation<CreateCognitoUserMutation, CreateCognitoUserMutationVariables>(
    CREATE_COGNITO_USER
  )

  const [deleteCognitoUser] = useMutation<DeleteCognitoUserMutation, DeleteCognitoUserMutationVariables>(
    DELETE_COGNITO_USER
  )

  const [sendEmailInviteApp] = useMutation<SendEmailInviteAppMutation, SendEmailInviteAppMutationVariables>(
    SEND_EMAIL_INVITE
  )

  const { data, refetch: refetchCognitoList } = useQuery<GetCognitoUsersQuery, GetCognitoUsersQueryVariables>(
    GET_COGNITO_USERS,
    {
      variables: {
        filter: {
          Emails: [selectedItem?.person?.Email || '']
        }
      },
      skip: skipQueryCognito,
      fetchPolicy: 'cache-and-network'
    }
  )

  const [enablePersonByPersonIdMutation, { loading: isLoadingEnablePersonByPersonId }] = useMutation<
    EnablePersonByPersonIdMutation,
    EnablePersonByPersonIdMutationVariables
  >(ENABLE_PERSON_BY_PERSON_ID, {
    onCompleted() {
      setSuccessMessage('Re-enabled user successfully')
      handleSuccessReEnableAccount()
    },
    onError: error => setErrorMessage(error?.message)
  })

  const { cognitoUser, isActiveInCognito } = useMemo(() => {
    const cognitoUser = data?.getCognitoUsers?.find(
      item => item.Email.toLowerCase() === selectedItem?.person?.Email?.toLowerCase()
    )

    return {
      cognitoUser,
      isActiveInCognito: cognitoUser?.Enabled
    }
  }, [data, selectedItem])

  const [openDeleteUserConfirm, setOpenDeleteUserConfirm] = React.useState(false)
  const [openResetPassword, setOpenResetPassword] = React.useState(false)
  const [openCreateUser, setOpenCreateUser] = React.useState(false)
  const [isRequestSendInvite, setIsRequestSendInvite] = React.useState(false)

  const onHandleDeleteUserConfirm = () => {
    if (openDeleteUserConfirm) {
      deleteCognitoUser({
        variables: {
          UserEmail: selectedItem?.person?.Email || ''
        }
      })
        .then(({ data }) => {
          if (data?.deleteCognitoUser) {
            onRefetch && onRefetch()
            refetchCognitoList()
            onCloseModal()
            setAutoHideFeedback(
              getSuccessFeedback(
                `"${selectedItem?.person?.Firstname} ${selectedItem?.person?.Lastname}" has been deleted from cognito successfully`
              )
            )
          }
        })
        .catch(error => {
          setAutoHideFeedback(getErrorFeedback(error.message))
        })
    }
  }

  const onHandleCreateUser = () => {
    if (openCreateUser) {
      if (!selectedItem?.person?.Email) {
        setAutoHideFeedback(getErrorFeedback('User is missing email. Cannot complete account actions for such users'))
      } else {
        createCognitoUser({
          variables: {
            input: {
              UserName: selectedItem?.person?.Email || '',
              Password: 'password'
            }
          }
        })
          .then(({ data }) => {
            if (data?.createCognitoUser) {
              onRefetch && onRefetch()
              refetchCognitoList()
              onCloseModal()
              setAutoHideFeedback(getSuccessFeedback(`A new user has been added to cognito successfully`))
            }
          })
          .catch(error => {
            setAutoHideFeedback(getErrorFeedback(error.message))
          })
      }
    }
  }

  const userType = useMemo(() => {
    if (selectedItem?.person?.internalPerson?.IsADFS) {
      return 'SSO Account'
    } else {
      if (cognitoUser) {
        return cognitoUser.UserStatus === 'EXTERNAL_PROVIDER' ? 'SSO Account' : 'Custom Account'
      } else {
        return 'No Account'
      }
    }
  }, [cognitoUser, selectedItem])

  const sendEmailInvite = () => {
    if (selectedItem && !isRequestSendInvite) {
      setIsRequestSendInvite(true)
      sendEmailInviteApp({
        variables: {
          input: {
            AssignedTo: selectedItem?.person?.ID
          }
        }
      })
        .then(({ data }) => {
          if (data?.sendEmailInviteApp) {
            onRefetch && onRefetch()
            refetchCognitoList()
            onCloseModal()
            setIsRequestSendInvite(false)
            setAutoHideFeedback(getSuccessFeedback(data.sendEmailInviteApp))
          }
        })
        .catch(error => {
          setIsRequestSendInvite(false)
          setAutoHideFeedback(getErrorFeedback(error.message))
        })
    }
  }

  const enablePersonByPersonId = () => {
    enablePersonByPersonIdMutation({
      variables: {
        personID: selectedItem?.person?.ID ?? ''
      }
    })
  }

  return (
    <>
      <Dialog onClose={onCloseModal} open={!!selectedItem} maxWidth={'md'} fullWidth>
        <DialogContent>
          <Typography
            id="max-width-dialog-title"
            variant="h5"
            className="pb-8"
          >{`${selectedItem?.person?.Firstname} ${selectedItem?.person?.Lastname}`}</Typography>

          <Grid container spacing={4}>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  id="email"
                  label="Email"
                  name="Email"
                  value={selectedItem?.person?.Email}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  id="mobile"
                  label="Mobile"
                  name="Mobile"
                  value={selectedItem?.person?.Phone ? selectedItem?.person?.Phone : 'No phone available'}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  id="supplier"
                  label="Supplier"
                  name="Supplier"
                  value={selectedItem?.supplier?.Name}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={6}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  id="type"
                  label="Linksite Account"
                  name="type"
                  value={userType}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item className="w-full flex justify-end gap-3">
              <Button
                disabled={isRequestSendInvite || !isActiveInCognito || !selectedItem?.person?.IsActive}
                variant="contained"
                onClick={() => sendEmailInvite()}
              >
                Send LinkedSite Mobile Download Invite
              </Button>
              <Button variant="contained" onClick={onCloseModal}>
                Close
              </Button>
              {(userType === 'No Account' || (userType === 'Custom Account' && !isActiveInCognito)) && (
                <Button
                  disabled={!isActiveInCognito && !selectedItem?.person?.IsActive}
                  color="secondary"
                  variant="contained"
                  onClick={() => setOpenCreateUser(true)}
                >
                  Create Account
                </Button>
              )}
              {!selectedItem?.person?.IsActive && (
                <Button
                  disabled={isLoadingEnablePersonByPersonId}
                  color="secondary"
                  variant="contained"
                  onClick={enablePersonByPersonId}
                >
                  Re-enable account
                  {isLoadingEnablePersonByPersonId && <CircularProgress size={12} className="m-1" />}
                </Button>
              )}
              {userType === 'Custom Account' && isActiveInCognito && (
                <>
                  <Button color="secondary" variant="contained" onClick={() => setOpenDeleteUserConfirm(true)}>
                    Delete Account
                  </Button>
                  <Button color="secondary" variant="contained" onClick={() => setOpenResetPassword(true)}>
                    Reset Password
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <ConfirmDialog
          buttonText="Delete"
          handleClose={() => setOpenDeleteUserConfirm(false)}
          open={openDeleteUserConfirm}
          onConfirm={onHandleDeleteUserConfirm}
          title="Delete account"
          description="Are you sure to delete this user account in AWS Cognito Identity Service ? User will be disabled and no longer get access to the system."
        />
        <ConfirmDialog
          buttonText="Create"
          handleClose={() => setOpenCreateUser(false)}
          open={openCreateUser}
          onConfirm={onHandleCreateUser}
          title="Create account"
          description="Are you sure to create this user account in AWS Cognito Identity Service ? User will receive an email for his/her temporary password and can get access to the system."
        />
        <ResetPasswordModal
          setAutoHideFeedback={setAutoHideFeedback}
          isOpen={openResetPassword}
          onCloseResetPasswordModal={() => setOpenResetPassword(false)}
          selectedItem={selectedItem?.person}
        />
      </Dialog>
    </>
  )
}
