import { useMutation } from '@apollo/react-hooks'
import { Button, Grid } from '@material-ui/core'
import { DocumentNode } from 'apollo-boost'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { SelectorObjectField } from 'components/common/SelectorObjectField'
import { DeleteIcon } from 'components/common/icons'
import { Form, FormikProvider, useFormik } from 'formik'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import React from 'react'
import * as yup from 'yup'
import { RoleSelector } from '../../../components/common/RolesSelector'
import { Table } from '../../../components/common/Table'
import {
  AssignRolesForPersonMutation,
  AssignRolesForPersonMutationVariables,
  FindManyAdminRolesQuery,
  Person,
  Role
} from '../../../generated/graphql'
import { globalColumns } from './columns'
import { ASSIGN_ROLES_FOR_PERSON } from './graphql'

interface FormProps {
  role: Role | null
}
interface GlobalRolesProps {
  selectedRoleItem?: Person | null
  refetchQuery: DocumentNode
  refetchOption: any
  isExternalRole?: boolean
}

export const GlobalRoles = ({ selectedRoleItem, isExternalRole, refetchQuery, refetchOption }: GlobalRolesProps) => {
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext()

  const [deletedItem, setDeletedItem] = React.useState<Role | null>(null)

  const [assignRolesForPerson] = useMutation<AssignRolesForPersonMutation, AssignRolesForPersonMutationVariables>(
    ASSIGN_ROLES_FOR_PERSON,
    {
      refetchQueries: [{ query: refetchQuery, ...refetchOption }]
    }
  )

  const extractEntities = (data: FindManyAdminRolesQuery): Role[] => {
    return data.findManyAdminRoles.items
      .filter(e => !selectedRoleItem?.roles?.find(role => role?.ID === e.ID))
      .map(e => e as Role)
  }

  const handleDelete = (roleToDelete: Role) => {
    setDeletedItem(roleToDelete)
  }

  const onDelete = () => {
    assignRolesForPerson({
      variables: {
        input: {
          PersonID: selectedRoleItem?.ID || '',
          RoleIDs: selectedRoleItem?.roles?.filter(item => item.ID !== deletedItem?.ID).map(e => e.ID) || []
        }
      }
    })
      .then(({ data }) => {
        if (data?.assignRolesForPerson) {
          setDeletedItem(null)
          setSuccessMessage('Global roles has been updated successfully')
        }
      })
      .catch(error => {
        setErrorMessage(error.message)
      })
  }

  const initialValues: FormProps = {
    role: null
  }
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      role: yup
        .object()
        .label('Role')
        .nullable()
        .required()
    }),
    onSubmit: ({ role }, { resetForm }) => {
      if (!role) return
      const roles = selectedRoleItem?.roles ? [...selectedRoleItem?.roles, role] : [role]
      assignRolesForPerson({
        variables: {
          input: {
            PersonID: selectedRoleItem?.ID || '',
            RoleIDs: roles.map(item => item?.ID)
          }
        }
      })
        .then(({ data }) => {
          if (data?.assignRolesForPerson) {
            setSuccessMessage('Global roles has been updated successfully')
            resetForm()
          }
        })
        .catch(error => {
          setErrorMessage(error.message)
        })
    }
  })

  const { isSubmitting } = formik

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <Grid container justifyContent="space-between" direction="row" spacing={2}>
            <Grid item xs={8}>
              <SelectorObjectField
                name="role"
                isExternalRole={isExternalRole}
                extractEntities={extractEntities}
                roleType="GlobalRole"
                selector={RoleSelector}
              />
            </Grid>
            <Grid item xs={4}>
              <Button disabled={isSubmitting} variant="contained" color="secondary" type="submit">
                Add Role
              </Button>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <br />
      <Grid>
        <Table<Role>
          columns={globalColumns}
          data={selectedRoleItem?.roles || []}
          actions={[
            {
              icon: () => <DeleteIcon style={{ marginRight: 10 }} spacing={4} color="secondary" fontSize="small" />,
              tooltip: 'Remove',
              onClick: (_event, rowData: Role | Role[]) => {
                if (!Array.isArray(rowData)) handleDelete(rowData)
              }
            }
          ]}
          option={{
            toolbar: false
          }}
        />
      </Grid>
      <ConfirmDialog
        buttonText="Delete"
        handleClose={() => setDeletedItem(null)}
        open={!!deletedItem}
        onConfirm={onDelete}
        title="Delete Role"
        description="Are you sure to delete this role from Global roles?"
      />
    </>
  )
}
