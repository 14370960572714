import { Dialog, DialogContent, IconButton, makeStyles } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { replaceToEmbedLink } from 'pages/Publication/cms/utils'
import React, { useState } from 'react'
import InnerHTML from './InnerHTML'
import { CancelIcon } from './icons'

type ListImageProps = {
  isView?: boolean
  imageData: string[]
  onRemoveImage?: (index: number) => void
  isShowVideo?: boolean
}

const useStyles = makeStyles({
  imageWrapper: {
    height: 100,
    width: 100,
    borderRadius: 10,
    marginLeft: 10,
    position: 'relative',
    marginTop: '0.5rem',
    '& .overlay': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 5,
      background: 'rgba(0,0,0,0.3)',
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white'
    },
    '& img': {
      height: 100,
      width: 100,
      objectFit: 'cover',
      borderRadius: 5
    },
    '& iframe': {
      height: 100,
      width: 100,
      objectFit: 'cover',
      borderRadius: 5
    },
    '&:hover': {
      '& .overlay': {
        display: 'flex'
      }
    }
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 2,
    zIndex: 100,
    color: 'red',
    cursor: 'pointer'
  },
  hide: {
    display: 'none'
  },
  image: {
    width: '90%',
    height: 'auto',
    margin: '5%'
  },
  iframe: {
    width: '90%',
    height: 300,
    margin: '5%'
  },
  icon: {
    color: 'white'
  },
  close: {
    position: 'absolute',
    right: 5,
    top: 5,
    width: 0,
    height: 0,
    '& .MuiSvgIcon-root': {
      zIndex: 9
    }
  }
})

export const ListImage = ({ isView, imageData, onRemoveImage, isShowVideo = false }: ListImageProps) => {
  const classes = useStyles()
  const [imageShow, setImageShow] = useState<string>('')

  return (
    <>
      <div className="flex flex-wrap items-end">
        {imageData.map((src: string, index: number) => {
          return (
            <div key={index} className={classes.imageWrapper}>
              {!!onRemoveImage && (
                <span
                  onClick={() => {
                    onRemoveImage && onRemoveImage(index)
                  }}
                  className={isView ? classes.hide : classes.deleteButton}
                >
                  &times;
                </span>
              )}
              {isShowVideo ? (
                src?.includes('<iframe') ? (
                  <InnerHTML html={src} />
                ) : (
                  <iframe title="myFrame" src={replaceToEmbedLink(src)} frameBorder={0} />
                )
              ) : (
                src?.includes('https') && <img src={src} alt="" />
              )}
              <div className="overlay">
                <IconButton onClick={() => setImageShow(src)}>
                  <VisibilityIcon className={classes.icon} />
                </IconButton>
              </div>
            </div>
          )
        })}
      </div>

      {!!imageShow && (
        <Dialog maxWidth="md" fullWidth onClose={() => setImageShow('')} open={!!imageShow}>
          <DialogContent>
            {imageShow?.includes('<iframe') ? (
              <InnerHTML className="flex justify-center" html={imageShow} />
            ) : isShowVideo ? (
              <iframe
                title="myFrame"
                className={classes.iframe}
                src={replaceToEmbedLink(imageShow)}
                frameBorder={0}
                allowFullScreen
              />
            ) : (
              <img className={classes.image} src={imageShow} alt="" />
            )}
            <IconButton className={classes.close} onClick={() => setImageShow('')}>
              <CancelIcon />
            </IconButton>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
