import React, { FC, ReactNode } from 'react'
import { Typography, Box, makeStyles } from '@material-ui/core'
import { tabPanelId, tabIndex } from '../../../utils/a11y'

interface UserTabPanelProps {
  children?: ReactNode
  index: number
  noHorizontalSpacing?: boolean
  value: number
}

const useStyles = makeStyles({
  box: {
    paddingLeft: 0,
    paddingRight: 0
  }
})

export const UserTabPanel: FC<UserTabPanelProps> = ({ children, index, noHorizontalSpacing, value }) => {
  const classes = useStyles()
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={tabPanelId(index)}
      aria-labelledby={tabIndex(index)}
    >
      {value === index && (
        <Box className={noHorizontalSpacing ? classes.box : ''} p={2}>
          {children}
        </Box>
      )}
    </Typography>
  )
}
