import { REQUIRED } from 'utils/Constants'
import { getTextInHTMLString } from 'utils/tools'
import * as yup from 'yup'
import { HeaderBackgroundOptions, PreviewTemplateValue } from '../types'

export const cmsHeaderContent = yup.object({
  title: yup
    .string()
    .nullable()
    .label('News Title')
    .required(),
  content: yup.array(
    yup.object({
      template: yup
        .number()
        .label('Layout')
        .required('Please select a layout to proceed'),
      textEditors: yup.array(
        yup.object().shape({
          description: yup
            .string()
            .nullable()
            .test('length', `Content ${REQUIRED}`, function(value) {
              return ![
                PreviewTemplateValue.Images,
                PreviewTemplateValue.OnlyVideo,
                PreviewTemplateValue.TwoColumns
              ].includes(this.parent?.template)
                ? getTextInHTMLString(value ?? '')?.trim()?.length > 0
                : true
            })
        })
      ),
      images: yup.array().test('required', 'Please upload at least one image', function(value) {
        return (this.parent?.headerBackgroundOption === HeaderBackgroundOptions.Image &&
          this.parent?.template === PreviewTemplateValue.Header) ||
          [
            PreviewTemplateValue.ImageAndText,
            PreviewTemplateValue.TextAndImage,
            PreviewTemplateValue.Images,
            PreviewTemplateValue.OnlyVideo
          ].includes(this.parent?.template)
          ? value?.length > 0
          : true
      })
    })
  )
})
