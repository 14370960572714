import React, { ChangeEvent, FocusEventHandler, useState, ReactNode } from 'react'
import { FormControl, TextField, TextFieldProps } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

export const NOAUTOFILL = 'off'
export const NEW_PASSWORD = 'new-password'

type noInputAutoComplete = 'new-password' | 'off'

export interface AutocompleteInputProps<T> {
  autoComplete?: noInputAutoComplete | undefined // 'new-password' disables autofill on the browser that uses heuristics from previous user forms
  blurHandler?: FocusEventHandler<HTMLDivElement>
  onFocus?: () => void
  changeHandler: (event: ChangeEvent<object>, value: T | null) => void
  className?: string
  clearOnEscape?: boolean
  disabled?: boolean
  error?: TextFieldProps['error']
  name?: TextFieldProps['name']
  freeSolo?: boolean
  getOptionDisabled?: (option: T) => boolean
  getOptionLabel: (option: T) => string
  helperText?: TextFieldProps['helperText']
  inputChangeHandler?: (event: ChangeEvent<NonNullable<any>>, newValue: string) => void
  label: string
  options: T[]
  required?: boolean
  value: T | null
  clearOnBlur?: boolean
  blurOnSelect?: boolean
  renderOption?: (params: any) => ReactNode
  disableClearable?: boolean
  loading?: boolean
  placeholder?: string
  getOptionSelected?: (option: T, value: T) => boolean
}

export const AutocompleteInput = <T,>({
  autoComplete,
  blurHandler,
  changeHandler,
  className,
  clearOnEscape,
  disabled,
  error,
  freeSolo,
  getOptionDisabled,
  getOptionLabel,
  helperText,
  inputChangeHandler,
  label,
  name,
  options,
  required,
  value,
  renderOption,
  blurOnSelect = false,
  clearOnBlur = false,
  disableClearable = false,
  onFocus,
  loading,
  placeholder,
  getOptionSelected
}: AutocompleteInputProps<T>) => {
  const [searchValue, setSearchValue] = useState(value && freeSolo ? getOptionLabel(value) : '')
  const _inputChangeHandler = (event: ChangeEvent<NonNullable<any>>, newValue: string) => {
    inputChangeHandler && inputChangeHandler(event, newValue)
    setSearchValue(newValue)
  }

  const _blurHandler = (event: React.FocusEvent<HTMLDivElement>) => {
    clearOnBlur && setSearchValue('')
    blurHandler && blurHandler(event)
  }
  return (
    <FormControl fullWidth variant="outlined" className={className}>
      <Autocomplete
        clearOnEscape={clearOnEscape}
        blurOnSelect={blurOnSelect}
        disabled={disabled}
        disableClearable={disableClearable}
        id={`${label}-select-outlined`}
        freeSolo={freeSolo}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        inputValue={searchValue}
        onBlur={_blurHandler}
        onChange={(e, value) => {
          typeof value !== 'string' && changeHandler(e, value)
        }}
        onInputChange={_inputChangeHandler}
        options={options}
        onFocus={onFocus}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            name={name}
            error={error}
            helperText={helperText}
            required={required}
            fullWidth
            label={label}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: autoComplete
            }}
            placeholder={placeholder}
          />
        )}
        style={{ width: 'auto' }}
        value={value}
        renderOption={renderOption}
      />
    </FormControl>
  )
}
