import React, { FC } from 'react'
import { WatchQueryFetchPolicy } from 'apollo-boost'
import { EntitySelector, EntitySelectorWrapperProps } from 'components/common/EntitySelector'
import { Company, GetAllCompaniesQuery, GetAllCompaniesQueryVariables } from 'generated/graphql'
import { GET_ALL_COMPANY } from '../graphql'

export type CompanyPayGroupSelectorProps = EntitySelectorWrapperProps<
  Company,
  GetAllCompaniesQuery,
  GetAllCompaniesQueryVariables
> & {
  extractEntities?: (data: GetAllCompaniesQuery) => Company[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  refetch?: boolean
}

const labelEntity = (value: Company) => (value.IsActive ? `${value.Name}` : `Inactive - ${value.Name}`)

const extractEntities = (data: GetAllCompaniesQuery) => data.getAllCompanies

export const CompanyEBASelector: FC<CompanyPayGroupSelectorProps> = ({ label = 'Select Company', ...props }) => {
  const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  return (
    <EntitySelector<Company, GetAllCompaniesQuery, GetAllCompaniesQueryVariables>
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={GET_ALL_COMPANY}
      queryHookOptions={{
        fetchPolicy: fetchPolicy
      }}
      {...props}
    />
  )
}
