import React, { useMemo } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import editIcon from 'images/icons/edit.svg'
import unPublishIcon from 'images/icons/eye.svg'
import deleteIcon from 'images/icons/trash.svg'
import publishIcon from 'images/icons/open-eye.svg'
import { ActionsType, OptionsType } from 'pages/Publication/types'
import { useReleaseNotesCommonStyles } from 'pages/Publication/styles'

interface ActionsMenuProps {
  onGetOption: (selectedOption: string) => void
  isPublish?: boolean
}

export default function ActionsMenu({ onGetOption, isPublish = false }: ActionsMenuProps) {
  const classes = useReleaseNotesCommonStyles({})
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const options: OptionsType[] = useMemo(
    () => [
      {
        title: ActionsType.Edit,
        value: ActionsType.Edit,
        icon: editIcon
      },
      {
        title: isPublish ? ActionsType.Unpublish : ActionsType.Publish,
        value: isPublish ? ActionsType.UnPublish : ActionsType.Publish,
        icon: isPublish ? unPublishIcon : publishIcon
      },
      {
        title: ActionsType.Delete,
        value: ActionsType.Delete,
        icon: deleteIcon
      }
    ],
    [isPublish]
  )

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="release-notes-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu id="release-notes-actions-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem
            key={String(index)}
            onClick={() => {
              onGetOption(option.value)
              handleClose()
            }}
            className={classes.menuItem}
          >
            {option.title}
            <img src={`${option.icon}`} alt={option.title} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
