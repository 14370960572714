import { useMutation, useQuery } from '@apollo/react-hooks'
import { Box, Button, CircularProgress, Grid, TablePagination, TextField } from '@material-ui/core'
import classNames from 'classnames'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import {
  DeleteSVGIcon,
  EditSVGIcon,
  FileCopyRoundedIcon,
  PublishedRoundedIcon,
  SearchIcon
} from 'components/common/icons'
import { Form, FormikProvider, useFormik } from 'formik'
import {
  CopyNewsArticleMutation,
  CopyNewsArticleMutationVariables,
  DeleteNewsArticleMutation,
  DeleteNewsArticleMutationVariables,
  GetPagingNewsArticlesQuery,
  GetPagingNewsArticlesQueryVariables,
  NewsArticle
} from 'generated/graphql'
import { useCopyFromClipboard } from 'hooks/useCopyFromClipboard'
import usePagination from 'hooks/usePagination'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { isArray } from 'lodash'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Table } from '../../../components/common/Table'
import CMSCreator from './CMSCreator'
import { columns } from './components/columns'
import { COPY_NEWS_ARTICLE, DELETE_NEWS_ARTICLE, GET_PAGING_NEWS_ARTICLES } from './graphql'
import { useCMSList } from './styles'

type CMSListProps = {
  setNewsArticle: Dispatch<SetStateAction<NewsArticle | null>>
  setCurrentTab: Dispatch<SetStateAction<number>>
}

export default function CMSList({ setCurrentTab, setNewsArticle }: CMSListProps) {
  const classes = useCMSList()

  const [isShowCMSPage, setIsShowCMSPage] = useState(false)
  const [newsArticleID, setNewsArticleID] = useState<string | null>(null)
  const [openDeletePopup, setOpenDeletePopup] = useState(false)

  const { currentPageSize, onChangeRowsPerPage, currentPage, onChangePage, filter, onFilter } = usePagination(
    'cmsTable'
  )
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext()
  const copyFromClipboard = useCopyFromClipboard()

  const { loading, data, refetch } = useQuery<GetPagingNewsArticlesQuery, GetPagingNewsArticlesQueryVariables>(
    GET_PAGING_NEWS_ARTICLES,
    {
      variables: {
        search: filter.trim(),
        pagination: {
          pageNumber: currentPage,
          pageSize: currentPageSize
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  )

  useEffect(() => {
    !isShowCMSPage && setNewsArticleID(null)
  }, [isShowCMSPage])

  const [deleteNewsArticle, { loading: deleteNewsArticleLoading }] = useMutation<
    DeleteNewsArticleMutation,
    DeleteNewsArticleMutationVariables
  >(DELETE_NEWS_ARTICLE, {
    onCompleted({ deleteNewsArticle }) {
      setSuccessMessage(deleteNewsArticle)
      setNewsArticleID(null)
      refetch()
    },
    onError(error) {
      setErrorMessage(error.message)
    }
  })

  const [copyNewsArticle, { loading: copyNewsArticleLoading }] = useMutation<
    CopyNewsArticleMutation,
    CopyNewsArticleMutationVariables
  >(COPY_NEWS_ARTICLE, {
    onCompleted({ copyNewsArticle }) {
      setSuccessMessage(copyNewsArticle)
      setNewsArticleID(null)
      refetch()
    },
    onError(error) {
      setErrorMessage(error.message)
    }
  })

  const formik = useFormik({
    initialValues: {
      title: '',
      authorName: '',
      publishedDate: undefined,
      published: ''
    },
    onSubmit: () => {
      return
    }
  })

  const handleNewsDelete = () => {
    newsArticleID &&
      deleteNewsArticle({
        variables: {
          id: newsArticleID
        }
      })
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <Box className="flex justify-between mt-4">
            <TextField
              className="flex w-96 border-thin-rounded-input rounded-input"
              placeholder="Title"
              variant="outlined"
              InputProps={{
                startAdornment: <SearchIcon />
              }}
              onChange={e => {
                onFilter(e.target.value)
              }}
            />
            <Button size="large" color="secondary" variant="contained" onClick={() => setIsShowCMSPage(true)}>
              Create News
            </Button>
          </Box>
        </Form>
      </FormikProvider>
      <Grid className={classNames('mt-5', classes.table)}>
        <Table<NewsArticle>
          isLoading={loading}
          columns={columns({ copyFromClipboard })}
          data={data?.getPagingNewsArticles?.items || []}
          isShowCustomEmptyData={!data?.getPagingNewsArticles?.items?.length}
          emptyDescription="No news found"
          option={{
            pageSize: currentPageSize,
            search: false,
            selection: false,
            minBodyHeight: `calc(98vh - 27rem)`,
            maxBodyHeight: `calc(98vh - 27rem)`,
            toolbar: false,
            actionsCellStyle: {
              borderTopRightRadius: '1rem'
            },
            sorting: false,
            filtering: false
          }}
          actions={[
            row => ({
              icon: () =>
                row.ID === newsArticleID && copyNewsArticleLoading ? (
                  <CircularProgress className="mt-1" size={14} />
                ) : (
                  <FileCopyRoundedIcon fontSize="small" />
                ),
              onClick: (_, row) => {
                if (!isArray(row)) {
                  copyNewsArticle({ variables: { fromId: row.ID } })
                  setNewsArticleID(row.ID)
                }
              },
              disabled: row.ID === newsArticleID && copyNewsArticleLoading,
              position: 'row',
              tooltip: 'Copy'
            }),
            {
              icon: () => <EditSVGIcon />,
              onClick: (_, row) => {
                if (!isArray(row)) {
                  setIsShowCMSPage(true)
                  setNewsArticleID(row.ID)
                }
              },
              position: 'row',
              tooltip: 'Edit'
            },
            {
              icon: () => <PublishedRoundedIcon />,
              onClick: (_, row) => {
                if (!isArray(row)) {
                  setCurrentTab(0)
                  setNewsArticle(row)
                }
              },
              position: 'row',
              tooltip: 'Publish'
            },
            row => ({
              icon: () =>
                row.ID === newsArticleID && deleteNewsArticleLoading ? (
                  <CircularProgress className="mt-1" size={14} />
                ) : (
                  <DeleteSVGIcon />
                ),
              onClick: (_, row) => {
                if (!isArray(row)) {
                  setOpenDeletePopup(true)
                  setNewsArticleID(row.ID)
                }
              },
              disabled: row.ID === newsArticleID && deleteNewsArticleLoading,
              position: 'row',
              tooltip: 'Delete'
            })
          ]}
          components={{
            Pagination: props => {
              return (
                <TablePagination
                  {...props}
                  rowsPerPageOptions={[10, 20, 50]}
                  rowsPerPage={currentPageSize}
                  count={data?.getPagingNewsArticles?.pagination?.total || 0}
                  page={currentPage - 1}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={e => {
                    onChangeRowsPerPage(e, props)
                  }}
                />
              )
            }
          }}
        />
      </Grid>
      {isShowCMSPage && (
        <CMSCreator
          isShowCMSPage={isShowCMSPage}
          setIsShowCMSPage={setIsShowCMSPage}
          newsArticleID={newsArticleID}
          refetch={refetch}
        />
      )}
      {openDeletePopup && (
        <ConfirmDialog
          buttonText="Delete"
          handleClose={() => setOpenDeletePopup(false)}
          open={openDeletePopup}
          onConfirm={handleNewsDelete}
          title="Delete News"
          description="Are you sure to delete this news?"
        />
      )}
    </>
  )
}
