import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides'
import { createTheme, ThemeOptions, StyleRules } from '@material-ui/core'
import { blue, green, red } from '@material-ui/core/colors'
import { ToggleButtonClassKey, AutocompleteClassKey } from '@material-ui/lab'

const typography = {
  fontFamily: [
    'Bliss Pro',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(',')
}

export const createThemeWithPalleteType = (theme: ThemeOptions, type?: 'dark' | 'light' | undefined) =>
  createTheme({ ...theme, palette: { ...theme.palette, type } })

interface Overrides extends CoreOverrides {
  // Define additional lab components here as needed....
  MuiAutocomplete?: Partial<StyleRules<AutocompleteClassKey>>
  MuiToggleButton?: Partial<StyleRules<ToggleButtonClassKey>>
}

const overrides: Overrides = {
  MuiAutocomplete: {
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"]': {
        padding: '0.1875rem'
      }
    }
  },
  MuiButton: {
    contained: {
      boxShadow: 'none'
    },
    outlined: {
      padding: '0.53125rem' // reducing .59375rem padding by 1 pixel to cover for extra border,
    },
    text: {
      padding: '0.59375rem'
    },
    root: {
      padding: '0.59375rem', // mui original was 0.625rem,
      textTransform: 'none'
    }
  },
  MuiChip: {
    root: {
      borderRadius: '0.5rem'
    },
    deleteIconColorSecondary: {
      color: 'var(--secondary-color)',
      '&:hover': {
        color: 'var(--secondary-color)'
      }
    },
    deletableColorSecondary: {
      '&:focus': {
        backgroundColor: '#fcafb9'
      }
    },
    colorSecondary: {
      backgroundColor: '#fee0e4',
      color: 'var(--secondary-color)',
      fontSize: '1rem'
    }
  },
  MuiInputLabel: {
    outlined: {
      transform: 'translate(0.75rem, 0.875rem) scale(1)'
    }
  },
  MuiLink: {
    root: {
      color: 'var(--secondary-color)'
    }
  },
  MuiOutlinedInput: {
    input: {
      padding: '0.75rem'
    },
    adornedEnd: {
      paddingRight: 0
    },
    multiline: {
      // height: '226px'
    }
  },
  MuiTab: {
    root: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      '&$selected': {
        '&& span': {
          color: 'var(--secondary-color)'
        }
      }
    }
  },
  MuiTableCell: {
    root: {
      padding: '0.5rem',
      '&&.MuiTableCell-head': {
        borderBottomColor: 'var(--primary-color)',
        borderBottomWidth: '2px'
      }
    }
  },
  MuiTableSortLabel: {
    root: {
      fontWeight: 'bold'
    }
  },
  MuiToggleButton: {
    root: {
      borderColor: 'var(--primary-color)',
      color: 'var(--primary-color)',
      fontWeight: 'bold',
      height: '2.6875rem',
      padding: '0px 12px',
      '&$selected': {
        backgroundColor: 'var(--primary-color)',
        '&:hover': {
          backgroundColor: 'var(--color-grey--dark)'
        },
        '&& span': {
          color: 'var(--color-white)'
        }
      },
      '&$selected&$disabled': {
        backgroundColor: 'var(--color-grey)',
        '&& span': {
          color: 'var(--color-white)',
          opacity: '0.5'
        }
      },
      '&$disabled': {
        borderColor: 'var(--color-white)'
      }
    },
    label: {
      fontSize: '1rem',
      textTransform: 'none'
    }
  },
  MuiTypography: {
    h4: {
      fontWeight: 'bold'
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '1.2rem !important'
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 14
    }
  }
}

export const mainTheme: ThemeOptions = {
  overrides,
  palette: {
    primary: {
      main: '#262626'
    },
    secondary: {
      light: '#ea4d4d',
      main: '#cb0000'
    },
    error: {
      main: '#cb0000'
    }
  },
  typography
}

export const greenTheme = {
  palette: {
    primary: green,
    secondary: blue,
    error: red
  },
  typography
}
