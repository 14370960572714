import { EntitySelector, EntitySelectorWrapperProps } from 'components/common/EntitySelector'
import { PlatFormOptions } from 'components/common/PlatformSelector'
import { GetPlatformsQuery, GetPlatformsQueryVariables } from 'generated/graphql'
import { loader } from 'graphql.macro'
import React from 'react'

const GET_PLATFORMS = loader('../graphql/getPlatforms.graphql')

export type Props = EntitySelectorWrapperProps<PlatFormOptions, GetPlatformsQuery, GetPlatformsQueryVariables>

export const PlatformSelector = ({ label = 'Please select platform', ...props }: Props) => {
  return (
    <EntitySelector<PlatFormOptions, GetPlatformsQuery, GetPlatformsQueryVariables>
      {...props}
      label={label}
      extractEntities={data =>
        data?.getPlatforms?.map(item => ({
          ID: item,
          label: item
        })) ?? []
      }
      query={GET_PLATFORMS}
      queryHookOptions={{
        fetchPolicy: 'cache-and-network',
        skip: false
      }}
      labelEntity={item => item.label}
    />
  )
}
