import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { Grid, FormControl, TextField, Typography } from '@material-ui/core'
import { fieldToTextField } from 'formik-material-ui'

export const FormAwareGridLayoutAutocomplete = props => {
  const {
    breakpoints: { titleBreakpoints, autoCompleteBreakpoints },
    form: { setTouched, setFieldValue },
    callbacks: { changeCallback } = {},
    label,
    title,
    required
  } = props
  const { error, helperText, ...field } = fieldToTextField(props)
  const { name } = field
  return (
    <>
      {title && (
        <Grid item {...titleBreakpoints}>
          <FormControl>
            <Typography variant="h5">{title}</Typography>
          </FormControl>
        </Grid>
      )}
      <Grid item {...autoCompleteBreakpoints}>
        <Autocomplete
          {...props}
          {...field}
          onChange={(_, value) => {
            setFieldValue(name, value)
            if (changeCallback) changeCallback(value)
          }}
          onBlur={() => setTouched({ [name]: true })}
          filterSelectedOptions
          renderInput={props => (
            <TextField
              {...props}
              label={label}
              required={required}
              helperText={helperText}
              error={error}
              variant="outlined"
            />
          )}
        />
      </Grid>
    </>
  )
}

FormAwareGridLayoutAutocomplete.propTypes = {
  breakpoints: PropTypes.shape({
    titleBreakpoints: PropTypes.object,
    autoCompleteBreakpoints: PropTypes.object
  }),
  callbacks: PropTypes.shape({
    changeCallback: PropTypes.func
  }),
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  required: PropTypes.bool,
  form: PropTypes.shape({
    setTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired
  })
}
