import classNames from 'classnames'
import { useGlobalStyles } from 'hooks/useGlobalStyles'
import React from 'react'
import reactHtmlParser from 'react-html-parser'

interface InnerHTMLProps {
  html?: string
  className?: string
}

export default function InnerHTML({ html = '', className }: InnerHTMLProps) {
  const classes = useGlobalStyles()

  return <div className={classNames(classes.innerHTML, className)}>{reactHtmlParser(html)}</div>
}
