import { makeStyles, Tabs, Tab } from '@material-ui/core'
import React, { FC, ChangeEvent, useState } from 'react'
import { a11yProps } from '../../utils/a11y'
import { StandardDivider } from '../../components/common/StandardDivider'
import { MyProfileTabPanel } from '../../components/common/my-profile/MyProfileTabPanel'
import { Details } from './details/Details'

const useStyles = makeStyles(theme => ({
  container: {},
  label: {
    color: theme.palette.text.hint
  }
}))

export const MyProfile: FC = () => {
  const [currentTab, setCurrentTab] = useState<number>(0)
  const classes = useStyles()
  const handleChange = (_event: ChangeEvent<NonNullable<any>>, newTab: number) => {
    setCurrentTab(newTab)
  }

  return (
    <div className={classes.container}>
      <Tabs value={currentTab || 0} onChange={handleChange} aria-label="projects tabs">
        <Tab label="My Details" {...a11yProps(0)} />
      </Tabs>
      <StandardDivider />
      <MyProfileTabPanel value={currentTab} index={0}>
        <Details />
      </MyProfileTabPanel>
    </div>
  )
}
