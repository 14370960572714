import React from 'react'
import { ToggleGroup, ToggleGroupProps } from './ToggleGroup'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { GridSize, Grid, FormControl, Typography } from '@material-ui/core'
export interface GridLayoutToggleProps<T> extends ToggleGroupProps<T> {
  title?: string
  titleBreakpoints?: Partial<Record<Breakpoint, boolean | GridSize>>
  toggleBreakpoints?: Partial<Record<Breakpoint, boolean | GridSize>>
}
export const GridLayoutToggle = <T,>({
  center,
  disabled,
  left,
  id,
  right,
  toggleBreakpoints,
  title,
  titleBreakpoints,
  value,
  setValue,
  onChange
}: GridLayoutToggleProps<T>) => (
  <>
    {title ? (
      <Grid item {...titleBreakpoints}>
        <FormControl>
          <Typography variant="h5">{title}</Typography>
        </FormControl>
      </Grid>
    ) : null}
    <Grid item {...toggleBreakpoints}>
      <ToggleGroup<T>
        center={center}
        disabled={disabled}
        id={id}
        left={left}
        right={right}
        value={value}
        setValue={setValue}
        onChange={onChange}
      />
    </Grid>
  </>
)
