import { Column } from 'material-table'
import { Role, UserCompanyAccess, UserProjectAccess } from '../../../generated/graphql'

export const globalColumns: Column<Role>[] = [
  {
    field: 'Name',
    title: 'Role Name'
  }
]

export const companyColumns: Column<UserCompanyAccess>[] = [
  {
    field: 'company.Name',
    title: 'Company Name'
  },
  {
    field: 'roleCompany.Name',
    title: 'Role'
  }
]

export const projectColumns: Column<UserProjectAccess>[] = [
  {
    field: 'project.ProjectNo',
    title: 'Job No'
  },
  {
    field: 'project.Name',
    title: 'Job Name'
  },
  {
    field: 'role.Name',
    title: 'Role'
  }
]
