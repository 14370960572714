import React, { ElementType } from 'react'
import { InputNumberFormat } from 'components/common/InputNumberFormat'

import { GridLayoutInput, GridLayoutInputProps } from './GridLayoutInput'
import { InputBaseComponentProps } from '@material-ui/core'

interface GridLayoutRateInputProps extends GridLayoutInputProps {
  decimalScale?: number
  thousandSeparator?: string | boolean
  prefix?: string
}
export const GridLayoutInputNumber = ({
  label = 'Rate',
  textFieldBreakpoints,
  titleBreakpoints,
  prefix = '',
  decimalScale,
  inputProps,
  name,
  thousandSeparator,
  ...props
}: GridLayoutRateInputProps) => (
  <GridLayoutInput
    {...props}
    name={name}
    label={label}
    textFieldBreakpoints={textFieldBreakpoints ? textFieldBreakpoints : { xs: 2 }}
    titleBreakpoints={titleBreakpoints ? titleBreakpoints : { xs: 1 }}
    InputProps={{
      inputComponent: InputNumberFormat as ElementType<InputBaseComponentProps>
    }}
    inputProps={{
      decimalScale,
      thousandSeparator,
      prefix,
      ...inputProps
    }}
  />
)
