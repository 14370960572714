import { loader } from 'graphql.macro'

export const GET_ALL_PROJECTS = loader('./getAllProjects.graphql')

export const CREATE_COGNITO_USER = loader('./createCognitoUser.graphql')
export const DISABLE_COGNITO_USER = loader('./disableCognitoUser.graphql')
export const ENABLE_COGNITO_USER = loader('./enableCognitoUser.graphql')
export const DELETE_COGNITO_USER = loader('./deleteCognitoUser.graphql')
export const GET_COGNITO_USERS = loader('./getCognitoUsers.graphql')
export const SEND_EMAIL_INVITE = loader('./sendEmailInviteApp.graphql')
export const ENABLE_COGNITO_USER_MFA = loader('./enableCognitoUserMFA.graphql')
export const DISABLE_COGNITO_USER_MFA = loader('./disableCognitoUserMFA.graphql')
export const GET_PAGING_PERSONS = loader('./getPagingPerson.graphql')
export const RESET_COGNITO_USER_PASSWORD = loader('./resetCognitoUserPassword.graphql')
export const GET_PAGING_EXTERNAL_PEOPLES = loader('./getPagingExternalPeoples.graphql')
export const UPDATE_PERSON_INFO = loader('./updatePersonInfo.graphql')
export const GET_ADMIN_COUNTRIES = loader('./getAdminCountries.graphql')
export const UPSERT_SYSTEM_ANNOUNCEMENT = loader('./upsertSystemAnnouncement.graphql')
export const GET_SYSTEM_ANNOUNCEMENTS = loader('./getSystemAnnouncements.graphql')
export const PERSON_INFO_QUERY = loader('./personInfo.graphql')
export const DELETE_PUBLICATION = loader('./removePublication.graphql')
export const GET_PAGING_PUBLICATIONS = loader('./getPagingPublications.graphql')
export const GET_AUDIT_TYPE = loader('./getAuditType.graphql')
export const GET_PAGING_EMPLOYEE_BENEFITS = loader('./getPagingEmployeeBenefits.graphql')
export const UPSERT_EMPLOYEE_BENEFIT = loader('./upsertEmployeeBenefit.graphql')
export const GET_EMPLOYEE_BENEFIT_CATEGORIES = loader('./getEmployeeBenefitCategories.graphql')
export const GET_PAYROLL_CLASSIFICATIONS = loader('./getPayrollClassifications.graphql')
export const IMPERSONATE_USER = loader('./impersonateUser.graphql')
export const GET_FILE = loader('./getFile.graphql')
export const GET_PAGING_WORKER_TYPES = loader('./getPagingWorkerTypes.graphql')
export const UPLOAD_FILE = loader('./uploadFile.graphql')
export const GET_BATCH_PROCESS = loader('./getBatchProcess.graphql')
export const DELETE_BATCH_PROCESS = loader('./deleteBatchProcess.graphql')
export const UPLOAD_IMAGE = loader('./uploadImages.graphql')
export const BULK_UPLOAD_IMAGES = loader('./bulkUploadImages.graphql')
export const ENABLE_PERSON_BY_PERSON_ID = loader('./enablePersonByPersonID.graphql')
