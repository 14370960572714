import React, { forwardRef, ReactNode, Ref } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  PropTypes
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface ConfirmDialogProps {
  handleClose: () => void
  onConfirm: () => void
  open: boolean
  description?: string
  title: string
  buttonText: string
  fullWidth?: boolean
  children?: ReactNode
  closeText?: string
  closeColor?: PropTypes.Color
}

export const ConfirmDialog = ({
  handleClose,
  onConfirm,
  open,
  title,
  description,
  buttonText,
  fullWidth,
  children,
  closeText = 'Cancel',
  closeColor = 'primary'
}: ConfirmDialogProps) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="delete-site-diary-dialog-title"
      aria-describedby="delete-site-diary-dialog-description"
      fullWidth={fullWidth}
    >
      <DialogTitle id="adelete-site-diary-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {description ? (
          <DialogContentText id="delete-site-diary-dialog-description">{description}</DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onConfirm()
            handleClose()
          }}
          aria-label="button-modal-confirm"
          color="secondary"
          variant="contained"
          className="rounded-md"
        >
          {buttonText}
        </Button>
        <Button onClick={handleClose} color={closeColor} variant="contained" className="rounded-md">
          {closeText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
