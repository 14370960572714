import React from 'react'
import { Selector } from './EntitySelector'
import { UploadImageCountCategories } from 'pages/Publication/cms/types'

export interface UploadImageCountValue {
  ID: UploadImageCountCategories
  label: UploadImageCountCategories
}

interface UploadImageCountProps {
  onChange: (newValue?: UploadImageCountValue | null) => void
  value?: string
  label?: string
}

export const UPLOAD_IMAGE_COUNT: UploadImageCountValue[] = [
  {
    ID: UploadImageCountCategories.GridOne,
    label: UploadImageCountCategories.GridOne
  },
  {
    ID: UploadImageCountCategories.GridTwo,
    label: UploadImageCountCategories.GridTwo
  },
  {
    ID: UploadImageCountCategories.GridThree,
    label: UploadImageCountCategories.GridThree
  },
  {
    ID: UploadImageCountCategories.GridFour,
    label: UploadImageCountCategories.GridFour
  },
  {
    ID: UploadImageCountCategories.GridSix,
    label: UploadImageCountCategories.GridSix
  },
  {
    ID: UploadImageCountCategories.GridTwelve,
    label: UploadImageCountCategories.GridTwelve
  }
]

export const UploadImageCountSelector = ({
  value,
  label = 'Number Images',
  onChange,
  ...props
}: UploadImageCountProps) => {
  return (
    <Selector<UploadImageCountValue>
      {...props}
      label={label}
      value={value}
      labelEntity={item => item.label}
      options={UPLOAD_IMAGE_COUNT}
      onChange={onChange}
    />
  )
}
