import { ApolloQueryResult } from 'apollo-boost'
import { BatchProcess, GetFileQuery, GetFileQueryVariables } from 'generated/graphql'
import { FileUrlProps } from 'hooks/useFileUrl'
import { Action } from 'material-table'
import React, { SetStateAction } from 'react'
import { handleDownloadFile } from 'utils/tools'
import { DeleteIcon, DownLoadIcon } from '../icons'

interface UploadActionProps {
  batchProcess?: BatchProcess | null
  setDeletedItem: (value: SetStateAction<string>) => void
  getFileUrl: ({
    query,
    variables
  }: FileUrlProps<GetFileQueryVariables>) => Promise<
    | ApolloQueryResult<GetFileQuery>
    | {
        data: {
          getFile: {
            Url: string
            FileName: string
          }
        }
      }
  >
}

export default function uploadActions({
  batchProcess,
  setDeletedItem,
  getFileUrl
}: UploadActionProps): Action<BatchProcess>[] {
  return [
    {
      icon: () => <DownLoadIcon />,
      tooltip: 'Download Template',
      onClick: (_, rowData) => {
        if (!Array.isArray(rowData)) {
          !!rowData?.FileUrl && handleDownloadFile(rowData?.FileUrl, getFileUrl)
        }
      },
      hidden: !batchProcess?.FileUrl
    },
    {
      icon: () => <DeleteIcon />,
      tooltip: 'Remove',
      onClick: (_, rowData) => {
        if (!Array.isArray(rowData)) {
          setDeletedItem(rowData.ID)
        }
      },
      hidden: !batchProcess?.FileUrl
    }
  ]
}
