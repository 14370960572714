import React, { ReactNode, useMemo, useState } from 'react'
import {
  Active,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { DragHandle, SortableItem, SortableOverlay } from './components'
import { Box } from '@material-ui/core'

interface BaseItem {
  id: UniqueIdentifier
}

interface Props<T extends BaseItem> {
  items: T[]
  onChange(items: T[], overIndex: number): void
  renderItem(item: T, index: number, startIndex?: number): ReactNode
}

export function SortableList<T extends BaseItem>({ items, onChange, renderItem }: Props<T>) {
  const [active, setActive] = useState<Active | null>(null)
  const [startIndex, setStartIndex] = useState(0)
  const activeItem = useMemo(() => items.find(item => item?.id === active?.id), [active, items])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active)
        const startFindIndex = items.findIndex(({ id }) => id === active.id)
        setStartIndex(startFindIndex)
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id)
          const overIndex = items.findIndex(({ id }) => id === over.id)

          onChange(arrayMove(items, activeIndex, overIndex), overIndex)
        }
        setActive(null)
      }}
      onDragCancel={() => {
        setActive(null)
      }}
    >
      <SortableContext items={items}>
        <ul className="flex flex-col p-0 gap-2 list-none" role="application">
          {items.map((item, index) => (
            <Box key={item?.id}>{renderItem(item, index, startIndex)}</Box>
          ))}
        </ul>
      </SortableContext>
      <SortableOverlay>{activeItem ? renderItem(activeItem, startIndex) : null}</SortableOverlay>
    </DndContext>
  )
}

SortableList.Item = SortableItem
SortableList.DragHandle = DragHandle
