import React, { FC, useState } from 'react'
import { Grid, styled, Button, Typography, Link, Breadcrumbs } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { useMutation } from '@apollo/react-hooks'
import { Formik } from 'formik'
import * as yup from 'yup'
import { S4 } from '../../../utils/Constants'
import {
  EmployeeBenefit,
  UpsertEmployeeBenefitMutation,
  UpsertEmployeeBenefitMutationVariables
} from '../../../generated/graphql'
import { GridLayoutInput } from '../../../components/common/GridLayoutInput'
import { AutoHideSnackBar } from '../../../components/common/AutoHideSnackBar'
import { Feedback, getSuccessFeedback, getErrorFeedback } from '../../../utils/feedback'
import { NavigateNextIcon } from '../../../components/common/icons'
import { UPSERT_EMPLOYEE_BENEFIT } from '../../../graphql/common/index'
import { SelectorField } from 'components/common/SelectorField'
import { EmployeeBenefitSelector } from 'components/common/EmployeeBenefitSelector'

const SubmitButton = styled(Button)({
  margin: 10,
  textAlign: 'center'
})

const REQUIRED = `is a required field`
const TITLE_MAX = 250

const employeeBenefitSchema = yup.object({
  EmployeeBenefitCategory: yup
    .string()
    .nullable()
    .required(`Type of Benefit ${REQUIRED}`),
  Title: yup
    .string()
    .nullable()
    .trim()
    .max(TITLE_MAX, `${TITLE_MAX} chars limit reached`)
    .required(`Title ${REQUIRED}`),
  URL: yup
    .string()
    .test('match', 'Invalid URL', function(url) {
      if (url) {
        const pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i' // fragment locator
        )
        return !!pattern.test(url)
      }
      return true
    })
    .required(`URL ${REQUIRED}`)
})

interface FormValues {
  Category: string
  Title: string
  URL: string
  IsActive: boolean
  KeepToTop: boolean
  EmployeeBenefitCategory: string
}

interface EmployeeBenefitFormProps {
  selectedItem: EmployeeBenefit | null
  onBack: () => void
  refetchData: () => void
}

export const EmployeeBenefitForm: FC<EmployeeBenefitFormProps> = ({ selectedItem, onBack, refetchData }) => {
  const [autoHideFeedback, setAutoHideFeedback] = useState<Feedback | null>(null)

  const initialValues: FormValues = {
    Category: selectedItem?.employeeBenefitCategory?.CategoryName ?? '',
    Title: selectedItem?.Title ?? '',
    URL: selectedItem?.URL ?? '',
    IsActive: selectedItem?.IsActive ?? true,
    KeepToTop: selectedItem?.KeepToTop ?? false,
    EmployeeBenefitCategory: selectedItem?.employeeBenefitCategory?.ID ?? ''
  }

  const [upsertEmployeeBenefit] = useMutation<UpsertEmployeeBenefitMutation, UpsertEmployeeBenefitMutationVariables>(
    UPSERT_EMPLOYEE_BENEFIT
  )

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
  }

  return (
    <>
      <Grid container direction="row">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" onClick={onBack}>
            <Typography variant="body2" color="primary">
              Employee Benefits
            </Typography>
          </Link>
          <Link color="inherit" href="/manage-comms/employee-benefit" onClick={handleClick}>
            <Typography variant="body2" style={{ fontWeight: 'bold' }} color="textPrimary">
              {`${selectedItem ? 'Update' : 'Create'} Employee Benefit`}
            </Typography>
          </Link>
        </Breadcrumbs>
      </Grid>

      <Formik
        initialValues={initialValues}
        validationSchema={employeeBenefitSchema}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          setSubmitting(true)
          await upsertEmployeeBenefit({
            variables: {
              input: {
                ID: selectedItem?.ID,
                Title: values.Title.trim(),
                URL: values.URL.trim(),
                IsActive: values.IsActive,
                KeepToTop: values.KeepToTop,
                EmployeeBenefitCategoryID: values?.EmployeeBenefitCategory
              }
            }
          })
            .then(({ data }) => {
              setAutoHideFeedback(
                getSuccessFeedback(`Employee Benefit "${data?.upsertEmployeeBenefit?.Title} updated successfully`)
              )
              resetForm()
              refetchData()
              onBack()
            })
            .catch(error => setAutoHideFeedback(getErrorFeedback(error.message)))
            .finally(() => setSubmitting(false))
        }}
      >
        {props => {
          const {
            values,
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
            handleSubmit,
            getFieldProps
          } = props
          return (
            <form style={{ width: '100%', marginTop: 15 }} onSubmit={handleSubmit}>
              <Grid container>
                <Grid container item xs={12} sm={6} justifyContent="space-between" direction="row" spacing={S4}>
                  {selectedItem && (
                    <Grid item xs={12}>
                      <SelectorField
                        disabled={selectedItem}
                        label="Type of Benefit*"
                        name="EmployeeBenefitCategory"
                        selector={EmployeeBenefitSelector}
                        error={!!errors.EmployeeBenefitCategory && !!touched.EmployeeBenefitCategory}
                        helperText={touched.EmployeeBenefitCategory ? errors.EmployeeBenefitCategory : null}
                      />
                    </Grid>
                  )}
                  <GridLayoutInput
                    fullWidth
                    id="title"
                    label="Enter Benefit Title*"
                    name="Title"
                    textFieldBreakpoints={{ xs: 12 }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.Title}
                    error={!!errors.Title && !!touched.Title}
                    helperText={errors.Title && touched.Title ? errors.Title : null}
                  />

                  <GridLayoutInput
                    fullWidth
                    label="Enter Benefit URL*"
                    id="url"
                    name="URL"
                    textFieldBreakpoints={{ xs: 12 }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.URL}
                    error={!!errors.URL && !!touched.URL}
                    helperText={errors.URL && touched.URL ? errors.URL : null}
                  />
                  <Grid container item alignItems="center" direction="row" justifyContent="space-between" spacing={2}>
                    {(() => {
                      if (selectedItem) {
                        return (
                          <Grid item>
                            <ToggleButtonGroup
                              exclusive
                              {...getFieldProps('IsActive')}
                              onChange={(e, value) => {
                                if (value !== null) {
                                  setFieldValue('IsActive', value)
                                }
                              }}
                              aria-label="type"
                              value={values?.IsActive}
                            >
                              <ToggleButton value={true} aria-label="display" key="true" id="true">
                                <Typography>Active</Typography>
                              </ToggleButton>
                              <ToggleButton value={false} aria-label="hide" key="false" id="false">
                                <Typography>Inactive</Typography>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                        )
                      } else {
                        return (
                          <Grid item xs={5}>
                            <SelectorField
                              label="Type of Benefit*"
                              name="EmployeeBenefitCategory"
                              selector={EmployeeBenefitSelector}
                              error={!!errors.EmployeeBenefitCategory && !!touched.EmployeeBenefitCategory}
                              helperText={touched.EmployeeBenefitCategory ? errors.EmployeeBenefitCategory : null}
                            />
                          </Grid>
                        )
                      }
                    })()}

                    <Grid item>
                      <b>Display at Top?&nbsp;&nbsp;&nbsp;</b>
                      <ToggleButtonGroup
                        exclusive
                        {...getFieldProps('KeepToTop')}
                        onChange={(e, value) => {
                          if (value !== null) {
                            setFieldValue('KeepToTop', value)
                          }
                        }}
                        aria-label="type"
                        value={values?.KeepToTop}
                      >
                        <ToggleButton value={true} aria-label="display" key="true" id="true">
                          <Typography>Yes</Typography>
                        </ToggleButton>
                        <ToggleButton value={false} aria-label="hide" key="false" id="false">
                          <Typography>No</Typography>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" direction="row" justifyContent="flex-end">
                    <SubmitButton size="large" type="submit" color="primary" variant="contained">
                      Save
                    </SubmitButton>
                    <SubmitButton type="button" size="large" onClick={onBack} color="secondary" variant="contained">
                      Cancel
                    </SubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setAutoHideFeedback(null)}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />
    </>
  )
}
