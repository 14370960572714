import React from 'react'
import { RefObject } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { Box, FormHelperText, Typography } from '@material-ui/core'
import classNames from 'classnames'

import 'react-quill/dist/quill.core.css'
import { getTextInHTMLString } from 'utils/tools'

interface ContentEditorProps {
  quillRef?: RefObject<ReactQuill>
  value?: string
  onChange?: (content: string) => void
  onKeyDown?: ((event: any) => void) | undefined
  onBlur?: () => void
  placeholder?: string
  theme?: string
  textLabel?: string
  className?: string
  containerClassName?: string
  contentValidation?: string
  isRequired?: boolean
}

const Font = Quill.import('formats/font')
const Size = Quill.import('attributors/style/size')

const fontSizes = [
  '16px',
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
  '26px',
  '28px',
  '36px',
  '40px',
  '48px',
  '72px'
]

const fontFamily = ['arial', 'calibri', 'comic-sans']

Font.whitelist = fontFamily
Quill.register(Font, true)

Size.whitelist = fontSizes
Quill.register(Size, true)

export default function ContentEditor({
  quillRef,
  value,
  onChange,
  onBlur,
  placeholder = 'Enter some rich text...',
  theme = 'snow',
  textLabel,
  className,
  containerClassName,
  onKeyDown,
  contentValidation,
  isRequired = false
}: ContentEditorProps) {
  const quillSettings = {
    toolbar: {
      container: [
        [{ font: fontFamily }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ size: fontSizes }],
        [
          {
            color: [
              '#000000',
              '#e60000',
              '#ff9900',
              '#ffff00',
              '#008a00',
              '#0066cc',
              '#9933ff',
              '#ffffff',
              '#facccc',
              '#ffebcc',
              '#ffffcc',
              '#cce8cc',
              '#cce0f5',
              '#ebd6ff',
              '#bbbbbb',
              '#f06666',
              '#ffc266',
              '#ffff66',
              '#66b966',
              '#66a3e0',
              '#c285ff',
              '#888888',
              '#a10000',
              '#b26b00',
              '#b2b200',
              '#006100',
              '#0047b2',
              '#6b24b2',
              '#444444',
              '#5c0000',
              '#663d00',
              '#666600',
              '#003700',
              '#002966',
              '#3d1466'
            ]
          },
          { background: [] }
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link'],
        ['clean']
      ],
      handlers: {}
    },
    clipboard: {
      matchVisual: false
    }
  }

  const format = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'color',
    'background',
    'align'
  ]

  const textLength = getTextInHTMLString(value ?? '')?.trim()?.length ?? 0

  return (
    <Box className={classNames('quill-container', containerClassName)}>
      <Box className="flex justify-between">
        <Typography className="text-base font-semibold mb-2 color-neutral-700">
          {textLabel} {isRequired && <span className="primary-color-500"> *</span>}
        </Typography>
      </Box>
      <ReactQuill
        className={className}
        theme={theme}
        ref={quillRef}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        modules={quillSettings}
        formats={format}
        bounds=".app"
        placeholder={placeholder}
      />
      {textLength <= 0 && <FormHelperText className="primary-color-500">{contentValidation}</FormHelperText>}
    </Box>
  )
}
