import { Grid, GridSize } from '@material-ui/core'
import classNames from 'classnames'
import InnerHTML from 'components/common/InnerHTML'
import { useFormikContext } from 'formik'
import { CMSCreatorValues, TextEditor } from 'pages/Publication/cms/types'
import React from 'react'
import { getGridSize } from '../../utils'

interface TextsTemplateProps {
  description?: string
  secondDescription?: string
  textEditors?: TextEditor[]
  columnCount?: number
}

export default function TextsTemplate({ textEditors, columnCount = 1 }: TextsTemplateProps) {
  const { values } = useFormikContext<CMSCreatorValues>()
  const gridSize = getGridSize(values?.setting?.device)
  const spacingGridSize = ((12 - gridSize) / 2) as GridSize

  return (
    <Grid container className="mt-3">
      <Grid item xs={1} lg={spacingGridSize} />
      <Grid container item xs={10} lg={gridSize}>
        {Array.from(Array(columnCount).keys()).map((_, index) => (
          <Grid
            key={String(index)}
            className={classNames({ 'pr-0': index === columnCount - 1, 'pr-2': columnCount > 1 && index === 0 })}
            item
            xs={12}
            lg={columnCount === 1 ? 12 : index === 0 ? 7 : 5}
          >
            <InnerHTML html={textEditors?.[index]?.description ?? ''} />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={1} lg={spacingGridSize} />
    </Grid>
  )
}
