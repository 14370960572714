import React from 'react'
import { Selector } from './EntitySelector'
import { TextPosition } from 'pages/Publication/cms/types'

export interface Position {
  ID: TextPosition
  label: TextPosition
}

interface Props {
  onChange: (newValue?: Position | null) => void
  value?: string
  label?: string
}

export const POSITIONS: Position[] = [
  {
    ID: TextPosition.Overlay,
    label: TextPosition.Overlay
  },
  {
    ID: TextPosition.Below,
    label: TextPosition.Below
  }
]

export const getLabelPosition = (position: string) =>
  POSITIONS?.find((item: Position) => item.ID === position)?.label || ''

export const TextInImagePositionSelector = ({ value, label = 'Position', onChange, ...props }: Props) => {
  return (
    <Selector<Position>
      {...props}
      label={label}
      value={value}
      labelEntity={item => item.label}
      options={POSITIONS}
      onChange={onChange}
    />
  )
}
