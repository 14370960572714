import { Box, Grid, GridSize } from '@material-ui/core'
import classNames from 'classnames'
import InnerHTML from 'components/common/InnerHTML'
import { useFormikContext } from 'formik'
import map from 'lodash/map'
import { CMSCreatorValues, TextEditor, TextInImages } from 'pages/Publication/cms/types'
import { getGridSize, getSplitImage } from 'pages/Publication/cms/utils'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useImagePreviews } from '../../hooks'
import ImageBox from './ImageBox'
import TextInImageBox from './TextInImageBox'

interface TextAndImagesTemplateProps {
  textEditors?: TextEditor[]
  images?: string[] | []
  imagePreviews?: string[] | []
  textInImages?: TextInImages[] | []
  setImagesShow?: Dispatch<SetStateAction<string[]>>
  setCurrentImageShowIndex?: Dispatch<SetStateAction<number>>
  imagesPerRow?: number
}

export default function TextAndImagesTemplate({
  textEditors,
  images = [],
  imagePreviews = [],
  textInImages,
  setImagesShow,
  setCurrentImageShowIndex
}: TextAndImagesTemplateProps) {
  const { renderImages, loading } = useImagePreviews(images, imagePreviews)

  const { values } = useFormikContext<CMSCreatorValues>()
  const gridSize = getGridSize(values?.setting?.device)
  const spacingGridSize = ((12 - gridSize) / 2) as GridSize

  const renderImageAndText = useMemo(
    () => renderImages.map((image, index) => ({ image, textInImage: textInImages?.[index] })),
    [renderImages, textInImages]
  )

  return (
    <Grid container className="mt-3">
      <Grid item xs={1} lg={spacingGridSize} />
      <Grid container item xs={10} lg={gridSize}>
        <Grid item xs={12} md={7} className="pl-0 pr-2">
          <InnerHTML html={textEditors?.[0]?.description ?? ''} />
        </Grid>
        <Grid item className="flex flex-col items-start w-full pr-0" xs={12} md={5}>
          {map(getSplitImage(renderImageAndText, 2), (item, wrapIndex) => (
            <Box className="flex flex-col gap-1 w-full" key={String(wrapIndex)}>
              <Box className="flex gap-1">
                {map(item, ({ image, textInImage }, index) => (
                  <Box
                    key={String(index)}
                    className={classNames('flex flex-col relative mt-1', {
                      [`w-1/${item?.length}`]: item?.length > 1,
                      'w-full': item?.length === 1
                    })}
                  >
                    <ImageBox
                      image={image}
                      loading={loading}
                      onClick={() => {
                        setImagesShow?.(renderImages)
                        setCurrentImageShowIndex?.(index)
                      }}
                      description={textInImage?.description}
                      position={textInImage?.position}
                    />
                  </Box>
                ))}
              </Box>
              <Box className="flex gap-1">
                {map(item, ({ textInImage }, index) => (
                  <Box
                    key={String(index)}
                    className={classNames({
                      [`w-1/${item?.length}`]: item?.length > 1,
                      'w-full': item?.length === 1
                    })}
                  >
                    <TextInImageBox description={textInImage?.description} position={textInImage?.position} />
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={1} lg={spacingGridSize} />
    </Grid>
  )
}
