import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { StandardDivider } from 'components/common/StandardDivider'
import React, { useMemo } from 'react'
import { Transition } from 'utils/modal'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { Form, FormikProvider, useFormik } from 'formik'
import { DatePickerField } from 'components/common/DatePickerField'
import { editModalSchema } from 'pages/Publication/schemas'
import { ReleaseNotes, UpdateReleaseNoteMutation, UpdateReleaseNoteMutationVariables } from 'generated/graphql'
import { EditModalFormProps } from 'pages/Publication/types'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_RELEASE_NOTES } from '../graphql'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { useReleaseNotesCommonStyles } from 'pages/Publication/styles'
import { formatUpdateReleaseNoteForClient, formatUpdateReleaseNoteForGraphql } from 'pages/Publication/helpers'
import { SelectorObjectField } from 'components/common/SelectorObjectField'
import { PlatformSelector } from 'components/common/PlatformSelector'

interface EditModalProps {
  isShow: boolean
  onClose: () => void
  selectedItem: ReleaseNotes | null
  onSuccess?: () => void
}

export default function EditModal({ isShow, onClose, selectedItem, onSuccess }: EditModalProps) {
  const classes = useReleaseNotesCommonStyles({})
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext()

  const [updateReleaseNote, { loading }] = useMutation<UpdateReleaseNoteMutation, UpdateReleaseNoteMutationVariables>(
    UPDATE_RELEASE_NOTES
  )

  const initialEditModal: EditModalFormProps = useMemo(() => formatUpdateReleaseNoteForClient(selectedItem), [
    selectedItem
  ])

  const formik = useFormik({
    initialValues: initialEditModal,
    validationSchema: editModalSchema,
    onSubmit: values => {
      updateReleaseNote({
        variables: {
          input: formatUpdateReleaseNoteForGraphql(values)
        }
      })
        .then(res => {
          !!res?.data?.updateReleaseNote && setSuccessMessage('Release note updated successfully')
          onSuccess?.()
          onClose()
        })
        .catch(error => setErrorMessage((error as Error)?.message))
    }
  })
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik

  return (
    <Dialog
      open={isShow}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="md"
      fullWidth
      onClose={onClose}
      aria-labelledby="delete-site-diary-dialog-title"
      aria-describedby="delete-site-diary-dialog-description"
    >
      <FormikProvider value={formik}>
        <Form>
          <DialogTitle id="release-notes-dialog-title" className={classes.dialogTitle}>
            Editing Release Note
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={2}>
              <GridLayoutInput
                name="releaseNote"
                label="Release Note*"
                textFieldBreakpoints={{ xs: 12 }}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                multiline
                rows={3}
                error={!!touched.releaseNote && !!errors.releaseNote}
                helperText={touched.releaseNote && errors.releaseNote}
                value={values.releaseNote}
              />
              <Grid container item spacing={2}>
                <Grid item xs={12} md={6}>
                  <DatePickerField
                    type="keyboard"
                    inputVariant="outlined"
                    label="Release Date*"
                    className={classes.fullWidth}
                    date={values.releaseDate}
                    error={!!touched.releaseDate && !!errors.releaseDate}
                    helperText={touched.releaseDate && errors.releaseDate}
                    onDateChange={date => setFieldValue('releaseDate', date)}
                  />
                </Grid>
                <GridLayoutInput
                  name="category"
                  label="Category*"
                  textFieldBreakpoints={{ xs: 12, md: 6 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={!!touched.category && !!errors.category}
                  helperText={touched.category && errors.category}
                  value={values.category}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectorObjectField name="platform" selector={PlatformSelector} />
              </Grid>
              <GridLayoutInput
                name="externalURL"
                label="External URL Link"
                textFieldBreakpoints={{ xs: 12 }}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                error={!!touched.externalURL && !!errors.externalURL}
                helperText={touched.externalURL && errors.externalURL}
                value={values.externalURL}
              />
            </Grid>
          </DialogContent>
          <StandardDivider className="m-0" />
          <DialogActions>
            <Button onClick={onClose} type="button" className="btn-cancel" variant="contained">
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              disabled={loading}
              className={`${!loading && 'btn-success'} ${classes.marginLeft10}`}
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}
