import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import { NOAUTOFILL } from 'components/common/AutocompleteInput'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { Form, FormikProvider, useFormik } from 'formik'
import {
  DeleteHolcimConfigMutation,
  DeleteHolcimConfigMutationVariables,
  GetHolcimConfigQuery,
  GetHolcimConfigQueryVariables,
  SaveHolcimConfigMutation,
  SaveHolcimConfigMutationVariables,
  SupplierIntegration
} from 'generated/graphql'
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'

import { DELETE_HOLCIM_CONFIG, GET_HOLCIM_CONFIG, SAVE_HOLCIM_CONFIG } from '../graphql'
import { convertHolcimConfigFormToClient, convertHolcimConfigFormToGraphql } from '../helpers/holcimConfig.helper'
import { HolcimForm } from '../types'
import { getHolcimConfigTitle, handlePasswordVisibleSecurity, onKeyDownHandler, onPasteHandler } from '../utils'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { holcimConfigurationSchema } from '../schemas'

type HolcimConfigurationModalProps = {
  supplierIntegration: SupplierIntegration | null
  setSupplierIntegration: Dispatch<SetStateAction<SupplierIntegration | null>>
  refetch: () => void
}

export default function HolcimConfigurationModal({
  supplierIntegration,
  setSupplierIntegration,
  refetch
}: HolcimConfigurationModalProps) {
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext()

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [isInitPassword, setIsInitPassword] = useState(true)
  const [isInitClientSecret, setIsInitClientSecret] = useState(true)

  const projectID = supplierIntegration?.project?.ID

  const { data } = useQuery<GetHolcimConfigQuery, GetHolcimConfigQueryVariables>(GET_HOLCIM_CONFIG, {
    variables: {
      projectID: projectID ?? ''
    },
    fetchPolicy: 'network-only',
    skip: !projectID
  })

  const handleCloseConfigClick = () => {
    setSupplierIntegration(null)
    setIsInitPassword(true)
    setIsInitClientSecret(true)
    resetForm()
  }

  const [saveHolcimConfig, { loading: saveHolcimConfigLoading }] = useMutation<
    SaveHolcimConfigMutation,
    SaveHolcimConfigMutationVariables
  >(SAVE_HOLCIM_CONFIG, {
    onCompleted() {
      setSuccessMessage('Holcim configuration is saved successfully.')
      handleCloseConfigClick()
      refetch()
    },
    onError(err) {
      setErrorMessage(err.message)
    }
  })

  const [deleteHolcimConfig, { loading: deleteHolcimConfigLoading }] = useMutation<
    DeleteHolcimConfigMutation,
    DeleteHolcimConfigMutationVariables
  >(DELETE_HOLCIM_CONFIG, {
    onCompleted() {
      setSuccessMessage('Holcim configuration is deleted successfully.')
      handleCloseConfigClick()
      refetch()
    },
    onError(err) {
      setErrorMessage(err.message)
    }
  })

  const initialValues = useMemo(() => convertHolcimConfigFormToClient(data?.getHolcimConfig, projectID), [
    data,
    projectID
  ])

  const holcimFormik = useFormik<HolcimForm>({
    enableReinitialize: true,
    initialValues,
    validationSchema: holcimConfigurationSchema,
    onSubmit(values) {
      saveHolcimConfig({
        variables: {
          input: convertHolcimConfigFormToGraphql({
            ...values,
            password: !isInitPassword ? values.password : undefined,
            clientSecret: !isInitClientSecret ? values.clientSecret : undefined
          })
        }
      })
    }
  })

  const { values, handleSubmit, resetForm, setFieldValue } = holcimFormik

  const handleCloseConfirmClick = () => {
    setOpenDeleteConfirm(false)
  }

  const handleDeleteHolcimConfig = () => {
    projectID && deleteHolcimConfig({ variables: { projectID: projectID } })
  }

  return (
    <>
      <Dialog open={!!projectID} fullWidth maxWidth="md">
        <DialogTitle className="text-lg font-semibold">{getHolcimConfigTitle(supplierIntegration)}</DialogTitle>
        <DialogContent>
          <Typography className="text-lg mb-6">Holcim Configuration</Typography>
          <FormikProvider value={holcimFormik}>
            <Form>
              <Grid container spacing={2} className="h-full">
                <GridLayoutInput
                  name="username"
                  customLabelV2="Username*"
                  isVersion2
                  textFieldBreakpoints={{ xs: 12, md: 6 }}
                  inputProps={{
                    form: {
                      autoComplete: NOAUTOFILL
                    }
                  }}
                />
                <GridLayoutInput
                  name="clientId"
                  customLabelV2="Client Id*"
                  isVersion2
                  textFieldBreakpoints={{ xs: 12, md: 6 }}
                  inputProps={{
                    form: {
                      autoComplete: NOAUTOFILL
                    }
                  }}
                />
                <GridLayoutInput
                  name="password"
                  customLabelV2="Password*"
                  isVersion2
                  textFieldBreakpoints={{ xs: 12, md: 6 }}
                  customValue={!isInitPassword ? values.password : handlePasswordVisibleSecurity(values.password)}
                  inputProps={{
                    form: {
                      autoComplete: NOAUTOFILL
                    },
                    className: !isInitPassword ? 'password-field' : ''
                  }}
                  onKeyDown={onKeyDownHandler({
                    field: 'password',
                    isInitValue: isInitPassword,
                    setFieldValue,
                    setIsInitValue: setIsInitPassword
                  })}
                  onPaste={onPasteHandler({
                    field: 'password',
                    isInitValue: isInitPassword,
                    setFieldValue,
                    setIsInitValue: setIsInitPassword
                  })}
                />
                <GridLayoutInput
                  name="clientSecret"
                  customLabelV2="Client Secret*"
                  isVersion2
                  textFieldBreakpoints={{ xs: 12, md: 6 }}
                  customValue={
                    !isInitClientSecret ? values.clientSecret : handlePasswordVisibleSecurity(values.clientSecret)
                  }
                  inputProps={{
                    form: {
                      autoComplete: NOAUTOFILL
                    },
                    className: !isInitClientSecret ? 'password-field' : ''
                  }}
                  onKeyDown={onKeyDownHandler({
                    field: 'clientSecret',
                    isInitValue: isInitClientSecret,
                    setFieldValue,
                    setIsInitValue: setIsInitClientSecret
                  })}
                  onPaste={onPasteHandler({
                    field: 'clientSecret',
                    isInitValue: isInitClientSecret,
                    setFieldValue,
                    setIsInitValue: setIsInitClientSecret
                  })}
                />
                <GridLayoutInput
                  name="customerCode"
                  customLabelV2="Customer Code*"
                  isVersion2
                  textFieldBreakpoints={{ xs: 12, md: 6 }}
                />
              </Grid>
            </Form>
          </FormikProvider>
          <ConfirmDialog
            title="Delete credentials"
            description="Are you sure you want to delete these credentials? This will stop the integration from running."
            open={openDeleteConfirm}
            buttonText="Delete"
            onConfirm={handleDeleteHolcimConfig}
            handleClose={handleCloseConfirmClick}
          />
        </DialogContent>
        <DialogActions>
          <Box className="px-4 w-full flex justify-end gap-2">
            <Button
              variant="contained"
              onClick={handleCloseConfigClick}
              disabled={saveHolcimConfigLoading || deleteHolcimConfigLoading}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenDeleteConfirm(true)}
              disabled={saveHolcimConfigLoading || deleteHolcimConfigLoading}
            >
              Delete Credentials
              {deleteHolcimConfigLoading && <CircularProgress size={15} className="ml-1" />}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleSubmit()}
              disabled={saveHolcimConfigLoading || deleteHolcimConfigLoading}
            >
              Save
              {saveHolcimConfigLoading && <CircularProgress size={15} className="ml-1" />}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}
