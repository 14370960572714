import { useQuery } from '@apollo/react-hooks'
import { Grid, makeStyles, Typography, Chip, Box } from '@material-ui/core'
import { loader } from 'graphql.macro'
import React, { FC } from 'react'
import { SimpleLoader } from '../../../components/common/SimpleLoader'
import { GetCompaniesQuery } from '../../../generated/graphql'
import { useUserInfo } from '../../../hooks/useUserInfo'

// GraphQL
const GET_COMPANIES_QUERY = loader('./getCompanies.graphql')

// Style
const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '5rem auto',
    gap: '1.5rem'
  },
  label: {
    color: theme.palette.text.hint
  },
  chip: {
    margin: `auto ${theme.spacing(1)}px ${theme.spacing(1)}px auto`
  }
}))

// Fuction Component
export const Details: FC = () => {
  // Declare variables
  const classes = useStyles()

  const { personInfoData } = useUserInfo()

  const { loading, error, data } = useQuery<GetCompaniesQuery>(GET_COMPANIES_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  return loading && !error ? (
    <SimpleLoader isLoading={true} />
  ) : (
    <Grid className={classes.container} container direction="column">
      <Typography className={classes.label} variant="body1">
        Name:
      </Typography>
      <Typography variant="body1">{`${personInfoData?.personInfo.Firstname} ${personInfoData?.personInfo.Lastname}`}</Typography>
      <Typography className={classes.label} variant="body1">
        Email:
      </Typography>
      <Typography variant="body1">{personInfoData?.personInfo.Email}</Typography>
      <Typography className={classes.label} variant="body1">
        Companies:
      </Typography>
      <Box>
        {data?.companies.map(({ Name: name }, index) => (
          <Chip key={index} className={classes.chip} label={name} />
        ))}
      </Box>
    </Grid>
  )
}
