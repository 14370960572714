import { makeStyles } from '@material-ui/core'

export const useCMSList = makeStyles(() => ({
  table: {
    '& .MuiPaper-rounded': {
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem'
    },
    '& .MuiTableCell-head': {
      backgroundColor: 'var(--color-neutral-200)',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      fontWeight: 'bold'
    },
    '& .MuiTableCell-head:last-child': {
      borderTopRightRadius: '0.25rem'
    },
    '& .MuiTableCell-head:first-child': {
      borderTopLeftRadius: '0.25rem'
    }
  },
  toolbar: {
    backgroundColor: 'var(--color-neutral-100)'
  },
  colorPrimary: {
    color: 'var(--success-color)',
    backgroundColor: 'var(--success-color-soft)',
    fontSize: '0.9rem',
    borderRadius: '0.5rem',
    fontWeight: 600
  },
  colorSecondary: {
    color: 'var(--color-neutral)',
    backgroundColor: 'var(--color-neutral-100)',
    fontSize: '0.9rem',
    borderRadius: '10px',
    fontWeight: 600
  },
  textOfImage: {
    '& p': {
      display: '-webkit-box',
      boxOrient: 'vertical',
      overflow: 'hidden',
      lineClamp: 2
    }
  }
}))
