import React, { FC, PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    flex: 1,
    padding: '1.5rem',
    overflowY: 'auto',
    scrollBehavior: 'smooth'
  }
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Container: FC<PropsWithChildren<any>> = ({ children }) => {
  const classes = useStyles()
  return (
    <div id="container" className={classes.container}>
      {children}
    </div>
  )
}
