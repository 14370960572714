// ================================= INTERFACE =================================

// ================================= ENUM =================================

export enum ActionsType {
  Edit = 'Edit',
  Publish = 'Publish',
  Unpublish = 'Unpublish', // This one is changed because the requirement change from UnPublished to Unpublished so that the BE side will reset DB so only update from FE side
  UnPublish = 'UnPublish',
  Delete = 'Delete',
  ExportExcel = 'Export to Excel',
  Published = 'Published',
  Unpublished = 'Unpublished', // This one is changed because the requirement change from UnPublished to Unpublished so that the BE side will reset DB so only update from FE side
  UnPublished = 'UnPublished'
}

export enum PlatformType {
  Web = 'Web',
  Mobile = 'Mobile',
  Both = 'Web, Mobile'
}
