import React, { FC, ReactNode } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { TabPanel } from '../TabPanel'
interface PayrollTabPanelProps {
  children?: ReactNode
  index: number
  noHorizontalSpacing?: boolean
  value: number
  accessGranted?: boolean
  page?: string
}

const useStyles = makeStyles({
  box: {
    padding: 0
  }
})

export const PayrollTabPanel: FC<PayrollTabPanelProps> = ({
  children,
  index,
  noHorizontalSpacing,
  value,
  accessGranted,
  page
}) => {
  const classes = useStyles()
  return (
    <TabPanel isNoHorizontalPadding index={index} value={value}>
      {(() => {
        if (!accessGranted) {
          return (
            <Alert severity="info">
              <AlertTitle>Access Denied</AlertTitle>
              {`You don't have permission to manage ${page}`}
            </Alert>
          )
        }
        if (value === index) {
          return (
            <Box className={noHorizontalSpacing ? classes.box : ''} p={2}>
              {children}
            </Box>
          )
        }
      })()}
    </TabPanel>
  )
}
