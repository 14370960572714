import { getIdToken } from 'auth'
import React, { useEffect, useState } from 'react'
import { Route, RouteProps, useHistory } from 'react-router'
import { getLocalStorageItem } from 'utils/tools'
import { Privilege } from '../generated/graphql'
import { useUserInfo } from '../hooks/useUserInfo'
import { SimpleLoader } from './common/SimpleLoader'
import { Privileges } from 'utils/rolesAndPrivileges'

interface PrivateRouteProps extends RouteProps {
  component: any
  permission: Privileges | Privileges[]
}

export const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  permission,
  ...rest
}) => {
  const { replace } = useHistory()
  const { personInfoData } = useUserInfo()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (getLocalStorageItem('isAuth') === 'false') {
      setIsLoading(true)
      getIdToken()
        .catch(() => window.location.reload())
        .finally(() => setIsLoading(false))
    }

    if (personInfoData) {
      const privileges: Privilege[] = []
      personInfoData?.personInfo?.roles?.forEach((e: { privileges: Privilege[] }) => {
        privileges.push(...e.privileges)
      })
      const access = privileges.find((privilege: { Name: string }) => permission.includes(privilege.Name as Privileges))
      if (!access) {
        replace('/my-profile')
      }
    }
  }, [replace, personInfoData, permission])

  return (
    <>
      {isLoading ? (
        <SimpleLoader isLoading={isLoading} />
      ) : (
        <Route {...rest} render={props => <Component {...props} />} />
      )}
    </>
  )
}
