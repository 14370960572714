import { Box } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { CMSCreatorValues, PreviewTemplateValue } from 'pages/Publication/cms/types'
import React, { useEffect, useMemo } from 'react'

import debounce from 'lodash/debounce'
import { getVarBasedOnCMSForm } from 'pages/Publication/cms/utils'
import ContentEditor from '../../ContentEditor'
import { REQUIRED } from 'utils/Constants'

interface CMSFormProps {
  className?: string
  index: number
}

export default function ContentForm({ index }: CMSFormProps) {
  const { values, setFieldValue, setFieldTouched, errors } = useFormikContext<CMSCreatorValues>()

  const { isHideContent, isShowSelectOption, isTwoColumnTemplate } = getVarBasedOnCMSForm(values, index)

  const headerTitle =
    isShowSelectOption && (errors.content?.[index] as any)?.textEditors?.[0]?.description ? `Title ${REQUIRED}` : ''

  const onDebounceEditorChange = useMemo(
    () =>
      debounce((field, value) => {
        field && setFieldValue(field, value)
      }, 400),
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    return () => {
      onDebounceEditorChange.cancel()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box>
      {!isHideContent && (
        <>
          {values?.content?.[index]?.textEditors?.map((text, textIndex) => (
            <Box key={String(textIndex)} className="mb-3">
              <ContentEditor
                className="w-full rounded-md"
                textLabel={
                  values?.content?.[index]?.template === PreviewTemplateValue.Header
                    ? 'Title'
                    : `Content ${(values?.content?.[index]?.textEditors?.length ?? 0) > 1 ? textIndex + 1 : ''}`
                }
                value={text?.description}
                onChange={e => onDebounceEditorChange(`content.${index}.textEditors.${textIndex}.description`, e)}
                onBlur={() => setFieldTouched(`content.${index}.textEditors.${textIndex}.description`)}
                contentValidation={
                  headerTitle || (errors.content?.[index] as any)?.textEditors?.[textIndex]?.description
                }
                isRequired={!isTwoColumnTemplate}
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  )
}
