import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Grid } from '@material-ui/core'
import { CompanySelector } from 'components/common/CompanySelector'
import { ConfirmDialog } from 'components/common/ConfirmDialog'
import { DeleteIcon } from 'components/common/icons'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import React from 'react'
import { RoleSelector } from '../../../components/common/RolesSelector'
import { Table } from '../../../components/common/Table'
import {
  AddRoleForPersonMutation,
  AddRoleForPersonMutationVariables,
  Company,
  DeleteRolePersonMutation,
  DeleteRolePersonMutationVariables,
  FindManyAdminRolesQuery,
  GetUserRoleCompanyAccessQuery,
  GetUserRoleCompanyAccessQueryVariables,
  Person,
  Role,
  UserCompanyAccess
} from 'generated/graphql'
import * as yup from 'yup'
import { companyColumns } from './columns'
import { ADD_ROLE_FOR_PERSON, DELETE_ROLE_FOR_PERSON, GET_USER_ROLE_COMPANY_ACCESS } from './graphql'
import { Form, FormikProvider, useFormik } from 'formik'
import { SelectorObjectField } from 'components/common/SelectorObjectField'

interface FormProps {
  company: Company | null
  role: Role | null
}
interface CompanyRolesProps {
  selectedRoleItem: Person
  isExternalRole?: boolean
}

export const CompanyRoles = ({ selectedRoleItem, isExternalRole }: CompanyRolesProps) => {
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext()
  const [deletedItem, setDeletedItem] = React.useState<UserCompanyAccess | null>(null)

  const { data: userAccessData } = useQuery<GetUserRoleCompanyAccessQuery, GetUserRoleCompanyAccessQueryVariables>(
    GET_USER_ROLE_COMPANY_ACCESS,
    {
      skip: !selectedRoleItem,
      variables: {
        PersonID: selectedRoleItem?.ID || '0'
      },
      fetchPolicy: 'cache-and-network'
    }
  )

  const allowedCompanies = userAccessData?.getUserRoleCompanyAccess as UserCompanyAccess[]

  const [deleteRolePerson] = useMutation<DeleteRolePersonMutation, DeleteRolePersonMutationVariables>(
    DELETE_ROLE_FOR_PERSON,
    {
      refetchQueries: [
        {
          query: GET_USER_ROLE_COMPANY_ACCESS,
          variables: {
            PersonID: selectedRoleItem?.ID || '0'
          }
        }
      ]
    }
  )

  const [addRoleForPerson] = useMutation<AddRoleForPersonMutation, AddRoleForPersonMutationVariables>(
    ADD_ROLE_FOR_PERSON,
    {
      refetchQueries: [
        {
          query: GET_USER_ROLE_COMPANY_ACCESS,
          variables: {
            PersonID: selectedRoleItem?.ID || '0'
          }
        }
      ]
    }
  )

  const handleDelete = (roleToDelete: UserCompanyAccess) => {
    setDeletedItem(roleToDelete)
  }

  const onDelete = () => {
    deleteRolePerson({
      variables: {
        input: {
          PersonID: selectedRoleItem?.ID || '',
          CompanyID: deletedItem?.CompanyID || '',
          RoleID: deletedItem?.roleCompany?.ID || ''
        }
      }
    })
      .then(({ data }) => {
        if (data?.deleteRolePersonAdminPage) {
          setDeletedItem(null)
          setSuccessMessage('Company roles has been updated successfully')
        }
      })
      .catch(error => {
        setErrorMessage(error.message)
      })
  }

  const initialValues: FormProps = {
    company: null,
    role: null
  }

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      company: yup
        .object()
        .label('Company')
        .nullable()
        .required(),
      role: yup
        .object()
        .label('Role')
        .nullable()
        .required()
    }),
    onSubmit: ({ company, role }, { resetForm }) => {
      if (!company || !role) return
      addRoleForPerson({
        variables: {
          input: {
            PersonID: selectedRoleItem?.ID || '',
            CompanyID: company.ID,
            RoleID: role?.ID
          }
        }
      })
        .then(({ data }) => {
          if (data?.addRoleForPersonAdminPage) {
            setSuccessMessage('Company roles has been updated successfully')
            resetForm()
          }
        })
        .catch(error => {
          setErrorMessage(error.message)
        })
    }
  })

  const {
    values: { company },
    isSubmitting
  } = formik

  const extractEntities = (data: FindManyAdminRolesQuery): Role[] => {
    return data.findManyAdminRoles.items
      .filter(
        e =>
          !company ||
          !allowedCompanies?.find(
            allowedCompany => company.ID === allowedCompany?.CompanyID && allowedCompany?.roleCompany?.ID === e.ID
          )
      )
      .map(e => e as Role)
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <Grid container justifyContent="space-between" direction="row" spacing={2}>
            <Grid item xs={6}>
              <SelectorObjectField name="company" selector={CompanySelector} />
            </Grid>
            <Grid item xs={4}>
              <SelectorObjectField
                name="role"
                selector={RoleSelector}
                extractEntities={extractEntities}
                isExternalRole={!!isExternalRole}
                roleType="CompanyLevelRole"
              />
            </Grid>
            <Grid item xs={2}>
              <Button disabled={isSubmitting} variant="contained" color="secondary" type="submit">
                Add Role
              </Button>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <br />
      <Grid>
        <Table<UserCompanyAccess>
          columns={companyColumns}
          data={allowedCompanies || []}
          actions={[
            {
              icon: () => <DeleteIcon style={{ marginRight: 10 }} spacing={4} color="secondary" fontSize="small" />,
              tooltip: 'Remove',
              onClick: (_event, rowData: UserCompanyAccess | UserCompanyAccess[]) => {
                if (!Array.isArray(rowData)) handleDelete(rowData)
              }
            }
          ]}
          option={{
            toolbar: false
          }}
        />
      </Grid>
      <ConfirmDialog
        buttonText="Delete"
        handleClose={() => setDeletedItem(null)}
        open={!!deletedItem}
        onConfirm={onDelete}
        title="Delete Role"
        description="Are you sure to delete this role from Company roles?"
      />
    </>
  )
}
