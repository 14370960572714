import React, { useState, useEffect } from 'react'
import { Scalars, Query } from 'generated/graphql'
import { AutocompleteInput, NOAUTOFILL, AutocompleteInputProps } from './AutocompleteInput'
import { Waypoint } from 'react-waypoint'
import { Skeleton } from '@material-ui/lab'
import { useInfiniteLoadQuery } from 'hooks/useInfiniteLoadQuery'
import { QueryHookOptions } from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'
import { debounce } from 'lodash'

interface BaseEntity {
  ID: string
}

export interface CompileDataResult<T> {
  data: T[]
}

export interface InfiniteSelectorProps<T> {
  disabled?: boolean
  error?: AutocompleteInputProps<T>['error']
  helperText?: AutocompleteInputProps<T>['helperText']
  label?: AutocompleteInputProps<T>['label']
  onBlur?: AutocompleteInputProps<T>['blurHandler']
  onChange: (newValue: T | null) => void
  value?: T | Scalars['ID'] | null
  labeller: (options: T) => string
  compileData: (data: Query) => CompileDataResult<T>
  query: DocumentNode
  queryHookOptions?: QueryHookOptions
  extractData?: (data: T[]) => T[]
}

const currentOption = <T extends BaseEntity>(options: T[], value: T | Scalars['ID'] | null | undefined) =>
  (typeof value === 'string' ? options.find(option => option?.ID === value) : value) || null

export const InfiniteSelector = <T extends BaseEntity>({
  value,
  onChange,
  label = '',
  onBlur,
  labeller,
  query,
  compileData,
  queryHookOptions,
  extractData,
  ...props
}: InfiniteSelectorProps<T>) => {
  const [options, setOptions] = useState(queryHookOptions || {})
  const { loading, data, loadMore } = useInfiniteLoadQuery<T>(query, options, compileData, extractData)

  const option = currentOption(data, value)

  const onFocus = () => {
    if (data.length === 0) {
      setOptions({
        ...queryHookOptions,
        variables: {
          ...queryHookOptions?.variables,
          filter: undefined
        }
      })
    }
  }
  const handleWaypointEnter = () => {
    loadMore && loadMore()
  }

  const onSearch = debounce(newValue => {
    setOptions({
      ...queryHookOptions,
      variables: {
        ...queryHookOptions?.variables,
        filter: newValue
      }
    })
  }, 400)

  useEffect(() => {
    setOptions(queryHookOptions || {})
  }, [queryHookOptions])
  return (
    <AutocompleteInput<T>
      autoComplete={NOAUTOFILL}
      clearOnEscape
      changeHandler={(_, value) => onChange(value)}
      onFocus={onFocus}
      inputChangeHandler={(e, newValue) => {
        if (!data.find(e => labeller(e) === newValue)) {
          !newValue
            ? setOptions({
                ...queryHookOptions,
                variables: {
                  ...queryHookOptions?.variables,
                  filter: undefined
                }
              })
            : onSearch(newValue)
        }
      }}
      blurHandler={onBlur}
      label={label}
      getOptionLabel={labeller}
      value={option}
      renderOption={option => (
        <div role="option" aria-selected style={{ width: '100%' }}>
          {labeller(option)}
          {option?.ID === data[data.length - 1]?.ID && !!loadMore ? (
            <div>
              <Waypoint onEnter={handleWaypointEnter} />
              {loading && (
                <Skeleton variant="rect" width="auto" height={40}>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, justifyContent: 'center' }}>
                    <b style={{ textAlign: 'center', margin: 'auto', marginTop: 10 }}>loading...</b>
                  </div>
                </Skeleton>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {...props}
      loading={loading}
      options={data}
    />
  )
}
