import React, { useState } from 'react'
import { Grid, Button, Typography, styled } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useMutation } from '@apollo/react-hooks'
import { Formik } from 'formik'
import * as yup from 'yup'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { StretchTextField } from 'components/common/StretchTextField'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { SelectorField } from 'components/common/SelectorField'
import { PublicationSelector } from 'components/common/publication/PublicationSelector'
import { S4, REQUIRED, getMaxLengthMessage } from 'utils/Constants'
import { Feedback, getSuccessFeedback, getErrorFeedback } from 'utils/feedback'
import { SendPushNotificationsToAllMutation, SendPushNotificationsToAllMutationVariables } from 'generated/graphql'
import { SEND_PUSH_NOTIFICATION_TO_ALL } from './graphql'

const DELAY_TYPES = ['N', 'A']

const SubmitButton = styled(Button)({
  alignItems: 'flex-end'
})

const MAX_LENGTH_TITLE = 63
const MAX_LENGTH_MESSAGE = 1024
interface FormValues {
  Title: string
  Type: 'N' | 'A'
  Message: string
  ArticleID: string
}

const initialValues: FormValues = {
  Title: '',
  Type: 'N',
  Message: '',
  ArticleID: ''
}
const pushnotificationSchema = yup.object({
  Title: yup
    .string()
    .trim()
    .nullable()
    .max(MAX_LENGTH_TITLE, getMaxLengthMessage(MAX_LENGTH_TITLE))
    .required(`Push notification title ${REQUIRED}`),
  Type: yup.object().nullable(),
  Message: yup
    .string()
    .nullable()
    // Maximum payload for both message types is 4KB, except when sending messages from the Firebase console, which enforces a 1024 character limit.
    .max(MAX_LENGTH_MESSAGE, getMaxLengthMessage(MAX_LENGTH_MESSAGE))
    .required(`Push notification message ${REQUIRED}`)
})

export const PushNotification = () => {
  const [autoHideFeedback, setAutoHideFeedback] = useState<Feedback | null>(null)
  const [typeD, setTypeD] = useState('N')
  const queyrHookOptions = {
    variables: {
      publicationType: typeD,
      filterStatus: 'P',
      sortBy: 'PublishDateTime'
    }
  }

  const [sendPushNotificationsToAll] = useMutation<
    SendPushNotificationsToAllMutation,
    SendPushNotificationsToAllMutationVariables
  >(SEND_PUSH_NOTIFICATION_TO_ALL)

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={pushnotificationSchema}
        onSubmit={async ({ Title, Message, ArticleID }, { resetForm, setSubmitting }) => {
          setSubmitting(true)
          await sendPushNotificationsToAll({
            variables: {
              PushNotificationInput: {
                Title: Title,
                Body: Message,
                ID: ArticleID
              }
            }
          })
            .then(({ data }) => {
              if (data?.sendPushNotificationsToAll) {
                setAutoHideFeedback(getSuccessFeedback(`Notifications sent successfully`))
                resetForm()
                setTypeD('N')
              }
            })
            .catch(error => {
              setAutoHideFeedback(getErrorFeedback(error.message))
            })
            .finally(() => setSubmitting(false))
        }}
      >
        {({
          values: { Title, ArticleID, Message },
          errors,
          getFieldProps,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,
          isSubmitting
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container justifyContent="space-between" direction="row" spacing={S4} alignItems="center">
                <Grid item xs={12}>
                  <GridLayoutInput
                    error={!!errors.Title && !!touched.Title}
                    fullWidth
                    id="Title"
                    inputProps={{ maxLength: MAX_LENGTH_TITLE + 1 }}
                    label="* Enter push title"
                    helperText={!!errors.Title && !!touched.Title ? errors.Title : null}
                    name="Title"
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('Title', e.target.value)}
                    rows={4}
                    textFieldBreakpoints={{ xs: 12, sm: 12 }}
                    value={Title}
                  />
                </Grid>
                <Grid item xs={6} sm={5}>
                  <SelectorField
                    name="ArticleID"
                    label="Please select the publication"
                    value={ArticleID}
                    onBlur={handleBlur}
                    error={!!errors.ArticleID && !!touched.ArticleID}
                    helperText={touched.ArticleID ? errors.ArticleID : null}
                    selector={PublicationSelector}
                    queryHookOptions={queyrHookOptions}
                  />
                </Grid>
                <Grid
                  item
                  xs={true}
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <ToggleButtonGroup
                    exclusive
                    style={{ alignSelf: 'flex-end' }}
                    {...getFieldProps('Type')}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setFieldValue('Type', value)
                        setTypeD(value)
                        setFieldValue('Article', null)
                      }
                    }}
                    aria-label="type"
                  >
                    {DELAY_TYPES.map(e => (
                      <ToggleButton value={e} aria-label={e} key={e} id={e}>
                        <Typography>{e === 'N' ? 'News' : 'Alert'}</Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <StretchTextField
                    error={!!errors.Message && !!touched.Message}
                    helperText={!!errors.Message && !!touched.Message ? errors.Message : null}
                    inputProps={{ maxLength: MAX_LENGTH_MESSAGE + 1 }}
                    label="* Enter push message"
                    multiline
                    name="Message"
                    onBlur={handleBlur}
                    onChange={e => setFieldValue('Message', e.target.value)}
                    placeholder="Enter push message"
                    rows={10}
                    value={Message}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  container
                  style={{ marginLeft: 15, marginBottom: 10 }}
                  alignItems="center"
                  direction={'row'}
                  justifyContent="flex-start"
                >
                  <SubmitButton type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                    Send Notification
                  </SubmitButton>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setAutoHideFeedback(null)}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />
    </>
  )
}
