import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { StandardDivider } from 'components/common/StandardDivider'
import React, { ChangeEvent, useRef, useState } from 'react'
import { Transition } from 'utils/modal'
import uploadIcon from 'images/icons/upload-simple.svg'
import documentIcon from 'images/icons/document.svg'
import { useMutation } from '@apollo/react-hooks'
import {
  ImportReleaseNoteMutation,
  ImportReleaseNoteMutationVariables,
  UploadFileMutation,
  UploadFileMutationVariables
} from 'generated/graphql'
import { IMPORT_RELEASE_NOTES } from '../graphql'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { UPLOAD_FILE } from 'graphql/common'
import { useReleaseNotesCommonStyles } from 'pages/Publication/styles'
import BatchProcessTable from 'components/common/batch-process/BatchProcessTable'

interface UploadModalProps {
  isShow: boolean
  onClose: () => void
  month: string
  year: string
  onSuccess?: () => void
}

interface StateProps {
  file: File | null
  hasError: boolean
  isUploading: boolean
}

export default function UploadModal({ isShow, onClose, month, year, onSuccess }: UploadModalProps) {
  const { setErrorMessage } = useSnackbarContext()
  const refInput = useRef<HTMLInputElement | null>(null)
  const [processID, setProcessID] = useState('')

  const [state, setState] = useState<StateProps>({ file: null, hasError: false, isUploading: false })
  const classes = useReleaseNotesCommonStyles({ hasError: state.hasError })
  const [input, setInput] = useState('')

  const [uploadReleaseNote] = useMutation<ImportReleaseNoteMutation, ImportReleaseNoteMutationVariables>(
    IMPORT_RELEASE_NOTES
  )

  const [uploadFile] = useMutation<UploadFileMutation, UploadFileMutationVariables>(UPLOAD_FILE)

  const handleClickUpload = () => {
    if (refInput.current) {
      refInput.current.click()
    }
  }

  const onFileChange = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (files && files.length > 0) {
      setState({ ...state, file: files[0], hasError: false })
    }
  }

  const handleSubmit = async () => {
    if (state.file) {
      setState({ ...state, isUploading: true })
      try {
        const resUploadFile = await uploadFile({
          variables: {
            input: {
              Name: state.file?.name,
              Type: state.file?.type,
              UseSimpleName: true
            }
          }
        })

        const uploadData = resUploadFile.data?.uploadFile

        if (uploadData) {
          await fetch(`${uploadData.UploadUrl}`, {
            method: 'PUT',
            body: state.file
          })

          const { data } = await uploadReleaseNote({
            variables: {
              FileKey: uploadData.FileKey
            }
          })

          setProcessID(data?.importReleaseNote?.ID ?? '')
          setState({ ...state, file: null, isUploading: false })
        }
      } catch (error) {
        setErrorMessage((error as Error)?.message)
        setState({ ...state, isUploading: false })
      }
    } else {
      setState({ ...state, hasError: true })
    }
  }

  return (
    <Dialog
      open={isShow}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="delete-site-diary-dialog-title"
      aria-describedby="delete-site-diary-dialog-description"
    >
      <DialogTitle id="release-notes-dialog-title" className={classes.dialogUploadTitle}>
        Uploading Release Notes ({month} {year})
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
          Upload your file*
        </Typography>
        <Box className={classes.box} onClick={handleClickUpload}>
          <input
            ref={refInput}
            accept=".xlsx"
            multiple={false}
            type="file"
            className={classes.input}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onFileChange(event)
              setInput('')
            }}
            value={input}
          />
          {state.file ? (
            <Typography variant="subtitle2" className={classes.uploadedFile} gutterBottom>
              <img src={documentIcon} alt="document_icon" />
              {state.file && `files/${state.file?.name}`}
            </Typography>
          ) : (
            <>
              <div>
                <img src={uploadIcon} alt="upload_icon" />
              </div>
              <Typography variant="subtitle2" gutterBottom>
                <span className="browse-text text-underline">Browse</span> Excel files to upload!
              </Typography>
            </>
          )}
        </Box>
        <BatchProcessTable
          processType="UploadReleaseNote"
          processID={processID}
          setProcessID={setProcessID}
          onSuccess={onSuccess}
        />
      </DialogContent>
      <StandardDivider className="m-0" />
      <DialogActions className={classes.actions}>
        {state.hasError ? (
          <Typography variant="subtitle2" className="error-color" gutterBottom>
            Please upload Excel file
          </Typography>
        ) : (
          <div />
        )}
        <Box>
          <Button disabled={state.isUploading} onClick={onClose} className="btn-cancel" variant="contained">
            Cancel
          </Button>
          <Button
            disabled={state.isUploading}
            onClick={() => handleSubmit()}
            className={`${!state.isUploading && 'btn-success'} ml-3`}
            variant="contained"
          >
            Upload
            {state.isUploading && <CircularProgress size="small" className="w-3 h-3 ml-1" />}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
