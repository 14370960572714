import { useMutation } from '@apollo/react-hooks'
import { Button, Grid, styled, SvgIcon, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import NotificationsIcon from '@material-ui/icons/Notifications'
import WarningIcon from '@material-ui/icons/Warning'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { AutoHideSnackBar } from 'components/common/AutoHideSnackBar'
import { Formik } from 'formik'
import { UpsertSystemAnnouncementMutation, UpsertSystemAnnouncementMutationVariables } from 'generated/graphql'
import { UPSERT_SYSTEM_ANNOUNCEMENT } from 'graphql/common'
import React, { createRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { S4 } from 'utils/Constants'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import { formatHTMLSpace, isEmptyHTML } from 'utils/tools'
import * as yup from 'yup'
import useUpdateSystemAnnouncement from './useUpdateSystemAnnouncement'

export const SYSTEM_ANNOUNCEMENTS = {
  Warning: WarningIcon,
  Info: InfoIcon,
  Notification: NotificationsIcon
}

const GridItem = styled(Grid)({
  '& .MuiPaper-root': {
    paddingLeft: '1.8125rem',
    paddingRight: '1.8125rem'
  },
  container: {
    '& p': {
      padding: 'auto'
    }
  }
})

const SubmitButton = styled(Button)({
  margin: 10,
  textAlign: 'center'
})

const REQUIRED = `is a required field`
const TEXT_MAX = 30000

const systemAnnouncementSchema = yup.object({
  Status: yup.string().required(`Announcement type ${REQUIRED}`),
  Description: yup
    .string()
    .nullable()
    .trim()
    .required(`Announcement text ${REQUIRED}`)
    .test('length', `Announcement text ${REQUIRED}`, val => !isEmptyHTML(val))
})

interface FormValues {
  IsActive: boolean
  Status: string
  Description: string
}

export const CreateSystemAnnouncement = () => {
  const [autoHideFeedback, setAutoHideFeedback] = useState<Feedback | null>(null)
  const { systemAnnouncement, updateSystemAnnouncement } = useUpdateSystemAnnouncement()

  const [upsertSystemAnnouncement, { loading }] = useMutation<
    UpsertSystemAnnouncementMutation,
    UpsertSystemAnnouncementMutationVariables
  >(UPSERT_SYSTEM_ANNOUNCEMENT, {
    onCompleted: data => {
      if (data?.upsertSystemAnnouncement) {
        setAutoHideFeedback(
          getSuccessFeedback(
            `Announcement ${data?.upsertSystemAnnouncement?.IsActive ? 'created' : 'hidden'} successfully`
          )
        )
        updateSystemAnnouncement([{ ...data?.upsertSystemAnnouncement, __typename: 'SystemAnnouncement' }])
      }
    },
    onError: error => {
      setAutoHideFeedback(getErrorFeedback(error.message))
    }
  })

  const initialValues: FormValues = {
    IsActive: systemAnnouncement?.IsActive ?? true,
    Status: systemAnnouncement?.Status || '',
    Description: systemAnnouncement?.Description || ''
  }

  const quillRef = createRef<ReactQuill>()

  const checkCharacterCount = () => {
    if (quillRef && quillRef.current) {
      const editor = quillRef.current.getEditor()
      if (editor.getLength() > TEXT_MAX) {
        quillRef.current.getEditor().deleteText(TEXT_MAX, editor.getLength())
      }
    }
  }

  const quillSettings = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline'],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean']
      ],
      handlers: {}
    },
    clipboard: {
      matchVisual: false
    }
  }

  return (
    <>
      <Formik
        enableReinitialize
        validate={value => {
          updateSystemAnnouncement([
            {
              ...systemAnnouncement,
              ...value,
              __typename: 'SystemAnnouncement'
            }
          ])
        }}
        initialValues={initialValues}
        validationSchema={systemAnnouncementSchema}
        onSubmit={values => {
          upsertSystemAnnouncement({
            variables: {
              input: {
                IsActive: values.IsActive,
                Status: values.Status,
                Description: formatHTMLSpace(values.Description)
              }
            }
          })
        }}
      >
        {props => {
          const {
            values,
            errors,
            setFieldValue,
            touched,
            setFieldTouched,
            handleSubmit,
            getFieldProps,
            resetForm
          } = props
          return (
            <form onSubmit={handleSubmit}>
              <Grid container justifyContent="space-between" direction="row" spacing={S4}>
                <Grid container alignContent="center" justifyContent="space-between" className="p-4" direction="row">
                  <Grid item xs={12}>
                    <Typography>
                      A sticky message will {values?.IsActive ? '' : 'not'} appear on top of every page within
                      LinkedSite.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignContent="center" justifyContent="space-between" className="p-4" direction="row">
                  <Grid item xs={1}>
                    <Typography>Status</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <ToggleButtonGroup
                      exclusive
                      {...getFieldProps('IsActive')}
                      onChange={(e, value) => {
                        if (value !== null) {
                          setFieldValue('IsActive', value)
                        }
                      }}
                      aria-label="type"
                      value={values?.IsActive}
                    >
                      <ToggleButton value={true} aria-label="display" key="true" id="true">
                        <Typography>Display</Typography>
                      </ToggleButton>
                      <ToggleButton value={false} aria-label="hide" key="false" id="false">
                        <Typography>Hide</Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
                <Grid container alignContent="center" justifyContent="space-between" className="p-4" direction="row">
                  <Grid item xs={1}>
                    <Typography>Type</Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <ToggleButtonGroup
                      exclusive
                      {...getFieldProps('Status')}
                      onChange={(e, value) => {
                        if (value !== null) {
                          setFieldValue('Status', value)
                        }
                      }}
                      aria-label="type"
                      value={values?.Status}
                    >
                      {Object.entries(SYSTEM_ANNOUNCEMENTS).map(([key, value]) => (
                        <ToggleButton value={key} aria-label={key} key={key} id={key}>
                          <SvgIcon component={value} viewBox="0 0 24 24" height="24px" width="24px" />
                          <Typography variant="caption">{key}</Typography>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                  {touched && !values.Status && (
                    <Typography variant="caption" color="error">
                      {errors.Status}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={S4}>
                <GridItem item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Announcement Text</Typography>
                    {values?.IsActive && (
                      <Typography
                        variant="caption"
                        style={{
                          fontSize: '1rem',
                          color: '#757575',
                          fontStyle: 'italic'
                        }}
                      >
                        As soon as you make changes to this, the data can be previewed on the top of this page. It will
                        not be published until you click Save
                      </Typography>
                    )}
                    <ReactQuill
                      theme={'snow'}
                      id="Description"
                      style={{ marginTop: 10 }}
                      ref={quillRef}
                      onKeyUp={checkCharacterCount}
                      onKeyDown={checkCharacterCount}
                      onKeyPress={checkCharacterCount}
                      value={values?.Description}
                      onChange={content => {
                        setFieldValue('Description', content)
                        setFieldTouched('Description', true)
                      }}
                      modules={quillSettings}
                      formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'indent', 'link']}
                      bounds={'.app'}
                      placeholder={'Please enter announcement text'}
                    />
                    {!!touched.Description && !!errors.Description && (
                      <Typography variant="caption" color="error">
                        {errors.Description}
                      </Typography>
                    )}
                  </Grid>
                  <Grid container alignItems="center" direction="row" justifyContent="center">
                    <SubmitButton size="large" type="submit" color="primary" variant="contained" disabled={loading}>
                      Save
                    </SubmitButton>
                    <SubmitButton
                      type="button"
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        resetForm()
                        updateSystemAnnouncement([systemAnnouncement])
                      }}
                    >
                      Cancel
                    </SubmitButton>
                  </Grid>
                </GridItem>
              </Grid>
            </form>
          )
        }}
      </Formik>
      <AutoHideSnackBar
        autoHideDuration={autoHideFeedback?.type === 'success' ? 3000 : 6000}
        handleClose={() => setAutoHideFeedback(null)}
        message={!!autoHideFeedback?.message ? autoHideFeedback.message : ''}
        severity={autoHideFeedback?.type}
        open={!!autoHideFeedback}
      />
    </>
  )
}
