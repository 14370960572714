import { Box, Grid, GridSize } from '@material-ui/core'
import classNames from 'classnames'
import { ImagePosition } from 'components/common/ImagePositionSelector'
import { Size } from 'components/common/ImageSizeSelector'
import { useFormikContext } from 'formik'
import map from 'lodash/map'
import { CMSCreatorValues, TextInImages } from 'pages/Publication/cms/types'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useImagePreviews } from '../../hooks'
import { getGridSize, getSplitImage } from '../../utils'
import ImageBox from './ImageBox'
import TextInImageBox from './TextInImageBox'

interface ImagesTemplateProps {
  images?: string[] | []
  imagePreviews?: string[] | []
  textInImages?: TextInImages[] | []
  maximumImage?: number
  setImagesShow?: Dispatch<SetStateAction<string[]>>
  setCurrentImageShowIndex?: Dispatch<SetStateAction<number>>
  imagesPerRow?: number
  size?: Size | null
  position?: ImagePosition | null
}

export default function ImagesTemplate({
  images = [],
  imagePreviews = [],
  textInImages = [],
  setImagesShow,
  setCurrentImageShowIndex,
  imagesPerRow = 1,
  size,
  position
}: ImagesTemplateProps) {
  const { renderImages, loading } = useImagePreviews(images, imagePreviews)

  const { values } = useFormikContext<CMSCreatorValues>()
  const gridSize = getGridSize(values?.setting?.device)
  const spacingGridSize = ((12 - gridSize) / 2) as GridSize

  const isFullWidth = size?.ID === 'Large'
  const isMediumSize = size?.ID === 'Medium'
  const isSmallSize = size?.ID === 'Small'

  const positionLeft = position?.ID === 'Left'
  const positionRight = position?.ID === 'Right'

  const renderImageAndText = useMemo(
    () => renderImages.map((image, index) => ({ image, textInImage: textInImages?.[index] })),
    [renderImages, textInImages]
  )

  return (
    <Grid
      container
      className={classNames('flex flex-col mt-3', {
        'items-start': positionLeft,
        'items-center': !positionLeft && !positionRight,
        'items-end': positionRight,
        'pl-1': !isFullWidth && positionLeft,
        'pr-1': !isFullWidth && positionRight
      })}
    >
      {!isFullWidth && <Grid item xs={1} lg={spacingGridSize} />}
      <Grid
        container
        item
        xs={isFullWidth ? 12 : 10}
        lg={isFullWidth ? 12 : gridSize}
        spacing={!isFullWidth ? 1 : 0}
        className={classNames('flex flex-col gap-1', {
          'items-start': positionLeft,
          'items-center': !positionLeft && !positionRight,
          'items-end': positionRight
        })}
      >
        {map(getSplitImage(renderImageAndText, imagesPerRow), (item, wrapIndex) => (
          <Box
            key={String(wrapIndex)}
            className={classNames('flex flex-col w-full gap-1', {
              'max-w-[600px]': isMediumSize,
              'max-w-[300px]': isSmallSize
            })}
          >
            <Box className="flex gap-1">
              {map(item, ({ image, textInImage }, index) => (
                <Box
                  key={String(index)}
                  className={classNames('flex flex-col relative', {
                    [`w-1/${item?.length}`]: item?.length > 1,
                    'w-full': item?.length === 1
                  })}
                >
                  <ImageBox
                    image={image}
                    loading={loading}
                    onClick={() => {
                      setImagesShow?.(renderImages)
                      setCurrentImageShowIndex?.(index)
                    }}
                    description={textInImage?.description}
                    position={textInImage?.position}
                  />
                </Box>
              ))}
            </Box>
            <Box className="flex gap-1">
              {map(item, ({ textInImage }, index) => (
                <Box
                  key={String(index)}
                  className={classNames({
                    [`w-1/${item?.length}`]: item?.length > 1,
                    'w-full': item?.length === 1
                  })}
                >
                  <TextInImageBox description={textInImage?.description} position={textInImage?.position} />
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Grid>
      {!isFullWidth && <Grid item xs={1} lg={spacingGridSize} />}
    </Grid>
  )
}
