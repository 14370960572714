import { useMutation } from '@apollo/react-hooks'
import { Button, Dialog, DialogActions, DialogContent, Slide, Grid, Typography, makeStyles } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { Formik } from 'formik'
import React, { forwardRef, Ref, useState } from 'react'
import {
  PayrollClassification,
  EditLockDatePayrollClassificationMutation,
  EditLockDatePayrollClassificationMutationVariables
} from '../../../generated/graphql'
import { Feedback, getErrorFeedback, getSuccessFeedback } from 'utils/feedback'
import { DatePickerField } from 'components/common/DatePickerField'
import { DDMY, formatIsoDate } from 'utils/dates'
import { subDays } from 'date-fns'
import { EDIT_LOCK_DATE_PAYROLL_CLASSIFICATION } from '../graphql'

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

// Style
const useStyles = makeStyles(() => ({
  plantHeader: {
    fontSize: '1rem'
  },
  plantTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center'
  }
}))

interface FormTimesheetLockingModalProps {
  handleClose: () => void
  item?: PayrollClassification
  setAutoHideFeedback: (value: Feedback) => void
  refetch: () => void
}

interface FormValues {
  LockDate?: Date
}

export const FormTimesheetLockingModal = ({
  handleClose,
  item,
  setAutoHideFeedback,
  refetch
}: FormTimesheetLockingModalProps) => {
  const initialValues: FormValues = {
    LockDate: new Date(item?.LockDate)
  }
  const [loading, setLoading] = useState(false)
  const [editLockDatePayrollClassification] = useMutation<
    EditLockDatePayrollClassificationMutation,
    EditLockDatePayrollClassificationMutationVariables
  >(EDIT_LOCK_DATE_PAYROLL_CLASSIFICATION)
  const classes = useStyles()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async ({ LockDate }, { resetForm }) => {
        setLoading(true)
        editLockDatePayrollClassification({
          variables: {
            input: {
              PayrollClassificationID: item?.ID || '',
              LockDate: formatIsoDate(LockDate as Date)
            }
          }
        })
          .then(() => {
            resetForm()
            handleClose()
            setAutoHideFeedback(
              getSuccessFeedback('The timesheets for this pay group are now locked up to the given date')
            )
            setLoading(false)
            refetch()
          })
          .catch((error: any) => {
            setAutoHideFeedback(getErrorFeedback(error.message))
            setLoading(false)
          })
      }}
    >
      {props => {
        const { values, errors, touched, handleSubmit, setFieldValue } = props
        return (
          <form onSubmit={handleSubmit}>
            <Dialog
              open={!!item}
              TransitionComponent={Transition}
              keepMounted
              fullWidth
              onClose={handleClose}
              aria-labelledby="delete-site-diary-dialog-title"
              aria-describedby="delete-site-diary-dialog-description"
            >
              <DialogContent>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" className={classes.plantTitle}>
                      Company
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    {item?.company?.Name}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" className={classes.plantTitle}>
                      Name
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    {item?.ClassificationName}
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="body1" className={classes.plantTitle}>
                      Lock Date
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <DatePickerField
                      maxDate={subDays(new Date(), 1)}
                      date={values?.LockDate}
                      format={DDMY}
                      inputStyle={{ width: '100%' }}
                      inputVariant="outlined"
                      onDateChange={date => {
                        setFieldValue('LockDate', date)
                      }}
                      type="keyboard"
                      error={!!errors.LockDate && !!touched.LockDate}
                      helperText={touched.LockDate ? errors.LockDate : null}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="contained">
                  Cancel
                </Button>
                <Button
                  disabled={loading}
                  color="secondary"
                  variant="contained"
                  type="submit"
                  onClick={() => handleSubmit()}
                  aria-label="button-modal-confirm"
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )
      }}
    </Formik>
  )
}
