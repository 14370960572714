import React from 'react'
import { GetAllProjectsQuery, GetAllProjectsQueryVariables, Project } from '../../generated/graphql'
import { EntitySelectorWrapperProps } from './EntitySelector'
import { InfiniteSelector } from './InfiniteSelector'
import { GET_ALL_PROJECTS } from 'graphql/common'
import { WatchQueryFetchPolicy } from 'apollo-boost'

const compileData = (data: GetAllProjectsQuery) => {
  return {
    data: data?.getAllProjects?.items || [],
    pagination: data?.getAllProjects?.pagination
  }
}

export type ProjectSelectorProps = EntitySelectorWrapperProps<
  Project,
  GetAllProjectsQuery,
  GetAllProjectsQueryVariables
> & {
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  extractData: (data: Project[]) => Project[]
}

const labelEntity = ({ Name }: Project) => Name

const fetchPolicy: WatchQueryFetchPolicy = 'network-only'

const queryHookOptions = {
  variables: { IsActiveOnly: true },
  fetchPolicy: fetchPolicy
}
export const labeller = (value: Project) => `${value.Name} (${value.ProjectNo}) - ${value.company?.Name}`

export const AllProjectsActiveSelector = ({ label = 'Add Job', extractData, ...props }: ProjectSelectorProps) => {
  return (
    <InfiniteSelector<Project>
      label={label}
      labelEntity={labelEntity}
      {...props}
      labeller={labeller}
      extractData={extractData}
      compileData={compileData}
      queryHookOptions={queryHookOptions}
      query={GET_ALL_PROJECTS}
    />
  )
}
