import { Grid, RadioGroup, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { CMSCreatorValues, SettingTheme } from 'pages/Publication/cms/types'
import React from 'react'
import { SettingTemplate } from './SettingTemplate'

export default function SettingToolCreator() {
  const { values, setFieldValue } = useFormikContext<CMSCreatorValues>()

  return (
    <RadioGroup
      value={values?.setting?.theme ?? ''}
      onChange={(_, value) => {
        setFieldValue('setting.theme', value)
      }}
    >
      <Typography className="text-base font-semibold mb-2">Select Theme</Typography>
      <Grid container className="w-full" spacing={2}>
        {[SettingTheme.DarkMode, SettingTheme.LightMode].map(theme => (
          <Grid key={theme} item xs={12} lg={6} xl={4}>
            <SettingTemplate theme={theme} />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  )
}
