import { ReleaseNoteInput, ReleaseNotes } from 'generated/graphql'
import { formatDateInYYYYMMDD } from 'utils/dates'
import { EditModalFormProps, PlatformType } from '../types'

export const formatUpdateReleaseNoteForClient = (selectedItem: ReleaseNotes | null): EditModalFormProps => ({
  releaseNoteID: selectedItem?.ID || '',
  releaseNote: selectedItem?.ReleaseNotesDescription || '',
  category: selectedItem?.CategoryName || '',
  externalURL: selectedItem?.ExternalURL || '',
  releaseDate: selectedItem?.ReleaseDate ? new Date(selectedItem?.ReleaseDate) : new Date(),
  platform: selectedItem?.Platform ? { ID: selectedItem?.Platform, label: selectedItem?.Platform } : null
})

export const formatUpdateReleaseNoteForGraphql = (values: EditModalFormProps): ReleaseNoteInput => ({
  ReleaseNoteID: values.releaseNoteID.trim(),
  ReleaseNotesDescription: values.releaseNote.trim(),
  ReleaseDate: values.releaseDate && formatDateInYYYYMMDD(values.releaseDate),
  CategoryName: values.category.trim(),
  ExternalURL: values.externalURL.trim(),
  Platform: values?.platform?.label ?? PlatformType.Web
})
