import { Box, styled, TextFieldProps, TextField, withStyles, Grid, Typography, GridSize } from '@material-ui/core'
import { BaseDatePickerProps, DatePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { BasePickerProps } from '@material-ui/pickers/typings/BasePicker'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import React, { CSSProperties, ReactNode } from 'react'
import { DDMY, isValidDate } from '../../utils/dates'
import { HelpIconButtonTooltip } from './mui'
import { useGlobalStyles } from 'hooks/useGlobalStyles'
import { CalendarIcon } from './icons'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

const DarkerDisabledTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black'
    }
  }
})(TextField)

const TextFieldComponent = (props: TextFieldProps) => {
  return <DarkerDisabledTextField {...props} disabled={true} color="secondary" />
}

const Boxed = styled(Box)({
  whiteSpace: 'nowrap',
  alignItems: 'center'
})

type PickerFormat =
  | 'eee d MMM y' // SHORT_DATE
  | 'eee dd/MM/yyyy' // DDMY
type PickertType = 'keyboard' | undefined
interface DatePickerNavigatorProps extends Pick<BasePickerProps, 'onError'>, BaseDatePickerProps {
  date?: Date
  format?: PickerFormat
  inputStyle?: CSSProperties
  inputVariant?: TextFieldProps['variant']
  maxDate?: ParsableDate
  minDate?: ParsableDate
  nextDayDisabled?: boolean
  previousDayDisabled?: boolean
  onDateChange?: (date: Date) => void
  maxDateMessage?: ReactNode
  minDateMessage?: ReactNode
  type?: PickertType
  label?: string
  disabled?: boolean
  error?: boolean
  helperText?: ReactNode
  className?: string
  textFieldBreakpoints?: Partial<Record<Breakpoint, boolean | GridSize>>
  isVersion2?: boolean
  isRequired?: boolean
  tooltipDescription?: ReactNode | string
  readOnly?: boolean
  placeholder?: string
}
export const DatePickerField = ({
  date,
  disablePast,
  format = DDMY,
  inputStyle: style,
  inputVariant,
  maxDate,
  minDate,
  maxDateMessage,
  minDateMessage,
  onDateChange,
  onError,
  type,
  label,
  disabled = false,
  error,
  helperText,
  className,
  isVersion2 = false,
  isRequired = false,
  textFieldBreakpoints = { xs: 12 },
  tooltipDescription,
  placeholder
}: DatePickerNavigatorProps) => {
  const classes = useGlobalStyles()
  const selectedDate = date ? date : null
  const pickerProps = {
    disablePast,
    format,
    style,
    inputVariant,
    onError,
    onChange: (date: MaterialUiPickersDate) => {
      if (isValidDate(date)) {
        onDateChange && onDateChange(date)
      }
    },
    value: selectedDate,
    maxDate,
    minDate,
    maxDateMessage,
    minDateMessage
  }

  return (
    <Grid item {...textFieldBreakpoints} className={isVersion2 ? classes.roundedInput : ''}>
      {isVersion2 ? (
        <Box display="flex" alignItems="center">
          <Typography className="font-semibold text-sm color-neutral-700 mb-1 d-block">
            {label} {isRequired && <span className="primary-color-500"> *</span>}
          </Typography>
          {!!tooltipDescription && (
            <HelpIconButtonTooltip imgSize={16} className="ml-2 mb-1" title={tooltipDescription} />
          )}
        </Box>
      ) : null}
      <Boxed>
        {type && type === 'keyboard' ? (
          <KeyboardDatePicker
            autoOk
            placeholder={placeholder}
            className={`${className} ${!disabled && isVersion2 ? 'keyboardTextColor' : ''}`}
            label={isVersion2 ? '' : label}
            disabled={disabled}
            variant="inline"
            error={error}
            helperText={helperText}
            {...pickerProps}
            keyboardIcon={isVersion2 ? <CalendarIcon color="var(--color-neutral-500)" /> : undefined}
            TextFieldComponent={TextFieldComponent}
            fullWidth
          />
        ) : (
          <DatePicker
            autoOk
            placeholder={placeholder}
            className={className}
            label={isVersion2 ? '' : label}
            disabled={disabled}
            variant="inline"
            error={error}
            helperText={helperText}
            {...pickerProps}
            fullWidth
          />
        )}
      </Boxed>
    </Grid>
  )
}
