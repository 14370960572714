import { Chip } from '@material-ui/core'
import React from 'react'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { ActionsType } from 'pages/Publication/types'
import { useReleaseNotesCommonStyles } from 'pages/Publication/styles'

interface StatusIconProps {
  status: string
}

export default function StatusIcon({ status }: StatusIconProps) {
  const classes = useReleaseNotesCommonStyles({})
  return (
    <Chip
      icon={
        <FiberManualRecordIcon
          classes={{
            fontSizeSmall: classes.fontSizeSmall
          }}
          fontSize="small"
        />
      }
      label={status}
      variant="outlined"
      color={status === ActionsType.Published ? 'secondary' : 'primary'}
      classes={{
        colorPrimary: classes.colorPrimary,
        colorSecondary: classes.colorSecondary
      }}
    />
  )
}
