import { SupplierIntegration } from 'generated/graphql'
import { ClipboardEvent, KeyboardEvent } from 'react'
import { checkIsValidCharacter } from 'utils/tools'
import { KeyDownProps } from '../types'
import { TAB_CHAR_CODE } from 'utils/Constants'

export const handlePasswordVisibleSecurity = (password?: string) => {
  if (!password) return ''

  return password.replaceAll('*', '•')
}

export const getHolcimConfigTitle = (supplierIntegration: SupplierIntegration | null) => {
  if (!supplierIntegration) return ''

  const project = supplierIntegration.project

  return `${project?.Name} (${project?.ProjectNo}) - ${project?.company?.Name}`
}

export const onKeyDownHandler = ({ field, isInitValue, setFieldValue, setIsInitValue }: KeyDownProps) => (
  event: KeyboardEvent<HTMLDivElement>
) => {
  if (event.which === TAB_CHAR_CODE) return

  if (isInitValue) {
    setFieldValue(field, checkIsValidCharacter(event.which) ? event.key : '')
    setIsInitValue(false)
    event.preventDefault()
  }
}

export const onPasteHandler = ({ field, isInitValue, setFieldValue, setIsInitValue }: KeyDownProps) => (
  event: ClipboardEvent<HTMLDivElement>
) => {
  if (isInitValue) {
    setFieldValue(field, event.clipboardData.getData('text'))
    setIsInitValue(false)
    event.preventDefault()
  }
}
