import { RewriteFrames } from '@sentry/integrations'

const { REACT_APP_SENTRY_DSN, REACT_APP_NODE_ENV } = process.env

export const SentryCredentials = {
  dsn: REACT_APP_SENTRY_DSN as string,
  environment: (REACT_APP_NODE_ENV as string) || 'local',
  integrations: [new RewriteFrames()],
  ignoreErrors: [
    /UnhandledRejection: Non-Error promise rejection captured with value: No current user/,
    /Failed to fetch dynamically imported module/,
    /The notification permission was not granted and blocked instead/,
    /The security token included in the request is expired/,
    /Cannot retrieve a new session/
  ]
}
