import { GET_FILE } from 'graphql/common'
import { Column } from 'material-table'
import { KeyboardEvent } from 'react'
import { GetFileProps } from './types'
import * as EmailValidator from 'email-validator'

export const PHONE_NUMBER_REGEX = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm

export const customFilterAndSearchColumn = <T>(filter: string, rowData: T, extractFilter: (rowData: T) => string) =>
  extractFilter(rowData)
    .toLowerCase()
    .indexOf(filter.toLowerCase()) > -1

export const isEmptyHTML = (value: string) => value.replace(/<(.|\n)*?>/g, '').trim().length === 0

export const formatHTMLSpace = (value: string) => value?.replace(/<p><br><[/]p>/g, '')

export const getUrl = (value: string) => {
  if (value.includes('http://') || value.includes('https://')) return value
  return `https://${value}`
}

export const validateNumericOnly = (event: KeyboardEvent<HTMLDivElement>): void => {
  const keyCode = event.which ? event.which : event.keyCode // keyCode is deprecated but needed for some browsers

  if (keyCode === 69 || keyCode === 171 || keyCode === 189 || keyCode === 107 || keyCode === 109 || keyCode === 187) {
    event.preventDefault()
  }
}
export const scrollToTop = () => {
  try {
    document.getElementById('container')?.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (error) {
    const container = document.getElementById('container')
    if (container) {
      container.scrollTop = 0
    }
  }
}

export const getLocalStorageItem = (key: string) => {
  if (typeof window !== 'undefined') return localStorage.getItem(key)
}

export const setLocalStorageItem = (key: string, value: string) => {
  if (typeof window !== 'undefined') return localStorage.setItem(key, value)
}

export const JSONParse = <T>(text: string): T | undefined => {
  if (text) {
    try {
      return JSON.parse(text) as T
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error((error as Error).toString())
    }
  }
  return undefined
}

export const clickToDownloadTemplate = (key: string) => {
  if (!key) return

  const aElement = document.createElement('a')
  aElement.href = key
  aElement.click()
  aElement.remove()
}

export const downloadURI = (uri: string, name: string) => {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.target = '_blank'
  link.href = uri
  // the filename you want
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const handleDownloadFile = async (fileKey: string, getFileUrl: GetFileProps) => {
  try {
    const {
      data: {
        getFile: { Url, FileName }
      }
    } = await getFileUrl({
      query: GET_FILE,
      variables: {
        fileKey: fileKey
      }
    })
    downloadURI(Url, FileName)
  } catch (_) {}
}

export const getTextInHTMLString = (htmlString = '') => htmlString.replace(/<[^>]+>/g, '')

export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v?.toString(16)
  })
}

export const removeEmptyElements = (str: string) => {
  const regex = /<([A-z]+)([^>^/]*)><br><\/\1>\s*/gi
  return str.replace(regex, '')
}

export const getLimitString = (str: string, limit: number) => {
  if (str?.length > limit) return `${str.substring(0, limit)}...`
  return str
}

export const copyFromClipboard = (url: string) => {
  navigator.clipboard.writeText(url)
}

export const convertUTF8ToBase64 = (text: string) => btoa(unescape(encodeURIComponent(text)))
export const convertBase64toUTF8 = (text: string) => decodeURIComponent(escape(window.atob(text.replace(/\s/g, ''))))

export const emailValidator = (value?: string | null) => {
  if (!value) return true

  return EmailValidator.validate(value)
}
export const getFieldSorter = <T extends object>(columnIndex: number, sortFields: Column<T>[]) => {
  const result = sortFields[columnIndex]
  return result && result.field
}

const getArrayFromRange = (min = 0, max = 0) => {
  const newArray = []
  for (let i = min; i <= max; i++) {
    newArray.push(i)
  }
  return newArray
}

export const checkIsValidCharacter = (charCode: number) =>
  [...getArrayFromRange(48, 90), ...getArrayFromRange(186, 223)].includes(charCode)

export const hasWhiteSpace = (text: string) => text.indexOf(' ') >= 0
