import React, { useMemo } from 'react'
import { TextFieldProps } from '@material-ui/core'
import { PlatformType } from 'pages/Publication/types'
import { Selector } from './EntitySelector'

export interface PlatFormOptions {
  ID: string
  label: string
}

interface Props {
  onChange: (newValue?: PlatFormOptions | null) => void
  value: PlatFormOptions | null
  label: string
  clearOnBlur?: boolean
  blurOnSelect?: boolean
  excludesIDs?: string[]
  error?: TextFieldProps['error']
  helperText?: TextFieldProps['helperText']
  disabled?: boolean
}

const options: PlatFormOptions[] = [
  {
    ID: PlatformType.Web,
    label: PlatformType.Web
  },
  {
    ID: PlatformType.Mobile,
    label: PlatformType.Mobile
  },
  {
    ID: PlatformType.Both,
    label: PlatformType.Both
  }
]

export const PlatformSelector = ({ value, onChange, label = 'Platform*', excludesIDs, ...props }: Props) => {
  const optionsSelector = useMemo(() => {
    return options.filter(item => !excludesIDs?.find(e => item.ID === e))
  }, [excludesIDs])

  return (
    <Selector<PlatFormOptions>
      {...props}
      label={label}
      value={value}
      labelEntity={item => item.label}
      options={optionsSelector}
      onChange={onChange}
    />
  )
}
