import { Box, IconButton, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { decreaseMonth, increaseMonth } from 'utils/commonFuncs'
import { months } from 'utils/Constants'
import { ResMonth } from 'utils/types'
import { NavigateBeforeIcon, NavigateNextIcon } from './icons'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    border: '1px solid #c4c4c4',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '0 0.5rem',
    height: '2.55rem'
  },
  textColor: {
    color: '#000000ab'
  },
  textContent: {
    color: '#000000ab',
    margin: 0,
    fontWeight: 600,
    minWidth: '7rem',
    display: 'flex',
    justifyContent: 'center'
  }
})

interface MonthNavigatorProps {
  onChange?: (value: ResMonth) => void
}

const MonthNavigator = ({ onChange }: MonthNavigatorProps) => {
  const classes = useStyles()
  const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth())
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear())

  const handleChangeMonth = (isIncrease: boolean) => {
    isIncrease
      ? increaseMonth(currentMonth, setCurrentMonth, setCurrentYear)
      : decreaseMonth(currentMonth, setCurrentMonth, setCurrentYear)
  }

  useEffect(() => {
    onChange && onChange({ monthIndex: currentMonth, monthName: months[currentMonth], year: currentYear })
    // eslint-disable-next-line
  }, [currentMonth, currentYear])

  return (
    <Box className={classes.box}>
      <IconButton aria-label="previous month" color="primary" onClick={() => handleChangeMonth(false)}>
        <NavigateBeforeIcon className={classes.textColor} />
      </IconButton>
      <Typography variant="subtitle1" className={classes.textContent} gutterBottom noWrap>
        {months[currentMonth]} {currentYear}
      </Typography>
      <IconButton aria-label="next month" color="primary" onClick={() => handleChangeMonth(true)}>
        <NavigateNextIcon className={classes.textColor} />
      </IconButton>
    </Box>
  )
}

export default MonthNavigator
