import React from 'react'
import { FormControl, RadioGroup, RadioGroupProps } from '@material-ui/core'

export const FormRadioGroup = ({
  className,
  row,
  name,
  value,
  defaultValue,
  onChange: handleChange,
  children
}: RadioGroupProps) => (
  <FormControl component="fieldset">
    <RadioGroup
      className={className}
      row={row}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
    >
      {children}
    </RadioGroup>
  </FormControl>
)
