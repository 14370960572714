import { useApolloClient } from '@apollo/react-hooks'
import { GetTaggedResourcesQuery, GetTaggedResourcesQueryVariables, Maybe, TaggedResource } from 'generated/graphql'
import { GET_TAGGED_RESOURCES } from './graphql'

export const replacetagIDsText = (matchName: Record<string, TaggedResource>, message?: Maybe<string>) => {
  if (!message) return message
  for (const tagID in matchName) {
    message = message.replace(
      tagID,
      `<span style="font-weight:bold;font-style:italic">${matchName[tagID].Name ?? tagID}</span>`
    )
  }
  return `<span>${message}</span>`
}

export const getTagIDsInMesssage = (message?: Maybe<string>) => {
  if (!message) return []
  const found = message.match(/JOB_#\d+|USER_#\d+|ROLE_#\d+|TSLT_#\d+/g)
  return found ?? []
}

export const useGetTaggedResources = () => {
  const client = useApolloClient()

  const getTaggedResources = async (TaggedResourceIDs: string[]) => {
    return client.query<GetTaggedResourcesQuery, GetTaggedResourcesQueryVariables>({
      query: GET_TAGGED_RESOURCES,
      variables: {
        TaggedResourceIDs: TaggedResourceIDs
      }
    })
  }

  return getTaggedResources
}
