import { styled } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export const LoadingSkeleton = styled(Skeleton)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%'
})
