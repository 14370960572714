import { Mark } from '@material-ui/core'
import { CMSContentValues, Devices, HeaderBackgroundOptions } from '../types'
import { uuidv4 } from 'utils/tools'
import { UPLOAD_IMAGE_COUNT } from 'components/common/UploadImageCountSelector'
import { EditIcon, TvIcon } from 'components/common/icons'

export const BG_COLOR_HEIGHT_SLIDER: Mark[] = [
  { label: '0%', value: 0 },
  { label: '50%', value: 50 },
  { label: '100%', value: 100 }
]

export const defaultContentSection: CMSContentValues = {
  id: uuidv4(),
  isExpanded: true,
  isLayoutExpanded: true,
  description: '',
  secondDescription: '',
  images: [],
  imagePreviews: [],
  textInImages: [],
  maximumImage: UPLOAD_IMAGE_COUNT[0],
  headerBackgroundOption: HeaderBackgroundOptions.Image,
  backgroundColor: '',
  backgroundColorHeight: 50,
  sectionName: '',
  imagesPerRow: 1,
  size: { ID: 'Default', label: 'Default' },
  position: { ID: 'Centre', label: 'Centre' }
}

export const DEVICES = [
  { label: 'Preview', title: Devices.Desktop, icon: TvIcon },
  { label: 'Edit', title: Devices.Tablet, icon: EditIcon }
]

export const PRESET_COLORS = [
  '#ff0000',
  '#3c8a94',
  '#85bfbd',
  '#807948',
  '#404040',
  '#bfbfbf',
  '#4D4D4D',
  '#999999',
  '#FFFFFF',
  '#F44E3B',
  '#FE9200',
  '#FCDC00',
  '#DBDF00',
  '#A4DD00',
  '#68CCCA',
  '#73D8FF',
  '#AEA1FF',
  '#FDA1FF',
  '#333333',
  '#808080',
  '#cccccc',
  '#D33115',
  '#E27300',
  '#FCC400',
  '#B0BC00',
  '#68BC00',
  '#16A5A5',
  '#009CE0',
  '#7B64FF',
  '#FA28FF',
  '#000000',
  '#666666',
  '#B3B3B3',
  '#9F0500',
  '#C45100',
  '#FB9E00',
  '#808900',
  '#194D33',
  '#0C797D',
  '#0062B1',
  '#653294',
  '#AB149E'
]
