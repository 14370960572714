import { GET_ADMIN_COUNTRIES } from 'graphql/common'
import React, { FC } from 'react'
import { WatchQueryFetchPolicy } from 'apollo-boost'
import { Country, GetAdminCountriesQuery, GetAdminCountriesQueryVariables } from '../../generated/graphql'
import { EntitySelector, EntitySelectorWrapperProps } from './EntitySelector'

export type CountriesSelectorProps = EntitySelectorWrapperProps<
  Country,
  GetAdminCountriesQuery,
  GetAdminCountriesQueryVariables
> & {
  extractEntities?: (data: GetAdminCountriesQuery) => Country[]
  blurOnSelect?: boolean
  clearOnBlur?: boolean
  refetch?: boolean
}

const labelEntity = ({ CountryName, CountryPhoneCode }: Country) =>
  CountryPhoneCode ? `${CountryName} ( ${CountryPhoneCode} )` : CountryName

const extractEntities = (data: GetAdminCountriesQuery) => data.getAdminCountries

export const CountriesSelector: FC<CountriesSelectorProps> = ({ label = 'Select Country', ...props }) => {
  const fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
  return (
    <EntitySelector<Country, GetAdminCountriesQuery, GetAdminCountriesQueryVariables>
      label={label}
      labelEntity={labelEntity}
      extractEntities={extractEntities}
      query={GET_ADMIN_COUNTRIES}
      queryHookOptions={{
        fetchPolicy: fetchPolicy,
        variables: {
          IsActiveOnly: true
        }
      }}
      {...props}
    />
  )
}
