import { Box, Button, Typography } from '@material-ui/core'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { SortableList } from 'components/common/dnd-kit/sortable-list/SortableList'
import { AddIcon } from 'components/common/icons'
import { FieldArray, useFormikContext } from 'formik'
import { defaultContentSection } from 'pages/Publication/cms/constants'
import { CMSCreatorValues } from 'pages/Publication/cms/types'
import React from 'react'
import { uuidv4 } from 'utils/tools'
import ContentSortableList from '../../ContentSortableList'

export default function ContentToolCreator() {
  const { values, setFieldValue } = useFormikContext<CMSCreatorValues>()

  return (
    <FieldArray
      name="content"
      render={props => (
        <Box>
          <GridLayoutInput label="News Title" name="title" isVersion2 isRequired className="mb-4" />
          <Button
            className="mb-2 w-full rounded-md border-2 color-neutral-900 border-neutral-900"
            size="medium"
            variant="outlined"
            onClick={() => {
              props.push({ ...defaultContentSection, id: uuidv4() })
            }}
          >
            <AddIcon />
            <Typography className="text-base font-semibold">Add New Section</Typography>
          </Button>
          <SortableList
            items={values?.content}
            onChange={(value, overIndex) => {
              overIndex !== 0 && setFieldValue('content', value)
            }}
            renderItem={(section, sectionIndex) => (
              <ContentSortableList section={section} sectionIndex={sectionIndex} fieldArrayProps={props} />
            )}
          />
        </Box>
      )}
    />
  )
}
